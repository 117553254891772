import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, retry, catchError } from 'rxjs';
import { BoxxResponse } from '../entities/boxx-response';
import { BaseService } from './base-services';

@Injectable({
  providedIn: 'root',
})
export class PolicyPeriodNotesService extends BaseService<any> {
  constructor(http: HttpClient) {
    const baseurl =
      environment.msPolicyBaseUrl + environment.policyPeriodNotesUrl;
    super(http, baseurl);
  }

  getByPolicyPeriodId(
    policyPeriodId: string,
    page: number = 1,
    limit = 100,
  ): Observable<any> {
    const url = `${this.baseurl}?policyPeriodId=${policyPeriodId}&page=${page}&limit=${limit}&active=1&sort=createdDt%3Adesc`;

    return this.http
      .get<BoxxResponse<any>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  getByPolicyPeriodNotesFilter(
    policyPeriodId: string,
    queryParams: any,
    page: number = 1,
    limit = 100,
  ): Observable<any> {
    let url = `${this.baseurl}?policyPeriodId=${policyPeriodId}&page=${page}&limit=${limit}&active=1&sort=createdDt%3Adesc`;
    // Append additional queryParams to the URL
    for (const key in queryParams) {
      if (
        queryParams.hasOwnProperty(key) &&
        queryParams[key] !== undefined &&
        queryParams[key] !== null &&
        queryParams[key] !== ''
      ) {
        url += `&${key}=${queryParams[key]}`;
      }
    }

    return this.http
      .get<BoxxResponse<any>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  getNotesUnderWriterList(
    policyPeriodId: number,
    page: number = 1,
    limit = 100,
  ): Observable<any> {
    let url = `${this.baseurl}writer/?policyPeriodId=${policyPeriodId}&page=${page}&limit=${limit}&active=1&sort=createdDt%3Adesc`;

    return this.http
      .get<BoxxResponse<any>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  GetAllNotesByFilter(
    filter,
    page = 1,
    limit = 10,
    sort = '',
  ): Observable<any> {
    let url = `${this.baseurl}?page=${page}&limit=${limit}&active=${filter.active}&noteParentId=${filter.noteParentId}&sort=createdDt%3Adesc`;
    if (filter?.policyPeriodId) {
      url += '&policyPeriodId=' + filter?.policyPeriodId;
    }

    if (filter?.trxType) {
      url += '&trxType=' + filter?.trxType;
    }
    if (filter?.scopeType) {
      url += '&scopeType=' + filter.scopeType;
    }
    if (filter?.quoteOptionId) {
      url += '&quoteOptionId=' + filter.quoteOptionId;
    }
    if (filter?.note) {
      url += '&note=' + filter.note;
    }
    if (filter?.active) {
      url += '&active=' + filter.active;
    }
    if (filter?.createdBy) {
      url += '&createdBy=' + filter.createdBy;
    }
    if (filter?.updatedBy) {
      url += '&updatedBy=' + filter.updatedBy;
    }
    if (filter?.writerId) {
      url += '&writerId=' + filter.writerId;
    }
    if (filter?.startDate) {
      url += '&startDate=' + filter.startDate;
    }
    if (filter?.endDate) {
      url += '&endDate=' + filter.endDate;
    }
    if (sort) {
      url += '&sort=' + sort;
    }

    return this.http
      .get<BoxxResponse<any>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
