<div class="plc-timeline-container">
  <ng-container *ngIf="showBodySpinner">
    <div class="body-spinner">
      <boxxds-spinner
        [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
      ></boxxds-spinner>
    </div>
  </ng-container>
  <div class="plc-timeline-contents" *ngIf="!showBodySpinner">
    <div class="plc-timeline-header">
      <div class="timeline-header">
        <div class="timeline-heading">
          <div class="main-heading">
            <div class="heading">
              {{ policyTimelineHeading.replace("{product}", product) }}
            </div>
            <div class="badge">
              {{ totalRecords }}
              {{ "PolicyLifeCycleTimeLine.records" | translate }}
            </div>
          </div>
          <div class="description">
            {{ policyTimelineSubHeading.replace("{product}", product) }}
          </div>
        </div>
        <div class="plc-timeline-buttons">
          <div
            class="filter-content"
            [ngClass]="{ 'filter-slider': filterSlide }"
          >
            <div class="filter-options" *ngFor="let filter of filteredOptions">
              <div class="option">
                {{ filter.value }}
                <button
                  type="button"
                  aria-label="close"
                  class="close-btn"
                  (click)="handleFilterOptnRemove(filter)"
                >
                  <img src="assets/img/dashboard/quote/remove.png" />
                </button>
              </div>
            </div>
          </div>
          <div class="timeline-buttons">
            <boxxds-dropdown-v2
              [placeholder]="selectedPolicyPeriodYear"
              [options]="periodOptions"
              [isReadonly]="!isActive"
              [control]="'period'"
              class="input-fields"
              [requiredErrMsg]="
                'workFlow3.components.brokerCommissionPopup.error.requiredErrMsg'
                  | translate
              "
              [formSubmitted]="submitted"
              [isDisabled]="!isActive"
              [isRequired]="true"
              [btnStyle]="{
                width: '187px',
                height: '36px',
                alignItems: 'end'
              }"
              [dropdownContainerStyle]="{ marginTop: '-8px' }"
              [class.noPointerEvents]="!this.permissionList[this.currentScreen]"
              [currentValue]="selectedPolicyPeriodYear"
              (currentValueChange)="changePolicyPeriod($event)"
            >
            </boxxds-dropdown-v2>

            <boxxds-button-v2
              [isBtnActive]="showFilter"
              [leftImage]="'assets/img/workflow3/filter-funnel.svg'"
              btnType="unfilled"
              [btnClass]="showFilter ? 'secondary' : 'secondary gray'"
              [btnStyle]="{
                width: '36px',
                background: '#fff',
                height: '36px',
                marginLeft: '8px'
              }"
              (handleClick)="handleFilter()"
            >
              <img src="assets/img/workflow3/filter-funnel.svg" />
            </boxxds-button-v2>
            <boxxds-button-v2
              sz="sm"
              [isBtnActive]="showFilter"
              [btnClass]="'secondary gray'"
              [buttonText]="''"
              [leftImage]="'download-01'"
              [type]="'submitted'"
              [btnStyle]="{
                width: '36px',
                margin: '0px 16px 0 8px',
                height: '36px'
              }"
              (handleClick)="handleExport()"
            >
            </boxxds-button-v2>
          </div>
        </div>
      </div>
    </div>
    <div class="plc-timeline-table" [class.spinner-active]="showTblSpinner">
      <boxxds-table-v2
        id="branch-table"
        class="branch-table"
        [tableData]="tableData"
        [sortableColumns]="sortableColumns"
        [iconColumns]="iconColumns"
        (sortHandler)="handleTableColSort($event)"
        [showPopOver]="true"
        [isRowClickable]="true"
        [columnHeadsToHide]="columnHeadsToHide"
        [columnsToHide]="columnsToHide"
        (pageChanged)="pageChangeHandler($event)"
        [showSpinner]="showTblSpinner"
        (ellipsisHandler)="trxViewClickHandler($event)"
        [tableContainerStyle]="{
          maxHeight: '550px',
          minHeight: 'max-content',
          paddingBottom: '5px',
          borderRadius: '12px 12px 16px 16px'
        }"
        [ngClass]="{
          'pointer-default': !this.permissionList[this.currentScreen],
          verticalEllipsis: true
        }"
        [totalCount]="totalRecords"
        (pageChanged)="pageChangeHandler($event)"
        [activePageNumber]="currentPage"
        [iconColumns]="iconColumns"
        [showViewIcon]="!this.permissionList[this.currentScreen]"
        [showEditIcon]="this.permissionList[this.currentScreen]"
        [showEllipsisIcon]="true"
        [verticalEllipsis]="true"
      >
      </boxxds-table-v2>
    </div>

    <div class="filter">
      <app-dynamic-filter-v2-modal
        *ngIf="showFilter"
        [filterTitle]="'workFlow3.branchProfile.label.filter' | translate"
        [filterTitleDescription]="
          'PolicyLifeCycleTimeLine.applyFilters' | translate
        "
        (handleClose)="handleCloseFilterPopup($event)"
        [filterInputOptions]="filterInputOptions"
        [filterFormGroup]="filterFormGroup"
        (handleSubmit)="handleApplyFilter($event)"
        (handleFilterClear)="handleFilterClear($event)"
        [clearAllBtn]="true"
      >
      </app-dynamic-filter-v2-modal>
    </div>

    <div class="slide-out">
      <app-slideout-popup-base
        *ngIf="showSlideOut"
        [isPopupOpen]="showSlideOut"
        (handleClose)="handleSlideOutClose()"
      >
        <app-policy-slideout-v2
          [details]="details"
          [riskTrxTypes]="riskTrxTypes"
        ></app-policy-slideout-v2>
      </app-slideout-popup-base>
    </div>
  </div>
</div>
