import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { BoxxResponse, Pagination } from 'src/app/entities/boxx-response';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';
import { PermissionService } from 'src/app/services/permission.service';
import { getErrorMessage } from 'src/app/utils/utils';
import { Permission } from 'src/app/entities/permission';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import {
  getDashboardSelector,
  getUserTypes,
} from 'src/app/store/dashboard/dashboard.selector';
import { Store, select } from '@ngrx/store';
@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.less'],
})
export class PermissionsComponent implements OnInit {
  form: FormGroup;
  itemsMenu: Breadcrumb[] = [
    { label: 'Roles & permissions', path: '/dashboard/security/roles' },
    { label: 'List of permissions', path: null },
  ];
  permissions: any = [];
  expandableData: any = [];
  permissionOptions: Array<any> = [];
  totalDataCount = 0;
  isSearchResult: boolean = false;
  pagination: Pagination;
  addSortTo: string | Array<string> = 'All';
  sortBy: string;
  sortColumn: string = 'id';
  sortDirection: string = 'desc';
  pageNumber: number = 1;
  searchKey: string = '';
  showTblSpinner: boolean = false;
  showErrorAlert = false;
  msgErrorAlert = '';
  userID: number;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  userTypes: Array<any> = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private permissionService: PermissionService,
    private localStorageService: LocalStorageService,
    private store: Store,
  ) {
    this.form = fb.group({
      searchBy: new FormArray([]),
      searchKey: [''],
    });
    this.sortBy = this.sortColumn + ':' + this.sortDirection;
    this.addSortTo = [
      'Permission name',
      'System user type',
      'Screen',
      'Number of roles assigned',
    ];
  }

  ngOnInit(): void {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
    });
    this.store.pipe(select(getUserTypes)).subscribe(async (data) => {
      const dropdownData = data.map((dataObj) => ({
        key: dataObj.description,
        value: dataObj.id,
        id: dataObj.id,
      }));
      this.userTypes = dropdownData;
    });
    this.fetchPermissions();
    this.userID = this.localStorageService.getBoxxUserId();
  }

  fetchPermissions() {
    this.showTblSpinner = true;
    this.permissionService
      .GetAllPermissions(this.searchKey, 1, this.pageNumber, 10, this.sortBy)
      .subscribe({
        next: (response) => {
          this.showTblSpinner = false;
          this.setSearchResult(response);
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.showErrorAlert = true;
          }
          this.msgErrorAlert = getErrorMessage(error);
        },
      });
  }

  setSearchResult(data: BoxxResponse<Permission>) {
    const tableData = data.data?.map((dataObj) => {
      const { id, Screen, permissionName } = dataObj;
      const description = Screen ? Screen.description : '';
      return {
        'Permission name': permissionName,
        'System user type': this.getType(dataObj.systemUserType),
        Screen: `${description}`,
        'Number of roles assigned': `${
          dataObj.roleCount ? dataObj.roleCount : 0
        }`,
        recordStatus: dataObj.active ? 'ACTIVE' : 'INACTIVE',
        ActionView: `${this.navigateToEditPermission(dataObj.id)}`,
      };
    });
    const expandableData = data.data?.map((dataObj) => {
      return {
        id: `${dataObj.id}`,
        createdDt: `${dataObj.createdDt}`,
        createdBy: dataObj.createdBy,
        updatedBy: dataObj.updatedBy,
        updatedDt: dataObj.updatedDt,
      };
    });
    this.expandableData = expandableData;
    // this.permissions = tableData.length ? tableData:"";
    this.permissions =
      tableData.length == 0 ? this.getTableHeaders() : tableData;
    this.totalDataCount = data.pagination.totalRecords;
    this.pagination = data.pagination;
  }

  getTableHeaders() {
    return [
      {
        'Permission name': '',
        'System user type': '',
        Screen: '',
        'Number of roles assigned': '',
      },
    ];
  }

  handlePageChange(pageNumber) {
    this.searchKey = this.form.value['searchKey'];
    this.pageNumber = pageNumber;
    if (this.pageNumber > 0) {
      this.permissionService
        .GetAllPermissions(this.searchKey, 1, this.pageNumber, 10, this.sortBy)
        .subscribe({
          next: (response) => {
            this.setSearchResult(response);
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    }
  }

  handleSearch(event) {
    if (event.key == 'Enter') {
      this.isSearchResult = true;
      this.showTblSpinner = true;
      this.pageNumber = 1; //added the pagenumber for sorting when the datas are list in single page
      this.searchKey = this.form.value['searchKey'];
      this.permissionService
        .GetAllPermissions(this.searchKey, 1, this.pageNumber, 10, this.sortBy)
        .subscribe({
          next: (response) => {
            this.showTblSpinner = false;
            this.setSearchResult(response);
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              this.showErrorAlert = true;
            }
            this.msgErrorAlert = getErrorMessage(error);
          },
        });
    }
  }

  navigateToAddPermission($event) {
    this.router.navigate(['dashboard/security/permissions/new'], {
      skipLocationChange: true,
    });
  }

  navigateToEditPermission(id) {
    return 'dashboard/security/permissions/edit/' + id;
  }

  mapSortColumns(key) {
    switch (key) {
      case 'Permission name':
        this.sortColumn = 'permissionName';
        break;
      case 'System user type':
        this.sortColumn = 'SystemUserType.description';
        break;
      case 'Screen':
        this.sortColumn = 'Screen.description';
        break;
      case 'Number of roles assigned':
        this.sortColumn = 'roleCount';
        break;
      default:
        this.sortColumn = 'id';
        break;
    }
  }

  handleAscSort(key) {
    if (key) {
      this.mapSortColumns(key);
    }
    this.sortDirection = 'asc';
    this.sortBy = this.sortColumn + ':' + this.sortDirection;
    this.fetchPermissions();
  }

  handleDescSort(key) {
    if (key) {
      this.mapSortColumns(key);
    }
    this.sortDirection = 'desc';
    this.sortBy = this.sortColumn + ':' + this.sortDirection;
    this.fetchPermissions();
  }

  handleRowClick(e) {
    // TODO - route to record page
  }
  getType(systemUserType) {
    let type: string = '';
    type = this.userTypes.find((item) => item.id === systemUserType)?.key;
    return type;
  }
  handleCloseSuccessEvent() {
    this.showErrorAlert = false;
  }
}
