<!-- Breadcrumbs and global search -->
<div class="header" *ngIf="showHeader">
  <div class="header-content">
    <div class="breadcrumbs-holder">
      <div class="img-holder">
        <a
          class="logo-link"
          [routerLink]="['/dashboard/home']"
          [queryParams]="{ mock: mockExternalAPIs }"
          skipLocationChange="true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M6.66667 14.1682H13.3333M9.18141 2.30492L3.52949 6.70086C3.15168 6.99471 2.96278 7.14163 2.82669 7.32563C2.70614 7.48862 2.61633 7.67224 2.56169 7.86746C2.5 8.08785 2.5 8.32717 2.5 8.8058V14.8349C2.5 15.7683 2.5 16.235 2.68166 16.5916C2.84144 16.9052 3.09641 17.1601 3.41002 17.3199C3.76654 17.5016 4.23325 17.5016 5.16667 17.5016H14.8333C15.7668 17.5016 16.2335 17.5016 16.59 17.3199C16.9036 17.1601 17.1586 16.9052 17.3183 16.5916C17.5 16.235 17.5 15.7683 17.5 14.8349V8.8058C17.5 8.32717 17.5 8.08785 17.4383 7.86746C17.3837 7.67224 17.2939 7.48862 17.1733 7.32563C17.0372 7.14163 16.8483 6.99471 16.4705 6.70086L10.8186 2.30492C10.5258 2.07721 10.3794 1.96335 10.2178 1.91959C10.0752 1.88097 9.92484 1.88097 9.78221 1.91959C9.62057 1.96335 9.47418 2.07721 9.18141 2.30492Z"
              stroke="#667085"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>
      </div>
      <div class="list-holder">
        <ul [ngStyle]="containerStyle">
          <li *ngFor="let breadcrumb of items">
            <img
              class="arrow-right"
              src="assets/img/workflow3/chevron-right.svg"
            />
            <a
              (click)="breadcrumb.path !== null && emitClickEvent($event)"
              [class.noPointerEvents]="breadcrumb.path === null"
              routerLink="{{ breadcrumb.path }}"
              skipLocationChange="true"
              >{{ breadcrumb.label }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- Breadcrumbs --->
    <div class="search-holder">
      <div class="search">
        <div class="search-dd">
          <boxxds-dropdown-v2
            [insideSearch]="true"
            [options]="dropdownOptions"
            [placeholder]="dropdownLabel"
            (currentValueChange)="dropdownValueChanged($event)"
            class="dropdown"
            [btnStyle]="{
              border: 'none',
              borderRadius: '0px',
              height: '42px',
              marginBottom: '0px',
              minWidth: '136px'
            }"
            [dropdownContainerStyle]="{ marginBottom: '0px' }"
          >
          </boxxds-dropdown-v2>
        </div>
        <div class="search-div">
          <div class="search-box">
            <input
              type="text"
              class="form-controls"
              aria-label="Text input with dropdown button"
              [required]="isRequired"
              [placeholder]="placeholder"
              [(ngModel)]="searchKey"
              autocomplete="off"
              (keyup)="onKeyUp($event)"
              (clickOutside)="closeSearchDropdown()"
              maxlength="255"
            />
            <div class="search-append" *ngIf="isRightIcon">
              <span class="search-icon">
                <img [src]="getIconSrc()" />
              </span>
            </div>
          </div>
          <div class="search-results">
            <ul
              class="search-list"
              role="listbox"
              aria-expanded="false"
              [ngClass]="{ 'dropdown-open': searchListOpen }"
            >
              <li *ngIf="inputListHdr !== ''" class="list-header">
                {{ inputListHdr }}
              </li>
              <li
                *ngFor="let o of inputOptions"
                class="search-list-item"
                tabindex="0"
                (click)="selectSuggested(o)"
                role="option"
              >
                <div>
                  <span class="suggestion">{{
                    getSuggestedValue(o?.name || "")[0]
                  }}</span
                  ><span *ngIf="hasSuggestedResults(o?.name || '')">{{
                    searchKey
                  }}</span
                  ><span class="suggestion">{{
                    getSuggestedValue(o?.name || "")[1]
                  }}</span>
                </div>
                <boxxds-body
                  sz="xxs"
                  [text]="o?.type"
                  [customStyle]="{
                    textTransform: 'uppercase',
                    color: '#8F8F91',
                    marginLeft: '2px'
                  }"
                ></boxxds-body>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Global search -->
<!-- banner -->
<div
  [ngStyle]="bannerStyle"
  class="header-banner"
  *ngIf="!showHeader && showHeaderBanner"
>
  <div class="banner-header">
    <div class="main-heading">
      <boxxds-heading
        [sz]="'5'"
        [text]="mainHeading"
        type="bold"
      ></boxxds-heading>
      <div class="badge-heading" *ngIf="badgeData !== ''">
        <span>{{ badgeData }}</span>
      </div>
    </div>
    <div class="sub-heading">
      <span>
        {{ subHeading }}
      </span>
    </div>
  </div>
  <div class="banner-button" *ngIf="isNeeded">
    <div class="button-holder">
      <boxxds-button-v2
        [size]="'lg'"
        [buttonText]="btnLabel"
        [btnClass]="btnClass"
        class="start-quote"
        (handleClick)="handleButtonClickHandler($event)"
      >
      </boxxds-button-v2>
    </div>
  </div>
</div>
