import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from './base-services';
import { Observable, retry, catchError } from 'rxjs';
import { BoxxResponse } from '../entities/boxx-response';
import { Permission } from '../entities/permission';
import { CognitoService } from './cognito-service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService extends BaseService<Permission> {
  constructor(
    http: HttpClient,
    private cognito: CognitoService,
  ) {
    const baseurl =
      environment.msSecurityBseUrl + environment.permissionPathUrl;
    super(http, baseurl);
  }

  override httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  GetAllPermissions(
    name = '',
    roleCount = 0,
    page = null,
    limit = 10,
    sort = '',
  ): Observable<BoxxResponse<Permission>> {
    let url = this.baseurl + '?limit=' + limit + '&sort=' + sort;

    if (name) {
      url += '&permissionName=' + name;
    }
    if (page) {
      url += '&page=' + page;
    }

    if (roleCount) {
      url += '&roleCount=' + 1;
    }

    return this.http
      .get<BoxxResponse<Permission>>(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  CreatePermission(data): Observable<BoxxResponse<Permission>> {
    let url = `${this.baseurl}`;
    return this.http.post<BoxxResponse<Permission>>(
      url,
      data,
      this.httpOptions,
    );
  }

  GetByNameNsystemUserType(
    page = 1,
    limit = 10,
    sort = '',
    name = '',
    systemUserType = null,
    permissionsId = null,
  ): Observable<BoxxResponse<Permission>> {
    let url =
      this.baseurl + '?page=' + page + '&limit=' + limit + '&sort=' + sort;

    if (name) {
      url += '&permissionName=' + name;
    }

    if (systemUserType) {
      url += '&systemUserType=' + systemUserType;
    }

    if (permissionsId) {
      url += '&permissionsId=' + permissionsId;
    }

    return this.http
      .get<BoxxResponse<Permission>>(url, this.httpOptions)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  UpdatePermission(permissionId, data): Observable<Permission> {
    let url = `${this.baseurl}${permissionId}`;
    return this.http.put<Permission>(url, data, this.httpOptions);
  }

  GetPermission(permissionId): Observable<BoxxResponse<Permission>> {
    let url = `${this.baseurl}${permissionId}`;
    return this.http.get<BoxxResponse<Permission>>(url, this.httpOptions);
  }
}
