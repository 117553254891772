import { Injectable } from '@angular/core';
import { alertDetails } from '../entities/common';

@Injectable({
  providedIn: 'root',
})
export class NewQuoteService {
  public packagePolicyNumber: string;
  private createDefaultQuoteError: string = '';
  private createDefaultQuoteWarning: string = '';
  private insuredId: number;
  private policyBindWarningMsg: string;
  private sanction: any = {};
  private closedAlertStatus: boolean = false;
  private sideMenu: string = '';
  private quoteMessage: alertDetails[] = null;
  private premiumForSendQuote = 0;
  private branchMessage: alertDetails = null;

  constructor() {}

  getPremiumForSendQuote() {
    return this.premiumForSendQuote;
  }

  setPremiumForSendQuote(premium) {
    this.premiumForSendQuote = premium;
  }

  clearPremiumForSendQuote() {
    this.premiumForSendQuote = 0;
  }

  getQuoteMessage() {
    return this.quoteMessage;
  }

  setquoteMessage(msg: alertDetails[]) {
    this.quoteMessage = msg;
  }

  getPolicyBindWarningMsg(): string {
    return this.policyBindWarningMsg;
  }

  setPolicyBindWarningMsg(msg: string) {
    this.policyBindWarningMsg = msg;
  }

  getPackagePolicyNumber(): string {
    return this.packagePolicyNumber;
  }

  setPackagePolicyNumber(id: string) {
    this.packagePolicyNumber = id;
  }

  clearPackagePolicyNumber() {
    this.packagePolicyNumber = undefined;
  }

  getCreateDefaultQuoteError(): string {
    return this.createDefaultQuoteError;
  }

  setCreateDefaultQuoteError(errorMessage: string) {
    this.createDefaultQuoteError = errorMessage;
  }

  clearCreateDefaultQuote() {
    this.createDefaultQuoteError = '';
  }

  getCreateDefaultQuoteWarning(): string {
    return this.createDefaultQuoteWarning;
  }

  setCreateDefaultQuoteWarning(errorMessage: string) {
    this.createDefaultQuoteWarning = errorMessage;
  }

  clearCreateDefaultWarning() {
    this.createDefaultQuoteWarning = '';
  }

  getInsuredId(): number {
    return this.insuredId;
  }

  setInsuredId(id: number) {
    this.insuredId = id;
  }

  clearInsuredId() {
    this.insuredId = 0;
  }

  setSanction(sanctionData) {
    this.sanction = sanctionData;
  }
  getSanctionData() {
    return this.sanction;
  }
  clearSanction() {
    this.sanction = {};
  }
  setClosedAlertStatus(status) {
    this.closedAlertStatus = status;
  }
  getClosedAlertStatus() {
    return this.closedAlertStatus;
  }
  clearSideMenu() {
    this.sideMenu = '';
  }
  setSideMenu(value) {
    this.sideMenu = value;
  }
  getSideMenu() {
    return this.sideMenu;
  }
  getBranchMsg(): alertDetails {
    return this.branchMessage;
  }

  setBranchMsg(msg: alertDetails) {
    this.branchMessage = msg;
  }

  clearBranchMsg() {
    this.branchMessage = null;
  }
}
