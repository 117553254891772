import {
  Component,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import {
  PopoverV2Component,
  popOverV2Content,
} from '../components/popover-v2/popover-v2.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { InsuredService } from 'src/app/services/insured.service';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { PolicyPeriodService } from 'src/app/services/policy-period.service';
import { PolicyDashboardService } from 'src/app/services/policy-dashboard.service';
import { LocationService } from 'src/app/services/location.service';
import { LanguageService } from 'src/app/services/language.service';
import { RiskQuestionService } from 'src/app/services/risk-question.service';
import { PolicyAnswerService } from 'src/app/services/policy-answer.service';
import { PolicyService } from 'src/app/services/policy.service';
import { BrokerageBranchService } from 'src/app/services/brokerage-branch.service';
import { NewQuoteService } from 'src/app/services/new-quote.service';
import { TranslateService } from '@ngx-translate/core';
import { BrokerageDirectoryService } from 'src/app/services/brokerage-directory.service';
import { PolicyRiskService } from 'src/app/services/policy-risk.service';
import { AlertService } from 'src/app/services/alert.service';
import { PolicyLifecycleService } from 'src/app/services/policy-lifecycle-service';
import { PolicyAtomicTrxService } from 'src/app/services/policy-atomic.service';
import { PolicyRiskTrxService } from 'src/app/services/policy-risk-trx.service';
import { PolicyQuoteOptionService } from 'src/app/services/policy-quote-option.service';
import { RiskRatingCalculateService } from 'src/app/services/risk-rating-calculate.service';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { BrokerageProducerCommissionService } from 'src/app/services/brokerage-producer-commission.service';
import { BrokerageCommissionService } from 'src/app/services/brokerage-commissions.service';
import { ConfRiskLimitsService } from 'src/app/services/conf-risk-limits.service';
import { planStructureRiskService } from 'src/app/services/plan-structure-risk.service';
import { Observable, Subject, firstValueFrom, take, takeUntil } from 'rxjs';
import { UISpecificValues } from 'src/app/models/quote.model';
import { TransactionRiskActions } from 'src/app/dashboard/constants/lifecycle-actions';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import { getLifecycleState } from 'src/app/store/lifecycle/lifecycle.select';
import {
  getAllMailIds,
  getQuoteSelector,
  getRiskQuestions,
  getSelectedQuoteOption,
  getUiPolicyID,
} from 'src/app/store/create-quote/create-quote.selector';
import {
  calculateDateAfterMonths,
  convertDate,
  getCityTime,
  getDateFormatForDatePicker,
  getFormatDate,
} from 'src/app/utils/formatDate';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import {
  DOMAIN_CODE_QUOTESTATUS_INDICATED,
  DOMAIN_CODE_QUOTESTATUS_QUOTED,
  EFFECTIVE_DATE_MAX_RANGE_LIMIT,
  STANDALONE_SELLABLE_TRUE,
  UNDERWRITER,
} from 'src/app/constants/quote-constant';
import { USER_ROLES } from 'src/app/constants/security/systemUserType';
import { alertDetails } from 'src/app/entities/common';
import {
  formatAmountWithCurrency,
  getErrorMessage,
  patternValidator,
  valInDigits,
} from 'src/app/utils/utils';
import { PolicyDeclineRequest } from 'src/app/entities/policy-lifecycle';
import { updatePolicySlideoutData } from 'src/app/store/lifecycle/lifecycle.action';
import { environment } from 'src/environments/environment';
import { SharedFunctionService } from 'src/app/services/shared/shared-function.service';

@Component({
  selector: 'app-risk-analysis',
  templateUrl: './risk-analysis.component.html',
  styleUrls: ['./risk-analysis.component.less'],
})
export class RiskAnalysisWorkflow3Component implements OnInit, OnDestroy {
  private popupComponentRef: ComponentRef<PopoverV2Component>;
  openPopover = [];
  popOverContent = {
    coverage: popOverV2Content.COVERAGES,
    service: popOverV2Content.SERVICES,
  };

  private destroy$ = new Subject<void>();
  showRecalcConfModal: boolean = false;
  defaultUnderwriterName: string;
  defaultUnderwriterId: number;
  riskCategoryForm: FormGroup;
  riskForm: FormGroup<any>;
  brokerageForm: FormGroup;
  isProductSelectedTrue: boolean = false;
  currentBrokerValue = '';
  riskQuestionSubscription;
  currency = '';
  regionId;
  riskQuestionsRef;
  subRiskQuestionForm;
  validateRiskAnswers = false;
  isContinue: boolean = false;
  isLimitSelected = false;
  formSubmitted = false;
  selectedBroker;
  selectedBranch;
  selectedProducer;
  insuredTimezone;
  insuredlocation;
  currentDateTimezone;
  brokerageDetails;
  initialFormValue: any = {};
  uiData: UISpecificValues;
  quoteId: any;
  isStartDateValid = true;
  isEndDateValid = true;
  BrokerOptions = [];
  productCheckbox: Array<any> = [];
  form$: Observable<any>;
  productForm$: Observable<any>;
  selectedProductStandaloneOptions = [];
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  currentQuoteStatus: string = '';
  selectedProductList;
  categoryExcluded: boolean = false;
  excludedCategories = [];
  excludedCategoriesIds = [];
  insuredData = {};
  showBodySpinner: boolean = true;

  minStartDate;
  maxStartDate;
  minEndDate;
  maxEndDate;
  eventData: any;
  products: any = [];
  isNavigatedFromQuoteCalc = false;
  boxxUserId;
  brokerageErrMsg = '';
  insuredId;
  categoryProperties: {
    [key: string]: { checkAllYesBtn: boolean };
  } = {};
  backButtonDisabled: boolean = false;
  showSpinner: boolean = false;
  policyRisks = null;
  showActionPopup: boolean = false;
  actionPopupDetails: {
    quoteNumber: string;
    effectiveDate: string;
    tbdFlag: boolean;
    quotePeriod: { effectiveDt: string; expiryDt: string };
    insured: string;
    brokerage: string;
    branch: string;
    reasonOptions: any[];
    action: TransactionRiskActions;
    statusType: string;
    brokerInfo: string;
  };
  limitOptionsForProducts = {};
  public transactionRiskActions = TransactionRiskActions;
  openInfoPopup = [];
  slides: number[] = [];
  translateValue: number = 0;
  policyPeriod: any;
  policyRiskId;
  defaultCommission: number = 0;
  noCommission: boolean = false;
  effectiveExpiryDatesTBDFlag: boolean = true;
  isRiskFormLoaded = false;
  shortDateFormat: string = '';
  longDateFormat: string = '';
  quoteOptionsAlreadyExists = false;
  hasError = 0;
  fromSaveDraft = false;
  buttonLabel: string = '';
  insuredType: number;
  InsuredType: boolean;
  backButton: boolean;
  currentSelectedProduct: { [x: string]: any } = {};

  //broker commission modal
  showCommissionModal: boolean = false;
  showBrokerRedirectModal: boolean = false;
  alertInfo;
  productInfo;
  regionInfo;
  commissionDisabled: boolean = false;
  commissionId;
  isTBD: boolean = true;

  underWriters: string[] = [];
  underWriterOptions: any = [];
  riskTrnxs;
  underwriterTotalCount = 0;
  underwriterTotalPages = 1;
  underWritersList: any;
  selectUnderwriterDetails: { [x: string]: any } = {};
  policyRiskIds = [];
  mailData: { [x: string]: string | string[] } = {};
  disabledDropdownList: string[] = [];
  productList: { [x: string]: any }[] = [];
  productsIds = [];

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private insuredService: InsuredService,
    private riskRegionService: RiskRegionService,
    private localStorageService: LocalStorageService,
    private policyPeriodService: PolicyPeriodService,
    private policyDashboardService: PolicyDashboardService,
    public locationService: LocationService,
    private languageService: LanguageService,
    public riskQuestionService: RiskQuestionService,
    private policyAnswerService: PolicyAnswerService,
    private policyService: PolicyService,
    private brokerageBranchService: BrokerageBranchService,
    private newQuoteService: NewQuoteService,
    private translate: TranslateService,
    private viewContainerRef: ViewContainerRef,
    public brokerageDirectoryService: BrokerageDirectoryService,
    private policyRiskService: PolicyRiskService,
    private alertService: AlertService,
    private router: Router,
    private policyLifecycleService: PolicyLifecycleService,
    private policyAtomicTrxService: PolicyAtomicTrxService,
    private policyRiskTrxService: PolicyRiskTrxService,
    private policyQuoteOptionService: PolicyQuoteOptionService,
    private riskRatingCalculateService: RiskRatingCalculateService,
    public boxxUserService: BoxxUserService,
    private brokerageProducerCommissionService: BrokerageProducerCommissionService,
    private brokerageCommissionService: BrokerageCommissionService,
    private confRiskLimitsService: ConfRiskLimitsService,
    private planStructureService: planStructureRiskService,
    private sharedService: SharedFunctionService,
    private translateService: TranslateService,
  ) {
    this.riskForm = this.fb.group({});
    this.riskCategoryForm = this.fb.group({});
    this.brokerageForm = this.fb.group({
      broker: ['', Validators.required],
      branch: ['', Validators.required],
      producer: ['', Validators.required],
    });
  }

  ngOnDestroy(): void {
    this.alertService.clearAlerts(-1);
    this.riskQuestionSubscription?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  async ngOnInit(): Promise<void> {
    this.setLoader(true, 'hidden');
    window.document.body.style.overflow = 'unset';
    const quoteID = this.activatedRoute.snapshot.paramMap.get('id');
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    this.store.pipe(select(getLifecycleState)).subscribe((data) => {
      this.currentQuoteStatus = data.quoteStatus;
    });
    if (quoteID) {
      this.quoteId = quoteID;
      await this.getPolicyData(quoteID);
    }

    if (quoteID) {
      this.buttonLabel = this.translate.instant(
        'workFlow3.components.riskAnalysis.button.save',
      );
    } else {
      this.buttonLabel = this.translate.instant(
        'workFlow3.components.riskAnalysis.button.saveSubmission',
      );
    }

    this.form$ = this.store.select(getQuoteSelector);
    this.productForm$ = this.store.pipe(select(getQuoteSelector));
    this.boxxUserId = this.localStorageService.getBoxxUserId();
    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));
    let insuredId,
      regionId,
      versionId = 1,
      languageId = 1,
      products = [],
      isInsuredTypeCompany,
      riskQuestions = {},
      startDate,
      policyPeriodId,
      insuredName,
      endDate,
      broker = '',
      producer = '',
      branch = '',
      policyId: number | null,
      selectedInsuredId;

    this.form$.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      ({
        isInsuredTypeCompany,
        insuredName,
        branch,
        insuredId,
        regionId,
        selectedInsuredId,
        policyPeriodId,
        isNavigatedFromQuoteCalc: this.isNavigatedFromQuoteCalc,
        policyId,
      } = event.ui);
      ({ products } = event);
      this.uiData = event.ui;
      products = event.products;
      broker = event.ui.broker;
      producer = event.ui.producer;
      branch == event.ui.branch;
      this.regionId = event.ui.regionId;
      if (broker && producer && branch) {
        this.brokerageDetails = {
          branch: branch,
          broker: broker,
          producer: producer,
        };
        this.selectedBranch = { id: event.ui.branchId, name: branch };
        this.selectedBroker = {
          id: event.ui.brokerageId,
          name: broker,
        };
        this.selectedProducer = {
          id: event.ui.producerId,
          name: producer,
        };
        this.initialFormValue['startDate'] = event.ui.startDate;
        this.initialFormValue['endDate'] = event.ui.endDate;
        this.brokerageForm.setValue({
          branch: branch,
          broker: broker,
          producer: producer,
        });
        this.currentBrokerValue = broker + ' / ' + branch + ' / ' + producer;
      }
      if (products) {
        this.products = products;

        this.selectedProductStandaloneOptions =
          this.getSelectedPdtStandaloneOptions(products);
      }
      this.InsuredType = isInsuredTypeCompany;
      if (products.length == 0 && this.InsuredType == false) {
        const errAlert = {
          show: true,
          type: 'info',
          headerText: 'common.infoHeader',
          bodyText: this.translate.instant(
            'productSummary.info.noProductAvailable',
          ),
        };
        this.alertService.addAlert(errAlert);
        this.setLoader(false, 'unset');
      }
    });
    await this.populateUnderwriterOptions();
    await this.loadGetAllByPolicyPeriodId(this.uiData.policyPeriodId);
    await Promise.all([
      this.getAllProducts(
        products,
        selectedInsuredId,
        insuredId,
        isInsuredTypeCompany,
        versionId,
        regionId,
      ),
    ]);

    this.form$.pipe(take(1)).subscribe({
      next: async (event) => {
        ({
          isInsuredTypeCompany,
          insuredName,
          insuredId,
          regionId,
          selectedInsuredId,
          policyPeriodId,
          branch,
          broker,
          isNavigatedFromQuoteCalc: this.isNavigatedFromQuoteCalc,
        } = event.ui);
        ({ policyId, startDate, endDate } = event.ui);
        this.uiData = event.ui;
        this.quoteId = this.uiData.policyId;

        if (this.quoteId) {
          this.buttonLabel = this.translate.instant(
            'workFlow3.components.riskAnalysis.button.save',
          );
        } else {
          this.buttonLabel = this.translate.instant(
            'workFlow3.components.riskAnalysis.button.saveSubmission',
          );
        }
        this.eventData = event;
        this.insuredId = event.ui?.insuredId;
        this.insuredTimezone = event.ui?.timezone;
        products = event.products;
        this.isNavigatedFromQuoteCalc = event.ui.isNavigatedFromQuoteCalc;
        if (products) {
          this.products = [...products];
        }
        this.selectedProductList = event.products.filter(
          (obj) => obj.checked === true,
        );
        if (!this.selectedProductList || this.selectedProductList.length == 0) {
          this.products = products.map((prod, index) => {
            // BOXXINS-3790 hardcoding the default value of product to be CRC(id: 2) if the brokerage is CRC(id: )
            if (
              this.selectedBroker.name?.toUpperCase().includes('CRC') &&
              !!!quoteID &&
              index == 1
            ) {
              return {
                ...prod,
                checked: true,
              };
            } else if (
              !this.selectedBroker.name?.toUpperCase().includes('CRC') &&
              index == 0
            ) {
              return {
                ...prod,
                checked: true,
              };
            } else {
              return { ...prod };
            }
          });
          this.store.dispatch(
            CreateQuoteAction.loadProductsSuccess({
              products: this.products,
            }),
          );
        }
        this.productCheckbox = products.map((prod) => ({
          name: prod.key,
          value: prod.value,
          checked: prod.checked,
          id: prod.value,
        }));
        this.selectedProductStandaloneOptions =
          this.getSelectedPdtStandaloneOptions(products);

        for (let product of products) {
          await Promise.all([
            this.populateLimitOptions(product.riskId, product.key),
          ]);
          let defaultLimit = await this.getDefaultLimit(product.riskId);
          this.handleLimitChange(defaultLimit, products, product.key);
        }
        if (!this.insuredTimezone) {
          await Promise.all([this.getInsuredTimeZone()]);
        }

        this.initialFormValue['products'] = products.map((prod) => ({
          key: prod.key,
          underwriter: prod.underwriter,
          underwriterId: prod.underwriterId,
          value: prod.value,
          limit: prod.limit,
        }));
        let brokerName = event.ui.broker;
        let brokerId = event.ui.brokerageId;
        let branchName = event.ui.branch;
        let branchId = event.ui.branchId;
        let producerName = event.ui.producer;
        let producerId = event.ui.producerId;
        this.initialFormValue['brokerage'] = {
          id: brokerId,
          name: brokerName,
        };
        this.initialFormValue['branch'] = {
          id: branchId,
          name: branchName,
        };
        this.initialFormValue['producer'] = {
          id: producerId,
          name: producerName,
        };
        this.initialFormValue['brokerBranchProd'] =
          brokerName + ' / ' + branchName + ' / ' + producerName;
        this.backButton = false;
        this.riskQuestionSubscription = this.store
          .select(getRiskQuestions)
          .pipe(takeUntil(this.destroy$))
          .subscribe((event) => {
            this.backButton = true;
            riskQuestions = event;
            let defaultStartDate;
            let defaultEndDate;
            let currentDate = getCityTime(this.insuredTimezone);
            this.currentDateTimezone = currentDate;

            if (!startDate) {
              defaultStartDate = currentDate.currentDate
                ? getFormatDate(
                    currentDate.currentDate,
                    'DD/MM/YYYY',
                    this.shortDateFormat,
                  )
                : this.getTomorrow();

              this.store.dispatch(
                new CreateQuoteAction.updateUiContents({
                  startDate: defaultStartDate,
                }),
              );
            } else {
              defaultStartDate = new Date(startDate);
            }
            if (!endDate) {
              // TODO check new date it will fail if user login from different location
              defaultEndDate = currentDate.oneYearLater
                ? getFormatDate(
                    currentDate.oneYearLater,
                    'DD/MM/YYYY',
                    this.shortDateFormat,
                  )
                : calculateDateAfterMonths(
                    new Date(),
                    18,
                    this.shortDateFormat,
                  );
              this.store.dispatch(
                new CreateQuoteAction.updateUiContents({
                  endDate: defaultEndDate,
                }),
              );
            } else {
              defaultEndDate = new Date(endDate);
            }
            if (
              currentDate.eightMonthsLater &&
              currentDate.endDate &&
              currentDate.thirtyDaysAgo &&
              currentDate.thirtyDaysLater
            ) {
              this.minStartDate = currentDate.thirtyDaysAgo;
              this.maxStartDate = currentDate.thirtyDaysLater;
              this.minEndDate = currentDate.eightMonthsLater;
              this.maxEndDate = currentDate.endDate;
            } else {
              this.minStartDate = new Date();
              this.maxStartDate = new Date();
              this.minStartDate.setDate(this.minStartDate.getDate() - 30);
              this.maxStartDate.setDate(
                this.maxStartDate.getDate() + EFFECTIVE_DATE_MAX_RANGE_LIMIT,
              );

              this.minEndDate = new Date(defaultStartDate);
              this.maxEndDate = new Date(defaultStartDate);
              this.minEndDate.setMonth(defaultStartDate.getMonth() + 8);
              this.maxEndDate.setMonth(defaultStartDate.getMonth() + 18);
            }
            const selectedProducts = products.filter(
              (obj) => obj.checked === true,
            );
            this.loadRiskQuestions(
              riskQuestions,
              products,
              isInsuredTypeCompany,
              versionId,
              languageId,
              insuredId,
              regionId,
            );
            this.initialFormValue.riskQuestions = {};
            this.initialFormValue.riskCategory = {};
            if (Object.keys(riskQuestions).length > 0) {
              Object.keys(riskQuestions).forEach((product) => {
                const numberAnswerQuestions = riskQuestions[product].filter(
                  (riskQuestion) =>
                    riskQuestion?.dataType?.description?.toLowerCase() ==
                    'numeric',
                );
                this.initialFormValue.riskQuestions[product] = riskQuestions[
                  product
                ].map(({ id, category, value }) => ({ id, category, value }));
                this.riskQuestionsRef = riskQuestions;
                // TODO - update the below logic later to use mandatory field from backend for each question
                const mandatoryQuestions = riskQuestions[product].filter(
                  (riskQuestion) => riskQuestion?.active,
                );
                this.riskForm.addControl(product, new FormArray([]));
                const formattedRiskQnArr = riskQuestions[product].map(
                  (risk) => ({
                    [risk.id]: risk.value,
                  }),
                );
                const formCntrls = formattedRiskQnArr.reduce(
                  (r, c) => ({ ...r, ...c }),
                  {},
                );
                let pdtFormCntrl = this.riskForm.controls[product] as FormArray;
                pdtFormCntrl.push(this.fb.group(formCntrls));
                if (pdtFormCntrl.length > 1) {
                  for (let i in Array.from(
                    { length: pdtFormCntrl.length },
                    (v, i) => i,
                  )) {
                    if (i != '0') {
                      pdtFormCntrl.removeAt(Number(i));
                    }
                  }
                }
                //All no or All yes radio input in category wise for each product
                this.riskCategoryForm.addControl(product, new FormArray([]));
                const categoryControlArray = this.getCategories(
                  riskQuestions[product],
                ).map((category) => ({
                  [category]: null,
                }));
                const categoryControls = categoryControlArray.reduce(
                  (r, c) => ({ ...r, ...c }),
                  {},
                );
                this.initialFormValue.riskCategory[product] =
                  categoryControlArray;
                let pdtCategoryCntrls = this.riskCategoryForm.controls[
                  product
                ] as FormArray;
                pdtCategoryCntrls.push(this.fb.group(categoryControls));

                this.subRiskQuestionForm = this.riskForm.get(`${product}.0`);
                this.isRiskFormLoaded = true;
                if (mandatoryQuestions.length > 0) {
                  this.updateMandatoryQuestionsValidation(
                    mandatoryQuestions,
                    product,
                  );
                }
                if (numberAnswerQuestions.length > 0) {
                  this.updateNumericQuestionsValidation(
                    numberAnswerQuestions,
                    product,
                  );
                }
              });
            }
            // if (
            //   !this.uiData.currentProductDisplayed ||
            //   this.uiData.currentProductDisplayed !== selectedProducts[0]?.value
            // ) {
            this.currentSelectedProduct = selectedProducts[0];
            this.store.dispatch(
              new CreateQuoteAction.UpdateCurrentproductAction(
                selectedProducts[0]?.value,
              ),
            );
            // }
          });

        this.loadGetAllByPolicyPeriodId(this.uiData.policyPeriodId);
      },
      error: (error) => {},
    });

    this.store.pipe(select(getQuoteSelector)).subscribe((item) => {
      if (item?.ui?.policyId) {
        this.backButtonDisabled = true;
      }
    });
  }

  getInsuredTimeZone() {
    return new Promise<any>(async (resolve, reject) => {
      await this.insuredService.GetByInsuredId(this.insuredId).subscribe({
        next: async (data) => {
          this.insuredTimezone =
            data.data.insuredLocation.timeZoneCode ?? 'America/New_York';
          this.store.dispatch(
            new CreateQuoteAction.UpdateInsuredAction({
              timezone:
                data.data?.insuredLocation.timeZoneCode ?? 'America/New_York',
            }),
          );
          resolve(true);
        },
        error: (error) => {
          reject(true);
        },
      });
    });
  }

  getAllProducts(
    products,
    selectedInsuredId,
    insuredId,
    isInsuredTypeCompany,
    versionId,
    regionId,
  ) {
    return new Promise<any>(async (resolve, reject) => {
      const { length } = products?.filter((obj) => obj.checked === true);
      if (length === 0 || selectedInsuredId !== insuredId) {
        if (insuredId && insuredId === 0) {
          insuredId = this.newQuoteService.getInsuredId();
        }
        this.newQuoteService.clearInsuredId();
        if (insuredId && insuredId > 0) {
          this.showSpinner = true;
          if (isInsuredTypeCompany) {
            versionId = 2;
          }
          this.insuredService.Get(insuredId).subscribe(async (data) => {
            const locationId = data.data.insuredLocation.id;
            await this.locationService
              .Get(locationId)
              .subscribe(async (data) => {
                regionId = data.data.region.id;
                this.store.dispatch(
                  new CreateQuoteAction.UpdateInsuredAction({
                    regionId: data.data.region.id,
                    versionId: versionId,
                  }),
                );

                await this.riskRegionService
                  .getRiskRegionAndVersion(
                    regionId,
                    versionId,
                    STANDALONE_SELLABLE_TRUE,
                    this.quoteId ? 2 : undefined,
                  )
                  .subscribe(async (data) => {
                    const riskProducts = await this.mapDataToRiskProducts(data);
                    await this.loadRiskServices(riskProducts);

                    resolve(true);
                  });
              });
          });
        }
      }
    });
  }

  isProductSelected(productsArr) {
    this.isProductSelectedTrue =
      productsArr.findIndex((obj) => obj.checked) !== -1;
    return this.isProductSelectedTrue;
  }

  loadMoreUnderwriters(pageNumber) {
    if (this.underwriterTotalCount > this.underWriterOptions?.length) {
      this.populateUnderwriterOptions(pageNumber);
    }
  }

  async populateUnderwriterOptions(page = 1) {
    const sortOrder = 'firstName:asc,lastName:asc';
    const systemUserState = 1;
    this.boxxUserService
      .GetUnderwriterlist(
        '',
        page,
        10,
        sortOrder,
        '',
        1,
        USER_ROLES.Underwriter,
        systemUserState,
      )
      .subscribe((data) => {
        const tableData = data.data?.map((dataObj) => ({
          key: dataObj.firstName + ' ' + dataObj.lastName,
          value: dataObj.firstName + ' ' + dataObj.lastName,
          id: dataObj.id,
        }));
        this.underWritersList = {
          ...this.underWritersList,
          ...data.data.reduce((accumulator, value) => {
            return {
              ...accumulator,
              [value.firstName + ' ' + value.lastName]: value,
            };
          }, {}),
        };
        this.underwriterTotalCount = data?.pagination?.totalRecords;
        this.underwriterTotalPages = data?.pagination?.totalPages;
        this.underWriterOptions = [...this.underWriterOptions, ...tableData];
      });
  }

  loadBrokerOption() {}

  searchBroker(value) {
    this.brokerageErrMsg = '';
    if (value === '') {
      this.BrokerOptions = [];
      this.selectedBroker = {};
      this.selectedBranch = {};
      this.selectedProducer = {};
      this.brokerageDetails = {
        branch: '',
        broker: '',
        producer: '',
        branchId: '',
        brokerageId: '',
        producerId: '',
      };
      this.brokerageForm.setValue({
        branch: '',
        broker: '',
        producer: '',
      });
      this.store.dispatch(
        new CreateQuoteAction.updateUiContents({
          broker: '',
          producer: '',
          branch: '',
          brokerageId: '',
          branchId: '',
          producerId: '',
        }),
      );
    }
    if (value.length >= 3) {
      const searchKey = value.trim();
      this.brokerageDirectoryService
        .GetBrokerageDirectories(1, 50, '', true, true, true, searchKey, true)
        .subscribe({
          next: (response) => {
            const tableData =
              response.data.length === 0
                ? []
                : response.data?.map((dataObj) => ({
                    key:
                      dataObj?.brokerage?.name +
                      ' / ' +
                      dataObj?.brokerageBranch?.name +
                      ' / ' +
                      dataObj?.brokerageProducer?.name,
                    value:
                      dataObj?.brokerage?.name +
                      ' / ' +
                      dataObj?.brokerageBranch?.name +
                      ' / ' +
                      dataObj?.brokerageProducer?.name,
                    disabled: !dataObj.active,
                    brokerage: dataObj?.brokerage,
                    branch: dataObj?.brokerageBranch,
                    producer: dataObj?.brokerageProducer,
                  }));
            this.BrokerOptions = tableData
              .filter((x) => x.brokerage && x.branch && x.producer)
              .splice(0, 10);
            this.brokerageForm.get('broker').setValue('');
            this.brokerageForm.controls['branch'].setValue('');
            this.brokerageForm.controls['producer'].setValue('');
            this.selectedBroker = '';
            this.selectedBranch = '';
            this.selectedProducer = '';

            this.store.dispatch(
              new CreateQuoteAction.updateUiContents({
                broker: this.brokerageForm.value['broker'] ?? '',
                producer: this.brokerageForm.value['producer'] ?? '',
                branch: this.brokerageForm.value['branch'] ?? '',
                brokerageId: this.brokerageDetails?.brokerageId,
                branchId: this.brokerageDetails?.branchId,
                producerId: this.brokerageDetails?.producerId,
              }),
            );
          },
          error: (error) => {
            if (![500].includes(error?.status)) {
              let errAlert: alertDetails = {
                type: 'error',
                headerText: 'common.errorMsg',
                bodyText: getErrorMessage(error),
              };
              if (errAlert.bodyText) {
                this.alertService.addAlert(errAlert);
              }
            } else {
              let errAlert: alertDetails = {
                type: 'error',
                headerText: 'error!',
                bodyText: error ?? '' ?? error?.message ?? '',
              };
              this.alertService.addAlert(errAlert);
            }
          },
        });
    } else {
      return;
    }
  }

  async selectBroker(value) {
    this.currentBrokerValue = value;
    let selectedValue = this.BrokerOptions.find((x) => x.value === value);

    this.brokerageDetails = {
      branch: selectedValue.branch.name,
      broker: selectedValue.brokerage.name,
      producer: selectedValue.producer.name,
      branchId: selectedValue.branch.id,
      brokerageId: selectedValue.brokerage.id,
      producerId: selectedValue.producer.id,
    };
    this.selectedBranch = selectedValue.branch;
    this.selectedBroker = selectedValue.brokerage;
    this.selectedProducer = selectedValue.producer;
    this.brokerageForm.setValue({
      branch: selectedValue.branch.name,
      broker: selectedValue.brokerage.name,
      producer: selectedValue.producer.name,
    });
    this.brokerageErrMsg = '';
    const hasCommission = await this.hasCommissionForSelectedBroker();
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        broker: this.brokerageForm.value['broker'] ?? '',
        producer: this.brokerageForm.value['producer'] ?? '',
        branch: this.brokerageForm.value['branch'] ?? '',
        brokerageId: this.brokerageDetails?.brokerageId,
        branchId: this.brokerageDetails?.branchId,
        producerId: this.brokerageDetails?.producerId,
      }),
    );
  }

  handleCheckbox(value, options) {}

  setCurentAsActive(product) {
    this.currentSelectedProduct = product;
    this.store.dispatch(
      new CreateQuoteAction.UpdateCurrentproductAction(product.value),
    );
  }

  getTomorrow() {
    let currentDate = new Date();
    return new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
  }
  getFormattedDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return day + '/' + month + '/' + year;
  }

  private loadRiskQuestions(
    riskQuestions: {},
    selectedProducts: any[],
    isInsuredTypeCompany,
    versionId: number,
    languageId: number,
    insuredId,
    regionId,
  ) {
    const riskQuestionsKey = Object.keys(riskQuestions);

    if (
      riskQuestionsKey?.length <= 0 ||
      (riskQuestionsKey?.length > 0 &&
        !selectedProducts.every((product) =>
          riskQuestionsKey.includes(product?.value),
        ))
    ) {
      if (isInsuredTypeCompany) {
        versionId = 2;
      }

      const riskQnsNotPresentFor = selectedProducts.filter(
        (product) => !riskQuestionsKey.includes(product?.value),
      );
      if (riskQnsNotPresentFor?.length > 0) {
        riskQnsNotPresentFor.forEach((productObj) => {
          this.riskRegionService
            .getRiskRegionAndVersion(
              regionId,
              versionId,
              null,
              this.quoteId ? 2 : undefined,
            )
            .subscribe((data) => {
              const riskRegionId = data?.data.find(
                (obj) => obj.risk.id === productObj.riskId,
              ).id;
              this.store.dispatch(
                CreateQuoteAction.loadRiskQuestions({
                  userId: this.boxxUserId,
                  regionId: riskRegionId,
                  languageId,
                  insuredId,
                  product: productObj?.value,
                  limit: 500,
                }),
              );
            });
        });
      }
    }
    return versionId;
  }

  getCategories(riskQuestions) {
    let filteredQuestions = riskQuestions;
    if (this.categoryExcluded) {
      filteredQuestions = riskQuestions.filter((questionObj) => {
        const category = questionObj.category
          ?.toLowerCase()
          .replace(/.*\./, '')
          .replace(/\s/g, '');
        return !this.excludedCategories.includes(category);
      });
    }
    return filteredQuestions
      .map((questionObj) => questionObj.category?.toLowerCase())
      .filter((val, idx, arr) => arr.indexOf(val) === idx);
  }

  private updateMandatoryQuestionsValidation(numericQuestions, product) {
    numericQuestions.forEach((question) => {
      const questionControl = this.riskForm.get(`${product}.0.${question.id}`);
      this.clearAndSetValidators(questionControl, [Validators.required]);
    });
  }

  private updateNumericQuestionsValidation(numericQuestions, product) {
    numericQuestions.forEach((question) => {
      const questionControl = this.riskForm.get(`${product}.0.${question.id}`);
      const numericPattern = /^(?:[1-8]\d{0,6}|9000000|[9]\d{0,5})$/;

      this.clearAndSetValidators(questionControl, [
        Validators.required,
        patternValidator(numericPattern),
      ]);
    });
  }

  showSwitchIndicate() {
    const currentQuoteStatusId =
      this.policyRisks?.[0]?.PolicyRiskTrxes[0]?.quoteStatus;
    if (currentQuoteStatusId) {
      return (
        currentQuoteStatusId === DOMAIN_CODE_QUOTESTATUS_QUOTED ||
        currentQuoteStatusId === DOMAIN_CODE_QUOTESTATUS_INDICATED
      );
    } else return false;
  }

  isIndicatedStatus() {
    return (
      this.policyRisks?.[0]?.PolicyRiskTrxes[0]?.quoteStatus ===
      DOMAIN_CODE_QUOTESTATUS_INDICATED
    );
  }

  isQuotedStatus() {
    return (
      this.policyRisks?.[0]?.PolicyRiskTrxes[0]?.quoteStatus ===
      DOMAIN_CODE_QUOTESTATUS_QUOTED
    );
  }

  async handleQuoteStatus(isIndicatedClicked) {
    const currentQuoteStatusId =
      this.policyRisks?.[0]?.PolicyRiskTrxes?.[0]?.quoteStatus;
    const policyPeriodId = this.policyRisks?.[0]?.policyPeriodId;
    if (
      policyPeriodId &&
      (currentQuoteStatusId === DOMAIN_CODE_QUOTESTATUS_QUOTED ||
        currentQuoteStatusId === DOMAIN_CODE_QUOTESTATUS_INDICATED)
    ) {
      let requestBody: {
        policyPeriodId: number;
        status?: number;
      } = {
        policyPeriodId,
      };
      if (isIndicatedClicked) {
        requestBody = {
          ...requestBody,
          status: 1,
        };
      } else {
        requestBody = {
          ...requestBody,
          status: 0,
        };
      }

      if (requestBody) {
        try {
          const indicateAPI =
            this.policyLifecycleService.SwitchIndicate(requestBody);
          const indicateQuoteResponse = await firstValueFrom(indicateAPI);
          this.policyRisks[0].PolicyRiskTrxes[0].quoteStatus =
            isIndicatedClicked
              ? DOMAIN_CODE_QUOTESTATUS_INDICATED
              : DOMAIN_CODE_QUOTESTATUS_QUOTED;
          const alertData = {
            show: true,
            type: 'success',
            headerText: 'success!',
            bodyText: this.translateService.instant(
              'common.success.quoteStatusChanged',
            ),
          };
          this.alertService.addAlert(alertData);
        } catch (error) {
          let errAlert: alertDetails = {
            type: 'error',
            headerText: 'common.errorMsg',
            bodyText: getErrorMessage(error),
          };
          this.alertService.addAlert(errAlert);
        }
      }
    }
  }

  async getPolicyData(quoteID) {
    const getPolicyByID = this.policyPeriodService.GetAllByPolicyId(quoteID);
    let dataPId = await firstValueFrom(getPolicyByID);
    this.isTBD = dataPId.data[0].effectiveExpiryDatesTBDFlag;
    const policyPeriodId = dataPId.data[0].id;
    const policyDashboardServiceObs =
      this.policyDashboardService.Get(policyPeriodId);
    const policyDashboardData = await firstValueFrom(policyDashboardServiceObs);
    this.policyPeriod = {
      effectiveDt: policyDashboardData.data.effectiveDt,
      expiryDt: policyDashboardData.data.expiryDt,
    };
    const insured = policyDashboardData.data.policy.insured;
    const quoteOptionLimit =
      policyDashboardData.data?.PolicyRisks[0]?.PolicyRiskTrxes[0]?.limit ?? 0;
    if (quoteOptionLimit && Number(quoteOptionLimit) > 0) {
      this.quoteOptionsAlreadyExists = true;
    }
    const insuredServiceObs = this.insuredService.Get(insured.id);
    let insuredData = await firstValueFrom(insuredServiceObs);
    const locationId = insuredData.data.insuredLocation?.id;
    const getLocation = this.locationService.Get(locationId);
    let locationData = await firstValueFrom(getLocation);
    const getLanguage = this.languageService.getLanguageId();
    let langData = await firstValueFrom(getLanguage);
    const languageId = langData.data[0]?.id;
    const regionId = locationData.data.region.id;

    const getRiskVersion = this.riskRegionService.getRiskRegionAndVersion(
      regionId,
      insured.insuredType,
      STANDALONE_SELLABLE_TRUE,
      quoteID ? 2 : undefined,
    );
    let riskRegion = await firstValueFrom(getRiskVersion);
    const policyRisks = policyDashboardData.data.PolicyRisks;
    let products = await this.makeProducts(riskRegion.data, policyRisks);
    //UPDATE STORE UI with PRODUCTS
    this.store.dispatch(
      CreateQuoteAction.loadProductsSuccess({ products: products }),
    );

    riskRegion?.data.forEach(async (riskRegion) => {
      const getQuestions = this.riskQuestionService.GetByRegionId(
        riskRegion.id,
        languageId,
        insured.id,
        500,
      );
      let dataQuestions = await firstValueFrom(getQuestions);
      const questionAnswers = dataQuestions?.data;

      const policyRiskData = policyDashboardData.data.PolicyRisks.filter(
        (policyRisk) => policyRisk.risk.id == riskRegion.risk.id,
      );
      if (policyRiskData.length > 0) {
        const policyRiskId = policyRiskData[0].id;
        const policyRiskValue = policyRiskData[0].risk.name;
        const policyQuestionList =
          this.policyAnswerService.GetAllByQuestionIdAndPolicyRiskId(
            policyRiskId,
            1,
            500,
          );
        let dataPolicyQuestionList = await firstValueFrom(policyQuestionList);
        const policyQuestionLists = dataPolicyQuestionList?.data;
        const selectedQuestions = questionAnswers.map((questions) => {
          const policyQuestionList = policyQuestionLists.find(
            (policyQuestions) =>
              policyQuestions.riskQuestionId === questions.id,
          );

          return {
            ...questions,
            value: policyQuestionList?.riskAnswer
              ? policyQuestionList?.riskAnswer
              : null,
          };
        });
        //UPDATE STORE UI with Question Answers
        this.store.dispatch(
          CreateQuoteAction.loadRiskQuestionsSuccess({
            riskQuestions: { [policyRiskValue]: selectedQuestions },
          }),
        );
      }
    });

    const getPolicyServiceObs = this.policyService.Get(quoteID);
    const policyData = await firstValueFrom(getPolicyServiceObs);
    const policyRiskTrxId =
      policyDashboardData.data.PolicyRisks[0].PolicyRiskTrxes[0].id;

    const brokerData = {
      branch: policyData.data.brokerageBranchBOR.name,
      broker: policyDashboardData.data.policy.brokerageBOR.name,
      producer: this.getBrokerNames(
        policyDashboardData.data.policy.brokerageProducerBOR,
      ),
      brokerageId: policyDashboardData.data.policy.brokerageBORId,
      branchId: policyDashboardData.data.policy.brokerageBranchBORId,
      producerId: policyDashboardData.data.policy.brokerageProducerBORId,
    };
    const startDate = getDateFormatForDatePicker(
      policyDashboardData.data.effectiveDt,
      this.shortDateFormat,
    );
    const endDate = getDateFormatForDatePicker(
      policyDashboardData.data.expiryDt,
      this.shortDateFormat,
    );
    this.effectiveExpiryDatesTBDFlag =
      policyDashboardData.data?.effectiveExpiryDatesTBDFlag;

    //UPDATE STORE DROPDOWNS with DATES

    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        startDate: startDate,
        endDate: endDate,
      }),
    );

    //UPDATE STORE DROPDOWNS with BROKER

    this.store.dispatch(new CreateQuoteAction.updateUiContents(brokerData));

    const branchObs = this.brokerageBranchService.GetBranchByBrokerageId(
      policyDashboardData.data.policy.brokerageBORId,
      1,
      10000000,
    );
    let branches = await firstValueFrom(branchObs);
    const branch = branches.data.filter(
      (b) => b.name === policyData.data.brokerageBranchBOR.name,
    )[0];

    this.insuredData = {
      brokerageId: Number(policyDashboardData.data.policy.brokerageBORId),
      branchId: Number(branch.id),
      producerId: Number(
        policyDashboardData.data.policy.brokerageProducerBORId,
      ),
      brokerageName: policyDashboardData.data.policy.brokerageBOR.name,
      regionId,
    };
    //UPDATE STORE UI with BROKER
    this.store.dispatch(
      new CreateQuoteAction.UpdateInsuredAction({
        ...brokerData,
        regionId,
      }),
    );

    //UPDATE STORE UI with PRODUCTS
    this.store.dispatch(
      CreateQuoteAction.loadProductsSuccess({ products: products }),
    );

    let isInsuredTypeCompany = true;
    if (insured.insuredType == 1) isInsuredTypeCompany = false;
    this.store.dispatch(
      new CreateQuoteAction.UpdateInsuredAction({
        insuredId: Number(insured.id),
        insuredName: this.getInsuredName(insured),
        policyId: Number(quoteID),
        saveAtomicTrx: true,
        policyRiskTrxId: Number(policyRiskTrxId),
        insuredEmail: insuredData.data.email,
        revenue: insuredData.data.revenue,
        noOfEmployees: insuredData.data?.numberOfEmployeesDomain?.description,
        industryClass: insuredData.data.industryClass.name,
        industry: insuredData.data.industry.name,
        address: insuredData.data.insuredLocation.fullAddress,
      }),
    );
    this.store.dispatch(
      new CreateQuoteAction.UpdateInsuredAction({
        isInsuredTypeCompany: isInsuredTypeCompany,
        policyPeriodId: Number(policyPeriodId),
      }),
    );
  }

  getSelectedPdtStandaloneOptions(productList) {
    let list = productList.filter(
      (pdt) =>
        this.quoteId
          ? pdt.checked && pdt.standaloneSellable
          : pdt.checked && pdt.active && pdt.standaloneSellable,
      // (pdt) => pdt.checked && pdt.active && pdt.standaloneSellable,
    );
    return list.map((pdt) => ({
      ...pdt,
    }));
  }

  private clearAndSetValidators(control, validators) {
    control.clearValidators();
    control.setValidators(validators);
    control.updateValueAndValidity();
  }

  async makeProducts(data, policyRisk) {
    const tableDataPromises = data?.map(async (item, index, arr) => {
      const policyRiskData = policyRisk.filter(
        (policyRisk) => policyRisk.risk.id == item.risk.id,
      );
      let underwriter = '';
      let isChecked = false;
      let underwriterId;
      if (policyRiskData.length > 0) {
        const underwriterObj =
          policyRiskData[0]?.PolicyRiskTrxes[0]?.underwriter;
        underwriter =
          underwriterObj?.firstName + ' ' + underwriterObj?.lastName;
        underwriterId = underwriterObj?.id;

        // update to logged in underwriter
        try {
          const underWriterData = await this.sharedService.updateUnderwriter(
            underwriterId,
            policyRiskData[0]?.PolicyRiskTrxes[0]?.id,
            underwriter,
          );
          underwriter = underWriterData?.underwriterName;
          underwriterId = underWriterData?.underwriterId;
        } catch (e) {}
        isChecked = true;
      }

      let hasSubProduct = false,
        subProductName = '',
        subProductRiskId = null;
      let hasParentProduct = false,
        parentRiskId = null;
      if (item.risk?.standaloneSellable == 1) {
        const getSubProduct = this.riskRegionService.getBySellableWithRiskId(
          item.risk.id,
        );
        const getSubProductResponse = await firstValueFrom(getSubProduct);

        if (getSubProductResponse?.data?.length > 0) {
          hasSubProduct = true;
          subProductName = getSubProductResponse?.data[0]?.name;
          subProductRiskId = getSubProductResponse?.data[0]?.id;
        }
      } else {
        const getParentProduct = this.riskRegionService.getRiskById(
          item.risk.id,
        );
        const getParentProductResponse = await firstValueFrom(getParentProduct);

        if (
          getParentProductResponse?.data?.id &&
          getParentProductResponse?.data?.sellableWithRiskId
        ) {
          hasParentProduct = true;
          parentRiskId = getParentProductResponse?.data?.id;
        }
      }

      return {
        key: item.risk.name,
        value: item.risk.name,
        description: item.risk.description,
        checked: isChecked,
        active: item.active,
        addons: [],
        underwriter: underwriter,
        riskId: item.risk.id,
        underwriterId: underwriterId,
        standaloneSellable:
          item.risk?.standaloneSellable === STANDALONE_SELLABLE_TRUE,
        hasSubProduct: hasSubProduct,
        subProductName: subProductName,
        subProductRiskId: subProductRiskId,
        hasParentProduct: hasParentProduct,
        parentRiskId: parentRiskId,
      };
    });

    // Wait for all the promises to resolve
    const resolvedTableData = await Promise.all(tableDataPromises);

    return resolvedTableData;
  }

  getBrokerNames(dataObj) {
    return dataObj.firstName + ' ' + dataObj.lastName;
  }

  getInsuredName(insured) {
    if (insured.insuredType == 2) {
      return insured.companyName;
    } else {
      let name = insured.firstName + ' ';
      name += insured.middleName ? insured.middleName : '';
      name += ' ' + insured.lastName;
      return name;
    }
  }

  getQuestions(category, riskQuestions) {
    return riskQuestions.filter(
      (qObj) => qObj.category?.toLowerCase() === category.toLowerCase(),
    );
  }

  getProductFormArray(value) {
    return this.riskForm.get(`${value}.0`) as FormGroup;
  }

  getCategoryProductFormArray(value) {
    return this.riskCategoryForm.get(`${value}.0`) as FormGroup;
  }

  checkAllYes(product, category, categoryNo, questions: any) {
    let pdtFormCntrlss = (this.riskForm.get(product) as FormArray).at(
      0,
    ) as FormGroup;

    for (let question of questions) {
      if (pdtFormCntrlss.contains(question.id)) {
        pdtFormCntrlss.controls[`${question.id}`].setValue('true');
      }
    }
    let riskCategoryFormGroup = (
      this.riskCategoryForm.get(product) as FormArray
    ).at(0) as FormGroup;
    riskCategoryFormGroup.controls[`${category}`].setValue('true');

    if (!this.categoryProperties.hasOwnProperty(product + categoryNo)) {
      this.categoryProperties[product + categoryNo] = {
        checkAllYesBtn: true,
      };
    } else {
      this.categoryProperties[product + categoryNo].checkAllYesBtn = true;
    }
  }

  checkAllNo(
    product,
    category,
    categoryNo,
    questions: any,
    value: any = 'false',
  ) {
    let pdtFormCntrlss = (this.riskForm.get(product) as FormArray).at(
      0,
    ) as FormGroup;

    for (let question of questions) {
      if (pdtFormCntrlss.contains(question.id)) {
        pdtFormCntrlss.controls[`${question.id}`].setValue('false');
      }
    }
    let riskCategoryFormGroup = (
      this.riskCategoryForm.get(product) as FormArray
    ).at(0) as FormGroup;
    riskCategoryFormGroup.controls[`${category}`].setValue(value);
    if (!this.categoryProperties.hasOwnProperty(product + categoryNo)) {
      this.categoryProperties[product + categoryNo] = {
        checkAllYesBtn: false,
      };
    } else {
      this.categoryProperties[product + categoryNo].checkAllYesBtn = false;
    }
  }

  getFormName(riskArr, id) {
    return 0;
  }

  isColumnLayout(explanation) {
    return explanation.split('<br>').length > 1;
  }

  getExplanationArr(explanation) {
    if (explanation.split('<br>').length > 1) {
      // <br> for grid styling
      return explanation.split('<br>');
    } else {
      // <li> for bullet point styling
      return explanation.split('<li>');
    }
  }

  handleRadioCheck(product, category: any, categoryNo) {
    if (!this.categoryProperties.hasOwnProperty(product + categoryNo)) {
      this.categoryProperties[product + categoryNo] = {
        checkAllYesBtn: null,
      };
    } else {
      this.categoryProperties[product + categoryNo].checkAllYesBtn = false;

      let riskCategoryFormGroup = (
        this.riskCategoryForm.get(product) as FormArray
      ).at(0) as FormGroup;
      riskCategoryFormGroup.controls[`${category}`].setValue(null);
    }
  }

  async mapDataToRiskProducts(data): Promise<any[]> {
    const riskProducts = await Promise.all(
      data.data?.map(async ({ risk, id, defaultUnderwriter, active }: any) => {
        this.defaultUnderwriterName = defaultUnderwriter
          ? `${defaultUnderwriter.firstName} ${defaultUnderwriter.lastName}`
          : '';

        this.defaultUnderwriterId = defaultUnderwriter?.id || '';
        const boxxUsrId = localStorage.getItem('bid');
        await new Promise<any>(async (resolve) => {
          this.boxxUserService.GetById(boxxUsrId).subscribe((dataUW) => {
            let currentuserRole = dataUW?.data?.systemUser?.role?.name;
            if (currentuserRole === USER_ROLES.Underwriter) {
              this.defaultUnderwriterName =
                dataUW?.data?.firstName + ' ' + dataUW?.data?.lastName;
              this.defaultUnderwriterId = dataUW?.data?.id || '';
            }
            resolve('true');
          });
        });
        const { hasSubProduct, subProductName, subProductRiskId } =
          await this.getSubProductData(risk);
        const { hasParentProduct, parentRiskId } =
          await this.getParentProductData(risk);

        let isChecked =
          risk.standaloneSellable === 1 && data.data?.length === 1;
        if (this.products?.length > 0) {
          const productData = this.products.find(
            (product) => product.key === risk.name,
          );
          isChecked = productData?.checked || isChecked;
        }
        return {
          key: risk.name,
          value: risk.name,
          description: risk.description,
          checked: isChecked,
          active: active,
          addons: [],
          underwriter: this.defaultUnderwriterName,
          underwriterId: this.defaultUnderwriterId,
          riskId: risk.id,
          standaloneSellable: risk.standaloneSellable === 1,
          hasSubProduct,
          subProductName,
          subProductRiskId,
          hasParentProduct,
          parentRiskId,
          riskRegionId: id,
        };
      }),
    );

    return riskProducts;
  }

  async getSubProductData(risk): Promise<{
    hasSubProduct: boolean;
    subProductName: string;
    subProductRiskId: any;
  }> {
    if (risk.standaloneSellable === 1) {
      const getSubProduct = this.riskRegionService.getBySellableWithRiskId(
        risk.id,
      );
      const getSubProductResponse = await firstValueFrom(getSubProduct);
      if (getSubProductResponse?.data?.length > 0) {
        return {
          hasSubProduct: true,
          subProductName: getSubProductResponse?.data[0]?.name,
          subProductRiskId: getSubProductResponse?.data[0]?.id,
        };
      }
    }
    return { hasSubProduct: false, subProductName: '', subProductRiskId: null };
  }

  async getParentProductData(
    risk,
  ): Promise<{ hasParentProduct: boolean; parentRiskId: any }> {
    if (risk.standaloneSellable !== 1) {
      const getParentProduct = this.riskRegionService.getRiskById(risk.id);
      const getParentProductResponse = await firstValueFrom(getParentProduct);
      if (
        getParentProductResponse?.data?.id &&
        getParentProductResponse?.data?.sellableWithRiskId
      ) {
        return {
          hasParentProduct: true,
          parentRiskId: getParentProductResponse?.data?.id,
        };
      }
    }
    return { hasParentProduct: false, parentRiskId: null };
  }

  loadRiskServices(riskProducts) {
    return new Promise<any>(async (resolve, reject) => {
      const riskIds = riskProducts.map(({ riskId }) => riskId).join(',');
      this.riskRegionService.getRiskServicesById(riskIds).subscribe({
        next: (data) => {
          const addons = data.data?.map((item: any) => ({
            name: item.description,
            id: item.id,
            value: item.serviceName,
            riskId: item.risk.id,
          }));

          riskProducts = riskProducts.map((item) => ({
            ...item,
            addons: addons.filter((a) => a.riskId === item.riskId),
          }));

          this.store.dispatch(
            CreateQuoteAction.loadProductsSuccess({ products: riskProducts }),
          );
          this.setLoader(false, 'unset');
          this.showSpinner = false;
          resolve(true);
        },
        error: (error) => {
          this.setLoader(false, 'unset');
          this.showSpinner = false;
          reject(false);
        },
      });
    });
  }

  getStandaloneSellablePdts(pdts) {
    return pdts?.filter((pdt) => pdt.standaloneSellable) ?? [];
  }

  private async loadGetAllByPolicyPeriodId(policyPeriodId) {
    return new Promise<any>(async (resolve, reject) => {
      this.policyRiskService.GetAllByPolicyPeriodId(policyPeriodId).subscribe({
        next: (data) => {
          if (data?.data[0]) {
            this.policyRisks = data?.data;
            this.effectiveExpiryDatesTBDFlag =
              data?.data[0]?.policyPeriod?.effectiveExpiryDatesTBDFlag;
            this.store.dispatch(
              new CreateQuoteAction.setPolicyPeriodId(
                this.policyRisks[0].policyPeriodId,
              ),
            );
            this.selectedProductList = data?.data[0].risk;
            const latestTransactionTypeIndex =
              this.policyRisks[0].PolicyRiskTrxes.length - 1;
            this.store.dispatch(
              new CreateQuoteAction.updateUiContents({
                transactionType:
                  this.policyRisks[0].PolicyRiskTrxes[
                    latestTransactionTypeIndex
                  ].policyRiskTrxType.description,
                transactionTypeId: Number(
                  this.policyRisks[0].PolicyRiskTrxes[
                    latestTransactionTypeIndex
                  ].type,
                ),
              }),
            );

            this.store.dispatch(
              new CreateQuoteAction.UpdateInsuredAction({
                policyPeriodId: this.policyRisks[0].policyPeriodId,
              }),
            );

            this.riskTrnxs = data.data.flatMap((dataObj) => {
              const riskTrnxs = dataObj.PolicyRiskTrxes || {};
              return riskTrnxs;
            });
            let latestUnderwriterList = [];
            let latestUnderwriterMailIdList = [];
            this.policyRiskIds = [];
            this.riskTrnxs.forEach((trnx) => {
              this.policyRiskIds.push(trnx.policyRiskId);
              this.boxxUserService
                .GetById(trnx.underwriterId)
                .subscribe((dataUW) => {
                  this.selectUnderwriterDetails = {
                    id: dataUW.data?.id,
                    key: dataUW.data?.name,
                    value: dataUW.data?.name,
                  };
                  latestUnderwriterList = [
                    ...latestUnderwriterList,
                    dataUW.data?.firstName + ' ' + dataUW.data?.lastName,
                  ];
                  latestUnderwriterMailIdList = [
                    ...latestUnderwriterMailIdList,
                    dataUW.data?.systemUser?.loginEmail ?? '',
                  ];
                  this.underWriters = latestUnderwriterList;
                  const emailIds = this.store.pipe(select(getAllMailIds));
                  emailIds
                    .pipe(take(1))
                    .subscribe((email) => (this.mailData = email));
                  let mailIdList = {
                    ...this.mailData,
                    underwriter: [...latestUnderwriterMailIdList],
                  };
                  this.store.dispatch(
                    new CreateQuoteAction.setAllMailId(mailIdList),
                  );

                  if (this.underWriters.length === this.riskTrnxs.length) {
                    this.productList.map((product: any) => {
                      if (product.hasSubProduct) {
                        this.disabledDropdownList = [
                          ...this.disabledDropdownList,
                          product.subProductName,
                        ];
                      }
                    });
                  }
                });
            });
          }
          resolve(true);
        },
        error: (error) => {
          this.setLoader(false, 'unset');
          this.showSpinner = false;
          reject(false);
        },
      });
    });
  }

  handleBackBtn() {
    this.newQuoteService.setInsuredId(this.insuredId);
    this.store.dispatch(new CreateQuoteAction.setIsNavigatedFromRiskAnalysis());
    this.router.navigate(['/dashboard/workflow3/new'], {
      relativeTo: this.activatedRoute,
      skipLocationChange: true,
    });
  }

  async handleRiskAction(action: TransactionRiskActions) {
    this.setLoader(true, 'hidden');
    const getPolicyByID = this.policyPeriodService.GetAllByPolicyId(
      this.quoteId,
    );
    let dataPId = await firstValueFrom(getPolicyByID);
    const policyPeriodId = dataPId.data[0].id;
    const policyDashboardServiceObs =
      this.policyDashboardService.Get(policyPeriodId);
    const policyDashboardData = await firstValueFrom(policyDashboardServiceObs);
    this.policyPeriod = {
      effectiveDt: policyDashboardData.data.effectiveDt,
      expiryDt: policyDashboardData.data.expiryDt,
    };

    this.updateRiskActionData();
    this.actionPopupDetails.action = action;
    this.showActionPopup = true;
    this.setLoader(false, 'unset');
  }

  updateRiskActionData() {
    this.form$ = this.store.select(getQuoteSelector);
    this.form$.pipe(take(1)).subscribe((event) => {
      this.eventData = event;
    });
    this.actionPopupDetails = {
      quoteNumber:
        this.eventData.policyId == null || this.eventData.policyId == ''
          ? this.eventData.ui.policyId
          : this.eventData.policyId,
      effectiveDate: this.eventData.ui.startDate
        ? getFormatDate(
            this.eventData.ui.startDate,
            this.shortDateFormat,
            this.longDateFormat,
          )
        : 'n/a',
      tbdFlag: this.effectiveExpiryDatesTBDFlag,
      quotePeriod: {
        effectiveDt: this.policyPeriod.effectiveDt,
        expiryDt: this.policyPeriod.expiryDt,
      },
      insured: this.eventData.ui.insuredName,
      brokerage: this.eventData.ui.broker,
      branch: this.eventData.ui.branch,
      reasonOptions: [],
      action: TransactionRiskActions.None,
      statusType: this.translate.instant('productForm.heading.submission'),
      brokerInfo: this.eventData.ui.broker + ', ' + this.eventData.ui.branch,
    };
  }

  handleConfirmRiskAction(event: {
    action: TransactionRiskActions;
    selectedReasonId: number;
  }) {
    const PolicyDeclineRequest: PolicyDeclineRequest = {
      policyPeriodId: Number(this.eventData.ui.policyPeriodId),
      declineReason: Number(event.selectedReasonId),
    };

    switch (event.action) {
      case TransactionRiskActions.Decline:
        this.policyLifecycleService
          .TransactionRiskDecline(PolicyDeclineRequest)
          .subscribe({
            next: (response) => {
              this.handleRiskActionApiResponse(
                TransactionRiskActions.Decline,
                response,
              );
            },
            error: (e) => {
              this.handleApiError(e);
            },
            complete: () => {
              const errAlert = {
                show: true,
                type: 'error',
                headerText: 'policyPeriod.update.success.header',
                bodyText: '',
              };
              this.alertService.addAlert(errAlert);
            },
          });
        break;
      default:
        const alertErrMsg = this.translate.instant('error.invalidRiskAction');
        this.handleApiError(alertErrMsg);
        this.showActionPopup = false;
        break;
    }
  }

  async handleSaveDraft(event) {
    this.fromSaveDraft = true;
    this.alertService.clearAlerts(-1);
    this.setLoader(true, 'hidden');
    this.showSpinner = true;
    let brokerageBORId = this.selectedBroker?.id,
      branchBORId = this.selectedBranch?.id,
      producerBORId = this.selectedProducer?.id;
    this.isContinue = false;
    this.formSubmitted = true;
    //Save Producer, branch, broker
    if (this.brokerageForm.valid) {
      if (!brokerageBORId || !branchBORId || !producerBORId) {
        this.setLoader(false, 'unset');
        this.showSpinner = false;
        return;
      }
      const hasCommission = await this.hasCommissionForSelectedBroker();

      this.store.dispatch(
        new CreateQuoteAction.updateUiContents({
          broker: this.brokerageForm?.value['broker'],
          producer: this.brokerageForm?.value['producer'],
          branch: this.brokerageForm?.value['branch'],
          brokerageId: brokerageBORId,
          branchId: branchBORId,
          producerId: producerBORId,
        }),
      );
      if (this.selectedProducer) {
        this.store.dispatch(
          new CreateQuoteAction.UpdateInsuredAction({
            brokerageId: brokerageBORId,
            branchId: branchBORId,
            producerId: producerBORId,
            broker: this.selectedBroker.name,
          }),
        );
      }
      // save broker, branch and producer
      this.store
        .select(getUiPolicyID)
        .pipe(take(1))
        .subscribe(async (policyId) => {
          if (policyId && this.selectedBroker) {
            const payload = {
              brokerageBORId: brokerageBORId,
              brokerageBranchBORId: branchBORId,
              brokerageProducerBORId: producerBORId,
            };
            try {
              const updateBrokerProducer = this.policyService.Update(
                policyId,
                payload,
              );
              let response = await firstValueFrom(updateBrokerProducer);
            } catch (error) {
              this.hasError++;
              if (![500].includes(error?.status)) {
                let errAlert: alertDetails = {
                  type: 'error',
                  headerText: 'common.errorMsg',
                  bodyText: getErrorMessage(error),
                };
                this.alertService.addAlert(errAlert);
              }
            }
          } else {
          }
        });
      if (this.hasPatternErrorsInRiskAnswers()) {
        this.validateRiskAnswers = true;
        this.setLoader(false, 'unset');
        this.showSpinner = false;
        return;
      }
      this.validateRiskAnswers = false;
      this.destroy$.next();
      this.destroy$.complete();
      let products, selectedPdts, ui, formData: any;

      await this.form$.subscribe((event) => (formData = event));
      ui = formData.ui;
      products = formData.products;
      this.loadGetAllByPolicyPeriodId(ui.policyPeriodId);
      selectedPdts = await this.getSelectedPdtStandaloneOptions(products);
      const pdtValues = selectedPdts.map((p) => p.value);

      pdtValues.forEach((p) => {
        const riskQnIds = this.riskForm?.value[p][0];
        for (let id in riskQnIds) {
          this.store.dispatch(
            new CreateQuoteAction.UpdateRiskAnswerAction({
              product: p,
              id: Number(id),
              value: riskQnIds[id],
            }),
          );
        }
      });

      if (ui.saveAtomicTrx && (ui.policyPeriodId > 0 || ui.policyId > 0)) {
        if (!ui.isNavigatedFromQuoteCalc) {
          await this.saveRisk(ui, selectedPdts, formData);
        }

        // save underwriter
        if (this.policyRisks) {
          for (const [i, product] of selectedPdts.entries()) {
            const policyRisk = this.policyRisks.find(
              (policyRisk) => policyRisk.riskId == product.riskId,
            );
            if (policyRisk) {
              const policyRiskTrx = policyRisk.PolicyRiskTrxes[0];
              const riskTrxData = {
                underwriterId: this.isNumeric(product.underwriter)
                  ? Number(product.underwriter)
                  : product?.underwriterId,
              };
              const updated = await this.updateQuote(
                policyRiskTrx.id,
                riskTrxData,
              );
            }
          }
        } else {
          this.setLoader(false, 'unset');
          this.showSpinner = false;
        }
      } else {
        const risk = selectedPdts.map((dataObj) => ({
          id: dataObj.riskId,

          underwriterId: this.isNumeric(dataObj.underwriter)
            ? Number(dataObj.underwriter)
            : dataObj?.underwriterId,
          confRiskLimitId: this.getLimitId(dataObj.riskId),
          riskAnswer: formData.riskQuestions[dataObj.value].map((item) => ({
            riskQuestionId: item.id,
            riskAnswer: item?.value?.toString() || '',
          })),
        }));
        const data = {
          policy: {
            brokerageBORId: brokerageBORId,
            brokerageBranchBORId: branchBORId,
            brokerageProducerBORId: producerBORId,
            insuredId: this.insuredId,
          },

          policyPeriod: {
            effectiveDt: getFormatDate(
              ui.startDate,
              this.shortDateFormat,
              'YYYY-MM-DD',
            ),
            expiryDt: getFormatDate(
              ui.endDate,
              this.shortDateFormat,
              'YYYY-MM-DD',
            ),
            effectiveExpiryDatesTBDFlag: this.effectiveExpiryDatesTBDFlag
              ? 1
              : 0,
          },

          risk: risk,
          quoteOption: false,
        };
        try {
          const createQuote = await this.createQuote(data);
        } catch (error) {
          this.hasError++;

          if (![500].includes(error?.status)) {
            const errAlert = {
              show: true,
              type: 'error',
              headerText: 'common.errorHeader',
              bodyText: getErrorMessage(error),
            };
            if (this.alertService.addAlert(errAlert))
              this.setLoader(false, 'unset');
            this.showSpinner = false;
          } else {
            this.setLoader(false, 'unset');
            this.showSpinner = false;
          }
        }
      }
    } else {
      this.hasError += 1;

      this.brokerageErrMsg = this.translate.instant(
        'quote4Flow.pleaseSelectBrokerage',
      );
      window.scroll(0, 0);
      const errAlert = {
        show: true,
        type: 'error',
        headerText: 'common.errorHeader',
        bodyText: this.translate.instant(
          'quote4Flow.pleaseSelectBrokerageToSaveDraft',
        ),
      };
      if (this.alertService.addAlert(errAlert)) {
        this.setLoader(false, 'unset');
        this.showSpinner = false;
      }
    }
  }
  async createQuote(data) {
    let showCommissionError = false;
    return new Promise((resolve, reject) => {
      this.policyAtomicTrxService.CreateQuote(data).subscribe({
        next: (data) => {
          const policyId = data.data.id;
          this.quoteId = policyId;
          if (this.quoteId) {
            this.buttonLabel = this.translate.instant(
              'workFlow3.components.riskAnalysis.button.save',
            );
          } else {
            this.buttonLabel = this.translate.instant(
              'workFlow3.components.riskAnalysis.button.saveSubmission',
            );
          }
          if (data?.data?.warning) {
            showCommissionError = true;
            const errAlert = {
              type: 'warn',
              headerText: 'common.warnMsg',
              bodyText: data?.data?.warning ?? '',
            };
            this.alertService.addAlert(errAlert);
          } else {
            const errAlert = {
              type: 'success',
              headerText: 'common.successHeader',
              bodyText: this.translate.instant(`policy.create.success.body`, {
                id: this.quoteId,
              }),
            };
            this.alertService.addAlert(errAlert);
          }
          this.store.dispatch(
            new CreateQuoteAction.UpdateInsuredAction({
              saveAtomicTrx: true,
              policyId: policyId,
            }),
          );
          this.policyPeriodService
            .GetAllByPolicyId(policyId)
            .subscribe((dataPId) => {
              const policyPeriodId = dataPId.data[0].id;
              this.policyRiskService
                .GetAllByPolicyPeriodId(policyPeriodId)
                .subscribe((dataResponse) => {
                  // TO DO - change logic for multiproduct

                  this.policyRiskId =
                    dataResponse.data[0]?.PolicyRiskTrxes[0]?.policyRiskId;
                  this.store.dispatch(
                    new CreateQuoteAction.setPolicyPeriodId(policyPeriodId),
                  );
                  const latestTransactionTypeIndex =
                    dataResponse.data[0]?.PolicyRiskTrxes.length - 1;
                  this.store.dispatch(
                    new CreateQuoteAction.updateUiContents({
                      transactionType:
                        dataResponse.data[0]?.PolicyRiskTrxes[
                          latestTransactionTypeIndex
                        ].policyRiskTrxType.description,
                      transactionTypeId: Number(
                        dataResponse.data[0]?.PolicyRiskTrxes[
                          latestTransactionTypeIndex
                        ].type,
                      ),
                    }),
                  );
                  this.store.dispatch(
                    new CreateQuoteAction.UpdateInsuredAction({
                      policyPeriodId: policyPeriodId,
                    }),
                  );
                  if (showCommissionError === true)
                    this.loadGetAllByPolicyPeriodId(policyPeriodId);
                  resolve(true);
                });
            });
          this.setLoader(false, 'unset');
          this.showSpinner = false;
        },
        error: (error) => {
          this.hasError++;

          if (![500].includes(error?.status)) {
            const errAlert = {
              type: 'error',
              headerText: 'common.errorHeader',
              bodyText: getErrorMessage(error),
            };
            if (this.alertService.addAlert(errAlert)) this.showSpinner = false;
            this.setLoader(false, 'unset');
          } else {
            this.setLoader(false, 'unset');
            this.showSpinner = false;
          }
          reject(error);
        },
      });
    });
  }
  async updateQuote(policyRiskTrxId, riskTrxData) {
    return new Promise((resolve, reject) => {
      this.policyRiskTrxService.Update(policyRiskTrxId, riskTrxData).subscribe({
        next: (_) => {
          const successAlert = {
            type: 'success',
            headerText: 'common.successHeader',
            bodyText: 'policy.update.success.body',
          };
          this.alertService.addAlert(successAlert);
          this.setLoader(false, 'unset');
          this.showSpinner = false;
          resolve('success');
        },
        error: (error) => {
          this.hasError++;
          if (![500].includes(error?.status)) {
          }
          this.setLoader(false, 'unset');
          this.showSpinner = false;
          reject(error);
        },
      });
    });
  }
  async handleContinue(e, formDetails) {
    this.alertService.clearAlerts(-1);
    this.setLoader(true, 'hidden');
    this.showSpinner = true;
    if (
      this.permissionList[this.currentScreen] &&
      this.currentQuoteStatus !== 'quote-closed'
    ) {
      this.isContinue = true;
      this.formSubmitted = true;
      this.validateRiskAnswers = true;
      if (await this.CheckFormValid(formDetails)) {
        const hasCommission = await this.hasCommissionForSelectedBroker();
        //check broker has commission
        if (hasCommission) {
          this.alertService.clearAlerts(-1);
          if (this.isNavigatedFromQuoteCalc) {
            if (this.formChanged(formDetails)) {
              this.updateRiskActionData();
              this.showRecalcConfModal = true;
              this.alertService.clearAlerts(-1);
              this.setLoader(false, 'unset');
              this.showSpinner = false;
            } else {
              this.setLoader(false, 'unset');
              this.showSpinner = false;
              this.navigateToNextPage();
            }
          } else {
            this.handleProceedContinue(e, formDetails);
          }
        } else {
          this.setLoader(false, 'unset');
          this.showSpinner = false;
          this.handleProceedContinue(e, formDetails);
        }
      } else {
        this.setLoader(false, 'unset');
        this.showSpinner = false;
        return;
      }
    } else {
      this.navigateToNextPage();
      this.setLoader(false, 'unset');
      this.showSpinner = false;
    }
  }
  private handleRiskActionApiResponse(type, response: any) {
    this.showActionPopup = false;
    if (response && type === TransactionRiskActions.Decline) {
      const errAlert = {
        show: true,
        type: 'Success',
        headerText: 'common.successHeader',
        bodyText: 'policy.declined.success.body',
      };
      this.alertService.addAlert(errAlert);
      let path = 'dashboard/quotes';
      let quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();
      if (quoteFlowSteps === 'workflow3') {
        path = 'dashboard/workflow3/quotes';
      }
      this.router
        .navigate([path], {
          skipLocationChange: true,
        })
        .then(() => {
          const alertData = {
            show: true,
            type: 'success',
            headerText: 'common.successHeader',
            bodyText: this.translate.instant('policy.declined.success.body'),
          };
          this.policyPeriodService.setAlertData(alertData);
        });
    } else {
      const errAlert = {
        show: true,
        type: 'error',
        headerText: 'common.errorHeader',
        bodyText: 'error.actionIsNotImplementedYet',
      };
      this.alertService.addAlert(errAlert);
    }
  }
  handleApiError(error: any) {
    this.showActionPopup = false;
    if (typeof error === 'string' || error instanceof String) {
      if (error?.toLowerCase().includes('error code: 404', 0)) {
        const errAlert = {
          show: true,
          type: 'error',
          headerText: 'common.errorHeader',
          bodyText: 'quote4Flow.error.quoteNotFound',
        };
        this.alertService.addAlert(errAlert);
        return;
      }
    }
    const errAlert = {
      show: true,
      type: 'error',
      headerText: 'common.errorHeader',
      bodyText: getErrorMessage(error),
    };
    if (![500].includes(error?.status)) {
      this.alertService.addAlert(errAlert);
    }
  }

  hasPatternErrorsInRiskAnswers() {
    const controls = this.subRiskQuestionForm?.controls;
    let hasPatternError = false;

    if (controls) {
      for (const controlName in controls) {
        if (controls.hasOwnProperty(controlName)) {
          const control = controls[controlName];
          if (control?.errors?.pattern?.value) {
            hasPatternError = true;
            break;
          }
        }
      }
    }

    if (hasPatternError) {
      return true;
    }
    return false;
  }
  private async saveRisk(
    ui: any,
    selectedPdts: any,
    formData: any,
    isCalledFromContinue = false,
  ) {
    try {
      if (ui?.policyPeriodId === 0) {
        return;
      }
      const policyDashboardServiceObs = this.policyDashboardService.Get(
        ui.policyPeriodId,
      );
      const policyDashboardData = await firstValueFrom(
        policyDashboardServiceObs,
      );

      for (let dataObj of policyDashboardData?.data?.PolicyRisks) {
        const policyRiskId = dataObj?.id;
        const answers =
          this.policyAnswerService.GetAllByQuestionIdAndPolicyRiskId(
            policyRiskId,
            1,
            500,
          );
        let dataAnswers = await firstValueFrom(answers);
        const policyQuestionsLists = dataAnswers?.data;

        const updatedQuestions = formData.riskQuestions[dataObj?.risk?.name]
          .map((questions) => {
            const policyQuestionsList = policyQuestionsLists.find(
              (answer) => answer?.riskQuestionId === questions.id,
            );
            if (policyQuestionsList) {
              const riskAnswerChanged =
                policyQuestionsList?.riskAnswer !== questions?.value;

              if (
                riskAnswerChanged &&
                policyQuestionsList?.id &&
                questions?.value
              ) {
                return {
                  id: Number(policyQuestionsList?.id),
                  riskAnswer: questions?.value?.toString() || '',
                };
              }
            }
            // Return null for rows with unchanged answer values
            return null;
          })
          .filter((updatedQuestion) => updatedQuestion !== null);

        if (updatedQuestions.length !== 0) {
          const riskAnswerArray = {
            policyRiskId: policyRiskId,
            answers: updatedQuestions,
          };
          const policyRiskQuestions =
            this.policyAnswerService.UpdateBulkQuestionAnswers(riskAnswerArray);
          let dataPolicyRiskQuestions =
            await firstValueFrom(policyRiskQuestions);

          if (dataPolicyRiskQuestions) {
            if (!isCalledFromContinue) {
              this.setLoader(false, 'unset');
              this.showSpinner = false;
            }
          }
        }
        this.store.dispatch(
          CreateQuoteAction.loadRiskQuestionsSuccess({
            riskQuestions: {
              [dataObj?.risk?.name]:
                formData.riskQuestions[dataObj?.risk?.name],
            },
          }),
        );
      }
    } catch (error) {
      if (!isCalledFromContinue) {
        this.setLoader(false, 'unset');
        this.showSpinner = false;
      } else {
        this.setLoader(false, 'unset');
      }
      if (![500].includes(error?.status)) {
        const errAlert = {
          show: true,
          type: 'error',
          headerText: 'common.errorHeader',
          bodyText: getErrorMessage(error),
        };
        this.alertService.addAlert(errAlert);
      } else {
        const errAlert = {
          show: true,
          type: 'error',
          headerText: 'common.errorHeader',
          bodyText: getErrorMessage(error?.error),
        };
        this.alertService.addAlert(errAlert);
      }
    }
  }
  isNumeric(val: string) {
    return !isNaN(Number(val));
  }
  formChanged(formValue): boolean {
    if (formValue.ui.startDate !== this.initialFormValue.startDate) {
      return true;
    }
    if (formValue.ui.endDate !== this.initialFormValue.endDate) {
      return true;
    }
    if (this.currentBrokerValue !== this.initialFormValue.brokerBranchProd) {
      return true;
    }
    for (let products of this.initialFormValue.products) {
      let currentProduct = formValue.products.find(
        (x) => x.value === products.value,
      );
      if (
        currentProduct.underwriterId !== products.underwriterId ||
        currentProduct.underwriter !== products.underwriter
      ) {
        return true;
      }
    }
    for (let product of Object.keys(this.initialFormValue.riskQuestions)) {
      let pdtFormCntrlss = (this.riskForm.get(product) as FormArray).at(
        0,
      ) as FormGroup;
      for (let question of this.initialFormValue.riskQuestions[product]) {
        if (pdtFormCntrlss.contains(question.id)) {
          if (
            typeof pdtFormCntrlss.controls[`${question.id}`].value === 'number'
          ) {
            if (
              pdtFormCntrlss.controls[`${question.id}`].value !==
              +question.value
            ) {
              return true;
            }
          } else {
            if (
              pdtFormCntrlss.controls[`${question.id}`].value !== question.value
            ) {
              return true;
            }
          }
        } else {
          return true;
        }
      }
    }
    return false;
  }
  navigateToNextPage() {
    this.alertService.clearAlerts(-1);
    this.router.navigate(['/dashboard/workflow3/quoting'], {
      relativeTo: this.activatedRoute,
      skipLocationChange: true,
    });
  }
  isFormValid(form) {
    if (
      form.products.findIndex((obj) => obj.checked === true) !== -1 &&
      this.isStartDateValid &&
      this.isEndDateValid &&
      this.riskForm.get(this.selectedProductStandaloneOptions[0].value)
        .status == 'VALID'
    ) {
      const selectedProducts = form.products.filter(
        (obj) => obj.checked === true,
      );
      const selectedQuestions = selectedProducts.filter(
        (obj) => obj.checked !== '',
      );
      const selectedUnderwritters = selectedProducts.filter(
        (obj) => obj.underwriter !== '',
      );

      if (!selectedQuestions && !selectedUnderwritters) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }
  isAllFormsFilled(selectedProducts) {
    const pdtValues = selectedProducts.map((pd) => pd.value);
    for (let p of pdtValues) {
      const riskQnIds = Object.values(this.riskForm?.value[p][0]);
      const hasNullOrEmptyValue = riskQnIds.some(
        (value) => value === null || value === '',
      );
      if (hasNullOrEmptyValue) {
        return false;
      }
    }
    return true;
  }
  private isQuoteRecordExists(ui: any) {
    return (
      (ui.saveAtomicTrx && ui.policyId > 0 && ui.policyPeriodId > 0) ||
      ui.isNavigatedFromQuoteCalc ||
      !!this.policyRiskId
    );
  }
  async calculatePremiumForDefaultQuote(risks) {
    try {
      for (const risk of risks) {
        // Premium Calculation
        for (const quoteOption of risk.PolicyRiskTrxes[0].QuoteOptions) {
          try {
            const riskRating = await this.riskRatingCalculate(quoteOption?.id);
            const ratingData = await firstValueFrom(riskRating);
          } catch (error) {
            if (error?.code === -20) {
              this.newQuoteService.setCreateDefaultQuoteWarning(error?.error);
            } else {
              this.newQuoteService.setCreateDefaultQuoteError(error?.error);
            }
          }
        }
      }
    } catch (error) {
      this.setLoader(false, 'unset');
      if (error?.code === -20) {
        this.newQuoteService.setCreateDefaultQuoteWarning(error?.error);
      } else {
        this.newQuoteService.setCreateDefaultQuoteError(error?.error);
      }
    }
  }
  async riskRatingCalculate(quoteOptionId): Promise<Observable<any>> {
    try {
      return this.riskRatingCalculateService.GetByQuoteOption(quoteOptionId);
    } catch (error) {
      return error;
    }
  }
  async populateDefaultQuoteOption(response) {
    try {
      let quoteOptions = [];
      let brokerageId, riskId, commissionRef;

      for (let risk of response.data) {
        const policyRiskTrxId = risk?.PolicyRiskTrxes[0].id;
        brokerageId = risk?.policyPeriod?.policy?.brokerageBORId;

        risk?.PolicyRiskTrxes[0]?.QuoteOptions.forEach((quoteOption) => {
          quoteOption.policyRiskTrxId = policyRiskTrxId;
          quoteOption.risk = risk?.risk;
          riskId = risk?.riskId;
          commissionRef = risk?.PolicyRiskTrxes[0].brokerCommissionPerc;
          quoteOptions.push(quoteOption);
        });
      }
      if (Number(commissionRef) === 0) {
        await Promise.all([
          new Promise<any>(async (resolve, reject) => {
            this.brokerageCommissionService
              .GetByRegionIdAndRiskIdsAndBrokerageId(
                this.regionId,
                riskId,
                brokerageId,
              )
              .subscribe((data) => {
                resolve(true);
                let defaultCommission = Number(
                  Number(data.data[0].commissionDefault * 100).toFixed(2) ?? 0,
                );
                let minCommission = Number(
                  Number(data.data[0].commissionMin * 100).toFixed(2) ?? 0,
                );
                this.defaultCommission =
                  defaultCommission !== 0 ? defaultCommission : minCommission;
              });
          }),
        ]);
      }
      const optionNumbers = quoteOptions
        .map((quoteOption) => quoteOption?.optionNumber)
        .filter((val, idx, arr) => arr.indexOf(val) === idx);
      for (let optionNumber of optionNumbers) {
        let quoteOptionGroup = {
          products: [],
          total: {
            premium: 0,
            premiumCalculated: 0,
            policyFee: 0,
            triaAmount: 0,
            netAmount: 0,
            taxesAmount: 0,
            totalAmount: 0,
            servicesAmount: 0,
            taxesPremiumAmount: 0,
            taxesServicesAmount: 0,
          },
          ui: {
            selectedForCompare: false,
            isQuoteBoxExpanded: true,
            optionSelected: false,
          },
        };
        quoteOptionGroup.products = quoteOptions
          ?.filter((quoteOption) => quoteOption.optionNumber == optionNumber)
          .map((quoteOption) => {
            const policyFee = quoteOption.policyFee
              ? Number(quoteOption.policyFee)
              : 0;
            const triaAmount = quoteOption.triaAmount
              ? Number(quoteOption.triaAmount)
              : 0;
            const netAmount =
              Number(quoteOption.premium ? quoteOption.premium : 0) +
              Number(
                quoteOption.servicesAmount ? quoteOption.servicesAmount : 0,
              );
            const taxesAmount =
              Number(
                quoteOption.taxesServicesAmount
                  ? quoteOption.taxesServicesAmount
                  : 0,
              ) +
              Number(
                quoteOption.taxesPremiumAmount
                  ? quoteOption.taxesPremiumAmount
                  : 0,
              );
            const totalAmount =
              Number(policyFee) +
              Number(triaAmount) +
              Number(netAmount) +
              Number(taxesAmount);
            const servicesAmount = Number(
              quoteOption.servicesAmount ? quoteOption.servicesAmount : 0,
            );
            const taxesPremiumAmount = Number(
              quoteOption.taxesPremiumAmount
                ? quoteOption.taxesPremiumAmount
                : 0,
            );
            const brokerCommissionPerc = Number(
              quoteOption.brokerCommissionPerc * 100,
            ).toFixed(2);

            const brokerCommissionPercent =
              Number(brokerCommissionPerc) !== 0
                ? brokerCommissionPerc
                : this.defaultCommission;

            return {
              ...quoteOption,
              key: quoteOption?.risk.name,
              value: quoteOption?.risk.name,
              description: 'description',
              checked: true,
              active: quoteOption.active,
              addons: [],
              underwriter: '',
              riskId: quoteOption?.risk.id,
              referralReasons: quoteOption?.QuoteOptionReasons || [],
              limit: quoteOption.limit,
              initialLimit: quoteOption.limit,
              brokerCommissionPerc: brokerCommissionPercent,
              initialBrokerCommissionPerc: brokerCommissionPercent,
              deductables: quoteOption.deductible,
              initialDeductible: quoteOption.deductible,
              standaloneSellable:
                response.data[0].risk?.standaloneSellable == 1,
              showLimitCustomInput: false,
              showDeductibleCustomInput: false,
              showPremiumCalcBtn: false,
              premium: quoteOption.premium,
              initialPremium: quoteOption.premium,
              showEditedPremiumCustomField: false,
              premiumCalculated: quoteOption.premium,
              initialPremiumCalculated: quoteOption.premium,
              editedPremiumDeviation: '',
              expandAddOns: false,
              quoteOptionId: quoteOption.id,
              optionNumber: quoteOption.optionNumber,
              showInvalidLimitErr: false,
              showInvalidLimitErrMsg: '',
              pdtAddOns: [],
              policyFee: policyFee,
              triaAmount: triaAmount,
              netAmount: netAmount,
              taxesAmount: taxesAmount,
              totalAmount: totalAmount,
              servicesAmount: servicesAmount,
              taxesPremiumAmount: taxesPremiumAmount,
              policyRiskTrxId: quoteOption?.policyRiskTrxId,
            };
          });

        for (let product of quoteOptionGroup?.products) {
          quoteOptionGroup.total.premium += Number(product.premium);

          quoteOptionGroup.total.premiumCalculated += Number(
            product.premiumCalculated,
          );
          quoteOptionGroup.total.triaAmount += Number(product.triaAmount);
          quoteOptionGroup.total.policyFee += Number(product.policyFee);
          quoteOptionGroup.total.taxesAmount += Number(product.taxesAmount);
          quoteOptionGroup.total.servicesAmount += Number(
            product.servicesAmount,
          );

          quoteOptionGroup.total.taxesPremiumAmount += Number(
            product.taxesPremiumAmount,
          );
          quoteOptionGroup.total.taxesServicesAmount += Number(
            product.taxesServicesAmount,
          );
          quoteOptionGroup.total.netAmount += Number(product.netAmount);
          quoteOptionGroup.total.totalAmount += Number(product.totalAmount);
        }

        this.store.dispatch(
          new CreateQuoteAction.updateUiContents({
            premium: quoteOptionGroup.total.premium,
          }),
        );

        quoteOptionGroup.ui.optionSelected =
          quoteOptionGroup?.products[0]?.optionSelected == 1;
        const quoteOptionCopy = this.getCopy(quoteOptionGroup);
        this.store.dispatch(
          new CreateQuoteAction.AddQuoteOption({
            quote: { ...quoteOptionCopy },
          }),
        );
        let products = [];
        let updatedProductsWithId = [];
        this.form$
          .pipe(take(1))
          .subscribe((event) => (products = event.products));
        if (products?.length > 0) {
          updatedProductsWithId = products.map((product) => {
            const policyRiskTrxId = quoteOptionCopy.products.filter(
              (pdt) => pdt.key == product.key,
            )[0]?.policyRiskTrxId;
            return {
              ...product,
              policyRiskTrxId,
            };
          });

          this.store.dispatch(
            CreateQuoteAction.loadProductsSuccess({
              products: updatedProductsWithId,
            }),
          );
        }
      }
    } catch (error) {
      return error;
    }
  }
  getCopy(dataObj) {
    return {
      products: dataObj.products.map((object) => ({ ...object })),
      total: { ...dataObj.total },

      ui: { ...dataObj.ui },
    };
  }
  closeModalRecalculateConf() {
    this.showRecalcConfModal = false;
    this.actionPopupDetails = {
      quoteNumber: null,
      effectiveDate: null,
      tbdFlag: null,
      quotePeriod: null,
      insured: null,
      brokerage: null,
      branch: null,
      reasonOptions: null,
      action: null,
      statusType: null,
      brokerInfo: null,
    };
  }
  handleSubmissionRecalculate(action: any, formData: any) {
    this.closeModalRecalculateConf();
    this.handleProceedContinue(action, formData);
  }
  handleDiscardRecalculate(action: any) {
    this.resetFormData();
    this.closeModalRecalculateConf();
  }
  resetFormData() {
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        startDate: this.initialFormValue.startDate,
        endDate: this.initialFormValue.endDate,
      }),
    );
    this.currentBrokerValue =
      this.initialFormValue['brokerage'].name +
      ' / ' +
      this.initialFormValue['branch'].name +
      ' / ' +
      this.initialFormValue['producer'].name;

    this.brokerageDetails = {
      branch: this.initialFormValue['branch'].name,
      broker: this.initialFormValue['brokerage'].name,
      producer: this.initialFormValue['producer'].name,
      branchId: this.initialFormValue['branch'].id,
      brokerageId: this.initialFormValue['brokerage'].id,
      producerId: this.initialFormValue['producer'].id,
    };
    this.brokerageForm.setValue({
      branch: this.initialFormValue['branch'].name,
      broker: this.initialFormValue['brokerage'].name,
      producer: this.initialFormValue['producer'].name,
    });
    this.selectedBroker = this.initialFormValue['brokerage'];
    this.selectedBranch = this.initialFormValue['branch'];
    this.selectedProducer = this.initialFormValue['producer'];
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        broker: this.selectedBroker.name,
        producer: this.selectedProducer.name,
        branch: this.selectedBranch.name,
        brokerageId: this.selectedBroker.id,
        branchId: this.selectedBranch.id,
        producerId: this.selectedProducer.id,
      }),
    );
    for (let products of this.initialFormValue.products) {
      this.underwriterReset(
        products.underwriter,
        this.initialFormValue.products,
        products.value,
      );
    }
    for (let product of Object.keys(this.initialFormValue.riskQuestions)) {
      let pdtFormCntrlss = (this.riskForm.get(product) as FormArray).at(
        0,
      ) as FormGroup;
      for (let question of this.initialFormValue.riskQuestions[product]) {
        if (pdtFormCntrlss.contains(question.id)) {
          pdtFormCntrlss.controls[`${question.id}`].setValue(question.value);
        }
      }
      let riskCategoryFormGroup = (
        this.riskCategoryForm.get(product) as FormArray
      ).at(0) as FormGroup;
      for (
        let categoryNo = 0;
        categoryNo < this.initialFormValue.riskCategory[product].length;
        categoryNo++
      ) {
        let category = Object.keys(
          this.initialFormValue.riskCategory[product][categoryNo],
        )[0];
        riskCategoryFormGroup.controls[`${category}`].setValue(
          this.initialFormValue.riskCategory[product][category],
        );
        if (!this.categoryProperties.hasOwnProperty(product + categoryNo)) {
          this.categoryProperties[product + categoryNo] = {
            checkAllYesBtn: null,
          };
        } else {
          this.categoryProperties[product + categoryNo].checkAllYesBtn = false;
        }
      }
    }
  }
  underwriterReset(underwriter, products, productVal) {
    let index = products.findIndex((obj) => obj.value == productVal);
    const underwriterId = products[index]?.underwriterId;
    this.store.dispatch(
      new CreateQuoteAction.UpdateUnderwriterForPdtAction({
        index,
        underwriter,
        underwriterId,
      }),
    );
  }

  async hasCommissionForSelectedBroker() {
    try {
      let brokerageId = this.selectedBroker?.id,
        producerId = this.selectedProducer?.id,
        regionId;
      let selectedProducts: any[];
      let riskIds = '';
      await this.form$.pipe(take(1)).subscribe({
        next: (event) => {
          if (!brokerageId) {
            brokerageId = event?.ui?.brokerageId;
          }

          if (!producerId) {
            producerId = event?.ui?.producerId;
          }
          regionId = event.ui.regionId;
          selectedProducts = event.products.filter((obj) => obj.checked);
          riskIds = selectedProducts.map((product) => product.riskId).join(',');
        },
      });
      if (producerId) {
        const getProducerCommission =
          this.brokerageProducerCommissionService.GetByRegionIdAndRiskIdsAndProducerId(
            regionId,
            riskIds,
            producerId,
          );
        const producerCommissionResponse = await firstValueFrom(
          getProducerCommission,
        );
        let producerCommissionNotExistsFor = [];

        if (producerCommissionResponse.data[0]) {
          riskIds
            ?.split(',')
            .map(Number)
            .forEach((riskId) => {
              const brokerForRiskId = producerCommissionResponse.data.filter(
                (broker) =>
                  broker.active && broker?.riskRegion.risk.id === riskId,
              );
              if (brokerForRiskId.length === 0) {
                producerCommissionNotExistsFor.push(riskId);
              }
            });
          this.commissionDisabled = producerCommissionResponse.data.some(
            (broker) => !broker.active,
          );
          this.commissionId =
            producerCommissionResponse.data.find((broker) => broker.id)?.id ||
            null;
        } else {
          producerCommissionNotExistsFor.push(
            ...riskIds.split(',').map(Number),
          );
        }
        if (producerCommissionNotExistsFor.length > 0) {
          let riskIdWithoutCommission = [];

          const getBrokerCommission =
            this.brokerageCommissionService.GetByRegionIdAndRiskIdsAndBrokerageId(
              regionId,
              riskIds,
              brokerageId,
            );
          const brokerCommissionResponse =
            await firstValueFrom(getBrokerCommission);
          let riskIdsToAddCommission = [];
          if (brokerCommissionResponse.data.length === 0) {
            riskIdsToAddCommission = riskIds.split(',').map(Number);
            riskIdWithoutCommission = riskIdsToAddCommission;
          } else {
            riskIdsToAddCommission = producerCommissionNotExistsFor;
          }
          if (brokerCommissionResponse.data?.length > 0) {
            riskIdsToAddCommission.forEach((riskId) => {
              const commissionForRiskId = brokerCommissionResponse.data.filter(
                (broker) =>
                  broker.active && broker?.riskRegion.risk.id === riskId,
              );
              if (commissionForRiskId.length === 0) {
                riskIdWithoutCommission.push(riskId);
              }
            });
            this.commissionDisabled = brokerCommissionResponse.data.some(
              (broker) => !broker.active,
            );
            this.commissionId =
              brokerCommissionResponse.data.find((broker) => broker.id)?.id ||
              null;
          }
          if (riskIdWithoutCommission?.length > 0) {
            this.noCommission = true;
            this.showSpinner = false;
            this.showBodySpinner = false;
            this.alertInfo = {
              alertType: 'error',
              alertHeader: this.translate.instant(
                'workFlow3.components.producerInformationComponent.error.noCommissionHead',
              ),
              alertText: this.translate.instant(
                'workFlow3.components.producerInformationComponent.error.noCommissionContent',
              ),
              alertLinkText: this.translate.instant(
                'workFlow3.components.producerInformationComponent.error.addCommission',
              ),
            };
            return false;
          }
          return true;
        }
        return true;
      } else {
        throw {
          message: this.translate.instant('quote4Flow.error.selectValidBroker'),
        };
      }
    } catch (error) {
      if (![500].includes(error?.status)) {
        let errAlert: alertDetails = {
          type: 'error',
          headerText: 'common.errorMsg',
          bodyText: getErrorMessage(error),
        };
        this.alertService.addAlert(errAlert);
      }
      return false;
    }
  }
  async CheckFormValid(formDetails) {
    const isProducerEmpty =
      !this.brokerageForm.valid &&
      this.brokerageForm.value['broker'] !== '' &&
      this.brokerageForm.value['branch'] !== '' &&
      this.brokerageForm.value['producer'] == '';
    if (isProducerEmpty) {
      window.scroll(0, 0);
      this.brokerageErrMsg = this.translate.instant(
        'quote4Flow.pleaseSelectBrokerage',
      );
      let errAlert: alertDetails = {
        type: 'error',
        headerText: 'common.errorMsg',
        bodyText: 'error.noProducerErrMsg',
      };
      this.alertService.addAlert(errAlert);
      this.setLoader(false, 'unset');
      return false;
    }
    if (!this.isProductSelected(this.products)) {
      window.scroll(0, 0);
      let errAlert: alertDetails = {
        type: 'error',
        headerText: 'common.errorMsg',
        bodyText: 'error.noProductErrMsg',
      };
      this.alertService.addAlert(errAlert);
      this.setLoader(false, 'unset');
      return false;
    }
    if (!this.brokerageForm.valid) {
      window.scroll(0, 0);
      this.brokerageErrMsg = this.translate.instant(
        'quote4Flow.error.selectValidBroker',
      );
      let errAlert: alertDetails = {
        type: 'error',
        headerText: 'common.errorMsg',
        bodyText: 'error.notValidFormErrMsg',
      };
      this.alertService.addAlert(errAlert);
      this.setLoader(false, 'unset');
      return false;
    }

    if (!this.isLimitSelected && !this.isNavigatedFromQuoteCalc) {
      window.scroll(0, 0);
      let errAlert: alertDetails = {
        type: 'error',
        headerText: 'common.errorMsg',
        bodyText: 'error.notValidFormErrMsg',
      };
      this.setLoader(false, 'unset');
      this.alertService.addAlert(errAlert);
      return false;
    }
    const selectedProducts = formDetails.products.filter(
      (obj) => obj.checked === true,
    );
    if (
      !this.isFormValid(formDetails) ||
      !this.isAllFormsFilled(selectedProducts)
    ) {
      window.scroll(0, 0);
      const errAlert = {
        show: true,
        type: 'error',
        headerText: 'common.errorHeader',
        bodyText: 'error.unansweredQuestionsErrMessage',
      };
      this.alertService.addAlert(errAlert);
      this.setLoader(false, 'unset');
      return false;
    }
    return true;
  }
  async handleProceedContinue(event, formDetails) {
    this.alertService.clearAlerts(-1);
    this.setLoader(true, 'hidden');
    let brokerageBORId =
        this.selectedBroker?.id ?? this.brokerageDetails?.brokerageId,
      branchBORId = this.selectedBranch?.id ?? this.brokerageDetails?.branchId,
      producerBORId =
        this.selectedProducer?.id ?? this.brokerageDetails?.producerId;

    this.destroy$.next();
    this.destroy$.complete();
    if (!brokerageBORId || !branchBORId || !producerBORId) {
      this.setLoader(false, 'unset');
      this.showSpinner = false;
      return;
    }
    let products;
    let selectedPdts;

    this.form$.pipe(take(1)).subscribe((event) => {
      products = event.products;
    });

    selectedPdts = this.getSelectedPdtStandaloneOptions(products);

    const pdtValues = selectedPdts.map((p) => p.value);
    pdtValues.forEach((p) => {
      const riskQnIds = this.riskForm?.value[p][0];

      if (
        this.excludedCategoriesIds.length > 0 &&
        this.categoryExcluded &&
        this.excludedCategories.length > 0
      ) {
        for (const index in riskQnIds) {
          if (this.excludedCategoriesIds.includes(Number(index))) {
            delete riskQnIds[index];
          }
        }
      }
      for (let id in riskQnIds) {
        this.store.dispatch(
          new CreateQuoteAction.UpdateRiskAnswerAction({
            product: p,
            id: Number(id),
            value: riskQnIds[id],
          }),
        );
      }
    });

    let formData: any;

    this.form$.subscribe((event) => (formData = event));

    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        broker: this.brokerageForm.value['broker'] ?? '',
        producer: this.brokerageForm.value['producer'] ?? '',
        branch: this.brokerageForm.value['branch'] ?? '',
        brokerageId: brokerageBORId,
        branchId: branchBORId,
        producerId: producerBORId,
        startDate:
          convertDate(formData.ui.startDate, this.shortDateFormat) ?? '',
        endDate: convertDate(formData.ui.endDate, this.shortDateFormat) ?? '',
      }),
    );
    this.store.dispatch(
      new CreateQuoteAction.UpdateInsuredAction({
        brokerageId: brokerageBORId,
        branchId: branchBORId,
        producerId: producerBORId,
      }),
    );
    // save broker, branch and producer
    this.store
      .select(getUiPolicyID)
      .pipe(take(1))
      .subscribe(async (policyId) => {
        if (policyId && this.selectedBroker) {
          const payload = {
            brokerageBORId: brokerageBORId,
            brokerageBranchBORId: branchBORId,
            brokerageProducerBORId: producerBORId,
          };
          try {
            const updateBrokerProducer = this.policyService.Update(
              policyId,
              payload,
            );
            let response = await firstValueFrom(updateBrokerProducer);
          } catch (error) {
            if (![500].includes(error?.status)) {
              let errAlert: alertDetails = {
                type: 'error',
                headerText: 'common.errorMsg',
                bodyText: getErrorMessage(error),
              };
              this.alertService.addAlert(errAlert);
              this.setLoader(false, 'unset');
            }
          }
        } else {
        }
      });
    let ui;

    this.form$.pipe(take(1)).subscribe((event) => (ui = event.ui));
    const risk = selectedPdts.map((dataObj) => ({
      id: dataObj.riskId,

      underwriterId: this.isNumeric(dataObj.underwriter)
        ? Number(dataObj.underwriter)
        : dataObj?.underwriterId,
      confRiskLimitId: this.getLimitId(dataObj.riskId),
      riskAnswer: formData.riskQuestions[dataObj.value].map((item) => ({
        riskQuestionId: item.id,
        riskAnswer: item?.value?.toString() || '',
      })),
    }));
    const atomicTrxBody = {
      policy: {
        brokerageBORId: brokerageBORId,
        brokerageBranchBORId: branchBORId,
        brokerageProducerBORId: producerBORId,
        insuredId: this.insuredId,
      },

      policyPeriod: {
        effectiveDt: getFormatDate(
          formData.ui.startDate,
          this.shortDateFormat,
          'YYYY-MM-DD',
        ),
        expiryDt: getFormatDate(
          formData.ui.endDate,
          this.shortDateFormat,
          'YYYY-MM-DD',
        ),
        effectiveExpiryDatesTBDFlag: this.effectiveExpiryDatesTBDFlag ? 1 : 0,
      },

      risk: risk,
      quoteOption: true,
    };

    let policyId;
    let quoteOption;
    try {
      if (!this.isQuoteRecordExists(ui)) {
        try {
          let showCommissionError = false;
          const createAtomicTrx =
            this.policyAtomicTrxService.CreateQuote(atomicTrxBody);
          let dataATrx = await firstValueFrom(createAtomicTrx);
          policyId = dataATrx.data.id;
          if (dataATrx?.warning) {
            showCommissionError = true;
            this.quoteId = policyId;
            this.buttonLabel = this.translate.instant(
              'workFlow3.components.riskAnalysis.button.save',
            );
          }
          this.store.dispatch(
            new CreateQuoteAction.UpdateInsuredAction({
              policyId: policyId,
            }),
          );

          const getPolicyByID =
            this.policyPeriodService.GetAllByPolicyId(policyId);
          let dataPId = await firstValueFrom(getPolicyByID);

          const policyPeriodId = dataPId.data[0].id;
          const getPolicyRiskData =
            this.policyRiskService.GetAllByPolicyPeriodId(policyPeriodId);
          const initialRiskData = await firstValueFrom(getPolicyRiskData);

          this.policyRiskId =
            initialRiskData.data[0]?.PolicyRiskTrxes[0]?.policyRiskId;
          this.store.dispatch(
            new CreateQuoteAction.setPolicyPeriodId(
              initialRiskData.data[0].policyPeriodId,
            ),
          );

          const latestTransactionTypeIndex =
            initialRiskData.data[0]?.PolicyRiskTrxes.length - 1;
          this.store.dispatch(
            new CreateQuoteAction.updateUiContents({
              transactionType:
                initialRiskData.data[0]?.PolicyRiskTrxes[
                  latestTransactionTypeIndex
                ].policyRiskTrxType.description,
              transactionTypeId: Number(
                initialRiskData.data[0]?.PolicyRiskTrxes[
                  latestTransactionTypeIndex
                ].type,
              ),
            }),
          );

          this.store.dispatch(
            new CreateQuoteAction.UpdateInsuredAction({
              policyRiskTrxId: initialRiskData.data[0].PolicyRiskTrxes[0].id,
              policyId: policyId,
              saveAtomicTrx: true,
              policyPeriodId: policyPeriodId,
            }),
          );

          if (dataATrx?.warning) {
            await this.loadGetAllByPolicyPeriodId(policyPeriodId);
            this.showBodySpinner = false;
            this.setLoader(false, 'unset');
            return;
          }

          this.store.dispatch(
            new updatePolicySlideoutData({
              policyRiskId: initialRiskData?.data[0]?.id,
              quoteStatus: 'quote',
            }),
          );
          await this.calculatePremiumForDefaultQuote(initialRiskData.data);

          const getPolicyRisk =
            this.policyRiskService.GetAllByPolicyPeriodId(policyPeriodId);
          const dataPPId = await firstValueFrom(getPolicyRisk);

          await this.populateDefaultQuoteOption(dataPPId);

          this.currentSelectedProduct = {};
          this.store.dispatch(
            new CreateQuoteAction.UpdateCurrentproductAction(''),
          );

          this.setLoader(false, 'unset');
          this.showSpinner = false;
          if (showCommissionError === false) {
            this.store.dispatch(
              new CreateQuoteAction.UpdateCurrentproductAction(''),
            );
            this.router.navigate(['/dashboard/workflow3/quoting'], {
              relativeTo: this.activatedRoute,
              skipLocationChange: true,
            });
          } else {
            const errAlert = {
              type: 'warn',
              headerText: 'common.warnMsg',
              bodyText: dataATrx?.warning ?? '',
            };
            this.alertService.addAlert(errAlert);
          }
        } catch (error) {
          if (![500].includes(error?.status)) {
            const errAlert = {
              type: 'error',
              headerText: 'common.errorHeader',
              bodyText: getErrorMessage(error),
            };

            this.alertService.addAlert(errAlert);
          }
          this.setLoader(false, 'unset');
          this.showSpinner = false;
        }
      } else {
        // TODO handle risk question changes after quote is in Quoted status
        await this.saveRisk(ui, selectedPdts, formData, true);
        // save dates
        const policyPeriodData = {
          effectiveDt: getFormatDate(
            formData.ui.startDate,
            this.shortDateFormat,
            'YYYY-MM-DD',
          ),
          expiryDt: getFormatDate(
            formData.ui.endDate,
            this.shortDateFormat,
            'YYYY-MM-DD',
          ),
        };
        this.policyPeriodService
          .Update(ui.policyPeriodId, policyPeriodData)
          .subscribe({
            next: (_) => {},
            error: (error) => {
              this.setLoader(false, 'unset');
              if (![500].includes(error?.status)) {
              }
            },
          });
        // save underwriter
        if (this.policyRisks) {
          selectedPdts.forEach((product) => {
            const policyRisk = this.policyRisks?.find(
              (policyRisk) => policyRisk.riskId == product.riskId,
            );
            if (policyRisk) {
              const policyRiskTrx = policyRisk.PolicyRiskTrxes[0];

              const riskTrxData = {
                underwriterId: this.isNumeric(product.underwriter)
                  ? Number(product.underwriter)
                  : product?.underwriterId,
              };
              this.policyRiskTrxService
                .Update(policyRiskTrx.id, riskTrxData)
                .subscribe({
                  next: (_) => {
                    const errAlert = {
                      show: true,
                      type: 'success',
                      headerText: 'common.successHeader',
                      bodyText: 'policy.update.success.body',
                    };
                    this.alertService.addAlert(errAlert);
                  },
                  error: (error) => {
                    this.setLoader(false, 'unset');
                    this.showSpinner = false;
                    if (![500].includes(error?.status)) {
                      const errAlert = {
                        show: true,
                        type: 'error',
                        headerText: 'common.errorHeader',
                        bodyText: error,
                      };
                      this.alertService.addAlert(errAlert);
                    }
                  },
                });
            }
          });
        }
        const riskLimit = [];
        for (let product of selectedPdts) {
          const policyRisk = this.policyRisks?.find(
            (policyRisk) => policyRisk.riskId == product.riskId,
          );

          riskLimit.push({
            policyRiskId:
              policyRisk?.PolicyRiskTrxes[0]?.policyRiskId ?? this.policyRiskId,
            confRiskLimitId:
              product.limitId ??
              this.limitOptionsForProducts[product.key][0].id,
          });
        }
        policyId = ui.policyId;
        const dataDefault = {
          policyPeriodId: ui.policyPeriodId,
          riskLimit: riskLimit,
        };
        if (!ui.isNavigatedFromQuoteCalc && !this.quoteOptionsAlreadyExists) {
          this.policyQuoteOptionService.CreateDefault(dataDefault).subscribe({
            next: async (data) => {
              const getPolicyRiskByPolicyPeriodID =
                this.policyRiskService.GetAllByPolicyPeriodId(
                  ui.policyPeriodId,
                );
              let initialRiskData = await firstValueFrom(
                getPolicyRiskByPolicyPeriodID,
              );
              this.store.dispatch(
                new CreateQuoteAction.setPolicyPeriodId(
                  initialRiskData.data[0].policyPeriodId,
                ),
              );
              try {
                await this.calculatePremiumForDefaultQuote(
                  initialRiskData.data,
                );

                const getPolicyRisk =
                  this.policyRiskService.GetAllByPolicyPeriodId(
                    initialRiskData.data[0].policyPeriodId,
                  );
                const dataPPId = await firstValueFrom(getPolicyRisk);
                await this.populateDefaultQuoteOption(dataPPId);

                this.store.dispatch(
                  new CreateQuoteAction.UpdateInsuredAction({
                    policyRiskTrxId: dataPPId.data[0].PolicyRiskTrxes[0].id,
                    policyId: policyId,
                    saveAtomicTrx: true,
                    policyPeriodId: ui.policyPeriodId,
                  }),
                );
                this.setLoader(false, 'unset');
                this.showSpinner = false;
                this.router.navigate(['/dashboard/workflow3/quoting'], {
                  relativeTo: this.activatedRoute,
                  skipLocationChange: true,
                });
              } catch (error) {
                this.setLoader(false, 'unset');
                this.showSpinner = false;
                const errorMessage = getErrorMessage(error);
                if (error?.code === -20) {
                  this.newQuoteService.setCreateDefaultQuoteWarning(
                    errorMessage,
                  );
                } else {
                  this.newQuoteService.setCreateDefaultQuoteError(errorMessage);
                }
                this.router.navigate(['/dashboard/workflow3/quoting'], {
                  relativeTo: this.activatedRoute,
                  skipLocationChange: true,
                });
              }

              this.categoryExcluded = false;
              this.excludedCategories = [];
              this.excludedCategoriesIds = [];
            },
            error: (error) => {
              this.setLoader(false, 'unset');
              this.showSpinner = false;
              const errAlert = {
                show: true,
                type: 'error',
                headerText: 'common.errorHeader',
                bodyText: getErrorMessage(error),
              };
              if (![500].includes(error?.status)) {
                this.alertService.addAlert(errAlert);
              }
            },
          });
        } else {
          let policyRiskList;
          this.policyRiskService
            .GetAllByPolicyPeriodId(ui.policyPeriodId)
            .subscribe(async (data) => {
              if (data?.data[0]) {
                policyRiskList = data?.data;

                const resultArray = policyRiskList.flatMap((item) =>
                  item.PolicyRiskTrxes.flatMap((trx) =>
                    trx.QuoteOptions.map((option) => option.id),
                  ),
                );

                this.store.dispatch(new CreateQuoteAction.clearQuoteOptions());
                for (const element of resultArray) {
                  try {
                    const riskRating =
                      await this.riskRatingCalculateService.GetByQuoteOption(
                        element,
                      );
                    const ratingData = await firstValueFrom(riskRating);
                  } catch (error) {
                    if (error?.code === -20) {
                      this.newQuoteService.setCreateDefaultQuoteWarning(
                        error?.error,
                      );
                    } else {
                      this.newQuoteService.setCreateDefaultQuoteError(
                        error?.error,
                      );
                    }
                  }
                }

                const getPolicyRisk =
                  await this.policyRiskService.GetAllByPolicyPeriodId(
                    ui.policyPeriodId,
                  );
                const dataPPId = await firstValueFrom(getPolicyRisk);
                await this.populateDefaultQuoteOption(dataPPId);

                this.setLoader(false, 'unset');
                this.showSpinner = false;
                this.router.navigate(['/dashboard/workflow3/quoting'], {
                  relativeTo: this.activatedRoute,
                  skipLocationChange: true,
                });
              }
            });
        }
      }
    } catch (error) {
      this.setLoader(false, 'unset');
      this.showSpinner = false;
      if (![500].includes(error?.status)) {
        const errAlert = {
          show: true,
          type: 'error',
          headerText: 'common.errorHeader',
          bodyText: getErrorMessage(error),
        };
        this.alertService.addAlert(errAlert);
      }
    }
  }
  handleExitFlow() {
    let isMock = 'true';
    if (
      this.localStorageService.getMockExternalAPIs() === 'false' ||
      environment.mockExternalAPIs === false
    ) {
      isMock = 'false';
    }
    this.router.navigate(['/dashboard/home'], {
      queryParams: {
        mock: isMock,
      },
      skipLocationChange: true,
    });
  }
  async populateLimitOptions(riskId, riskName) {
    return new Promise<any>(async (resolve, reject) => {
      this.confRiskLimitsService
        .GetByRegionIdAndRiskIds(this.regionId, riskId)
        .subscribe({
          next: (response) => {
            const limitOptions = response.data.map((dataObj) => ({
              key: formatAmountWithCurrency(dataObj.limit, this.currency),
              value: valInDigits(dataObj.limit),
              id: dataObj.id,
            }));
            this.limitOptionsForProducts[riskName] = limitOptions;
            resolve(true);
          },
          error: (error) => {
            const limitOptions = [
              { key: 'Custom amount', value: 'custom-amount' },
            ];
            this.limitOptionsForProducts[riskName] = limitOptions;
            reject(false);
          },
        });
    });
  }
  handleLimitChange(value, products, pdt) {
    this.isLimitSelected = true;
    const currentProduct = this.selectedProductStandaloneOptions.filter(
      (p) => p.value == pdt,
    )[0];
    let index = products.findIndex((obj) => obj.value == pdt);
    const limitOption = this.limitOptionsForProducts[
      products[index].key
    ].filter((l) => l.value == value)[0];
    const limitId = limitOption.id;
    const limit = limitOption.value;
    if (currentProduct) {
      currentProduct.limit = value;
      currentProduct.limitId = limitOption.id;
      currentProduct.showInvalidLimitErr = false;
      currentProduct.showInvalidLimitErrMsg = '';
    }
    this.store.dispatch(
      new CreateQuoteAction.UpdateLimitForPdtAction({
        index,
        limit,
        limitId,
      }),
    );
  }
  getLimitErrMsg(product) {
    const currentProduct = this.selectedProductStandaloneOptions.filter(
      (p) => p.key == product,
    )[0];
    return currentProduct?.showInvalidLimitErr
      ? currentProduct?.showInvalidLimitErrMsg
      : '';
  }
  getLimitValue(riskId) {
    if (this.isNavigatedFromQuoteCalc) {
      try {
        let selectedQuoteOption;
        this.store
          .pipe(select(getSelectedQuoteOption))
          .pipe(take(1))
          .subscribe((quoteOption) => (selectedQuoteOption = quoteOption));
        const productData = selectedQuoteOption?.products.filter(
          (product) => product?.riskId == riskId,
        );
        if (productData?.length > 0) {
          return productData[0]?.limit;
        }
        return this.selectedProductStandaloneOptions.filter(
          (p) => p.riskId == riskId,
        )[0].limit;
      } catch (error) {
        return this.selectedProductStandaloneOptions.filter(
          (p) => p.riskId == riskId,
        )[0].limit;
      }
    }
    return this.selectedProductStandaloneOptions.filter(
      (p) => p.riskId == riskId,
    )[0].limit;
  }
  getLimitId(riskId) {
    return this.selectedProductStandaloneOptions.filter(
      (p) => p.riskId == riskId,
    )[0].limitId;
  }

  async getDefaultLimit(riskId) {
    return new Promise<any>(async (resolve, reject) => {
      this.planStructureService.GetByRiskIds(riskId).subscribe({
        next: (response) => {
          let limit = response.data[0].limit;
          resolve(limit);
        },
        error: (error) => {
          const errAlert = {
            show: true,
            type: 'error',
            headerText: 'common.errorHeader',
            bodyText: getErrorMessage(error),
          };
          this.alertService.addAlert(errAlert);
          reject(false);
        },
      });
    });
  }

  getProductDescription(desc: string) {
    const index = desc.indexOf('<div');
    if (index !== -1) {
      desc = desc.substring(0, index);
    }
    return desc;
  }

  async handleSaveAndExit(event) {
    await this.handleSaveDraft(event);
    this.fromSaveDraft = false;
  }
  openPopup(event: MouseEvent, content?: popOverV2Content): void {
    let target = event.target as HTMLElement;

    while (target && !target.matches('boxxds-button-v2')) {
      target = target.parentElement;
    }
    const buttonRect = target.getBoundingClientRect();
    const width = content === popOverV2Content.SERVICES ? 494 : 528;
    let position = this.calculatePopupPosition(buttonRect, width);
    const popupPosition = {
      left: position.left,
      top: position.top,
      content: content,
      width: `${width}`,
    };

    this.showPopup(popupPosition);
  }

  calculatePopupPosition(buttonRect: DOMRect, width: number) {
    const popupWidth = width; // Width of the popup
    const offsetX = 30; // Horizontal offset from the button
    const offsetY = 10; // Vertical offset from the button
    let containerRect = document
      .getElementsByClassName('riskAnalysisPageOuter')[0]
      .getBoundingClientRect();
    // Calculate left position
    let popupLeft = buttonRect.left;
    if (popupLeft + popupWidth > window.innerWidth) {
      popupLeft = window.innerWidth - popupWidth - offsetX;
    }

    // Calculate top position
    let popupTop = buttonRect.bottom + offsetY;
    if (containerRect.top) {
      popupTop -= containerRect.top;
    }

    return { left: popupLeft, top: popupTop };
  }
  showPopup(popupPosition: {
    left: number;
    top: number;
    content: popOverV2Content;
    width: string;
  }): void {
    for (let componentRef of this.openPopover) {
      componentRef.destroy();
    }
    this.popupComponentRef =
      this.viewContainerRef.createComponent(PopoverV2Component);
    this.openPopover.push(this.popupComponentRef);
    this.popupComponentRef.instance.left = popupPosition.left;
    this.popupComponentRef.instance.top = popupPosition.top;
    this.popupComponentRef.instance.contentType = popupPosition.content;
    this.popupComponentRef.instance.popupWidth = popupPosition.width;

    const popupContainer = document.getElementById('popup-container');
    popupContainer.appendChild(this.popupComponentRef.location.nativeElement);
    this.addClickOutsideListener();
  }
  addClickOutsideListener(): void {
    document.addEventListener('click', this.handleOutsideClick);
  }

  removeClickOutsideListener(): void {
    document.removeEventListener('click', this.handleOutsideClick);
  }

  handleOutsideClick = (event: MouseEvent): void => {
    let te = event.target instanceof Element ? event.target.parentElement : '';
    if (
      this.popupComponentRef &&
      this.popupComponentRef.location.nativeElement &&
      !this.popupComponentRef.location.nativeElement.contains(event.target) &&
      event.target instanceof Element &&
      !(
        event.target.classList.contains('productPopover') ||
        event.target.parentElement.classList.contains('productPopover')
      )
    ) {
      this.popupComponentRef.destroy();
      this.removeClickOutsideListener();
    }
  };
  setLoader(status, type) {
    this.showBodySpinner = status;
    window.document.body.style.overflow = type;
  }

  //broker commission

  // Broker Commission Popup
  handleLinkAction() {
    this.showCommissionModal = true;
  }
  handleAlertClose() {
    this.alertInfo = undefined;
  }

  async handleClose() {
    this.showCommissionModal = false;
    const hasCommission = await this.hasCommissionForSelectedBroker();
  }

  submitBrokerCommission(event) {}

  async handleNotifMsg(alert) {
    this.showCommissionModal = false;
    if (alert.type === 'success') {
      this.noCommission = false;
      const hasCommission = await this.hasCommissionForSelectedBroker();
    }
    this.alertService.clearAlerts(-1);
    const alertData = {
      show: true,
      type: alert.type,
      headerText: alert.header,
      bodyText: alert.body,
    };
    this.alertService.addAlert(alertData);
  }
  selectProduct(detail, index) {
    const ind = this.products.findIndex((x) => x.riskId === detail.riskId);
    let productList = this.products.map((prod) => {
      return {
        ...prod,
        checked: false,
      };
    });
    productList[ind].checked = true;
    this.store.dispatch(
      CreateQuoteAction.loadProductsSuccess({
        products: productList,
      }),
    );
    this.currentSelectedProduct = productList[ind];
    this.store.dispatch(
      new CreateQuoteAction.UpdateCurrentproductAction(productList[ind]?.value),
    );
    this.selectedProductStandaloneOptions =
      this.getSelectedPdtStandaloneOptions(this.products);
  }
  getSelectedProduct(prod) {
    let selectedList = this.getSelectedPdtStandaloneOptions(this.products);
    return selectedList[0].riskId;
  }
  getSelectedProductIndex() {
    let index = -1;
    index = this.products.findIndex(
      (x) => x.riskId === this.getSelectedProduct(''),
    );
    return index;
  }

  underwriterChanged(id: number) {
    let index = this.getSelectedProductIndex();
    let selectedUnderwriter =
      this.underWriterOptions.filter(
        (underwriter) => underwriter.id === id,
      )?.[0] ?? {};
    this.productList.map((product: any) => {
      if (product.hasSubProduct) {
        let subProductIndex = this.products.findIndex(
          (prod) => prod === product.subProductName,
        );
        this.underWriters[index] = `${selectedUnderwriter?.value ?? ''}`;
        this.underWriters[subProductIndex] = `${
          selectedUnderwriter?.value ?? ''
        }`;
      } else {
        this.underWriters[index] = `${selectedUnderwriter?.value ?? ''}`;
      }
    });
    let underwriterId;
    let underwriterName;
    const policyRisk = this.policyRisks?.find(
      (policyRisk) => policyRisk.riskId == this.productsIds[index],
    );
    if (policyRisk) {
      const policyRiskTrx = policyRisk.PolicyRiskTrxes[0];
    }
    underwriterId = id;
    underwriterName = selectedUnderwriter?.value ?? '';
    this.selectUnderwriterDetails = {
      id: underwriterId,
      key: underwriterName,
      value: underwriterName,
    };
    this.store.dispatch(
      new CreateQuoteAction.UpdateUnderwriterForPdtAction({
        index,
        underwriter: underwriterName,
        underwriterId,
      }),
    );
  }
}
