<div
  class="modal-outer-subjectivities"
  *ngIf="showModal"
  (click)="handleBackDropClick()"
>
  <div class="modal-container" (click)="handleModalClick($event)">
    <div class="modal-content-list">
      <boxxds-textinput-v2
        [showClearButton]="true"
        (handleClearValue)="clearValue()"
        [form]="form"
        [placeholder]="
          'workFlow3.components.modalSearchDocuments.placeholder.endorsements'
            | translate
        "
        [label]="''"
        [control]="'filterKeyWord'"
        [id]="'search'"
        [leftIcon]="'assets/img/workflow3/sidemenu/search.png'"
        [width]="'517px'"
        (handleKeyUp)="onSearch($event)"
        maxLength="100"
      >
      </boxxds-textinput-v2>

      <div class="subjectivities-list" (scroll)="onScroll($event)">
        <ng-container *ngIf="form.value['filterKeyWord']?.length >= 3">
          <div class="list-item selected">
            <button class="list-item-name">
              <div class="list-text">
                {{ form.value["filterKeyWord"] }}
              </div>
              <img
                *ngIf="form.value['filterKeyWord']?.length >= 3"
                src="assets/img/x-close-02.svg"
                alt="clear"
                class="endorse-clear-btn"
                (click)="handleRemoveTypedEndorsement()"
              />
            </button>
          </div>
        </ng-container>
        <ng-container
          *ngFor="let sub of endorsementOptions[product]; let i = index"
        >
          <div
            class="list-item"
            [ngClass]="{
              selected: isSelected(sub),
              'filtered-result': form.value['filterKeyWord']?.length >= 3
            }"
          >
            <button
              class="list-item-name"
              [attr.disabled]="sub.disabled ? true : null"
            >
              <span
                [innerHtml]="
                  form.value['filterKeyWord']?.length >= 3
                    ? getFilteredResult(sub.key)
                    : sub.key
                "
                (click)="handleSubjectivitySelect(sub)"
              >
              </span>
              <img
                *ngIf="isSelected(sub)"
                src="assets/img/x-close-02.svg"
                alt="clear"
                class="endorse-clear-btn"
                (click)="handleSubjectivitySelect(sub, 'close')"
              />
            </button>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="add-btn-holder">
      <div class="badge">
        {{
          endorsementsSelectedToAdd?.length +
            getEndorseCount() +
            " " +
            "Selected"
        }}
      </div>
      <boxxds-button-v2
        [buttonText]="'Add' | translate"
        [btnClass]="'primary'"
        [btnType]="'default'"
        [size]="'lg'"
        (handleClick)="
          endorsementsSelectedToAdd?.length + getEndorseCount() > 0 &&
            addClickHandler()
        "
        [isDisabled]="
          endorsementsSelectedToAdd?.length + getEndorseCount() === 0
        "
        [btnStyle]="{ width: '87px' }"
      ></boxxds-button-v2>
    </div>
  </div>
</div>
