<div class="holder">
  <div class="alert-holder" *ngIf="alerts.length > 0 && !isWorkflow3">
    <div
      *ngFor="let alert of alerts; let i = index"
      class="alert-wrapper"
      [ngStyle]="alert.wrapperStyle"
    >
      <boxxds-alert
        [type]="alert.type"
        [linkData]="alert.linkData"
        [headerText]="alert.headerText | translate"
        [bodyText]="alert.bodyText | translate"
        (handleClick)="handleAlertLinkClickEvent($event, i)"
        (handleCloseEvent)="handleAlertClose(i)"
        [containerStyle]="{
          position: 'relative',
          top: 'unset',
          transition: 'all 0.3s ease-out',
          marginTop: '8px',
          marginBottom: '0'
        }"
      >
      </boxxds-alert>
    </div>
  </div>
  <ng-container *ngIf="alerts.length > 0 && isWorkflow3">
    <div
      class="alert-holder-workflow3"
      [ngClass]="{
        'quotes-grid': isQuotesGridV2,
        'submission-step-1': isSubmissionFirstStep
      }"
    >
      <div
        *ngFor="let alert of alerts; let i = index"
        class="alert-wrapper"
        [ngStyle]="alert.wrapperStyle"
      >
        <boxxds-alert-v2
          [type]="alert.type"
          [linkData]="alert.linkData"
          [headerText]="alert.headerText | translate"
          [bodyText]="alert.bodyText | translate"
          (handleClick)="handleAlertLinkClickEvent($event, i)"
          (handleCloseEvent)="handleAlertClose(i)"
          [containerStyle]="{
            position: 'relative',
            top: 'unset',
            transition: 'all 0.3s ease-out',
            marginTop: '0',
            marginBottom: '8px'
          }"
        >
        </boxxds-alert-v2>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="showMaintenanceBanner()">
    <div
      class="maintenance-banner-holder"
      [ngClass]="{
        'quotes-grid': isQuotesGridV2 || isInsuredOrBrokerModule,
        'submission-step-1': isSubmissionFirstStep
      }"
    >
      <app-maintenance-banner></app-maintenance-banner>
    </div>
  </ng-container>
  <app-logout-popup
    [showModal]="showLogoutPopup"
    (cancelClickHandler)="cancelClickHandler()"
    (logoutClickHandler)="logoutClickHandler()"
  ></app-logout-popup>
  <div class="header-holder" *ngIf="!isWorkflow3">
    <div class="header">
      <a
        class="logo-link"
        [routerLink]="['/dashboard/home']"
        [queryParams]="{ mock: mockExternalAPIs }"
        skipLocationChange="true"
        ><img src="./assets/img/dashboard/boxx.png" alt="logo"
      /></a>
      <app-navigation [marginLeft]="'50px'"></app-navigation>
      <app-search-input
        [placeholder]="'Search' | translate"
        [label]="''"
        [control]="'search'"
        [id]="'search'"
        [leftIcon]="'assets/img/dashboard/search/search.png'"
        [maxWidth]="'339'"
        [inputListHdr]="'Suggested' | translate"
        (suggestedSearchSelected)="handleSearch($event)"
      >
      </app-search-input>
    </div>
  </div>

  <!-- workflo3 navigation -->
  <div
    class="header-holder-v2 no-scroll-x"
    *ngIf="showWorkFlow3navigation"
    [ngClass]="{ wf3: isWorkflow3 }"
  >
    <div class="header">
      <app-navigation-v2
        [showHeader]="true"
        [showHeaderBanner]="false"
        (suggestedSearchSelected)="handleSearch($event)"
        [items]="getCrumbs()"
        [badgeData]="'0 Records'"
        (buttonClicked)="handleStartNewQuote()"
      ></app-navigation-v2>
    </div>
  </div>

  <div class="content-holder" [ngClass]="{ wf3: isWorkflow3 }">
    <div
      class="content"
      [ngClass]="{ wf3: isWorkflow3, 'quotes-grid': isQuotesGridV2 }"
    >
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-side-menu
    *ngIf="!isWorkflow3"
    [sideMenu]="menus"
    (toggleLogoutPopup)="toggleLogoutPopup()"
  >
  </app-side-menu>
  <app-side-menu-workflow3
    *ngIf="isWorkflow3"
    [sideMenu]="menus"
    [boxxUser]="boxxUser"
    (toggleLogoutPopup)="toggleLogoutPopup()"
    (handleLogoutProceed)="logoutClickHandler()"
  >
  </app-side-menu-workflow3>
</div>
