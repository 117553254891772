import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { RiskRegionSubjectivityService } from 'src/app/services/risk-region-subjectivity.service';
import { RiskTemplateService } from 'src/app/services/risk-template';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { convertDate, getFormattedPeriod } from 'src/app/utils/formatDate';

@Component({
  selector: 'app-modal-endorsements',
  templateUrl: './modal-endorsements.component.html',
  styleUrls: ['./modal-endorsements.component.less'],
})
export class ModalEndorsementsComponent implements OnInit {
  form: FormGroup;
  @Input() showModal: boolean = false;
  @Input() riskRegionId;
  @Input() product;
  @Input() selectedEndorsements;
  @Input() policyRiskIds;
  @Input() products;
  @Input() templateCodeForEndorsement;

  @Output() handleClose = new EventEmitter<any>();
  @Output() addHandler = new EventEmitter<any>();

  endorsementOptions = [];
  currentPage = 1;
  totalPages = 1;
  totalCount = 1;
  endorsementsSelectedToAdd = [];

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private riskRegionSubjectivityService: RiskRegionSubjectivityService,
    private riskTemplateService: RiskTemplateService,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      filterKeyWord: [''],
    });
    this.handleEndorsementSearch('', this.product);
  }

  handleModalClick(event) {
    event.stopPropagation();
  }

  handleBackDropClick() {
    this.showModal = false;
    this.handleClose.emit();
  }

  async onSearch(event) {
    const keyword = event.target.value?.trim();
    if (keyword?.length >= 3 && keyword?.length <= 1000) {
      await this.handleEndorsementSearch(keyword, this.product, 1, false, 10);
    } else {
      this.handleEndorsementSearch('', this.product, 1, false, 10);
    }
  }

  handleEndorsementSearch(
    searchKey,
    product,
    page = 1,
    isDefaultLoad = true,
    limit = 10,
  ) {
    const index = this.products.findIndex((p) => p == product);
    this.riskTemplateService
      .getRiskTemplate(
        1,
        this.riskRegionId,
        this.templateCodeForEndorsement,
        searchKey,
      )
      .subscribe((data) => {
        this.currentPage = data.pagination.currentPage;
        this.totalPages = data.pagination.totalPages;
        this.totalCount = data.pagination.totalRecords;
        const newList = data.data.map((endorsementDoc) => {
          let selected = this.selectedEndorsements[product].find(
            (x) => x.riskTemplateId && x.riskTemplateId == endorsementDoc.id,
          )
            ? true
            : false;

          return {
            key: endorsementDoc.templateDescription,
            value: endorsementDoc.templateDescription,
            disabled: selected ? true : false,
            mandatory: endorsementDoc.mandatory,
            url: endorsementDoc.templatePath,
            templateType: endorsementDoc.templateType,
            policyRiskId: this.policyRiskIds[index],
            riskTemplateId: endorsementDoc.id,
            documentTblEndorsId: -1,
            dataInjection: endorsementDoc.dataInjection,
            ignoreTemplate: endorsementDoc.ignoreTemplate,
          };
        });

        if (isDefaultLoad && this.endorsementOptions[product]?.length > 0) {
          this.endorsementOptions[product] = [
            ...this.endorsementOptions[product],
            ...newList,
          ];
        } else {
          this.endorsementOptions[product] = newList;
        }
      });
  }

  clearValue() {
    this.form.reset();
    this.handleEndorsementSearch('', this.product, 1, false, 10);
  }

  getFilteredResult(keyName) {
    return keyName?.replace(
      this.form.value['filterKeyWord'],
      `<strong>${this.form.value['filterKeyWord']}</strong>`,
    );
  }

  handleSubjectivitySelect(sub, from: string = 'body') {
    const alreadySelectedIdx = this.endorsementsSelectedToAdd.findIndex(
      (selectedEndorsement) => selectedEndorsement.key === sub.key,
    );
    if (alreadySelectedIdx !== -1) {
      if (from === 'close')
        this.endorsementsSelectedToAdd.splice(alreadySelectedIdx, 1);
    } else {
      this.endorsementsSelectedToAdd.push({
        ...sub,
        selectedToAdd: true,
      });
    }
  }

  isSelected(sub) {
    return this.endorsementsSelectedToAdd.filter(
      (selectedEndorsement) => selectedEndorsement.key === sub.key,
    )[0]?.selectedToAdd;
  }

  onScroll(event: any): void {
    const currentScrollPosition = event.target.scrollTop;
    const maxScrollPosition =
      event.target.scrollHeight - event.target.clientHeight;

    if (this.canLoadMoreOptions(currentScrollPosition, maxScrollPosition)) {
      this.currentPage++;
      this.handleEndorsementSearch(
        this.form.value['filterKeyWord'],
        this.product,
        this.currentPage,
      );
    }
  }

  canLoadMoreOptions(
    currentScrollPosition: number,
    maxScrollPosition: number,
    threshold: number = 4,
  ): boolean {
    return (
      maxScrollPosition - currentScrollPosition <= threshold &&
      this.endorsementOptions[this.product].length <= this.totalCount &&
      this.currentPage <= this.totalPages
    );
  }

  addClickHandler() {
    const index = this.products.findIndex((p) => p == this.product);
    const selectedEndorsements = this.endorsementsSelectedToAdd.map(
      ({ selectedToAdd, ...rest }) => rest,
    );
    const keyword = this.form.value['filterKeyWord']
      ? this.form.value['filterKeyWord'].trim()
      : '';
    if (keyword.length >= 3) {
      const customEndorsement = {
        key: keyword,
      };
      selectedEndorsements.push(customEndorsement);
    }

    this.addHandler.emit({
      endorsementsToAdd: selectedEndorsements,
      product: this.product,
    });
  }

  handleRemoveTypedEndorsement() {
    this.form.value['filterKeyWord'] = '';
    this.clearValue();
  }

  getEndorseCount(): number {
    const keyword = this.form.value['filterKeyWord']
      ? this.form.value['filterKeyWord'].trim()
      : '';
    if (keyword.length >= 3) {
      const alreadySelectedIdx = this.endorsementsSelectedToAdd.findIndex(
        (selectedEndorsement) => selectedEndorsement.key.trim() === keyword,
      );
      if (alreadySelectedIdx === -1) return 1;
      else return 0;
    } else return 0;
  }
}
