import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { DomainsService } from 'src/app/services/domains.service';
import { Pagination } from 'src/app/entities/boxx-response';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import { UNDERWRITER_CLEARANCE } from 'src/app/constants/systemType';
import * as utils from '../../../dashboard/utils/lifecycle-utils';
import { PolicyRiskService } from 'src/app/services/policy-risk.service';
import { Observable, firstValueFrom, lastValueFrom, take } from 'rxjs';
import {
  getQuoteSelector,
  getSelectedQuoteOption,
} from 'src/app/store/create-quote/create-quote.selector';
import { Quote, UISpecificValues } from 'src/app/models/quote.model';
import { PolicyRiskTrxService } from 'src/app/services/policy-risk-trx.service';
import {
  getFormattedDateTime,
  getFormatDate,
  convertDate,
  formatDateMoment,
  getDateString,
  getFormattedDate,
  getFormattedPolicyPeriod,
  getMonthAndYear,
  formatDate,
} from 'src/app/utils/formatDate';
import {
  StatusMapping,
  getPolicyStatusTypeV2,
  mapPolicy,
} from 'src/app/constants/policyStatus';
import { ConfDeductibleService } from 'src/app/services/conf-deductible.service';
import {
  makeSorting,
  formatAmountWithCurrency,
  excelFileDownload,
  getAlertHead,
  getErrorMessage,
  valInDigits,
  removeDuplicatedKeys,
} from 'src/app/utils/utils';
import { ConfRiskLimitsService } from 'src/app/services/conf-risk-limits.service';
import { KeyValueDto } from 'src/app/dtos/keyvalueList.dto';
import { Policy, PolicyPeriod } from 'src/app/entities/policy';
import { PolicyPeriodService } from 'src/app/services/policy-period.service';
import { AlertService } from 'src/app/services/alert.service';
import * as objects from 'src/app/dashboard/policy-lifecycle/objects/policy-lifecycle-objects';
import {
  setPolicyRiskTrxIdAction,
  setPolicyRiskTrxStatusAction,
  setInsuredIdAction,
  setRiskIdAction,
  setPolicySlideoutData,
} from 'src/app/store/lifecycle/lifecycle.action';
import { toSentenceCase } from 'src/app/utils/utils';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-plc-timeline',
  templateUrl: './plc-timeline.component.html',
  styleUrls: ['./plc-timeline.component.less'],
})
export class PLCTimelineComponent implements OnInit {
  @Input() policyId;
  @Input() policyRiskId;
  @Input() pkgPolicyNumber;
  // @Input() timelineData;
  @Input() details;
  @Input() regionId;
  @Input() riskId;

  policyPeriodId;
  currency = '';
  addSortTo: string[] = [
    'Quotes ID',
    'Effective dates',
    'Status',
    'Insured name',
  ];
  sortBy: string = '';
  pageNumber: number = 1;
  form$: Observable<Quote>;
  periodOptions: KeyValueDto[] = [];
  selectedPolicyPeriodId: number = 0;
  showBodySpinner: boolean = false;

  //form
  form: FormGroup;
  filterForm: FormGroup;
  submitted: boolean = false;
  count: number = 20;

  //dropdown
  period = [];

  isActive: boolean = true;
  isUnderwriter: boolean = false;
  showTblSpinner: boolean = false;

  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  shortDateFormat: string = '';
  longDateFormat: string = '';
  totalCount: number = 0;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalPages: number = 0;

  // filter
  showFilter: boolean = false;
  filterInputOptions = [];
  filteredOptions: any[];
  filterSelectedOptions: any;
  searchKey = '';
  filterFormGroup: FormGroup;
  statusOptions = [];
  premiumOptions = [];
  limitOptions = [];
  deductibleOptions = [];

  //table
  columnsToHide: string[] = ['id', 'policyRiskTrxId'];
  columnHeadsToHide: string[] = ['ellipsis'];
  sortableColumns: string[] = ['Timeline'];
  iconColumns: string[] = ['ellipsis'];
  tableData = [];
  tableHeaders = [
    {
      Timeline: '',
      Status: '',
      Premium: '',
      'Annual premium': '',
      Limit: '',
      Retention: '',
      'Effective date': '',
      'Invoice number': '',
      ellipsis: '',
    },
  ];
  pagination: Pagination;
  selectedPolicyPeriodYear: string = '';
  totalRecords: 0;
  riskTrxTypes = [];
  sortDirection = 'id:desc';
  filterSlide: boolean = false;
  showSlideOut: boolean = false;

  constructor(
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private domainsService: DomainsService,
    private fb: FormBuilder,
    private policyRiskService: PolicyRiskService,
    private policyRiskTrxService: PolicyRiskTrxService,
    private confDeductibleService: ConfDeductibleService,
    private confRiskLimitsService: ConfRiskLimitsService,
    private policyPeriodService: PolicyPeriodService,
    private alertService: AlertService,
    private translateService: TranslateService,
  ) {
    this.filterFormGroup = this.fb.group({
      effectiveDt: this.fb.group({
        from: [''],
        to: [''],
      }),
      trxDt: this.fb.group({
        from: [''],
        to: [''],
      }),
      createdDt: this.fb.group({
        from: [''],
        to: [''],
      }),
      status: [[]],
      deductible: [''],
      limitRange: [''],
      invoiceNumber: [''],
      premiumRange: this.fb.group({
        from: [''],
        to: [''],
      }),
    });
  }

  policyTimelineHeading: string = this.translateService.instant(
    'PolicyLifeCycleTimeLine.cyberboxxTimelinePolicy',
  );
  policyTimelineSubHeading: string = this.translateService.instant(
    'PolicyLifeCycleTimeLine.keepTrackOfAll',
  );
  product: string = '';

  async ngOnInit(): Promise<void> {
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      const underWriterPermission = data.permissions?.filter(
        (permissionData: any) =>
          permissionData?.permission?.permissionName?.toLowerCase() ===
          UNDERWRITER_CLEARANCE,
      );
      if (underWriterPermission?.length > 0) {
        this.isUnderwriter = underWriterPermission[0].readWrite ? true : false;
      } else {
        this.isUnderwriter = false;
      }
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    //store
    let uiData: UISpecificValues;
    this.form$ = this.store.pipe(select(getQuoteSelector));
    this.form$
      .pipe(take(1))
      .subscribe(
        (event) => (
          (this.policyPeriodId = Number(event.ui.policyPeriodId)),
          (this.policyId = Number(event.ui.policyId))
        ),
      );

    await this.getPolicyStatuses();
    this.form$.subscribe((event) => (uiData = event.ui));
    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));

    this.tableData = this.tableHeaders;
    this.product = this.details?.product?.[0]?.name ?? '';

    const periods = await this.getPolicyPeriodsFromApi();
    this.populatePolicyTimelineTrxes(periods);
  }

  getPolicyStatuses() {
    return new Promise<any>(async (resolve, reject) => {
      this.domainsService.GetByDomainCode('POLICYRISKTRXTYPE').subscribe({
        next: (data) => {
          let riskTypeList = [];
          data.data.map((dataObj) => {
            riskTypeList = [
              ...riskTypeList,
              {
                description: dataObj.description,
                status: getPolicyStatusTypeV2(dataObj.description),
                seqId: dataObj.seqId,
                id: dataObj.id,
              },
            ];
          });
          this.riskTrxTypes = riskTypeList;
          resolve(true);
        },
        error: (error) => {
          reject(false);
        },
      });
    });
  }

  setPolicyStatusType(type, id = true) {
    let valuesOfRenewal;
    if (id) {
      const filteredArray = this.riskTrxTypes.filter(
        (item) => item.id === type,
      );
      valuesOfRenewal = filteredArray.map((item) => item.description);
    } else {
      let status;
      switch (type.toLowerCase()) {
        case 'bound':
          type = 'bind';
          break;
        case 'renewed':
          type = 'renewal';
          break;
        case 'cancelled':
          type = 'cancellation';
          break;
        case 'reinstated':
          type = 'reinstate';
          break;
        default:
          type;
          break;
      }
      const filteredArray = this.riskTrxTypes.filter(
        (item) => item.description.toLowerCase() === type.toLowerCase(),
      );
      valuesOfRenewal = filteredArray.map((item) => item.id);
    }
    let statusBadge = valuesOfRenewal[0];
    return statusBadge;
  }

  async populatePolicyTimelineTrxes(periods: any[]) {
    this.populatePeriodDropDown(periods);
    await this.populatePolicyRiskTrxes();
    await this.fetchQuoteStatusLists();
    await this.populateDeductibles();
    await this.populateLimitOptions();
  }

  async populatePolicyRiskTrxes() {
    let filter = this.makeFilter();
    let status;
    filter = {
      ...filter,
      policyRiskId: this.policyRiskId,
      page: this.currentPage,
      sort: this.sortDirection,
    };
    this.showTblSpinner = true;
    const riskTrx$ = this.policyRiskTrxService.GetBypolicyRiskIdFilter(
      filter,
      10,
      true,
    );
    const riskTrx = await lastValueFrom(riskTrx$);

    const tableData = riskTrx.data?.map((dataObj) => {
      const annualPremium = dataObj.quoteStatus
        ? utils.calculateAnnualAmount(dataObj)
        : dataObj.totalInvoiceAmount;
      status = this.setPolicyStatusType(dataObj.type);
      return {
        Timeline: formatDate(dataObj.createdDt, this.longDateFormat),
        Status: toSentenceCase(status),
        Premium: formatAmountWithCurrency(
          dataObj.premiumCalculated,
          this.currency,
        ),
        'Annual premium': formatAmountWithCurrency(
          annualPremium,
          this.currency,
        ),
        Limit: formatAmountWithCurrency(dataObj.limit, this.currency),
        Retention: formatAmountWithCurrency(dataObj.deductible, this.currency),
        'Effective date': formatDate(
          dataObj.transactionEffectiveDate,
          this.longDateFormat,
        ),
        'Invoice number': dataObj.invoiceNumber
          ? String(dataObj.invoiceNumber)
          : dataObj.invoiceNumber,
        ellipsis: '',
        policyRiskTrxId: dataObj.id,
      };
    });
    const updatedData = tableData.length == 0 ? this.tableHeaders : tableData;
    this.tableData = updatedData.map((item) => {
      if (parseInt(item['Invoice number']) <= 0) {
        item['Invoice number'] = null;
      }
      return item;
    });
    this.pagination = riskTrx?.pagination;
    this.totalRecords = riskTrx?.pagination.totalRecords;
    this.pageNumber = this.pagination.currentPage;
    this.showTblSpinner = false;
  }

  handleFilter() {
    this.showFilter = true;
    this.populateFilterOptions();
    this.createFilterFormGroup();
  }

  createFilterFormGroup() {
    const formGroup = {};
    this.filterInputOptions.forEach((control) => {
      const isRequired = control.hasValidation ? Validators.required : '';
      let selectedValue;
      switch (control.type) {
        case 'text':
          selectedValue = control.selectedValue ? control.selectedValue : '';
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        case 'number':
          selectedValue = control.selectedValue ? control.selectedValue : '';
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        case 'dropdown':
          selectedValue = control.selectedValue ? control.selectedValue : '';
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        case 'customDropdown':
          selectedValue = control.selectedValue ? control.selectedValue : '';
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        case 'dateRange':
          selectedValue = control.selectedValue
            ? control.selectedValue
            : { from: '', to: '' };
          formGroup[control.value] = this.fb.group({
            from: [selectedValue.from],
            to: [selectedValue.to],
          });
          break;
        case 'dropdownRange':
          selectedValue = control.selectedValue
            ? control.selectedValue
            : { from: '', to: '' };
          formGroup[control.value] = this.fb.group({
            from: [selectedValue.from],
            to: [selectedValue.to],
          });
          break;
        case 'multiDropdown':
          selectedValue = control.selectedValue ? control.selectedValue : [];
          formGroup[control.value] = [selectedValue, isRequired];
          break;
        default:
          selectedValue = control.selectedValue ? control.selectedValue : '';
          formGroup[control.value] = [selectedValue, isRequired];
      }
    });
    this.filterFormGroup = this.fb.group(formGroup);
  }

  handleExport(): void {
    if (this.totalRecords > 0) {
      this.showTblSpinner = true;
      let filter = this.makeFilter();
      filter = {
        ...filter,
        page: 1,
        sort: this.sortDirection,
      };
      const limit = this.totalRecords + 1;
      this.policyRiskTrxService
        .ExportPolicyRiskTrxDetails(filter, limit)
        .subscribe({
          next: (response) => {
            excelFileDownload('ExportedData.xlsx', response);
            this.showTblSpinner = false;
          },
          error: (error) => {
            this.showTblSpinner = false;
            if (![500].includes(error?.status)) {
              // this.showErrorAlert = true;
            }
            //this.msgErrorAlert = getErrorMessage(error);
          },
        });
    } else {
      const alertData = {
        type: 'info',
        headerText: 'Info',
        bodyText: 'No records to export',
      };
      this.alertService.addAlert(alertData);
      this.showTblSpinner = false;
    }
  }

  handleFilterOptnRemove(option) {
    if (this.filterSelectedOptions.hasOwnProperty(option.key)) {
      switch (option.key) {
        case 'invoiceNumber':
          this.filterSelectedOptions[option.key] = '';
          break;
        case 'limitRange':
          this.filterSelectedOptions[option.key] = '';
          break;
        case 'deductible':
          this.filterSelectedOptions[option.key] = '';
          break;
        case 'premiumRange':
          this.filterSelectedOptions[option.key] = { from: '', to: '' };
          break;
        case 'type':
          let ind3 = this.filterSelectedOptions[option.key].findIndex(
            (x) => x.name === option.value,
          );
          if (ind3 >= 0) {
            this.filterSelectedOptions[option.key].splice(ind3, 1);
          }
          break;
        case 'createdDt':
          this.filterSelectedOptions[option.key] = { from: '', to: '' };
          break;
        case 'trxDt':
          this.filterSelectedOptions[option.key] = { from: '', to: '' };
          break;
        default:
      }
    }
    this.getFilteredValues();
    this.handleApplyFilter(this.filterSelectedOptions);
  }

  handleCloseFilterPopup(event) {
    this.showFilter = false;
  }

  async handleApplyFilter(event) {
    this.showFilter = false;
    this.filterSelectedOptions = {};
    this.filterSelectedOptions = event;
    this.searchKey = '';
    this.currentPage = 1;
    let filter = this.makeFilter();
    this.getFilteredValues();
    this.populatePolicyRiskTrxes();
  }

  //Filter
  makeFilter() {
    let filter = {};
    let filterCount = 0;
    let statusArray = this.filterSelectedOptions?.type ?? [];
    if (statusArray) {
      let statusValues = statusArray.map((f) =>
        this.riskTrxTypes
          .filter(
            (item) =>
              item.id === this.setPolicyStatusType(f.name.toLowerCase(), false),
          )
          .map((filteredItem) => filteredItem.id),
      );

      if (statusValues.length > 0) {
        filterCount++;
        filter = { ...filter, type: statusValues };
      }
    }
    const createdDt = this.filterSelectedOptions?.createdDt;
    const effectiveDt = this.filterSelectedOptions?.trxDt;
    const premium = this.filterSelectedOptions?.premiumRange;
    const limit = this.filterSelectedOptions?.limitRange;
    const deductables = this.filterSelectedOptions?.deductible;
    const invoiceNumber = this.filterSelectedOptions?.invoiceNumber;
    let createdDtRange = '';
    let effectiveRange = '';
    let premiumRange = '';
    let limitRange = '';
    let deductibleRange = '';

    if (invoiceNumber) {
      filter = { ...filter, invoiceNumber: invoiceNumber };
      filterCount++;
    }
    if (createdDt && createdDt.from && createdDt.to) {
      const formattedFromDate = getFormattedDate(createdDt.from);
      const formattedToDate = getFormattedDate(createdDt.to);
      createdDtRange = `${formattedFromDate},${formattedToDate}`;
      filter = { ...filter, createdDt: createdDtRange };
      filterCount++;
    }
    if (effectiveDt && effectiveDt.from && effectiveDt.to) {
      const formattedFromDate = getFormattedDate(effectiveDt.from);
      const formattedToDate = getFormattedDate(effectiveDt.to);
      effectiveRange = `${formattedFromDate},${formattedToDate}`;
      filter = { ...filter, trxDt: effectiveRange };
      filterCount++;
    }
    if (limit && limit !== '') {
      const formattedFrom =
        this.limitOptions.find((x) => x.id == limit)?.value ?? limit;
      filter = { ...filter, limitRange: formattedFrom };
      filterCount++;
    }
    if (deductables && deductables !== '') {
      const formattedFrom =
        this.deductibleOptions.find((x) => x.id == deductables)?.value ??
        deductables;
      filter = { ...filter, deductible: formattedFrom };
      filterCount++;
    }
    filter = { ...filter, policyRiskId: this.policyRiskId };

    if (filterCount > 3) {
      this.filterSlide = true;
    } else {
      this.filterSlide = false;
    }
    return filter;
  }

  getFilteredValues() {
    let filteredOptions = [];
    let filterText = '';

    if (this.filterSelectedOptions) {
      if (this.filterSelectedOptions.type) {
        let status = this.filterSelectedOptions.type;
        status.forEach((b) => {
          if (b.fromNav) {
            return;
          }
          filteredOptions.push({ key: 'type', value: b.name });
        });
      }
      if (this.filterSelectedOptions.invoiceNumber) {
        let invoiceNumber = this.filterSelectedOptions.invoiceNumber;
        filteredOptions.push({ key: 'invoiceNumber', value: invoiceNumber });
      }
      if (this.filterSelectedOptions.createdDt) {
        const createDt = this.filterSelectedOptions.createdDt;
        let createRange = '';
        if (createDt.from && createDt.to) {
          const formattedFromDate = formatDateMoment(
            createDt.from,
            this.shortDateFormat,
          );
          const formattedToDate = formatDateMoment(
            createDt.to,
            this.shortDateFormat,
          );
          createRange = `${formattedFromDate} - ${formattedToDate}`;
          filteredOptions.push({ key: 'createdDt', value: createRange });
        }
      }
      if (this.filterSelectedOptions.trxDt) {
        const effectiveDt = this.filterSelectedOptions.trxDt;
        let createRange = '';
        if (effectiveDt.from && effectiveDt.to) {
          const formattedFromDate = formatDateMoment(
            effectiveDt.from,
            this.shortDateFormat,
          );
          const formattedToDate = formatDateMoment(
            effectiveDt.to,
            this.shortDateFormat,
          );
          createRange = `${formattedFromDate} - ${formattedToDate}`;
          filteredOptions.push({ key: 'trxDt', value: createRange });
        }
      }
      if (this.filterSelectedOptions.limitRange) {
        const limitRange = this.filterSelectedOptions.limitRange;
        let createRange = '';
        if (limitRange.from !== '' && limitRange.to !== '') {
          const formattedFrom =
            this.limitOptions.find((x) => x.id == limitRange)?.value ??
            limitRange;
          filteredOptions.push({ key: 'limitRange', value: formattedFrom });
        }
      }
      if (this.filterSelectedOptions.deductible) {
        const deductibleRange = this.filterSelectedOptions.deductible;
        const formattedFrom =
          this.deductibleOptions.find((x) => x.id == deductibleRange)?.value ??
          deductibleRange;
        filteredOptions.push({ key: 'deductible', value: formattedFrom });
      }
      this.filteredOptions = filteredOptions;
    }
    return filteredOptions;
  }

  filterSelectedHasValue() {
    let flag = 0;
    if (this.filterSelectedOptions) {
      if (this.filterSelectedOptions.type) {
        flag += this.filterSelectedOptions.type.length > 0 ? 1 : 0;
      }
      if (this.filterSelectedOptions.invoiceNumber) {
        flag++;
      }

      if (this.filterSelectedOptions.limitRange) {
        if (this.filterSelectedOptions.limitRange !== '') {
          flag++;
        }
      }
      if (this.filterSelectedOptions.deductible) {
        if (this.filterSelectedOptions.deductible !== '') {
          flag++;
        }
      }
      if (this.filterSelectedOptions.trxDt) {
        if (
          this.filterSelectedOptions.trxDt.from !== '' &&
          this.filterSelectedOptions.trxDt.to !== ''
        ) {
          flag++;
        }
      }
      if (this.filterSelectedOptions.createdDt) {
        if (
          this.filterSelectedOptions.createdDt.from !== '' &&
          this.filterSelectedOptions.createdDt.to !== ''
        ) {
          flag++;
        }
      }
    }
    if (flag > 0) {
      return true;
    } else {
      return false;
    }
  }

  async populateFilterOptions() {
    if (this.filterSelectedHasValue()) {
      let defaultOptions = 0;
      let filteredOptions = this.filterInputOptions.map((option) => {
        const { value } = option;
        const selectedValue = this.filterSelectedOptions[value];
        if (option.type === 'dateRange' || option.type === 'dropdownRange') {
          if (selectedValue.from !== '' && selectedValue.to !== '') {
            defaultOptions++;
            return { ...option, selectedValue, isDefault: true };
          }
        } else if (
          option.type === 'multiDropdown' ||
          option.type === 'multiSearch'
        ) {
          if (selectedValue.length > 0) {
            defaultOptions++;
            return { ...option, selectedValue, isDefault: true };
          }
        } else if (option.type === 'customDropdown') {
          if (selectedValue !== '') {
            defaultOptions++;
            return { ...option, selectedValue, isDefault: true };
          }
        } else if (selectedValue !== undefined && selectedValue !== '') {
          defaultOptions++;
          return { ...option, selectedValue, isDefault: true };
        }

        return { ...option, selectedValue, isDefault: false };
      });
      if (defaultOptions < 3) {
        filteredOptions = filteredOptions.map((option) => {
          if (defaultOptions < 3) {
            if (!option.isDefault) {
              defaultOptions++;
              return { ...option, isDefault: true };
            }
          }
          return { ...option };
        });
      }

      this.filterInputOptions = filteredOptions;
    } else {
      this.filterInputOptions = [
        {
          label: 'Policy period',
          value: 'trxDt',
          type: 'dateRange',
          disabled: false,
          isDefault: true,
          selectedValue: '',
          isRequired: false,
          hasValidation: true,
        },
        {
          label: 'Retention',
          value: 'deductible',
          type: 'customDropdown',
          disabled: false,
          isDefault: false,
          selectedValue: '',
          isRequired: false,
          hasValidation: false,
          dropdownOptions: this.deductibleOptions,
        },
        {
          label: 'Created date',
          value: 'createdDt',
          type: 'dateRange',
          disabled: false,
          isDefault: true,
          selectedValue: '',
          isRequired: false,
          hasValidation: true,
        },
        {
          label: 'Status',
          value: 'type',
          type: 'multiDropdown',
          disabled: false,
          isDefault: false,
          selectedValue: '',
          dropdownOptions: this.statusOptions,
          isRequired: false,
          hasValidation: false,
        },
        {
          label: 'Limit',
          value: 'limitRange',
          type: 'customDropdown',
          disabled: false,
          isDefault: false,
          selectedValue: '',
          isRequired: false,
          hasValidation: false,
          dropdownOptions: this.limitOptions,
        },
        {
          label: 'Invoice Number',
          value: 'invoiceNumber',
          type: 'number',
          disabled: false,
          isDefault: false,
          selectedValue: '',
          isRequired: false,
          hasValidation: false,
        },
      ];
    }
  }

  private async fetchQuoteStatusLists() {
    return new Promise<any>(async (resolve, reject) => {
      this.domainsService.GetByDomainCode('POLICYRISKTRXTYPE').subscribe({
        next: (data) => {
          let statusList = [];
          data.data.map((dataObj) => {
            statusList = [
              ...statusList,
              {
                key: dataObj.description,
                value: dataObj.description,
                name: dataObj.description,
                status: getPolicyStatusTypeV2(dataObj.description),
                seqId: dataObj.seqId,
                id: dataObj.id,
              },
            ];
          });
          this.statusOptions = statusList;
          resolve(true);
        },
        error: (error) => {
          reject(false);
        },
      });
    });
  }

  populateDeductibles() {
    return new Promise<any>(async (resolve, reject) => {
      this.confDeductibleService.GetAll().subscribe({
        next: (data) => {
          const tableData = data.data?.map((dataObj, index) => ({
            key: formatAmountWithCurrency(dataObj.value, this.currency),
            value: valInDigits(dataObj.value),
            id: index + 1,
          }));

          tableData.push({
            id: data.data?.length + 1,
            key: 'Custom',
            subKey: '( Between 0 to 1M only. )',
            value: 'custom-amount',
          });
          this.deductibleOptions = removeDuplicatedKeys(tableData);
          resolve(true);
        },
        error: (error) => {
          const deductableOptions = [
            {
              key: 'Custom',
              value: 'custom-amount',
              subKey: '( Between 0 to 1M only. )',
              id: 0,
            },
          ];
          this.deductibleOptions = deductableOptions;
          reject(error);
        },
      });
    });
  }

  populateLimitOptions() {
    return new Promise<any>(async (resolve, reject) => {
      if (!this.regionId || !this.riskId) {
        this.setDefaultLImitOption();
        return;
      }
      this.confRiskLimitsService
        .GetByRegionIdAndRiskIds(this.regionId, this.riskId)
        .subscribe({
          next: (response) => {
            const limitOptions = response.data.map((dataObj, index) => ({
              key: formatAmountWithCurrency(dataObj.limit, this.currency),
              value: valInDigits(dataObj.limit),
              id: index + 1,
            }));
            limitOptions.push({
              key: 'Custom',
              value: 'custom-amount',
              subKey: '( Between 0 to 10M only. )',
              id: response.data?.length + 1,
            });
            this.limitOptions = limitOptions;
            resolve(true);
          },
          error: (error) => {
            this.setDefaultLImitOption();
            reject(false);
          },
        });
    });
  }

  setDefaultLImitOption() {
    const limitOptions = [
      {
        key: 'Custom',
        value: 'custom-amount',
        subKey: '( Between 0 to 10M only. )',
        id: 0,
      },
    ];
    this.limitOptions = limitOptions;
  }

  private populatePeriodDropDown(periods) {
    const sortedPeriod = periods.sort((a, b) => b.sequence - a.sequence);

    this.periodOptions = sortedPeriod.map((data) => {
      const peroid = data.period
        ? data.period.toString()
        : new Date().getFullYear().toString();

      const periodMonth = data.effectiveDt
        ? getMonthAndYear(data.effectiveDt)
        : new Date().getFullYear().toString();

      const kv: KeyValueDto = {
        key: 'Period' + ' ' + periodMonth,
        value: data.id?.toString(),
        id: data.id,
      };
      return kv;
    });

    if (this.periodOptions.length == 0) {
      return;
    }

    this.selectedPolicyPeriodId = Number(this.periodOptions[0].value);
    this.selectedPolicyPeriodYear = this.periodOptions[0]?.key ?? '';
  }

  private async getPolicyPeriodsFromApi(): Promise<any> {
    const period$ = this.policyPeriodService.GetAllByPolicyId(this.policyId);
    const period = await lastValueFrom(period$);
    return period.data;
  }

  handleTableColSort(e) {
    if ((e.column = 'Timeline')) {
      this.sortDirection = 'id:' + e.sort;
    }
    this.populatePolicyRiskTrxes();
  }

  pageChangeHandler(pageNumber) {
    this.currentPage = pageNumber;
    if (pageNumber > 0) {
      this.populatePolicyRiskTrxes();
    }
  }

  trxViewClickHandler(e) {
    const policyRiskTrxId = this.tableData[e].policyRiskTrxId;
    this.showSlideOut = true;
    this.store.dispatch(new setPolicyRiskTrxIdAction(policyRiskTrxId));
  }

  handleFilterClear(e) {
    this.filterSelectedOptions = [];
    this.filterSelectedOptions = {};
    this.searchKey = '';
    this.currentPage = 1;
    let filter = this.makeFilter();
    this.getFilteredValues();
    this.populatePolicyRiskTrxes();
  }

  changePolicyPeriod(value) {
    const id = Number(value);
    if (this.selectedPolicyPeriodId == id) {
      return;
    }
    this.selectedPolicyPeriodId = id;
    this.populateOnPolicyPeriodChange();
  }

  async populateOnPolicyPeriodChange() {
    this.showTblSpinner = true;
    const risk$ = this.policyRiskService.GetByPolicyIdAndPolicyPeriodId(
      this.policyId,
      this.selectedPolicyPeriodId,
    );
    const risk = await lastValueFrom(risk$);
    risk.data.map((dataObj) => {
      dataObj.PolicyRiskTrxes.map((trx) => {
        this.policyRiskId = trx.policyRiskId;
      });
    });
    this.populatePolicyRiskTrxes();
  }

  handleSlideOutClose() {
    this.showSlideOut = false;
  }

  getStatusBadges(type) {
    let status;
    switch (type.toLowerCase()) {
      case 'bind':
        type = 'bound';
        break;
      case 'renewal':
        type = 'renewed';
        break;
      case 'cancellation':
        type = 'cancelled';
        break;
      case 'reinstate':
        type = 'reinstated';
        break;
      default:
        type;
        break;
    }
    return type;
  }
}
