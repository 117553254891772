import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  firstValueFrom,
  map,
  Observable,
  Subject,
  take,
  takeUntil,
} from 'rxjs';
import { ViewportScroller } from '@angular/common';
import { Actions, ofType } from '@ngrx/effects';
import { Pagination } from 'src/app/entities/boxx-response';
import { InsuredCompanyPostRequest } from 'src/app/entities/insured-company';
import { InsuredIndividualPostRequest } from 'src/app/entities/insured-individual';
import { Quote } from 'src/app/models/quote.model';
import { InsuredService } from 'src/app/services/insured.service';
import { LocationService } from 'src/app/services/location.service';
import { PolicyMarketReservationService } from 'src/app/services/policy-market.service';
import { RegionService } from 'src/app/services/region.service';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import * as InsuredAction from 'src/app/store/insured/insured.action';
import { retrievedInsuredsByCompany } from 'src/app/store/create-quote/create-quote.action';
import {
  getInsuredCompanySelector,
  getInsuredIndividualSelector,
  getPaginationSelector,
  getQuoteSelector,
} from 'src/app/store/create-quote/create-quote.selector';
import {
  getErrorMessage,
  makeSortingCol,
  regexHelpers,
} from 'src/app/utils/utils';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { IndustryRestrictedService } from 'src/app/services/industry-restricted.service';
import { NewQuoteService } from 'src/app/services/new-quote.service';
import { BillingCompanyContactService } from 'src/app/services/billing-company-contact.service';
import { PolicyRiskService } from 'src/app/services/policy-risk.service';
import { RiskRatingCalculateService } from 'src/app/services/risk-rating-calculate.service';
import { PolicyPeriodService } from 'src/app/services/policy-period.service';
import { OFACService } from 'src/app/services/ofac.service';
import { PolicyLifecycleService } from 'src/app/services/policy-lifecycle-service';
import { formatDateMoment } from 'src/app/utils/formatDate';
import { AlertService } from 'src/app/services/alert.service';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-submission',
  templateUrl: './new-submission.component.html',
  styleUrls: ['./new-submission.component.less'],
})
export class NewSubmissionComponent
  implements OnInit, AfterContentChecked, OnDestroy
{
  form: FormGroup;
  form$: Observable<Quote>;
  searchKey;
  companySearchData: Observable<any>;
  companyColumnsToSort: Array<string> = ['Name', 'Website'];
  noOfEmpOptns: any;
  numberOfEmp: any;
  businessSectorOptns: any = [];
  businessIndustrySectorOptns = [];
  businessIndustryClass = [];
  businessIndustry;
  requiredErrMsg: string = 'error.thisFieldIsRequired';
  invalidErrMsg: string = 'error.thisFieldIsInvalid';
  customErrMsg: string = 'error.customSumPercentageErrMsg';
  minLengthErrMsg: string = '';
  submitted: boolean = false;
  searchSubmitted: boolean = false;
  totalDataCount = 0;
  addCustomerExpanded: boolean = false;
  householdMemberOptns: any;
  householdMember: any;
  individualSearchData: Observable<any>;
  customerColumnsToSort: Array<string> = ['Name', 'Email'];
  pagination: Observable<Pagination>;
  isInsuredTypeCompany: boolean;
  companyLocationRegionIdSelected: number = 0;
  individuaLocationlRegionIdSelected: number = 0;
  isFormLoaded = false;
  showBanner = false;
  bannerMsg = '';
  errorAPIMsg = 'error.errorAPIMsg';
  colCompanyHeader = {
    Name: { key: 'companyName', order: '' },
    Website: { key: 'companyWebsite', order: '' },
    Address: { key: 'address', order: '' },
  };
  sortingCompanyKeys = '';
  colIndividualHeader = {
    Name: { key: 'firstName', order: '' },
    Email: { key: 'email', order: '' },
    Address: { key: 'address', order: '' },
  };
  sortingIndividualKeys = '';
  boxxUserId;
  activeCompanySearchPgNo = 1;
  activeIndividualSearchPgNo = 1;
  hasMinimumCharacter: boolean = false;
  showTblSpinner: boolean = false;
  showBodySpinner: boolean = false;
  showSpinner: boolean = false;
  childSpinnerChanged: boolean = false;
  percentageRegex = regexHelpers.percentage;
  industryClass = {};
  selectedIndustryId = 0;
  linkIconSrc: string = '';
  sanctionPayload = [];
  isRestrictedIndustry = false;

  private ngUnsubscribe$ = new Subject<void>();
  insuredId = null;
  isEditMode = false;
  insuredName = '';
  quoteExist: boolean = false;
  actionPopupDetails: {
    quoteNumber: string;
    effectiveDate: string;
    insured?: string;
  };
  formDataAddCompanyForm: any;
  policyPeriodId;
  showRecalcConfModal: boolean = false;
  latestFormData;
  showSanctionModal = false;
  formDataBack: any;
  fromSubmissionFlow: boolean = false;
  showContactDetails: boolean = false;
  contactToggleEnabled: boolean = false;
  isOfacError = false;
  shortDateFormat: string = '';
  longDateFormat: string = '';
  mockExternalAPIs: boolean = true;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private viewportScroller: ViewportScroller,
    private regionService: RegionService,
    private actions$: Actions,
    private insuredService: InsuredService,
    private locationService: LocationService,
    private policyMarketReservationService: PolicyMarketReservationService,
    private changeDetector: ChangeDetectorRef,
    private localStorageService: LocalStorageService,
    private industryRestrictedService: IndustryRestrictedService,
    private newQuoteService: NewQuoteService,
    private billingCompanyContactService: BillingCompanyContactService,
    private policyRiskService: PolicyRiskService,
    private riskRatingCalculateService: RiskRatingCalculateService,
    private policyPeriodService: PolicyPeriodService,
    private ofacService: OFACService,
    private policyLifecycleService: PolicyLifecycleService,
    private alertService: AlertService,
  ) {
    this.form = this.fb.group({
      companySearchForm: this.fb.group({
        companyBusinessName: [''],
        website: [''],
        address: [''],
      }),
      addCompanyForm: this.fb.group({
        businessName: ['', Validators.required],
        website: ['', [Validators.pattern(regexHelpers.Website)]],
        revenue: [
          '',
          [
            Validators.required,
            Validators.pattern('^([1-9][0-9]{0,9}|10000000000)$'),
          ],
        ],
        noOfEmployees: [''],
        industry: ['', Validators.required],
        industryClass: ['', Validators.required],
        revenuePercentUS: [
          '',
          [
            Validators.required,
            Validators.pattern(this.percentageRegex),
            this.isRevenuePercentageValid(
              'revenuePercentCA',
              'revenuePercentOther',
            ),
          ],
        ],
        revenuePercentCA: [
          '',
          [
            Validators.required,
            Validators.pattern(this.percentageRegex),
            this.isRevenuePercentageValid(
              'revenuePercentUS',
              'revenuePercentOther',
            ),
          ],
        ],
        revenuePercentOther: [
          '',
          [
            Validators.required,
            Validators.pattern(this.percentageRegex),
            this.isRevenuePercentageValid(
              'revenuePercentUS',
              'revenuePercentCA',
            ),
          ],
        ],
        firstName: [''],
        lastName: [''],
        email: [
          '',
          [Validators.email, Validators.pattern(regexHelpers.MailId)],
        ],
        country: ['', Validators.required],
        province: ['', Validators.required],
        city: ['', Validators.required],
        address: ['', Validators.required],
        zipPostalCode: ['', Validators.required],
        addressLine2: [''],
      }),
      addIndividualForm: this.fb.group({
        firstName: ['', Validators.required],
        middleName: [''],
        lastName: ['', Validators.required],
        email: [
          '',
          [Validators.required, Validators.pattern(regexHelpers.MailId)],
        ],
        householdMembers: ['', Validators.required],
        country: ['', Validators.required],
        province: ['', Validators.required],
        city: ['', Validators.required],
        address: ['', Validators.required],
        zipPostalCode: ['', Validators.required],
        addressLine2: [''],
      }),
      individualSearchForm: this.fb.group({
        customerName: [''],
        email: [''],
        address: [''],
      }),
    });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  get companySearchFormCntrl() {
    return (<FormGroup>this.form.get('companySearchForm')).controls;
  }
  get individualSearchFormCntrl() {
    return (<FormGroup>this.form.get('individualSearchForm')).controls;
  }

  get addCompanyForm() {
    return this.form.get('addCompanyForm') as FormGroup;
  }

  get companySearchForm() {
    return this.form.get('companySearchForm') as FormGroup;
  }

  get addIndividualForm() {
    return this.form.get('addIndividualForm') as FormGroup;
  }

  get individualSearchForm() {
    return this.form.get('individualSearchForm') as FormGroup;
  }

  hasEmptyValueOrInvalid(...values) {
    return values.some((value) => {
      return value === '' || !this.percentageRegex.test(value);
    });
  }

  isRevenuePercentageValid(revenue1, revenue2) {
    return (control: FormControl) => {
      if (!control || !control.parent) {
        return null;
      } else if (control.value == 100) {
        control.parent.controls[revenue1].setValue(0);
        control.parent.controls[revenue2].setValue(0);
        return null;
      } else if (
        this.hasEmptyValueOrInvalid(
          control.parent.value[revenue1],
          control.parent.value[revenue2],
          control.value,
        )
      ) {
        return null;
      }
      if (
        Number(control.parent.value[revenue1]) +
          Number(control.parent.value[revenue2]) +
          Number(control.value) !=
        100
      ) {
        return {
          customError: true,
        };
      } else {
        control.parent.controls[revenue1].errors = null;
        control.parent.controls[revenue2].errors = null;
        control.parent.controls[revenue1].status = 'VALID';
        control.parent.controls[revenue2].status = 'VALID';
      }

      return {};
    };
  }

  ngOnInit(): void {
    if (
      this.localStorageService.getMockExternalAPIs() === 'false' ||
      environment.mockExternalAPIs === false
    ) {
      this.mockExternalAPIs = false;
    }
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    this.linkIconSrc = 'assets/img/plus-circle.png';
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.store.dispatch(new CreateQuoteAction.ResetState());
    this.boxxUserId = this.localStorageService.getBoxxUserId();
    this.actions$
      .pipe(
        ofType(
          CreateQuoteAction.SET_ADD_COMPANY_EXPANDED,
          CreateQuoteAction.SET_ADD_CUSTOMER_EXPANDED,
        ),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => {
        setTimeout(() => {
          this.viewportScroller.scrollToAnchor('btn-add');
        }, 0);
      });

    this.form$ = this.store.pipe(select(getQuoteSelector));

    this.form$.subscribe(
      (event) => (this.isInsuredTypeCompany = event.ui.isInsuredTypeCompany),
    );
    if (this.isInsuredTypeCompany) {
      this.companySearchData = this.store.pipe(
        select(getInsuredCompanySelector),
      );
    } else {
      this.individualSearchData = this.store.pipe(
        select(getInsuredIndividualSelector),
      );
    }
    this.pagination = this.store.pipe(select(getPaginationSelector));
    this.pagination.subscribe((pagination) => {
      if (this.isInsuredTypeCompany) {
        this.activeCompanySearchPgNo = pagination.currentPage;
      } else {
        this.activeIndividualSearchPgNo = pagination.currentPage;
      }
    });

    let isInsuredTypeCompany;
    let companySearchForm;
    let individualSearchForm;
    let pagination: Pagination;

    this.form$.subscribe(
      (event) => (isInsuredTypeCompany = event.ui.isInsuredTypeCompany),
    );
    this.form$.subscribe(
      (event) => (companySearchForm = event.companySearchForm),
    );
    this.form$.subscribe(
      (event) => (individualSearchForm = event.individualSearchForm),
    );
    this.form$.subscribe((event) => (pagination = event.pagination));

    if (isInsuredTypeCompany) {
      const companyName = companySearchForm.companyBusinessName;
      const companyWebsite = companySearchForm.website;
      const address = companySearchForm.address;

      if (companyName || companyWebsite || address) {
        this.store.dispatch(
          retrievedInsuredsByCompany({
            userId: this.boxxUserId,
            companyName: companyName,
            companyWebsite: companyWebsite,
            address: address,
            page: pagination.currentPage,
            sort: this.sortingCompanyKeys,
            active: 1,
          }),
        );
        this.companySearchData = this.store.pipe(
          select(getInsuredCompanySelector),
        );
        this.pagination = this.store.pipe(select(getPaginationSelector));
      }
    } else {
      const customerName = individualSearchForm.customerName;
      const email = individualSearchForm.email;
      const address = individualSearchForm.address;
      if (customerName || email || address) {
        this.store.dispatch(
          CreateQuoteAction.retrievedInsuredsByIndividual({
            userId: this.boxxUserId,
            name: customerName,
            email: email,
            address: address,
            page: pagination.currentPage,
            sort: this.sortingIndividualKeys,
            active: 1,
          }),
        );
        this.individualSearchData = this.store.pipe(
          select(getInsuredIndividualSelector),
        );
        this.pagination = this.store.pipe(select(getPaginationSelector));
      }
    }
  }

  ngOnDestroy(): void {
    this.alertService.clearAlerts(-1);
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  getSearchKeyWords(keywordObj = {}) {
    if (Object.keys(keywordObj).length !== 0) {
      return Object.values(keywordObj)
        .filter((str) => str !== '')
        .join(', ');
    }
    return this.searchKey;
  }

  onToggle(isCompany, isCompanyPrevSelected) {
    if (isCompany == isCompanyPrevSelected) {
      return;
    }

    this.submitted = false;
    this.searchSubmitted = false;
    if (!isCompany) {
      this.store.dispatch(new CreateQuoteAction.UnsetShowCompanySearchAction());
      this.store.dispatch(
        new CreateQuoteAction.UnsetAddCompanyExpandedAction(),
      );
    } else {
      this.store.dispatch(
        new CreateQuoteAction.UnsetShowCustomerSearchAction(),
      );
      this.store.dispatch(
        new CreateQuoteAction.UnsetAddCustomerExpandedAction(),
      );
    }
    this.store.dispatch(new CreateQuoteAction.ResetInsuredTypeAction());
  }

  // Company related methods

  handleCompanySearch(event) {
    this.submitted = false;
    this.searchSubmitted = true;
    this.hasMinimumCharacter = false;
    this.activeCompanySearchPgNo = 1;
    if (
      Object.values(this.form.value['companySearchForm']).filter(
        (str) => str !== '',
      ).length == 0
    ) {
      this.minLengthErrMsg = 'error.minOneLengthsearchErrMsg';
      return this.form.controls['companySearchForm']
        .get('companyBusinessName')
        .setErrors({ pattern: true });
    } else {
      this.form.controls['companySearchForm']
        .get('companyBusinessName')
        .setErrors(null);
    }

    Object.values(this.form.value['companySearchForm']).map(
      (str: string, index) => {
        if (str.length >= 3) {
          this.hasMinimumCharacter = true;
        } else {
          if (str) {
            this.minLengthErrMsg = 'error.minThreeLengthsearchErrMsg';
            if (index === 0) {
              return this.form.controls['companySearchForm']
                .get('companyBusinessName')
                .setErrors({ pattern: true });
            } else if (index === 1) {
              return this.form.controls['companySearchForm']
                .get('website')
                .setErrors({ pattern: true });
            } else if (index === 2) {
              return this.form.controls['companySearchForm']
                .get('address')
                .setErrors({ pattern: true });
            }
          }
        }
      },
    );
    if (this.companySearchForm.valid) {
      this.showTblSpinner = true;
      this.form.get('addCompanyForm').reset();
      this.store.dispatch(CreateQuoteAction.resetPagination());
      this.pagination = this.store.pipe(select(getPaginationSelector));
      this.store.dispatch(
        new CreateQuoteAction.AddInsuredCompanyAction(
          this.form.value['addCompanyForm'],
        ),
      );
      this.store.dispatch(
        new CreateQuoteAction.UnsetAddCompanyExpandedAction(),
      );
      this.store.dispatch(new CreateQuoteAction.SetShowCompanySearchAction());
      this.searchKey = Object.values(this.form.value['companySearchForm'])
        .filter((str) => str !== '')
        .join(', ');
      this.store.dispatch(
        new CreateQuoteAction.SearchInsuredCompanyAction(
          this.form.value['companySearchForm'],
        ),
      );
      const companyName =
        this.form.value['companySearchForm'].companyBusinessName;
      const companyWebsite = this.form.value['companySearchForm'].website;
      const address = this.form.value['companySearchForm'].address;
      this.store.dispatch(
        retrievedInsuredsByCompany({
          userId: this.boxxUserId,
          companyName: companyName,
          companyWebsite: companyWebsite,
          address: address,
          page: 1,
          sort: this.sortingCompanyKeys,
          active: 1,
        }),
      );
      this.companySearchData = this.store
        .pipe(select(getInsuredCompanySelector))
        .pipe(
          map((data: any) => {
            if (data.length < 1) {
              return [{ Name: '', Website: '', Address: '' }];
            } else {
              return data;
            }
          }),
        ) as Observable<any[]>;
      this.pagination = this.store.pipe(select(getPaginationSelector));
      this.showTblSpinner = false;
    }
  }

  closeSanctionPopup() {
    this.showSanctionModal = false;
  }

  handleSanctionProceed() {
    const doRecalculate =
      this.checkForRecalculate(
        'addCompanyForm',
        this.latestFormData.initialFormData,
      ) === true;
    this.updateInsuredCompany(this.formDataAddCompanyForm, doRecalculate, true);
  }

  clearCompanyResults() {
    this.form.get('companySearchForm').reset();
    this.sortingCompanyKeys = '';
    this.store.dispatch(new CreateQuoteAction.UnsetShowCompanySearchAction());
    this.store.dispatch(new CreateQuoteAction.UnsetAddCompanyExpandedAction());
    this.submitted = false;
    this.searchSubmitted = false;
  }

  companyPageChange(pageNumber) {
    this.activeCompanySearchPgNo = pageNumber;
    this.showTblSpinner = true;
    if (pageNumber > 0) {
      const companyName =
        this.form.value['companySearchForm'].companyBusinessName;
      const companyWebsite = this.form.value['companySearchForm'].website;
      const address = this.form.value['companySearchForm'].address;
      this.store.dispatch(
        retrievedInsuredsByCompany({
          userId: this.boxxUserId,
          companyName: companyName,
          companyWebsite: companyWebsite,
          address: address,
          page: pageNumber,
          sort: this.sortingCompanyKeys,
          active: 1,
        }),
      );
      this.companySearchData = this.store.pipe(
        select(getInsuredCompanySelector),
      );
      this.pagination = this.store.pipe(select(getPaginationSelector));
      this.showTblSpinner = false;
    }
  }

  handleCompanySort(event) {}

  handleCompanyTableColSortUp(e) {
    let order = 'asc';
    this.sortingCompanyKeys = makeSortingCol(e, this.colCompanyHeader, order);
    this.sortingCompany(this.sortingCompanyKeys);
  }

  sortingCompany(sortingKeys) {
    this.showTblSpinner = true;
    let pagination: Pagination;
    const companyName =
      this.form.value['companySearchForm'].companyBusinessName;
    const companyWebsite = this.form.value['companySearchForm'].website;
    const address = this.form.value['companySearchForm'].address;
    this.form$.subscribe((event) => (pagination = event.pagination));
    this.store.dispatch(
      retrievedInsuredsByCompany({
        userId: this.boxxUserId,
        companyName: companyName,
        companyWebsite: companyWebsite,
        address: address,
        page: pagination.currentPage,
        sort: sortingKeys,
        active: 1,
      }),
    );
    this.companySearchData = this.store.pipe(select(getInsuredCompanySelector));
    this.pagination = this.store.pipe(select(getPaginationSelector));
    this.showTblSpinner = false;
  }

  handleCompanyTableColSortDown(e) {
    let order = 'desc';
    this.sortingCompanyKeys = makeSortingCol(e, this.colCompanyHeader, order);
    this.sortingCompany(this.sortingCompanyKeys);
  }

  async handleCompanyRowClick(index, result) {
    let insured;
    this.companySearchData.subscribe((event) => (insured = event));
    const { Name: insuredName, Email: insuredEmail } = insured[index];
    this.store.dispatch(
      new CreateQuoteAction.UpdateInsuredAction({
        insuredName,
        insuredEmail,
        insuredId: result[index]?.id,
        industryId: result[index]?.industry?.id,
        industryName: result[index]?.industry?.name,
      }),
    );
    let reserved = false;
    await this.policyMarketReservationService
      .GetByInsuredId(result[index]?.id)
      .subscribe(async (data) => {
        reserved = data.data.activeQuote >= 1;
        if (reserved) {
          this.router.navigate(['reserved'], {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          });
        } else {
          await this.checkIfRestrictiveIndustry(
            result[index]?.industry?.id,
            false,
            result[index]?.industry?.name,
          );
        }
      });
  }

  checkIfRestrictiveIndustry(
    industryId,
    isNewCompanyAdd = false,
    industryName = '',
  ) {
    this.industryRestrictedService.GetByIndustryId(industryId).subscribe({
      next: (response) => {
        if (response?.data?.length > 0) {
          this.sanctionPayload.unshift({
            Type: 'Restricted industry',
            Description: industryName,
            Reasons: 'no-data',
            Source: 'Hiscox',
            Score: 'no-data',
          });
          this.isRestrictedIndustry = true;
          this.newQuoteService.setSanction({
            clearance: this.sanctionPayload,
            isRestricted: this.isRestrictedIndustry,
          });
          this.store.dispatch(
            new CreateQuoteAction.setSanctionAction({
              clearance: this.sanctionPayload,
              isRestricted: this.isRestrictedIndustry,
            }),
          );
          this.router.navigate(['restricted-industry'], {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          });
        } else {
          this.newQuoteService.setSanction({
            clearance: this.sanctionPayload,
            isRestricted: false,
          });
          this.store.dispatch(
            new CreateQuoteAction.setSanctionAction({
              clearance: this.sanctionPayload,
              isRestricted: false,
            }),
          );
          if (this.sanctionPayload?.length > 0) {
            this.router.navigate(['restricted-industry'], {
              relativeTo: this.activatedRoute,
              skipLocationChange: true,
            });
          } else {
            if (this.isOfacError) {
              this.handleErrorOrSuccess({
                show: true,
                type: 'error',
                header: 'common.errorHeader',
                body: 'submission.error.ofacError',
              });
              return;
            } else if (this.quoteExist) {
              this.router.navigate(['reserved'], {
                relativeTo: this.activatedRoute,
                skipLocationChange: true,
              });
            } else {
              this.router.navigate(['product'], {
                relativeTo: this.activatedRoute,
                skipLocationChange: true,
              });
            }
          }
        }
      },
      error: (error) => {
        this.bannerMsg = getErrorMessage(error);
        this.showBanner = true;
        this.showBodySpinner = false;
        this.showSpinner = false;
      },
    });
  }

  handleAddCompanyBtn(event) {
    this.submitted = false;
    this.store.dispatch(new CreateQuoteAction.UnsetShowCompanySearchAction());
    this.store.dispatch(new CreateQuoteAction.UnsetShowCustomerSearchAction());
    this.store.dispatch(new CreateQuoteAction.SetAddCompanyExpandedAction());
  }

  async addCompanyContinue(formData) {
    this.showBodySpinner = true;
    this.showBanner = false;
    this.searchSubmitted = false;
    this.submitted = true;
    const formValue = this.form.value['addCompanyForm'];
    try {
      const getInsuredByName = this.insuredService.GetByCompanyName(
        formValue.businessName,
      );
      const getInsuredResponse = await firstValueFrom(getInsuredByName);
      if (getInsuredResponse.data.length > 0) {
        const ifInsuredExists =
          getInsuredResponse.data.filter(
            (result) => formValue.businessName === result.companyName,
          )?.length > 0;
        if (ifInsuredExists) {
          this.handleErrorOrSuccess({
            show: true,
            type: 'error',
            header: 'common.errorHeader',
            body: 'insured.error.duplicateInsuredCompany',
          });
          return;
        }
      }
    } catch (error) {}

    this.regionService.GetByName(formValue.province).subscribe((data) => {
      const regionId = data.data[0].id;

      let location = {
        address: !!formValue.address ? formValue.address : null,
        city: !!formValue.city ? formValue.city : null,
        country: !!formValue.country ? formValue.country : null,
        zipPostalCode: !!formValue.zipPostalCode
          ? formValue.zipPostalCode
          : null,
        addressLine2: !!formValue.addressLine2 ? formValue.addressLine2 : null,
        geoLocationLatitude: 'string',
        geoLocationLongitude: ' string',
        source: 0,
        regionId: regionId,
      };
      try {
        this.locationService.Create(location).subscribe(async (data) => {
          let insured: InsuredCompanyPostRequest;

          insured = {
            companyName: formValue.businessName,
            companyWebsite: formValue.website,
            email: formValue.email,
            industryClassId: formData?.industryClass?.id,
            industryId: formData?.industry?.id,
            insuredLocationId: data.data?.id,
            insuredType: 2,
            numOfEmployees: formData?.numberOfEmp?.id,
            revenue: Number(formValue.revenue),
            webPressence: 'true',
            revenueUSPerc: Number(formValue.revenuePercentUS),
            revenueCAPerc: Number(formValue.revenuePercentCA),
            revenueOtherPerc: Number(formValue.revenuePercentOther),
            ofac: true,
            isMock: this.mockExternalAPIs,
          };
          this.insuredService.Create(insured).subscribe({
            next: async (data) => {
              const insuredId = data.data.id;
              const ofacData = data.data?.ofac;
              if (ofacData?.length > 0) {
                this.sanctionPayload.push(...ofacData);
              } else if (!(ofacData?.error === false)) {
                if ([400, 401, 500].includes(ofacData?.status)) {
                  this.isOfacError = true;
                }
              }

              let billingContactPayload = {};
              let firstName = this.form.value['addCompanyForm'].firstName ?? '';
              let lastName = this.form.value['addCompanyForm'].lastName ?? '';
              let email = this.form.value['addCompanyForm'].email;
              if (firstName) {
                billingContactPayload = {
                  ...billingContactPayload,
                  firstName: this.form.value['addCompanyForm'].firstName ?? '',
                };
              }
              if (lastName) {
                billingContactPayload = {
                  ...billingContactPayload,
                  lastName: this.form.value['addCompanyForm'].lastName ?? '',
                };
              }
              if (email) {
                billingContactPayload = {
                  ...billingContactPayload,
                  email: this.form.value['addCompanyForm'].email ?? null,
                };
              }
              try {
                if (Object.keys(billingContactPayload).length > 0) {
                  billingContactPayload = {
                    ...billingContactPayload,
                    insuredId: insuredId,
                  };
                  const addBillingContactData =
                    this.billingCompanyContactService.Create(
                      billingContactPayload,
                    );
                  let insuredBillingContactData = await firstValueFrom(
                    addBillingContactData,
                  );
                }

                this.store.dispatch(
                  new CreateQuoteAction.AddInsuredCompanyAction(
                    this.form.value['addCompanyForm'],
                  ),
                );

                this.store.dispatch(
                  new CreateQuoteAction.UpdateInsuredAction({
                    insuredName: this.form.value['addCompanyForm'].businessName,
                    insuredEmail: this.form.value['addCompanyForm'].email,
                    insuredId: insuredId,
                    regionId: regionId,
                  }),
                );
                let isInsuredTypeCompany;
                let versionId = 1;
                this.form$
                  .pipe(take(1))
                  .subscribe(
                    (event) =>
                      (isInsuredTypeCompany = event.ui.isInsuredTypeCompany),
                  );
                if (isInsuredTypeCompany) {
                  versionId = 2;
                }
                this.store.dispatch(
                  CreateQuoteAction.loadProducts({
                    userId: this.boxxUserId,
                    regionId: regionId,
                    versionId: versionId,
                  }),
                );
                this.selectedIndustryId = formData?.industry?.id;
                await this.checkIfRestrictiveIndustry(
                  this.selectedIndustryId,
                  true,
                  this.form.value['addCompanyForm']?.industry,
                );
              } catch (error) {
                if (![500].includes(error?.status)) {
                  this.handleErrorOrSuccess({
                    show: true,
                    type: 'error',
                    header: 'common.errorHeader',
                    body: getErrorMessage(error),
                  });
                }
              }
            },
            error: (error) => {
              if (![500].includes(error?.status)) {
                this.handleErrorOrSuccess({
                  show: true,
                  type: 'error',
                  header: 'common.errorHeader',
                  body: getErrorMessage(error),
                });
              }
            },
          });
        });
      } catch (error) {
        if (![500].includes(error?.status)) {
          this.handleErrorOrSuccess({
            show: true,
            type: 'error',
            header: 'common.errorHeader',
            body: getErrorMessage(error),
          });
        }
      }
    });
  }

  // Individual
  getCustomerName(form) {
    return form.firstName + ' ' + form.middleName + ' ' + form.lastName;
  }

  addIndividualContinue(formData) {
    this.showBanner = false;
    this.searchSubmitted = false;
    this.submitted = true;
    const individualForm = this.form.value['addIndividualForm'];

    if (this.form.get('addIndividualForm').valid) {
      try {
        this.regionService
          .GetByName(individualForm.province)
          .subscribe((data) => {
            const regionId = data.data[0].id;

            let location = {
              address: !!individualForm.address ? individualForm.address : null,
              city: !!individualForm.city ? individualForm.city : null,
              country: !!individualForm.country ? individualForm.country : null,
              zipPostalCode: !!individualForm.zipPostalCode
                ? individualForm.zipPostalCode
                : null,
              addressLine2: !!individualForm.addressLine2
                ? individualForm.addressLine2
                : null,
              geoLocationLatitude: 'string',
              geoLocationLongitude: ' string',
              source: 0,
              regionId: regionId,
            };

            this.locationService.Create(location).subscribe((data) => {
              let insured: InsuredIndividualPostRequest;
              insured = {
                insuredType: 1,
                insuredLocationId: data.data?.id,
                email: individualForm.email,
                firstName: individualForm.firstName,
                lastName: individualForm.lastName,
                middleName: individualForm.middleName,
                numOfHouseholdMem: formData?.numberOfHouseholdMembersId,
                ofac: true,
                isMock: this.mockExternalAPIs,
              };

              this.insuredService.Create(insured).subscribe({
                next: (data) => {
                  const insuredId = data.data.id;
                  const ofac = data.data.ofac;
                  if (ofac?.length > 0) {
                    this.sanctionPayload.push(...ofac);
                  } else if (!(ofac?.error === false)) {
                    if ([400, 401, 500].includes(ofac?.status)) {
                      this.isOfacError = true;
                    }
                  }
                  this.newQuoteService.setSanction({
                    clearance: this.sanctionPayload,
                    isRestricted: false,
                  });
                  this.store.dispatch(
                    new CreateQuoteAction.setSanctionAction({
                      clearance: this.sanctionPayload,
                      isRestricted: false,
                    }),
                  );
                  this.store.dispatch(
                    new CreateQuoteAction.AddInsuredCustomerAction(
                      individualForm,
                    ),
                  );
                  let isInsuredTypeCompany;
                  let versionId = 1;
                  this.form$.subscribe(
                    (event) =>
                      (isInsuredTypeCompany = event.ui.isInsuredTypeCompany),
                  );
                  if (isInsuredTypeCompany) {
                    versionId = 2;
                  }

                  this.store.dispatch(
                    new CreateQuoteAction.UpdateInsuredAction({
                      insuredName: this.getCustomerName(individualForm),
                      insuredEmail: individualForm.email,
                      insuredId: insuredId,
                      regionId: regionId,
                    }),
                  );
                  this.store.dispatch(
                    CreateQuoteAction.loadProducts({
                      userId: this.boxxUserId,
                      regionId: regionId,
                      versionId: versionId,
                    }),
                  );
                  this.showBodySpinner = false;
                  this.showSpinner = false;
                  if (ofac.length > 0) {
                    this.router.navigate(['restricted-industry'], {
                      relativeTo: this.activatedRoute,
                      skipLocationChange: true,
                    });
                    return;
                  } else {
                    this.newQuoteService.setInsuredId(insuredId);
                    this.router.navigate(['product'], {
                      relativeTo: this.activatedRoute,
                      skipLocationChange: true,
                    });
                  }
                  this.newQuoteService.setInsuredId(insuredId);
                  this.router.navigate(['product'], {
                    relativeTo: this.activatedRoute,
                    skipLocationChange: true,
                  });
                  this.clearIndividualResults();
                },
                error: (error) => {
                  if (![500].includes(error?.status)) {
                    this.handleErrorOrSuccess({
                      show: true,
                      type: 'error',
                      header: 'common.errorHeader',
                      body: getErrorMessage(error),
                    });
                  }
                },
              });
            });
          });
      } catch (error) {
        if (![500].includes(error?.status)) {
          this.handleErrorOrSuccess({
            show: true,
            type: 'error',
            header: 'common.errorHeader',
            body: getErrorMessage(error),
          });
        }
      }
    }
  }

  handleIndividualSearch(event) {
    this.submitted = false;
    this.searchSubmitted = true;
    this.hasMinimumCharacter = false;
    this.activeIndividualSearchPgNo = 1;
    if (
      Object.values(this.form.value['individualSearchForm']).filter(
        (str) => str !== '',
      ).length == 0
    ) {
      this.minLengthErrMsg = 'error.minOneLengthsearchErrMsg';
      return this.form.controls['individualSearchForm']
        .get('customerName')
        .setErrors({ pattern: true });
    } else {
      this.form.controls['individualSearchForm']
        .get('customerName')
        .setErrors(null);
    }

    Object.values(this.form.value['individualSearchForm']).map(
      (str: string, index) => {
        if (str.length >= 3) {
          this.hasMinimumCharacter = true;
        } else {
          if (str) {
            this.minLengthErrMsg = 'error.minThreeLengthsearchErrMsg';
            if (index === 0) {
              return this.form.controls['individualSearchForm']
                .get('customerName')
                .setErrors({ pattern: true });
            } else if (index === 1) {
              return this.form.controls['individualSearchForm']
                .get('email')
                .setErrors({ pattern: true });
            } else if (index === 2) {
              return this.form.controls['individualSearchForm']
                .get('address')
                .setErrors({ pattern: true });
            }
          }
        }
      },
    );

    if (this.individualSearchForm.valid) {
      this.showTblSpinner = true;
      this.form.get('addIndividualForm').reset();
      this.store.dispatch(
        new CreateQuoteAction.AddInsuredCustomerAction(
          this.form.value['addIndividualForm'],
        ),
      );
      this.store.dispatch(
        new CreateQuoteAction.UnsetAddCompanyExpandedAction(),
      );
      this.store.dispatch(new CreateQuoteAction.SetShowCustomerSearchAction());
      this.searchKey = Object.values(this.form.value['individualSearchForm'])
        .filter((str) => str !== '')
        .join(', ');
      this.store.dispatch(
        new CreateQuoteAction.SearchInsuredCustomerAction(
          this.form.value['individualSearchForm'],
        ),
      );

      const customerName = this.form.value['individualSearchForm'].customerName;
      const email = this.form.value['individualSearchForm'].email;
      const address = this.form.value['individualSearchForm'].address;

      this.store.dispatch(
        CreateQuoteAction.retrievedInsuredsByIndividual({
          userId: this.boxxUserId,
          name: customerName,
          email: email,
          address: address,
          page: 1,
          sort: this.sortingIndividualKeys,
          active: 1,
        }),
      );
      this.individualSearchData = this.store
        .pipe(select(getInsuredIndividualSelector))
        .pipe(
          map((data: any) => {
            if (data.length < 1) {
              return [{ Name: '', Email: '', Address: '' }];
            } else {
              return data;
            }
          }),
        ) as Observable<any[]>;
      this.pagination = this.store.pipe(select(getPaginationSelector));
      this.showTblSpinner = false;
    }
  }
  handleAddCustomerBtn($event) {
    this.submitted = false;
    this.store.dispatch(new CreateQuoteAction.UnsetShowCompanySearchAction());
    this.store.dispatch(new CreateQuoteAction.UnsetShowCustomerSearchAction());
    this.store.dispatch(new CreateQuoteAction.SetAddCustomerExpandedAction());
  }

  handleIndividualRowClick(index, result) {
    let insured;
    this.individualSearchData.subscribe((event) => (insured = event));

    this.store.dispatch(
      new CreateQuoteAction.UpdateInsuredAction({
        insuredName: insured[index].Name,
        insuredEmail: insured[index].Email,
        insuredId: result[index].id,
      }),
    );

    const insuredId = result[index].id;
    let reserved = false;
    this.policyMarketReservationService
      .GetByInsuredId(insuredId)
      .subscribe((data) => {
        reserved = data.data.activeQuote === 1;
        if (reserved) {
          this.router.navigate(['reserved'], {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          });
        } else {
          this.router.navigate(['product'], {
            relativeTo: this.activatedRoute,
            skipLocationChange: true,
          });
        }
      });
  }

  customerPageChange(pageNumber) {
    if (pageNumber > 0) {
      this.activeIndividualSearchPgNo = pageNumber;
      const customerName = this.form.value['individualSearchForm'].customerName;
      const email = this.form.value['individualSearchForm'].email;
      const address = this.form.value['individualSearchForm'].address;
      this.store.dispatch(
        CreateQuoteAction.retrievedInsuredsByIndividual({
          userId: this.boxxUserId,
          name: customerName,
          email: email,
          address: address,
          page: pageNumber,
          sort: this.sortingIndividualKeys,
          active: 1,
        }),
      );
      this.individualSearchData = this.store.pipe(
        select(getInsuredIndividualSelector),
      );
      this.pagination = this.store.pipe(select(getPaginationSelector));
    }
  }
  handleCustomerSort(event) {}

  handleIndividualTableColSortUp(e) {
    let order = 'asc';
    this.sortingIndividualKeys = makeSortingCol(
      e,
      this.colIndividualHeader,
      order,
    );
    this.sortingIndividual(this.sortingIndividualKeys);
  }

  sortingIndividual(sortingKeys) {
    this.showTblSpinner = true;
    let pagination: Pagination;
    this.form$.subscribe((event) => (pagination = event.pagination));
    const customerName = this.form.value['individualSearchForm'].customerName;
    const email = this.form.value['individualSearchForm'].email;
    const address = this.form.value['individualSearchForm'].address;
    this.store.dispatch(
      CreateQuoteAction.retrievedInsuredsByIndividual({
        userId: this.boxxUserId,
        name: customerName,
        email: email,
        address: address,
        page: pagination.currentPage,
        sort: sortingKeys,
        active: 1,
      }),
    );
    this.individualSearchData = this.store.pipe(
      select(getInsuredIndividualSelector),
    );
    this.pagination = this.store.pipe(select(getPaginationSelector));
    this.showTblSpinner = false;
  }

  handleIndividualTableColSortDown(e) {
    let order = 'desc';
    this.sortingIndividualKeys = makeSortingCol(
      e,
      this.colIndividualHeader,
      order,
    );
    this.sortingIndividual(this.sortingIndividualKeys);
  }

  handleBusinessIndustrySector(industryName) {
    this.selectedIndustryId = this.businessIndustry[industryName]?.id;
    this.businessIndustrySectorOptns = [];
  }

  clearIndividualResults() {
    this.form.get('individualSearchForm').reset();
    this.sortingIndividualKeys = '';
    this.store.dispatch(new CreateQuoteAction.UnsetShowCustomerSearchAction());
    this.store.dispatch(new CreateQuoteAction.UnsetAddCustomerExpandedAction());
    this.submitted = false;
    this.searchSubmitted = false;
  }
  handleNotificationAlertClose() {
    this.showBanner = false;
    this.bannerMsg = '';
  }

  handleErrorOrSuccess(alertData) {
    if (alertData.type === 'error') {
      this.bannerMsg = alertData?.body;
      this.showBanner = true;
      this.showBodySpinner = false;
      this.showSpinner = false;
    }
  }

  async addOrUpdateIndividualInsured(formData) {
    this.isOfacError = false;
    this.showBodySpinner = true;
    this.submitted = true;
    this.insuredId = formData.insuredId ? formData.insuredId : null;
    this.isEditMode = formData.insuredId ? true : false;

    if (this.form.get('addIndividualForm').valid) {
      if (this.isEditMode) {
        this.updateInsuredIndividual(formData);
      } else {
        this.addIndividualContinue(formData);
      }
    } else {
      this.showBodySpinner = false;
    }
  }

  updateInsuredIndividual(formData) {
    if (formData.insuredLocationId) {
      this.showBodySpinner = true;
      const individualForm = this.form.value['addIndividualForm'];
      this.regionService
        .GetByName(individualForm.province)
        .subscribe(async (data) => {
          const regionId = data.data[0].id;

          let location = {
            address: !!individualForm.address ? individualForm.address : null,
            city: !!individualForm.city ? individualForm.city : null,
            country: !!individualForm.country ? individualForm.country : null,
            zipPostalCode: !!individualForm.zipPostalCode
              ? individualForm.zipPostalCode
              : null,
            addressLine2: !!individualForm.addressLine2
              ? individualForm.addressLine2
              : null,
            geoLocationLatitude: 'string',
            geoLocationLongitude: ' string',
            source: 0,
            regionId: regionId,
            id: formData.insuredLocationId,
          };

          const hasLocationChanged = this.hasLocationDataChanged(
            location,
            formData?.initialFormData,
          );

          try {
            if (hasLocationChanged) {
              const updateLocation =
                this.locationService.updateLocation(location);
              const locationUpdated = await firstValueFrom(updateLocation);
            }

            const insuredPayload = {
              insuredType: 1,
              insuredLocationId: data.data?.id,
              email: individualForm.email,
              firstName: individualForm.firstName,
              lastName: individualForm.lastName,
              middleName: individualForm.middleName,
              numOfHouseholdMem: formData?.numberOfHouseholdMembersId,
            };
            try {
              const updateInsuredDetails = this.insuredService.Update(
                this.insuredId,
                insuredPayload,
              );
              const insuredUpdated = await firstValueFrom(updateInsuredDetails);

              this.store.dispatch(
                new CreateQuoteAction.AddInsuredCustomerAction(individualForm),
              );
              let isInsuredTypeCompany;
              let versionId = 1;
              this.form$.subscribe(
                (event) =>
                  (isInsuredTypeCompany = event.ui.isInsuredTypeCompany),
              );
              if (isInsuredTypeCompany) {
                versionId = 2;
              }

              this.store.dispatch(
                new CreateQuoteAction.UpdateInsuredAction({
                  insuredName: this.getCustomerName(individualForm),
                  insuredEmail: individualForm.email,
                  insuredId: this.insuredId,
                  regionId: regionId,
                }),
              );
              this.store.dispatch(
                CreateQuoteAction.loadProducts({
                  userId: this.boxxUserId,
                  regionId: regionId,
                  versionId: versionId,
                }),
              );
              this.store.dispatch(
                new InsuredAction.setInsuredNameAction(
                  individualForm?.businessName ?? '',
                ),
              );
              this.newQuoteService.setInsuredId(this.insuredId);
              this.showBodySpinner = false;
              if (this.quoteExist) {
                this.router.navigate(['reserved'], {
                  relativeTo: this.activatedRoute,
                  skipLocationChange: true,
                });
              } else {
                this.router.navigate(['product'], {
                  relativeTo: this.activatedRoute,
                  skipLocationChange: true,
                });
              }
            } catch (error) {
              if (![500].includes(error?.status)) {
                this.handleErrorOrSuccess({
                  show: true,
                  header: 'common.errorHeader',
                  body: getErrorMessage(error),
                  type: 'error',
                });
              }
              this.showBodySpinner = false;
            }
          } catch (error) {
            if (![500].includes(error?.status)) {
              this.handleErrorOrSuccess({
                show: true,
                header: 'common.errorHeader',
                body: getErrorMessage(error),
                type: 'error',
              });
            }
            this.showBodySpinner = false;
          }
        });
    }
  }

  hasLocationDataChanged(latest, previous) {
    const latestLocationData = {
      address: latest?.address,
      addressLine2: latest?.addressLine2,
      city: latest?.city,
      country: latest?.country,
      province: this.form.value['addIndividualForm'].province,
      zipPostalCode: latest?.zipPostalCode,
    };

    const previousLocationData = {
      address: previous?.address,
      addressLine2: previous?.addressLine2,
      city: previous?.city,
      country: previous?.country,
      province: previous?.province,
      zipPostalCode: previous?.zipPostalCode,
    };

    if (
      JSON.stringify(latestLocationData) !==
      JSON.stringify(previousLocationData)
    ) {
      return true;
    }
    return false;
  }

  ofacCheckRequired(newForm, oldForm) {
    if (
      !(
        newForm?.businessName == oldForm?.businessName &&
        newForm.website == oldForm.website &&
        newForm.address == oldForm.address &&
        newForm.province == oldForm.province &&
        newForm.city == oldForm.city &&
        newForm.zipPostalCode == oldForm.zipPostalCode &&
        newForm.country == oldForm.country
      )
    ) {
      return true;
    }
    return false;
  }

  async addOrUpdateCompanyInsured(formData) {
    this.isOfacError = false;
    this.submitted = true;
    if (formData.initialFormData == null && this.quoteExist)
      formData = this.formDataBack;
    this.insuredId = formData?.insuredId
      ? formData?.insuredId
      : this.insuredId ?? null;
    this.isEditMode = formData?.insuredId
      ? true
      : this.insuredId
      ? true
      : false;
    if (!this.quoteExist && this.insuredId !== null)
      await Promise.all([this.checkForActiveQuote(this.insuredId)]);

    if (this.form.get('addCompanyForm').valid) {
      if (this.isEditMode) {
        if (this.quoteExist) {
          this.insuredName = formData?.initialFormData?.businessName ?? '';
          this.actionPopupDetails = {
            ...this.actionPopupDetails,
            insured: this.insuredName,
          };
          const formValue = this.form.value['addCompanyForm'];
          if (
            (formData?.initialFormData &&
              this.ofacCheckRequired(formValue, formData.initialFormData)) ||
            this.sanctionPayload.length > 0
          ) {
            const entitySearchData = {
              cases: [
                {
                  name: formValue?.businessName,
                  type: 'entity',
                  ids: [
                    {
                      id: formValue?.website,
                    },
                  ],
                  address: {
                    address1: formValue.address,
                    city: formValue?.city,
                    country: formValue?.country,
                    postalCode: formValue?.zipPostalCode,
                  },
                },
              ],
            };

            const ofacSearchData = this.ofacService.Create(entitySearchData);
            const ofacData = await firstValueFrom(ofacSearchData);

            if (ofacData?.data?.length > 0) {
              this.sanctionPayload = [...ofacData?.data];
            } else if (!(ofacData?.error === false)) {
              if ([400, 401, 500].includes(ofacData?.status)) {
                this.isOfacError = true;
              }
            } else {
              this.sanctionPayload = [];
            }
          }
          const restrictedIndustry =
            this.industryRestrictedService.GetByIndustryId(
              formData?.industry?.id,
            );
          const restrictedIndustryResponse =
            await firstValueFrom(restrictedIndustry);
          if (restrictedIndustryResponse?.data?.length > 0) {
            this.sanctionPayload.unshift({
              Type: 'Restricted industry',
              Description: formValue?.industry,
              Reasons: 'no-data',
              Source: 'Hiscox',
              Score: 'no-data',
            });
            this.isRestrictedIndustry = true;
          } else {
            this.isRestrictedIndustry = false;
          }
          if (this.sanctionPayload.length > 0) {
            this.formDataAddCompanyForm = formData;
            this.latestFormData = formData;
            this.showSanctionModal = true;
            return;
          } else {
            if (
              this.checkForRecalculate(
                'addCompanyForm',
                formData.initialFormData,
              ) === true
            ) {
              this.formDataAddCompanyForm = null;
              this.formDataAddCompanyForm = formData;
              this.showRecalcConfModal = true;
            } else {
              this.updateInsuredCompany(formData);
            }
          }
        } else {
          this.updateInsuredCompany(formData);
        }
      } else {
        this.addCompanyContinue(formData);
      }
    }
  }

  updateInsuredCompany(
    formData: any,
    isRecalculate: boolean = false,
    proceedWithSanctions = false,
  ) {
    if (formData.insuredLocationId) {
      this.showBodySpinner = true;
      const formValue = this.form.value['addCompanyForm'];
      this.regionService
        .GetByName(this.form.value['addCompanyForm'].province)
        .subscribe(async (data) => {
          const regionId = data.data[0].id;

          let location = {
            address: !!formValue.address ? formValue.address : null,
            city: !!formValue.city ? formValue.city : null,
            country: !!formValue.country ? formValue.country : null,
            zipPostalCode: !!formValue.zipPostalCode
              ? formValue.zipPostalCode
              : null,
            addressLine2: !!formValue.addressLine2
              ? formValue.addressLine2
              : null,
            geoLocationLatitude: 'string',
            geoLocationLongitude: ' string',
            source: 0,
            regionId: regionId,
            id: formData.insuredLocationId,
          };

          const hasLocationChanged = this.hasLocationDataChanged(
            location,
            formData?.initialFormData,
          );

          let billingContactId = formData.billingContactId;
          let billingContactPayload = {};
          billingContactPayload = {
            ...billingContactPayload,
            firstName: this.form.value['addCompanyForm'].firstName ?? '',
            lastName: this.form.value['addCompanyForm'].lastName ?? '',
          };
          let email = this.form.value['addCompanyForm'].email;
          if (email) {
            billingContactPayload = {
              ...billingContactPayload,
              email: this.form.value['addCompanyForm'].email ?? null,
            };
          }

          const initialBillingContactData = {
            firstName: formData?.initialFormData?.firstName,
            lastName: formData?.initialFormData?.lastName,
            email: formData?.initialFormData?.email,
          };
          try {
            if (
              JSON.stringify(billingContactPayload) !==
              JSON.stringify(initialBillingContactData)
            ) {
              if (billingContactId) {
                const billingContactPayload = {
                  firstName: this.form.value['addCompanyForm'].firstName,
                  lastName: this.form.value['addCompanyForm'].lastName,
                  email: this.form.value['addCompanyForm'].email ?? '',
                };
                const updateBillingCompanyContact =
                  this.billingCompanyContactService.Update(
                    billingContactId,
                    billingContactPayload,
                  );
                const billingUpdated = await firstValueFrom(
                  updateBillingCompanyContact,
                );
              } else {
                let billingContactPayload = {};
                let firstName =
                  this.form.value['addCompanyForm'].firstName ?? '';
                let lastName = this.form.value['addCompanyForm'].lastName ?? '';
                let email = this.form.value['addCompanyForm'].email;
                if (firstName) {
                  billingContactPayload = {
                    ...billingContactPayload,
                    firstName:
                      this.form.value['addCompanyForm'].firstName ?? '',
                  };
                }
                if (lastName) {
                  billingContactPayload = {
                    ...billingContactPayload,
                    lastName: this.form.value['addCompanyForm'].lastName ?? '',
                  };
                }
                if (email) {
                  billingContactPayload = {
                    ...billingContactPayload,
                    email: this.form.value['addCompanyForm'].email ?? null,
                  };
                }
                if (Object.keys(billingContactPayload).length > 0) {
                  billingContactPayload = {
                    ...billingContactPayload,
                    insuredId: Number(this.insuredId),
                  };
                  const addBillingContactData =
                    this.billingCompanyContactService.Create(
                      billingContactPayload,
                    );
                  let insuredBillingContactData = await firstValueFrom(
                    addBillingContactData,
                  );
                }
              }
            }
            if (hasLocationChanged) {
              const updateLocation =
                this.locationService.updateLocation(location);
              const locationUpdated = await firstValueFrom(updateLocation);
            }

            let insuredPayload: any = {
              companyName: formValue.businessName,
              companyWebsite: formValue.website,
              industryClassId: formData?.industryClass?.id,
              industryId: formData?.industry?.id,
              insuredLocationId: formData?.insuredLocationId,
              insuredType: 2,
              numOfEmployees: formData?.numberOfEmp?.id,
              revenue: Number(formValue.revenue),
              webPressence: 'true',
              revenueUSPerc: Number(formValue.revenuePercentUS),
              revenueCAPerc: Number(formValue.revenuePercentCA),
              revenueOtherPerc: Number(formValue.revenuePercentOther),
              ofac: true,
              isMock: this.mockExternalAPIs,
            };

            try {
              const updateInsuredDetails = await this.insuredService.Update(
                this.insuredId,
                insuredPayload,
              );
              const insuredUpdated: any =
                await firstValueFrom(updateInsuredDetails);
              const ofac = insuredUpdated?.data?.ofac;
              if (!proceedWithSanctions && ofac.length > 0) {
                this.sanctionPayload.push(...ofac);
              } else if (!(ofac?.error === false)) {
                if ([400, 401, 500].includes(ofac?.status)) {
                  this.isOfacError = true;
                }
              } else if (proceedWithSanctions) {
                this.sanctionPayload = [];
              }

              this.store.dispatch(
                new CreateQuoteAction.AddInsuredCompanyAction(
                  this.form.value['addCompanyForm'],
                ),
              );

              this.store.dispatch(
                new CreateQuoteAction.UpdateInsuredAction({
                  insuredName: this.form.value['addCompanyForm'].businessName,
                  insuredEmail: this.form.value['addCompanyForm'].email,
                  insuredId: this.insuredId,
                  regionId: regionId,
                }),
              );
              let isInsuredTypeCompany;
              let versionId = 1;
              this.form$
                .pipe(take(1))
                .subscribe(
                  (event) =>
                    (isInsuredTypeCompany = event.ui.isInsuredTypeCompany),
                );
              if (isInsuredTypeCompany) {
                versionId = 2;
              }
              this.store.dispatch(
                CreateQuoteAction.loadProducts({
                  userId: this.boxxUserId,
                  regionId: regionId,
                  versionId: versionId,
                }),
              );
              this.selectedIndustryId = formData?.industry?.id;
              if (!proceedWithSanctions) {
                await this.checkIfRestrictiveIndustry(
                  this.selectedIndustryId,
                  false,
                );
              }

              this.store.dispatch(
                new InsuredAction.setInsuredNameAction(
                  formValue?.businessName ?? '',
                ),
              );

              this.showBodySpinner = false;

              if (isRecalculate === true) this.doRecalculate();
              if (proceedWithSanctions) {
                this.newQuoteService.setInsuredId(this.insuredId);

                if (this.quoteExist) {
                  this.router.navigate(['reserved'], {
                    relativeTo: this.activatedRoute,
                    skipLocationChange: true,
                  });
                } else {
                  this.router.navigate(['product'], {
                    relativeTo: this.activatedRoute,
                    skipLocationChange: true,
                  });
                }
              }
            } catch (error) {
              if (![500].includes(error?.status)) {
                this.handleErrorOrSuccess({
                  show: true,
                  header: 'common.errorHeader',
                  body: getErrorMessage(error),
                  type: 'error',
                });
              }
              this.showBodySpinner = false;
            }
          } catch (error) {
            if (![500].includes(error?.status)) {
              this.handleErrorOrSuccess({
                show: true,
                header: 'common.errorHeader',
                body: getErrorMessage(error),
                type: 'error',
              });
            }
            this.showBodySpinner = false;
          }
        });
    }
  }

  checkForRecalculate(formControlName: string, initialValues): boolean {
    let changedControls = [
      'industry',
      'industryClass',
      'revenue',
      'revenuePercentUS',
      'revenuePercentCA',
      'revenuePercentOther',
    ];
    let triggerRecalculate = false;
    if (formControlName === 'addCompanyForm') {
      triggerRecalculate = this.getChangedFieldsStatus(
        this.form.controls[formControlName].value,
        initialValues,
        changedControls,
      );
    }
    return triggerRecalculate;
  }

  getChangedFieldsStatus(
    obj1: any,
    obj2: any,
    fieldsToCheck: string[],
  ): boolean {
    const stringifyAndSort = (obj: any): string =>
      JSON.stringify(obj, Object.keys(obj).sort());

    const normalizeObject = (obj: any): any =>
      JSON.parse(JSON.stringify(obj), (key, value) =>
        typeof value === 'string' && !isNaN(Number(value))
          ? this.roundNumber(Number(value), 2)
          : value,
      );

    const normalizedObj1 = normalizeObject(obj1);
    const normalizedObj2 = normalizeObject(obj2);

    const stringifiedObj1 = stringifyAndSort(normalizedObj1);
    const stringifiedObj2 = stringifyAndSort(normalizedObj2);

    if (stringifiedObj1 === stringifiedObj2) {
      return false;
    }

    const changedFields: string[] = [];
    fieldsToCheck.forEach((field) => {
      if (normalizedObj1[field] !== normalizedObj2[field]) {
        changedFields.push(field);
      }
    });

    return changedFields.length === 0 ? false : true;
  }

  roundNumber(value: number, precision: number): number {
    const factor = Math.pow(10, precision);
    return Math.round(value * factor) / factor;
  }

  doRecalculate() {
    let policyRiskList;
    if (this.policyPeriodId) {
      this.showBodySpinner = true;
      this.policyRiskService
        .GetAllByPolicyPeriodId(this.policyPeriodId)
        .subscribe(async (data) => {
          if (data?.data[0]) {
            policyRiskList = data?.data;

            const resultArray = policyRiskList.flatMap((item) =>
              item.PolicyRiskTrxes.flatMap((trx) =>
                trx.QuoteOptions.map((option) => option.id),
              ),
            );

            for (const element of resultArray) {
              try {
                const riskRating =
                  await this.riskRatingCalculateService.GetByQuoteOption(
                    element,
                  );
                const ratingData = await firstValueFrom(riskRating);
              } catch (error) {}
            }

            this.closeModalRecalculateConf();
            this.showBodySpinner = false;

            this.newQuoteService.setInsuredId(this.insuredId);

            if (this.quoteExist) {
              this.router.navigate(['reserved'], {
                relativeTo: this.activatedRoute,
                skipLocationChange: true,
              });
            }
          }
        });
    }
  }
  closeModalRecalculateConf() {
    this.showRecalcConfModal = false;
  }

  handleSubmissionRecalculate(action: any, formData: any) {
    this.updateInsuredCompany(this.formDataAddCompanyForm, true);
  }

  handleDiscardRecalculate(action: any) {
    this.formDataBack = this.formDataAddCompanyForm;
    this.addCompanyForm.patchValue(this.formDataAddCompanyForm.initialFormData);
    this.showRecalcConfModal = false;
    this.showSanctionModal = false;
  }

  handleDeclineAndSave() {
    const PolicyDeclineRequest = {
      policyPeriodId: Number(this.policyPeriodId),
      declineReason: 173,
    };

    this.policyLifecycleService
      .TransactionRiskDecline(PolicyDeclineRequest)
      .subscribe({
        next: (response) => {
          this.updateInsuredCompany(this.formDataAddCompanyForm, false, true);
        },
        error: (error) => {
          this.showSanctionModal = false;
          this.handleErrorOrSuccess({
            show: true,
            header: 'common.errorHeader',
            body: getErrorMessage(error),
            type: 'error',
          });
        },
      });
  }

  checkForActiveQuote(insuredId: any) {
    this.showBodySpinner = true;
    return new Promise<any>(async (resolve, reject) => {
      this.policyMarketReservationService.GetByInsuredId(insuredId).subscribe({
        next: async (resp) => {
          const quoteDetails = resp?.data;
          this.quoteExist = quoteDetails?.activeQuote > 0 ? true : false;
          this.actionPopupDetails = {
            quoteNumber: null,
            effectiveDate: null,
            insured: null,
          };
          this.actionPopupDetails = {
            quoteNumber: quoteDetails?.policyId,
            effectiveDate: formatDateMoment(
              quoteDetails?.effectiveDt,
              this.shortDateFormat,
            ),
          };
          const getPolicyByID = this.policyPeriodService.GetAllByPolicyId(
            quoteDetails?.policyId,
          );
          let dataPId = await firstValueFrom(getPolicyByID);

          this.policyPeriodId = dataPId?.data[0]?.id;
          this.showBodySpinner = false;
          resolve(true);
        },
        error: (error) => {
          this.showBodySpinner = false;
          reject(getErrorMessage(error));
        },
      });
    });
  }

  handleSpinnerChange(event: boolean) {
    this.childSpinnerChanged = event;
  }

  toggleChange(data) {
    this.showContactDetails = data.showContactDetails;
    this.contactToggleEnabled = data.contactToggleEnabled;
  }
}
