import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { firstValueFrom, Observable, Subject, take, takeUntil } from 'rxjs';
import { ViewportScroller } from '@angular/common';
import { Actions, ofType } from '@ngrx/effects';
import { Pagination } from 'src/app/entities/boxx-response';
import { Quote } from 'src/app/models/quote.model';
import { InsuredService } from 'src/app/services/insured.service';
import * as InsuredAction from 'src/app/store/insured/insured.action';
import { LocationService } from 'src/app/services/location.service';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import {
  getInsuredCompanySelector,
  getInsuredIndividualSelector,
  getPaginationSelector,
  getQuoteSelector,
} from 'src/app/store/create-quote/create-quote.selector';
import {
  arrayToObjet,
  getErrorMessage,
  postalCodeValidator,
  regexHelpers,
  removeDuplicatedKeys,
} from 'src/app/utils/utils';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { NewQuoteService } from 'src/app/services/new-quote.service';
import { BillingCompanyContactService } from 'src/app/services/billing-company-contact.service';
import { AlertService } from 'src/app/services/alert.service';
import {
  getCountrySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import { CANADA, UNITED_STATES } from 'src/app/constants/location-constant';
import { NAICSPredictionService } from 'src/app/services/naicsPrediction.service';
import { IndustryClassService } from 'src/app/services/industry-class.service';
import { IndustryService } from 'src/app/services/industry.service';
import { DomainsService } from 'src/app/services/domains.service';
import { MapService } from 'src/app/services/map.service';
import { DOMAIN_CODE_RANGENUMEMPLOYEES } from 'src/app/constants/quote-constant';
import { LanguageService } from 'src/app/services/language.service';
import { UiContentService } from 'src/app/services/uiContent.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { REVENUE_MARGIN_VALUE } from 'src/app/constants/submission-constant';
import { alertDetails } from 'src/app/entities/common';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import { PolicyMarketReservationService } from 'src/app/services/policy-market.service';
import { PolicyPeriodService } from 'src/app/services/policy-period.service';
import { PolicyDashboardService } from 'src/app/services/policy-dashboard.service';
import {
  NAICS_EXISTENCE_CHECK_NAME,
  NAICS_KEYWORDS_NAME,
  NAICS_PREDICTION_OBJECT_NAME,
} from 'src/app/constants/naics-constants';
import { IndustryRestrictedService } from 'src/app/services/industry-restricted.service';
import {
  QUOTESTATUS_SUBMISSION,
  QUOTESTATUS_UADECLINE,
} from 'src/app/constants/config-constant';

@Component({
  selector: 'app-business-insured-form-workflow3',
  templateUrl: './business-insured-form-workflow3.component.html',
  styleUrls: ['./business-insured-form-workflow3.component.less'],
})
export class BusinessInsuredFormWorkflow3Component
  implements OnInit, AfterContentChecked, AfterViewInit, OnDestroy
{
  @Input() form: FormGroup;
  @Input() contactForm: FormGroup;
  @Input() form$: Observable<Quote>;
  @Input() checkValidation: boolean = false;
  @Input() checkContactValidation: boolean = false;
  @Input() fromSubmissionFlow: boolean = false;
  @Input() insuredId;
  @Input() isAtLeastOneFieldFilled: boolean = false;
  @Input() productId;
  @Input() productName;
  @Input() disableProduct: boolean = false;

  @Output() enableProducerInfo = new EventEmitter<any>();
  @Output() selectInsured = new EventEmitter<any>();
  @Output() clearInsuredForm = new EventEmitter<any>();
  @Output() setRegionID = new EventEmitter<any>();
  @Output() addContactEvent = new EventEmitter<void>();
  @Input() isExistingInsuredFormEdit: boolean = false;
  @Output() populateLatestInsured = new EventEmitter<void>();
  @Output() setNAICSData = new EventEmitter<any>();

  searchKey;
  companySearchData: Observable<any>;
  companyColumnsToSort: Array<string> = ['Name', 'Website'];
  noOfEmpOptns: any;
  numberOfEmp: any;
  businessSectorOptns: any = [];
  businessIndustrySectorOptns = [];
  businessIndustryClass = null;
  businessIndustry;
  requiredErrMsg: string = 'error.thisFieldIsRequired';
  invalidErrMsg: string = 'error.thisFieldIsInvalid';
  customErrMsg: string = 'error.customSumPercentageErrMsg';
  minLengthErrMsg: string = '';
  submitted: boolean = false;
  searchSubmitted: boolean = false;
  totalDataCount = 0;
  addCustomerExpanded: boolean = false;
  householdMemberOptns: any;
  householdMember: any;
  individualSearchData: Observable<any>;
  customerColumnsToSort: Array<string> = ['Name', 'Email'];
  pagination: Observable<Pagination>;
  isInsuredTypeCompany: boolean;
  companyLocationRegionIdSelected: number = 0;
  individuaLocationlRegionIdSelected: number = 0;
  isFormLoaded = false;
  showBanner = false;
  bannerMsg = '';
  errorAPIMsg = 'error.errorAPIMsg';
  colCompanyHeader = {
    Name: { key: 'companyName', order: '' },
    Website: { key: 'companyWebsite', order: '' },
    Address: { key: 'address', order: '' },
  };
  sortingCompanyKeys = '';
  colIndividualHeader = {
    Name: { key: 'firstName', order: '' },
    Email: { key: 'email', order: '' },
    Address: { key: 'address', order: '' },
  };
  sortingIndividualKeys = '';
  boxxUserId;
  activeCompanySearchPgNo = 1;
  activeIndividualSearchPgNo = 1;
  hasMinimumCharacter: boolean = false;
  showTblSpinner: boolean = false;
  showBodySpinner: number = 0;
  showBodySpinnerLocation: boolean = false;
  showSpinner: boolean = false;
  isLoaded: boolean = false;
  percentageRegex = regexHelpers.percentage;
  industryClass = {};
  selectedIndustryId = 0;
  linkIconSrc: string = '';
  sanctionPayload = [];
  isRestrictedIndustry = false;
  private ngUnsubscribe$ = new Subject<void>();
  isEditMode = false;
  insuredName = '';
  quoteExist: number = 0; // 0- No, 1-exist with selected producer, 2-exist with different producer
  actionPopupDetails: {
    quoteNumber: string;
    effectiveDate: string;
    insured?: string;
    quotePeriod?: {
      effectiveDt: string;
      expiryDt: string;
    };
    producerId?: number;
    producerName?: string;
    branchId?: number;
    branchName?: string;
    brokerageId?: number;
    brokerageName?: string;
  };
  formDataAddCompanyForm: any;
  policyPeriodId;
  showRecalcConfModal: boolean = false;
  latestFormData;
  showSanctionModal = false;
  formDataBack: any;
  showContactDetails: boolean = false;
  contactToggleEnabled: boolean = false;
  isOfacError = false;
  shortDateFormat: string = '';
  longDateFormat: string = '';

  showMarketReservationModal = false;
  showStateChangeModal = false;
  showRiskAnalysisUpdateModal = false;

  columnsToHide = ['currentState'];
  sortableColumns = [];
  iconColumns: string[] = ['Reasons'];
  customColumnWidth: {
    columnName: string;
    minWidth: string;
    maxWidth: string;
  }[] = [
    {
      columnName: 'Type',
      minWidth: '112',
      maxWidth: '120',
    },
    {
      columnName: 'Description',
      minWidth: '250',
      maxWidth: '250',
    },
  ];
  revenueMarginValue = REVENUE_MARGIN_VALUE;

  //************************************************************* */

  isActive: boolean = true;
  allowLocationLoader: boolean = true;
  insuredOptns: any = [];
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  country: string = '';
  shortDateTimeFormat: string = '';
  longDateTimeFormat: string = '';
  confirmIndustryShow: boolean = true;

  showPredictions = true;
  showIndustrySection = false;

  industryPredictionForm: FormGroup;
  isNavigatedFromRiskAnalysis = false;

  insuredLocationId = null;
  insuredData = null;
  insuredDataIndividual = null;
  initialFormData = null;
  initialFormDataIndividual = null;
  billingCompanyContactId = null;
  toggleLabel: string = 'insured.heading.showContactDetails';
  industryPredictions = [];
  enableConfirmButton = false;
  predictionsReturned = [];
  predictionEvidence = '';
  predictionKeywords = '';
  showIndustryDropdowns = true;
  regionCode = '';
  alertData = {
    show: false,
    type: '',
    header: '',
    body: '',
  };
  showRevenueField = true;

  //************************************************************* */

  //*****location*********************************************** */
  formSubmitted: boolean = false;

  listRegions: any[] = [];
  regionTotalRecords = 0;
  regionTotalPages = 0;
  countrySelected = '';
  address: string;

  provinceOrStateList = [];
  userID: number;
  province: string;
  zip: string;
  mask: string = 'S0S 0S0';
  locations: any;
  regionId: number;
  currentProvinceObj: {
    id: number;
    value: string;
    key: string;
    subKey?: string;
    img?: string;
    code?: string;
  };
  existingQuoteIsSubmission: boolean = false;
  //**************************************************** */

  insuredTypeForm: FormGroup<any>;
  typeBusinessInsured: boolean = true;
  mockExternalAPIs = true;
  // enableProducerInfo: boolean = false;
  isReferral: boolean = false;
  quoteFlowUrl = '';
  showExistingQuoteModal = false;
  activeQuoteId = null;
  selectedFromSuggestion = false;
  isSuggestedOption = false;
  businessAutoFocus = false;
  contactToggleFocus = false;
  revenueAutoFocus = true;
  products = [];
  selectedProductOption;
  selectedRegionOption;
  isRestrictedRoleSubmission: boolean = false;
  showRestrictedIndustryWarning: boolean = false;
  debounceTimer: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private store: Store,
    private viewportScroller: ViewportScroller,
    private actions$: Actions,
    private insuredService: InsuredService,
    private locationService: LocationService,
    private changeDetector: ChangeDetectorRef,
    private localStorageService: LocalStorageService,
    private newQuoteService: NewQuoteService,
    private billingCompanyContactService: BillingCompanyContactService,
    private alertService: AlertService,
    private naicsPredictionService: NAICSPredictionService,
    private industryClassService: IndustryClassService,
    private industryService: IndustryService,
    private domainsService: DomainsService,
    private mapService: MapService,
    private languageService: LanguageService,
    private uiContentService: UiContentService,
    private translateService: TranslateService,
    private riskRegionService: RiskRegionService,
    private policyMarketReservationService: PolicyMarketReservationService,
    private policyPeriodService: PolicyPeriodService,
    private policyDashboardService: PolicyDashboardService,
    private industryRestrictedService: IndustryRestrictedService,
  ) {
    this.industryPredictionForm = this.fb.group({
      industry: [''],
    });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  ngAfterViewInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
    this.businessAutoFocus = true;
  }

  async ngOnInit(): Promise<void> {
    this.showBodySpinner++;
    if (
      this.localStorageService.getMockExternalAPIs() === 'false' ||
      environment.mockExternalAPIs === false
    ) {
      this.mockExternalAPIs = false;
    }
    this.businessAutoFocus = true;
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.isActive = this.permissionList[this.currentScreen] ?? false;
      this.country = data.country;
      this.shortDateTimeFormat = data.shortDateTimeFormat;
      this.longDateTimeFormat = data.longDateTimeFormat;
      this.isRestrictedRoleSubmission = data.isRestrictedRoleSubmission;
    });
    this.form$ = this.store.select(getQuoteSelector);
    this.form$.subscribe((event) => {
      ({ isNavigatedFromRiskAnalysis: this.isNavigatedFromRiskAnalysis } =
        event.ui);
      this.isNavigatedFromRiskAnalysis = event.ui.isNavigatedFromRiskAnalysis;
    });
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    this.boxxUserId = this.localStorageService.getBoxxUserId();

    this.linkIconSrc = 'assets/img/plus-circle.png';
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.boxxUserId = this.localStorageService.getBoxxUserId();
    this.actions$
      .pipe(
        ofType(
          CreateQuoteAction.SET_ADD_COMPANY_EXPANDED,
          CreateQuoteAction.SET_ADD_CUSTOMER_EXPANDED,
        ),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => {
        setTimeout(() => {
          this.viewportScroller.scrollToAnchor('btn-add');
        }, 0);
      });

    this.store
      .select(getCountrySelector)
      .pipe(take(1))
      .subscribe((country) => {
        this.country = country?.toUpperCase();
        if (this.country === UNITED_STATES) {
          this.mask = '00000||00000-0000';
        }
        this.updatePostalCodeValidation(this.country);
      });
    this.userID = this.localStorageService.getBoxxUserId();
    this.getRegionList();
    this.domainsService.GetByDomainCode('INSTANCECONFIG').subscribe((data) => {
      if (data.data.length > 0) {
        const tableData = data.data.map((dataObj) => ({
          key: dataObj.description,
          value: dataObj.description,
        }));
      }
    });

    this.languageService.getLanguageId().subscribe((data) => {
      const languageId = data.data[0]?.id;
      this.uiContentService
        .GetByLangAdnKey(languageId, 'LOCATIONPROVSTATE')
        .subscribe((data) => {
          this.province = data.data[0].value;
          this.uiContentService
            .GetByLangAdnKey(languageId, 'LOCATIONZIPPOSTAL')
            .subscribe((data) => {
              this.zip = data.data[0].value;
            });
        });
    });

    this.form$ = this.store.pipe(select(getQuoteSelector));

    this.form$.subscribe(
      (event) => (this.isInsuredTypeCompany = event.ui.isInsuredTypeCompany),
    );
    if (this.isInsuredTypeCompany) {
      this.companySearchData = this.store.pipe(
        select(getInsuredCompanySelector),
      );
    } else {
      this.individualSearchData = this.store.pipe(
        select(getInsuredIndividualSelector),
      );
    }
    this.pagination = this.store.pipe(select(getPaginationSelector));
    this.pagination.subscribe((pagination) => {
      if (this.isInsuredTypeCompany) {
        this.activeCompanySearchPgNo = pagination.currentPage;
      } else {
        this.activeIndividualSearchPgNo = pagination.currentPage;
      }
    });

    let isInsuredTypeCompany;

    this.form$.subscribe(
      (event) => (isInsuredTypeCompany = event.ui.isInsuredTypeCompany),
    );

    await this.domainsService
      .GetByDomainCode(DOMAIN_CODE_RANGENUMEMPLOYEES)
      .subscribe(async (data) => {
        const tableData = data.data.map((dataObj) => ({
          key: dataObj.description,
          value: dataObj.description,
          id: dataObj.id,
        }));
        this.noOfEmpOptns = tableData;
        this.numberOfEmp = arrayToObjet(data.data, 'description');
        if (this.insuredId) {
          await this.populateInsuredDetails(this.insuredId);
          this.newQuoteService.clearInsuredId();
          if (this.insuredData.active == false) {
            this.form.get('address').disable();
          } else {
            this.form.get('address').enable();
          }
        } else {
          this.showBodySpinner--;
        }
      });
    if (this.country.toUpperCase() == UNITED_STATES) {
      this.form.get('revenuePercentUS').setValue('100');
      this.form.get('revenuePercentCA').setValue('0');
      this.form.get('revenuePercentOther').setValue('0');
    }
  }

  ngOnDestroy(): void {
    this.alertService.clearAlerts(-1);
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  handleRevenueBlur() {
    const initialRevenue = this.initialFormData?.revenue;
    if (initialRevenue) {
      const formValue = this.form.value;
      const updatedRevenue = formValue?.revenue;
      if (
        (initialRevenue < REVENUE_MARGIN_VALUE &&
          updatedRevenue >= REVENUE_MARGIN_VALUE) ||
        (initialRevenue >= REVENUE_MARGIN_VALUE &&
          updatedRevenue < REVENUE_MARGIN_VALUE)
      ) {
        this.store.dispatch(new CreateQuoteAction.clearRiskQuestions());
      }
    }
  }

  async checkIfRestrictedIndustry(industryId) {
    if (!!!industryId) {
      return;
    }
    const restrictedIndustryAPI =
      this.industryRestrictedService.GetByIndustryId(industryId);
    const restrictedIndustryResponse = await firstValueFrom(
      restrictedIndustryAPI,
    );
    if (restrictedIndustryResponse?.data?.length > 0) {
      this.showRestrictedIndustryWarning = true;
    } else {
      this.showRestrictedIndustryWarning = false;
    }
  }

  async handleBusinessIndustrySector(industryId) {
    this.selectedIndustryId = this.businessIndustry[industryId]?.id;
    this.form.get('industry').setValue(this.businessIndustry[industryId]?.name);
    this.selectedIndustryId = 0;
    this.form.get('industryId').setValue(this.businessIndustry[industryId]?.id);
    this.businessIndustrySectorOptns = [];
    await this.checkIfRestrictedIndustry(this.businessIndustry[industryId]?.id);
  }

  handleErrorOrSuccess(alertData) {
    if (alertData.type === 'error' || alertData.type === 'warn') {
      this.bannerMsg = alertData?.body;
      this.showBanner = true;
      this.showBodySpinner--;
      if (this.showBodySpinner < 0) this.showBodySpinner = 0;
      this.showSpinner = false;
      let errAlert: alertDetails = {
        type: alertData.type,
        headerText: alertData?.header,
        bodyText: alertData?.body,
      };
      this.alertService.addAlert(errAlert);
    }
  }

  getIndustryData(industryClass) {
    const prediction = this.predictionsReturned.filter(
      (industryObj) => industryObj.industryClass.name === industryClass,
    );
    if (prediction.length > 0) {
      return prediction[0];
    }
    return null;
  }

  getBusinessNames(query: string, insuredType: number) {
    this.insuredName = this.form?.value?.businessName;
    if (query) query = query?.trim();
    if (query.length >= 3) {
      const page = 1;
      const limit = 10;
      const sort = '';

      // to reset the existing data
      this.insuredDataIndividual = null;
      this.insuredData = null;
      this.initialFormDataIndividual = null;
      this.initialFormData = null;

      this.insuredService
        .GetByInsuredNameAndType(query, insuredType, page, limit, sort, 1)
        .subscribe((response: any) => {
          this.insuredOptns = response?.data?.map((dataObj) => ({
            main_text:
              insuredType === 2 ? dataObj?.companyName : dataObj?.firstName,
            id: dataObj?.id,
          }));
          // this.confirmIndustryShow = false;
        });
    } else {
      this.insuredOptns = [];
      this.insuredDataIndividual = null;
      this.insuredData = null;
      this.initialFormDataIndividual = null;
      this.initialFormData = null;
    }
  }

  handleBusinessNameSearch(query: string, insuredType: number = 2) {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      this.getBusinessNames(query, insuredType);
    }, 300);
  }
  handleClearForm() {
    this.showIndustrySection = false;

    this.clearInsuredForm.emit();
  }
  selectInsuredSearch(insuredId) {
    if (this.fromSubmissionFlow === true) this.selectInsured.emit(insuredId);
  }
  async populateInsuredDetails(insuredId) {
    this.showPredictions = false;
    this.showIndustrySection = true;
    this.confirmIndustryShow = true;
    this.showBodySpinner++;
    this.enableProducerInfo.emit(true);
    this.formSubmitted = false;
    this.isSuggestedOption = true;
    this.selectedFromSuggestion = true;
    this.businessAutoFocus = true;
    this.showBodySpinner--;

    const naicsData = {
      industryIdPredictionA: 0,
      accuracyPredictionA: 0,
      industryIdPredictionB: 0,
      accuracyPredictionB: 0,
    };
    this.setNAICSData.emit(naicsData);
    try {
      const getInsuredData = this.insuredService.GetByInsuredId(insuredId);
      let insuredData = await firstValueFrom(getInsuredData);
      this.populateLatestInsured.emit(insuredData?.data);
      const getBillingContactData =
        this.billingCompanyContactService.GetByInsuredId(insuredId);
      let insuredBillingContactData = await firstValueFrom(
        getBillingContactData,
      );

      if (insuredData?.data) {
        const insured = insuredData?.data;
        this.regionCode = insured?.insuredLocation?.region?.regionCode;
        this.regionId = insured?.insuredLocation?.regionId;
        this.store.dispatch(
          new CreateQuoteAction.updateUiContents({
            regionId: this.regionId ?? 0,
          }),
        );
        if (this.isRestrictedRoleSubmission === true)
          await Promise.all([this.checkForActiveQuote(insuredId)]);

        this.form.setValue({
          businessName: insured?.companyName ?? '',
          website: insured?.companyWebsite ?? '',
          revenue: insured?.revenue ? Number(insured?.revenue) : '',
          noOfEmployees: this.getNumberOfEmployees(
            insured?.numOfEmployees ?? '',
          ),
          industry: insured?.industry?.name ?? '',
          industryClass: insured?.industryClass?.name ?? '',
          revenuePercentUS: insured?.revenueUSPerc ?? '',
          revenuePercentCA: insured?.revenueCAPerc ?? '',
          revenuePercentOther: insured?.revenueOtherPerc ?? '',
          country: insured?.insuredLocation?.country ?? '',
          province: insured?.insuredLocation?.region?.name ?? '',
          city: insured?.insuredLocation?.city ?? '',
          address: insured?.insuredLocation?.address ?? '',
          zipPostalCode: insured?.insuredLocation?.zipPostalCode ?? '',
          addressLine2: insured?.insuredLocation?.addressLine2 ?? '',
          insuredId: insured?.id,
          insuredLocationId: insured?.insuredLocation?.id,
          industryClassId: insured?.industryClass?.id,
          industryId: insured?.industry?.id,
          numOfEmployees: insured?.numOfEmployees ?? '',
          brokerageinfo: insured?.brokerageinfo ?? null,
          productId: this.productId ?? '',
          productName: this.productName ?? '',
        });

        this.insuredName = insured?.companyName ?? '';
        this.store.dispatch(
          new InsuredAction.setInsuredNameAction(this.insuredName ?? ''),
        );
        this.store.dispatch(
          new CreateQuoteAction.updateUiContents({
            insuredName: this.insuredName ?? '',
          }),
        );
        this.actionPopupDetails = {
          ...this.actionPopupDetails,
          insured: this.insuredName,
        };
        if (
          insuredBillingContactData?.data[0]?.firstName ||
          insuredBillingContactData?.data[0]?.lastName ||
          insuredBillingContactData?.data[0]?.email ||
          insuredBillingContactData?.data[0]?.title ||
          insuredBillingContactData?.data[0]?.phone
        ) {
          this.showContactDetails = true;
          this.contactToggleEnabled = true;
        }
        this.initialFormData = this.form.value;
        this.insuredData = insured;
        this.insuredId = insured?.id;

        this.showBodySpinner--;
        if (this.showBodySpinner < 0) this.showBodySpinner = 0;
      } else {
        this.showBodySpinner--;
        if (this.showBodySpinner < 0) this.showBodySpinner = 0;
      }
    } catch (error) {
      if (![500].includes(error?.status)) {
        this.handleErrorOrSuccess({
          show: true,
          type: 'error',
          header: 'common.errorMsg',
          body: getErrorMessage(error),
        });
      }
      this.showBodySpinner--;
      if (this.showBodySpinner < 0) this.showBodySpinner = 0;
    }
  }

  getNumberOfEmployees(id) {
    let numberOfEmployees = '';
    if (id) {
      const selectedNumberOfEmployeesIdx = this.noOfEmpOptns?.findIndex(
        (numOfEmployee) => numOfEmployee?.id === id,
      );
      if (selectedNumberOfEmployeesIdx !== -1) {
        numberOfEmployees =
          this.noOfEmpOptns[selectedNumberOfEmployeesIdx]?.value ?? '';
      }
    }
    return numberOfEmployees;
  }

  handleNoOfEmployees(event) {
    this.form.get('numOfEmployees').setValue(event);
  }
  onHandlercompanyRegionIdSelected(regionId: number) {}
  handleRegionCode(regionCode) {
    this.regionCode = regionCode;
  }

  transformPercentage(value) {
    if (!value || value === null) {
      return 0;
    }
    return value?.toFixed(2) % 1 === 0 ? value?.toFixed(0) : value?.toFixed(2);
  }
  async handleGetIndustryPredictions() {
    this.businessAutoFocus = false;
    // this.handleAlertClose();
    if (
      !this.form.value['businessName'] ||
      !this.form.value['address'] ||
      !this.form.value['province'] ||
      !this.form.value['country']
    ) {
      this.checkValidation = true;
      this.alertData = {
        show: true,
        type: 'error',
        header: 'common.errorMsg',
        body: 'error.confirmIndustryValidation',
      };
      return;
    }
    this.insuredName = this.form?.value?.businessName;
    this.showBodySpinner++;
    this.insuredOptns = [];
    let country = 'can';
    this.store
      .select(getCountrySelector)
      .pipe(take(1))
      .subscribe((countryName) => {
        if (countryName?.toUpperCase() === UNITED_STATES) {
          country = 'usa';
        }
      });
    if (!this.regionCode && this.form.value['province']) {
      const region = await firstValueFrom(
        this.locationService.getRegionByName(this.form.value['province']),
      );
      this.regionCode = region?.data[0]?.regionCode;
    }
    this.naicsPredictionService
      .getNaicsPredictions({
        name: this.form.value?.businessName,
        address: this.form.value.address,
        state: this.regionCode,
        country: country,
        isMock: this.mockExternalAPIs,
      })
      .subscribe({
        next: async (response) => {
          if (response.data?.['usa_naics_2017_prediction_hp']) {
            this.predictionsReturned =
              response.data[NAICS_PREDICTION_OBJECT_NAME];
            if (
              response.data[NAICS_EXISTENCE_CHECK_NAME] &&
              response.data[NAICS_EXISTENCE_CHECK_NAME]['exists']
            ) {
              if (
                response.data[NAICS_EXISTENCE_CHECK_NAME]['evidence_exist']
                  ?.length > 0
              ) {
                const evidence =
                  response.data[NAICS_EXISTENCE_CHECK_NAME]['evidence_exist'];
                this.predictionEvidence = evidence?.join(', ');
              }
            }

            if (
              response.data[NAICS_KEYWORDS_NAME] &&
              response.data[NAICS_KEYWORDS_NAME]?.length > 0
            ) {
              const keywords = response.data[NAICS_KEYWORDS_NAME];
              this.predictionKeywords = keywords?.join(', ');
            }
            const predictions = [];
            let index = 0;
            let predictedIndustryIds = [];
            for (let prediction in this.predictionsReturned) {
              const popOverContent =
                response.data?.['explain_confidence_score']?.length > 0
                  ? response.data?.['explain_confidence_score']
                  : [];
              const noDataContent = this.translateService.instant(
                'workFlow3.components.shared.info.noData',
              );
              const popoverTitle =
                popOverContent[index]?.split(':')[0] ?? noDataContent;
              const popOverBody = popOverContent[index]?.split('- ')[1] ?? '-';
              const predictionData = this.predictionsReturned[prediction];
              predictions.push({
                label: `Class: ${predictionData['industryClass'].name}`,
                subLabel: `Industry: ${predictionData['industry'].name}`,
                currentValue: this.industryPredictionForm.value['industry'],
                value: index,
                showPopoverButton: true,
                popoverButtonType: this.getPopOverType(
                  this.transformPercentage(
                    Number(predictionData['accuracy'] ?? 0) * 100,
                  ),
                ),
                popoverTitle,
                popoverContent: popOverBody,
                popoverPlacement: index === 0 ? 'top' : 'bottom',
                popoverButtonLabel:
                  this.transformPercentage(
                    Number(predictionData['accuracy'] ?? 0) * 100,
                  ) + '%',
              });

              predictedIndustryIds.push({
                id: predictionData['industry']?.id,
                score: Number(predictionData['accuracy'] ?? 0).toFixed(2),
              });

              if (index === 0) {
                this.industryPredictionForm.get('industry').setValue(0);
                this.form
                  .get('industryClass')
                  .setValue(predictionData['industryClass'].name);
                this.form
                  .get('industry')
                  .setValue(predictionData['industry'].name);
                this.selectedIndustryId = predictionData['industry'].id;
                this.form
                  .get('industryClassId')
                  .setValue(predictionData['industryClass'].id);
                this.form
                  .get('industryId')
                  .setValue(predictionData['industry'].id);
                await this.checkIfRestrictedIndustry(
                  predictionData['industry'].id,
                );
              }
              index++;
            }

            predictions.push({
              label:
                'None of the above. I already know the industry class code and the industry.',
              subLabel: ' ',
              currentValue: this.industryPredictionForm.value['industry'],
              value: predictions?.length,
              showPopoverButton: false,
            });

            let naicsData = {
              industryIdPredictionA: 0,
              accuracyPredictionA: 0,
              industryIdPredictionB: 0,
              accuracyPredictionB: 0,
            };
            if (predictedIndustryIds?.length >= 2) {
              naicsData = {
                industryIdPredictionA: predictedIndustryIds[0]?.id,
                accuracyPredictionA: Number(
                  predictedIndustryIds[0]?.score ?? 0,
                ),
                industryIdPredictionB: predictedIndustryIds[1]?.id,
                accuracyPredictionB: Number(
                  predictedIndustryIds[1]?.score ?? 0,
                ),
              };
            } else if (predictedIndustryIds?.length === 1) {
              naicsData = {
                industryIdPredictionA: predictedIndustryIds[0]?.id,
                accuracyPredictionA: predictedIndustryIds[0]?.score,
                industryIdPredictionB: null,
                accuracyPredictionB: 0,
              };
            }

            this.setNAICSData.emit(naicsData);

            this.industryPredictions = predictions;
            this.showPredictions = true;
            this.showIndustryDropdowns = false;
            this.confirmIndustryShow = true;
            this.showBodySpinner--;
            if (this.showBodySpinner < 0) this.showBodySpinner = 0;
          } else {
            this.handleErrorOrSuccess({
              show: true,
              type: 'warn',
              header: 'common.warnMsg',
              body: 'error.noPredictionReturned',
            });
            this.showPredictions = false;
            this.showIndustryDropdowns = true;
            this.confirmIndustryShow = true;
            this.showBodySpinner--;
            if (this.showBodySpinner < 0) this.showBodySpinner = 0;
          }
          this.showIndustrySection = true;
        },
        error: (error) => {
          this.handleErrorOrSuccess({
            show: true,
            type: 'warn',
            header: 'common.warnMsg',
            body: 'error.noPredictionReturned',
          });
          this.showPredictions = false;
          this.showIndustrySection = true;
          this.showBodySpinner--;
          if (this.showBodySpinner < 0) this.showBodySpinner = 0;
          this.showIndustryDropdowns = true;
          this.confirmIndustryShow = true;
          this.predictionEvidence = '';
          this.predictionKeywords = '';
        },
      });
  }

  getPopOverType(score) {
    if (score >= 85) {
      return 'primary';
    } else if (score >= 40 && score < 85) {
      return 'secondary';
    } else if (score < 40) {
      return 'tertiary';
    }
    return 'primary';
  }

  enableConfirmIndustry() {
    return this.form.value['businessName'] &&
      this.form.value['address'] &&
      this.form.value['province'] &&
      this.form.value['country']
      ? true
      : false;
  }
  async handleRadioCheck(param) {
    const valueSelected = this.industryPredictionForm.value['industry'];
    if (valueSelected === this.industryPredictions.length - 1) {
      this.form.get('industryClass').setValue('');
      this.form.get('industryClassId').setValue('');
      this.form.get('industry').setValue('');
      this.form.get('industryId').setValue('');
      this.selectedIndustryId = null;
      this.showIndustryDropdowns = true;
    } else {
      const predictionKey = Object.keys(this.predictionsReturned)[
        valueSelected
      ];
      this.form
        .get('industryClass')
        .setValue(this.predictionsReturned[predictionKey]?.industryClass.name);
      this.form
        .get('industry')
        .setValue(this.predictionsReturned[predictionKey]?.industry.name);
      this.selectedIndustryId =
        this.predictionsReturned[predictionKey]?.industry.id;
      this.form
        .get('industryClassId')
        .setValue(this.predictionsReturned[predictionKey]?.industryClass.id);
      this.form
        .get('industryId')
        .setValue(this.predictionsReturned[predictionKey]?.industry.id);
      await this.checkIfRestrictedIndustry(
        this.predictionsReturned[predictionKey]?.industry.id,
      );
      this.showIndustryDropdowns = false;
    }
  }
  handleIndustryClassSearch(searchKey) {
    this.form.get('industry').setValue('');
    this.form.get('industryId').setValue('');
    if (searchKey?.length >= 2) {
      this.industryClassService
        .GetIndustryClassByName(searchKey)
        .subscribe((data) => {
          const tableData = data.data?.map((dataObj) => ({
            key: dataObj.name,
            value: dataObj.name,
            id: dataObj.id,
          }));

          data.data = data.data.reverse();
          this.businessIndustryClass = data.data?.reduce(
            (accumulator, value) => {
              return { ...accumulator, [value.id]: value };
            },
            {},
          );
          this.businessSectorOptns = removeDuplicatedKeys(tableData);
          if (this.businessSectorOptns?.length === 0) {
            this.form.get('industryClass').setValue('');
            this.form.get('industryClassId').setValue('');
          }
        });
    } else {
      this.businessSectorOptns = [];
      this.form.get('industryClass').setValue('');
      this.form.get('industryClassId').setValue('');
    }
  }

  handleBusinessSector(value) {
    this.form.get('industryClass').setValue('');
    this.form.get('industry').setValue('');
    this.selectedIndustryId = 0;
    this.form.get('industryClassId').setValue('');
    this.form.get('industryId').setValue('');
    this.industryClass = this.businessIndustryClass[value];
    this.businessSectorOptns = [];

    this.form
      .get('industryClass')
      .setValue(this.businessIndustryClass[value]?.name);
    this.form
      .get('industryClassId')
      .setValue(this.businessIndustryClass[value]?.id);
  }
  handleIndustrySearch(searchKey) {
    if (searchKey?.length >= 2) {
      const industryClass = this.businessIndustryClass
        ? this.businessIndustryClass[this.form.value.industryClassId]
        : this.insuredData?.industryClass;
      this.industryService
        .GetByIndustryClassIdAndName(industryClass?.id, searchKey, 1, 10)
        .subscribe((data) => {
          const tableData = data.data?.map((dataObj) => ({
            key: dataObj.name,
            value: dataObj.name,
            id: dataObj.id,
          }));
          this.businessIndustrySectorOptns = removeDuplicatedKeys(tableData);

          this.businessIndustry = data.data?.reduce((accumulator, value) => {
            return { ...accumulator, [value.id]: value };
          }, {});
          if (this.businessIndustrySectorOptns?.length === 0) {
            this.form.get('industry').setValue('');
            this.form.get('industryId').setValue('');
          }
        });
    } else {
      this.businessIndustrySectorOptns = [];
      this.form.get('industry').setValue('');
      this.form.get('industryId').setValue('');
    }
  }
  toggleChanged(event: boolean) {
    this.showContactDetails = !this.showContactDetails;
    this.contactToggleEnabled = !this.contactToggleEnabled;
    if (this.contactToggleEnabled === true) {
      this.toggleLabel = 'insured.heading.hideContactDetails';
      this.contactToggleFocus = true;
      this.businessAutoFocus = false;
    } else {
      this.toggleLabel = 'insured.heading.showContactDetails';
      this.contactToggleFocus = false;
      this.businessAutoFocus = false;
    }
  }

  //**************************************************************** */

  //******location***************************************** */
  getPlaces(query: string) {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      this.getPlacesApiHandler(query);
    }, 300);
  }

  getPlacesApiHandler(query: string) {
    this.mapService.getPlaces(query).subscribe((response: any) => {
      if (response?.data && response?.data instanceof Array) {
        this.locations = response?.data?.map((obj) => {
          const { place_id, ...rest } = obj;
          return { ...rest, id: place_id };
        });
      }
    });
  }

  selectPlaceById(placeId: string) {
    this.selectedFromSuggestion = true;
    this.mapService.getPlaceById(placeId).subscribe((response: any) => {
      if (response.data.target === 'address') {
        const addressArray = response.data.address.split(',');
        const extractedAddress = addressArray[0]?.trim();
        this.form.get('address').setValue(extractedAddress);
        this.form.get('city').setValue(response.data.city);
        this.form.get('zipPostalCode').setValue(response.data.postalCode);
        this.form.get('country').setValue(response.data.country);
        this.form.get('province').setValue(response.data.province);
        this.currentProvinceObj = {
          id: response.data.regionId,
          value: response.data.province,
          key: response.data.province,
        };

        this.regionId = response.data.regionId;
        this.regionCode = response?.data?.regionCode;
        this.setRegionID.emit(this.regionId);
        this.store.dispatch(
          new CreateQuoteAction.updateUiContents({
            regionId: this.regionId ?? 0,
          }),
        );
      }
    });
  }
  handleProvinceChange(provinceId) {
    this.currentProvinceObj =
      this.listRegions.reduce((resultArray, item) => {
        if (item.id === provinceId) {
          resultArray.push({
            key: item.name,
            value: item.name,
            id: item.id,
            code: item.code,
          });
        }
        return resultArray;
      }, [])[0] ?? {};
    this.form.get('province').setValue(this.currentProvinceObj?.value);
    this.regionId = provinceId;
    this.regionCode = this.currentProvinceObj?.code;
    this.setRegionID.emit(this.regionId);
    this.store.dispatch(
      new CreateQuoteAction.updateUiContents({
        regionId: this.regionId ?? 0,
      }),
    );
    // this.eventEmitRegionIdSelected.emit(idSelectedRegion);
  }
  getRegionList(page = 1, isLoadMore = false) {
    this.locationService.getAllRegions(page).subscribe((region) => {
      const regions = region.data.map((item: any) => {
        const data = { id: item.id, name: item.name, code: item.regionCode };
        return data;
      });
      const stateList = regions.map((item: any) => {
        return { key: item.name, value: item.name, id: item.id };
      });
      if (isLoadMore) {
        this.listRegions = [...this.listRegions, ...regions];
        this.provinceOrStateList = [...this.provinceOrStateList, ...stateList];
      } else {
        this.listRegions = regions;
        this.provinceOrStateList = stateList;
      }
      this.regionTotalRecords = region.pagination.totalRecords;
      this.regionTotalPages = region.pagination.totalPages;
    });
  }
  loadMoreRegion(page) {
    if (this.regionTotalRecords > this.listRegions.length) {
      this.getRegionList(page, true);
    }
  }

  private updatePostalCodeValidation(country: string) {
    const zipPostalCodeControl = this.form.get('zipPostalCode');

    // Add new validator based on the selected country
    if (
      country?.toUpperCase() === UNITED_STATES ||
      country?.toUpperCase() === CANADA
    ) {
      // Clear previous validators for the postal code control
      zipPostalCodeControl.clearValidators();
      zipPostalCodeControl.updateValueAndValidity();

      zipPostalCodeControl.setValidators([
        Validators.required,
        postalCodeValidator(country), // Custom validator for US postal code
      ]);
    }

    // Update the postal code control's validation status
    zipPostalCodeControl.updateValueAndValidity();
  }
  //*********************************************** */

  handleInsuredTypeChange() {
    this.typeBusinessInsured = !this.typeBusinessInsured;
    this.store.dispatch(new CreateQuoteAction.ResetInsuredTypeAction());
    // this.form.get('addCompanyForm').reset();
    // this.form.get('addIndividualForm').reset();
  }

  setMaxLengthErrorMessage(formName: string, fieldName: string) {
    const charLimit =
      this.form.get([fieldName]).errors['maxlength']?.requiredLength ?? 0;
    const errMsg =
      charLimit !== 0
        ? this.translateService
            .instant('error.maximumAllowedCharErroMsg')
            ?.replace('{charLimit}', charLimit)
        : this.translateService.instant(
            'error.maximumAllowedCharDefaultErroMsg',
          );
    this.form.get([fieldName]).setErrors({
      message: errMsg,
    });
  }

  handleAlertClose() {
    this.alertData = {
      show: false,
      type: '',
      header: '',
      body: '',
    };
  }

  handleAlertInfo(event) {
    this.alertData = {
      type: event.type,
      header: event.header,
      body: event.body,
      show: event?.show,
    };
  }

  clickRevenueDetais() {
    if (this.fromSubmissionFlow === true) {
      this.enableProducerInfo.emit(true);
      this.revenueAutoFocus = false;
      this.enableProducerInfo.emit(true);
      setTimeout(() => {
        this.revenueAutoFocus = true;
      }, 0);
    }
  }

  handleAddContact(): void {
    this.checkContactValidation = false;
    this.addContactEvent.emit();
  }
  checkForActiveQuote(insuredId: any) {
    return new Promise<any>(async (resolve, reject) => {
      this.policyMarketReservationService.GetByInsuredId(insuredId).subscribe({
        next: async (resp) => {
          const quoteDetails = resp?.data;
          this.activeQuoteId = quoteDetails?.policyId;
          if (quoteDetails?.policyId) {
            this.existingQuoteIsSubmission =
              quoteDetails?.quoteStatus === QUOTESTATUS_SUBMISSION ||
              quoteDetails?.quoteStatus === QUOTESTATUS_UADECLINE
                ? true
                : false;
            this.policyPeriodService
              .GetAllByPolicyId(quoteDetails?.policyId)
              .subscribe(async (data) => {
                this.policyPeriodId = data.data[0]?.id;
                if (this.isRestrictedRoleSubmission === true) {
                  const policyDashboardServiceObs =
                    this.policyDashboardService.Get(this.policyPeriodId);
                  const policyDashboardData = await firstValueFrom(
                    policyDashboardServiceObs,
                  );
                  this.productId =
                    policyDashboardData?.data?.PolicyRisks[0]?.risk.id ?? '';
                  this.productName =
                    policyDashboardData?.data?.PolicyRisks[0]?.risk.name ?? '';
                }
                resolve(true);
              });
          } else {
            resolve(true);
          }
        },
        error: (error) => {
          reject(getErrorMessage(error));
        },
      });
    });
  }
}
