import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, firstValueFrom, last, take } from 'rxjs';
import { InsuredCompanyPostRequest } from 'src/app/entities/insured-company';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';
import { Quote } from 'src/app/models/quote.model';
import { BillingCompanyContactService } from 'src/app/services/billing-company-contact.service';
import { IndustryRestrictedService } from 'src/app/services/industry-restricted.service';
import { InsuredService } from 'src/app/services/insured.service';
import { LocationService } from 'src/app/services/location.service';
import { PolicyMarketReservationService } from 'src/app/services/policy-market.service';
import { RegionService } from 'src/app/services/region.service';
import { getInsuredAlertSelector } from 'src/app/store/insured/insured.selector';
import { getErrorMessage, regexHelpers } from 'src/app/utils/utils';
import * as InsuredAction from 'src/app/store/insured/insured.action';
import { getInsuredSelector } from 'src/app/store/insured/insured.selector';
import { InsuredIndividualPostRequest } from 'src/app/entities/insured-individual';
import { Insured } from 'src/app/models/insured.model';
import { PolicyPeriodService } from 'src/app/services/policy-period.service';
import { PolicyRiskService } from 'src/app/services/policy-risk.service';
import { RiskRatingCalculateService } from 'src/app/services/risk-rating-calculate.service';
import { OFACService } from 'src/app/services/ofac.service';
import { PolicyLifecycleService } from 'src/app/services/policy-lifecycle-service';
import { formatDateMoment } from 'src/app/utils/formatDate';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import {
  REVENUE_MARGIN_VALUE,
  RISK_QUESTION_OVER20M_CATEGORY,
  RISK_QUESTION_UNDER20M_CATEGORY,
} from 'src/app/constants/submission-constant';
import { RiskQuestionService } from 'src/app/services/risk-question.service';
import { PolicyAnswerService } from 'src/app/services/policy-answer.service';
import { RiskRegionService } from 'src/app/services/risk-region.service';
import { QUOTESTATUS_SUBMISSION } from 'src/app/constants/config-constant';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/services/localstorage-service';

@Component({
  selector: 'app-insured-form',
  templateUrl: './insured-form.component.html',
  styleUrls: ['./insured-form.component.less'],
})
export class InsuredFormComponent implements OnInit, OnDestroy {
  form: FormGroup;
  form$: Observable<Quote>;
  insuredForm$: Observable<Insured>;
  itemsMenu: Breadcrumb[] = [
    { label: 'Insured directory', path: '/dashboard/insured/directory' },
    { label: 'New insured', path: null },
  ];

  isInsuredTypeCompany: boolean = true;
  percentageRegex = regexHelpers.percentage;
  formSubmitted = false;
  insuredId = null;
  insuredName = '';
  isEditMode = false;
  alert = {
    show: false,
    type: '',
    header: '',
    body: '',
  };
  reload: boolean = false;
  skipNgOnDestroy: boolean = false;
  quoteExist: boolean = false;
  existingQuoteStatus: string;
  riskRegionId: number;
  actionPopupDetails: {
    quoteNumber: string;
    effectiveDate: string;
    insured?: string;
    broker?: string;
    popupTitle?: string;
    description?: string;
    tableTitle?: string;
  };

  showBodySpinner: boolean = false;
  formDataAddCompanyForm: any;
  policyPeriodId;
  showRecalcConfModal: boolean = false;

  isRestrictedIndustry = false;
  sanctionPayload = [];
  showSanctionModal = false;
  latestFormData;
  fromSubmissionFlow = false;
  shortDateFormat: string = '';
  longDateFormat: string = '';

  columnsToHide = ['currentState'];
  sortableColumns = [];
  iconColumns: string[] = ['Reasons'];
  customColumnWidth: {
    columnName: string;
    minWidth: string;
    maxWidth: string;
  }[] = [
    {
      columnName: 'Type',
      minWidth: '112',
      maxWidth: '120',
    },
    {
      columnName: 'Description',
      minWidth: '250',
      maxWidth: '250',
    },
  ];
  revenueMarginValue = REVENUE_MARGIN_VALUE;
  riskQuestions = [];
  showModalRiskQuestions = false;
  questionsCategory = '';
  policyRiskId: number;

  showSanctionsRiskQuestionModal = false;
  showModalStateChange = false;
  showModalRiskAnalysis = false;
  mockExternalAPIs = true;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private regionService: RegionService,
    private insuredService: InsuredService,
    private locationService: LocationService,
    private policyMarketReservationService: PolicyMarketReservationService,
    private industryRestrictedService: IndustryRestrictedService,
    private billingCompanyContactService: BillingCompanyContactService,
    private policyPeriodService: PolicyPeriodService,
    private policyRiskService: PolicyRiskService,
    private riskRatingCalculateService: RiskRatingCalculateService,
    private ofacService: OFACService,
    private policyLifecycleService: PolicyLifecycleService,
    private riskQuestionService: RiskQuestionService,
    private policyAnswerService: PolicyAnswerService,
    private riskRegionService: RiskRegionService,
    private localStorageService: LocalStorageService,
  ) {
    this.form = this.fb.group({
      addCompanyForm: this.fb.group({
        businessName: ['', Validators.required],
        website: ['', [Validators.pattern(regexHelpers.Website)]],
        revenue: [
          '',
          [
            Validators.required,
            Validators.pattern('^([1-9][0-9]{0,9}|10000000000)$'),
          ],
        ],
        noOfEmployees: [''],
        industry: ['', Validators.required],
        industryClass: ['', Validators.required],
        revenuePercentUS: [
          '',
          [
            Validators.required,
            Validators.pattern(this.percentageRegex),
            this.isRevenuePercentageValid(
              'revenuePercentCA',
              'revenuePercentOther',
            ),
          ],
        ],
        revenuePercentCA: [
          '',
          [
            Validators.required,
            Validators.pattern(this.percentageRegex),
            this.isRevenuePercentageValid(
              'revenuePercentUS',
              'revenuePercentOther',
            ),
          ],
        ],
        revenuePercentOther: [
          '',
          [
            Validators.required,
            Validators.pattern(this.percentageRegex),
            this.isRevenuePercentageValid(
              'revenuePercentUS',
              'revenuePercentCA',
            ),
          ],
        ],
        firstName: [''],
        lastName: [''],
        email: [
          '',
          [
            Validators.pattern(
              '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6}$',
            ),
          ],
        ],
        country: ['', Validators.required],
        province: ['', Validators.required],
        city: ['', Validators.required],
        address: ['', Validators.required],
        zipPostalCode: ['', Validators.required],
        addressLine2: [''],
      }),
      addIndividualForm: this.fb.group({
        firstName: ['', Validators.required],
        middleName: [''],
        lastName: ['', Validators.required],
        email: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6}$',
            ),
          ],
        ],
        householdMembers: ['', Validators.required],
        country: ['', Validators.required],
        province: ['', Validators.required],
        city: ['', Validators.required],
        address: ['', Validators.required],
        zipPostalCode: ['', Validators.required],
        addressLine2: [''],
      }),
    });
  }

  ngOnInit(): void {
    if (
      this.localStorageService.getMockExternalAPIs() === 'false' ||
      environment.mockExternalAPIs === false
    ) {
      this.mockExternalAPIs = false;
    }
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
    });
    this.activatedRoute.paramMap.subscribe(async (params) => {
      const insuredId = params.get('id');
      const insuredType = params.get('type');
      if (insuredType && Number(insuredType) === 0) {
        this.isInsuredTypeCompany = false;
      } else {
        this.isInsuredTypeCompany = true;
      }
      this.insuredForm$ = this.store.select(getInsuredSelector);
      this.insuredForm$.pipe(take(1)).subscribe(async (event) => {
        if (event.ui.isNavigatedFromInsuredIndividualForm) {
          this.isInsuredTypeCompany = false;
          this.onInsuredTypeToggle(false, this.isInsuredTypeCompany);
        }
      });
      if (insuredId) {
        this.store
          .select(getInsuredAlertSelector)
          .pipe(take(1))
          .subscribe(
            (value) =>
              (this.alert = {
                ...this.alert,
                ...value,
              }),
          );

        this.store.dispatch(
          new InsuredAction.setInsuredAlertAction({
            show: false,
            type: '',
            header: '',
            body: '',
          }),
        );
        this.insuredId = insuredId;
        this.isEditMode = true;
        this.policyMarketReservationService
          .GetByInsuredId(this.insuredId)
          .subscribe(async (data) => {
            const quoteDetails = data?.data;
            this.quoteExist = quoteDetails?.activeQuote > 0 ? true : false;
            if (this.quoteExist) {
              this.existingQuoteStatus = quoteDetails?.quoteStatus;
            }
            this.actionPopupDetails = {
              quoteNumber: null,
              effectiveDate: null,
              insured: null,
              broker: '',
              popupTitle: 'Submission clearance',
              description:
                'We cannot offer a quote at the moment for this Insured account due to the following reasons.',
              tableTitle: 'List of sanctions and restrictions found for:',
            };
            this.actionPopupDetails = {
              ...this.actionPopupDetails,
              quoteNumber: quoteDetails?.policyId,
              effectiveDate: formatDateMoment(
                quoteDetails?.effectiveDt,
                this.shortDateFormat,
              ),
              insured: this.insuredName,
            };
            const getPolicyByID = this.policyPeriodService.GetAllByPolicyId(
              quoteDetails?.policyId,
            );
            let dataPId = await firstValueFrom(getPolicyByID);

            this.policyPeriodId = dataPId?.data[0]?.id;
          });
      }
    });
  }

  ngOnDestroy(): void {
    if (!this.skipNgOnDestroy) {
      this.setIsNavigatedFromInsuredInStore(false);
    }
  }

  handleSort(data: any): void {}

  columnClickHandler(data: any): void {}

  setIsNavigatedFromInsuredInStore(value: boolean) {
    this.store.dispatch(
      new InsuredAction.setIsNavigatedFromInsuredIndividual({
        isNavigatedFromIndividual: value,
      }),
    );
  }

  breadcrumbClicked() {
    this.skipNgOnDestroy = true;
  }

  updateBreadCrumbs(insuredName) {
    this.insuredName = insuredName;
    this.itemsMenu = [
      { label: 'Insured directory', path: '/dashboard/insured/directory' },
      { label: insuredName ?? '', path: null },
    ];
  }

  handleErrorOrSuccess(alertData) {
    this.alert = alertData;
  }

  handleAlertClear() {
    this.alert = {
      show: false,
      type: '',
      header: '',
      body: '',
    };
  }

  hasEmptyValueOrInvalid(...values) {
    return values.some((value) => {
      return value === '' || !this.percentageRegex.test(value);
    });
  }

  isRevenuePercentageValid(revenue1, revenue2) {
    return (control: FormControl) => {
      if (!control || !control.parent) {
        return null;
      } else if (control.value == 100) {
        control.parent.controls[revenue1].setValue(0);
        control.parent.controls[revenue2].setValue(0);
        return null;
      } else if (
        this.hasEmptyValueOrInvalid(
          control.parent.value[revenue1],
          control.parent.value[revenue2],
          control.value,
        )
      ) {
        return null;
      }

      if (
        Number(control.parent.value[revenue1]) +
          Number(control.parent.value[revenue2]) +
          Number(control.value) !=
        100
      ) {
        return {
          customError: true,
        };
      } else {
        control.parent.controls[revenue1].errors = null;
        control.parent.controls[revenue2].errors = null;
        control.parent.controls[revenue1].status = 'VALID';
        control.parent.controls[revenue2].status = 'VALID';
      }

      return {};
    };
  }

  get addCompanyForm() {
    return this.form.get('addCompanyForm') as FormGroup;
  }

  get addIndividualForm() {
    return this.form.get('addIndividualForm') as FormGroup;
  }

  onInsuredTypeToggle(isCompany, isCompanyPrevSelected) {
    if (isCompany == isCompanyPrevSelected) {
      return;
    }
    if (isCompany) {
      this.setIsNavigatedFromInsuredInStore(false);
    } else {
      this.setIsNavigatedFromInsuredInStore(true);
    }
    this.isInsuredTypeCompany = isCompany;
  }

  hasLocationDataChanged(latest, previous) {
    const latestLocationData = {
      address: latest?.address,
      addressLine2: latest?.addressLine2,
      city: latest?.city,
      country: latest?.country,
      province: this.form.value['addIndividualForm'].province,
      zipPostalCode: latest?.zipPostalCode,
    };

    const previousLocationData = {
      address: previous?.address,
      addressLine2: previous?.addressLine2,
      city: previous?.city,
      country: previous?.country,
      province: previous?.province,
      zipPostalCode: previous?.zipPostalCode,
    };

    if (
      JSON.stringify(latestLocationData) !==
      JSON.stringify(previousLocationData)
    ) {
      return true;
    }
    return false;
  }

  updateInsuredCompany(
    formData: any,
    isRecalculate: boolean = false,
    saveRiskAnswers: boolean = false,
    riskAnswers = [],
    quoteDeclined = false,
  ) {
    if (formData.insuredLocationId) {
      this.showBodySpinner = true;
      const formValue = this.form.value['addCompanyForm'];
      this.regionService
        .GetByName(this.form.value['addCompanyForm'].province)
        .subscribe(async (data) => {
          const regionId = data.data[0].id;

          let location = {
            address: !!formValue.address ? formValue.address : null,
            city: !!formValue.city ? formValue.city : null,
            country: !!formValue.country ? formValue.country : null,
            zipPostalCode: !!formValue.zipPostalCode
              ? formValue.zipPostalCode
              : null,
            addressLine2: !!formValue.addressLine2
              ? formValue.addressLine2
              : null,
            geoLocationLatitude: 'string',
            geoLocationLongitude: ' string',
            source: 0,
            regionId: regionId,
            id: formData.insuredLocationId,
          };

          const hasLocationChanged = this.hasLocationDataChanged(
            location,
            formData?.initialFormData,
          );

          let billingContactId = formData.billingContactId;
          const billingContactPayload = {
            firstName: formValue.firstName,
            lastName: formValue.lastName,
            email: formValue.email,
          };
          const initialBillingContactData = {
            firstName: formData?.initialFormData?.firstName,
            lastName: formData?.initialFormData?.lastName,
            email: formData?.initialFormData?.email,
          };

          try {
            if (
              JSON.stringify(billingContactPayload) !==
              JSON.stringify(initialBillingContactData)
            ) {
              if (billingContactId) {
                const updateBillingCompanyContact =
                  this.billingCompanyContactService.Update(
                    billingContactId,
                    billingContactPayload,
                  );
                const billingUpdated = await firstValueFrom(
                  updateBillingCompanyContact,
                );
              } else {
                let billingContactPayload = {};
                let firstName =
                  this.form.value['addCompanyForm'].firstName ?? '';
                let lastName = this.form.value['addCompanyForm'].lastName ?? '';
                let email = this.form.value['addCompanyForm'].email ?? null;

                if (email) {
                  billingContactPayload = {
                    ...billingContactPayload,
                    email: email,
                  };
                }
                billingContactPayload = {
                  ...billingContactPayload,
                  firstName: firstName,
                  lastName: lastName,
                  insuredId: Number(this.insuredId),
                };
                const addBillingContactData =
                  this.billingCompanyContactService.Create(
                    billingContactPayload,
                  );
                let insuredBillingContactData = await firstValueFrom(
                  addBillingContactData,
                );
              }
            }

            if (hasLocationChanged) {
              const updateLocation =
                this.locationService.updateLocation(location);
              const locationUpdated = await firstValueFrom(updateLocation);
            }

            let insuredPayload: any = {
              companyName: formValue.businessName,
              companyWebsite: formValue.website,
              industryClassId: formData?.industryClass?.id,
              industryId: formData?.industry?.id,
              insuredLocationId: formData?.insuredLocationId,
              insuredType: 2,
              numOfEmployees: formData?.numberOfEmp?.id,
              revenue: Number(formValue.revenue),
              webPressence: 'true',
              revenueUSPerc: Number(formValue.revenuePercentUS),
              revenueCAPerc: Number(formValue.revenuePercentCA),
              revenueOtherPerc: Number(formValue.revenuePercentOther),
            };

            try {
              const updateInsuredDetails = await this.insuredService.Update(
                this.insuredId,
                insuredPayload,
              );
              const insuredUpdated = await firstValueFrom(updateInsuredDetails);

              if (saveRiskAnswers) {
                const getPolicyRiskAnswers =
                  this.policyAnswerService.GetAllByQuestionIdAndPolicyRiskId(
                    this.policyRiskId,
                    1,
                    500,
                  );
                const policyRiskAnswersResponse =
                  await firstValueFrom(getPolicyRiskAnswers);
                const policyRiskAnswers = policyRiskAnswersResponse?.data;
                if (riskAnswers?.length > 0) {
                  const policyRiskAnswerPayload = riskAnswers.map(
                    (riskQuestion) => {
                      const id = policyRiskAnswers?.find(
                        (answer) =>
                          answer.riskQuestionId ===
                          Number(riskQuestion?.riskQuestionId),
                      )?.id;
                      return {
                        id,
                        riskAnswer: riskQuestion?.riskAnswer,
                      };
                    },
                  );
                  if (policyRiskAnswerPayload?.length > 0) {
                    const riskAnswerArray = {
                      policyRiskId: this.policyRiskId,
                      answers: policyRiskAnswerPayload,
                    };
                    const policyRiskQuestions =
                      this.policyAnswerService.UpdateBulkQuestionAnswers(
                        riskAnswerArray,
                      );
                    let dataPolicyRiskQuestions =
                      await firstValueFrom(policyRiskQuestions);
                    this.doRecalculate();
                  }
                }
              }

              this.store.dispatch(
                new InsuredAction.setInsuredNameAction(
                  formValue?.businessName ?? '',
                ),
              );
              if (quoteDeclined) {
                this.store.dispatch(
                  new InsuredAction.setInsuredAlertAction({
                    show: true,
                    header: 'common.successMsg',
                    body: 'insured.success.insuredUpdatedAfterDecline',
                    type: 'success',
                  }),
                );
              } else {
                this.store.dispatch(
                  new InsuredAction.setInsuredAlertAction({
                    show: true,
                    header: 'common.successMsg',
                    body: 'insured.success.insuredUpdated',
                    type: 'success',
                  }),
                );
              }

              this.showBodySpinner = false;
              this.router
                .navigate(['/dashboard'], { skipLocationChange: true })
                .then(() => {
                  // to trigger the page reload
                  this.router.navigate(
                    ['/dashboard/insured/form', this.insuredId],
                    { skipLocationChange: true },
                  );
                });
              if (isRecalculate === true) this.doRecalculate();
            } catch (error) {
              if (![500].includes(error?.status)) {
                this.handleErrorOrSuccess({
                  show: true,
                  header: 'common.errorHeader',
                  body: getErrorMessage(error),
                  type: 'error',
                });
              }
              this.showBodySpinner = false;
            }
          } catch (error) {
            if (![500].includes(error?.status)) {
              this.handleErrorOrSuccess({
                show: true,
                header: 'common.errorHeader',
                body: getErrorMessage(error),
                type: 'error',
              });
            }
            this.showBodySpinner = false;
          }
        });
    }
  }

  async addInsuredCompany(formData) {
    this.showBodySpinner = true;
    const formValue = this.form.value['addCompanyForm'];
    try {
      const getInsuredByName = this.insuredService.GetByCompanyName(
        formValue.businessName,
      );
      const getInsuredResponse = await firstValueFrom(getInsuredByName);
      if (getInsuredResponse.data.length > 0) {
        const ifInsuredExists =
          getInsuredResponse.data.filter(
            (result) =>
              formValue.businessName?.toLowerCase() ===
                result.companyName?.toLowerCase() &&
              formValue?.province?.toLowerCase() ===
                result?.insuredLocation?.region?.name.toLowerCase(),
          )?.length > 0;
        if (ifInsuredExists) {
          this.handleErrorOrSuccess({
            show: true,
            type: 'error',
            header: 'common.errorHeader',
            body: 'insured.error.duplicateInsuredCompany',
          });
          this.showBodySpinner = false;
          return;
        }
      }
    } catch (error) {
      this.showBodySpinner = false;
    }

    this.regionService.GetByName(formValue.province).subscribe((data) => {
      const regionId = data.data[0].id;

      let location = {
        address: !!formValue.address ? formValue.address : null,
        city: !!formValue.city ? formValue.city : null,
        country: !!formValue.country ? formValue.country : null,
        zipPostalCode: !!formValue.zipPostalCode
          ? formValue.zipPostalCode
          : null,
        addressLine2: !!formValue.addressLine2 ? formValue.addressLine2 : null,
        geoLocationLatitude: 'string',
        geoLocationLongitude: ' string',
        source: 0,
        regionId: regionId,
      };
      try {
        this.locationService.Create(location).subscribe(async (data) => {
          let insured: InsuredCompanyPostRequest;

          insured = {
            companyName: formValue.businessName,
            companyWebsite: formValue.website,
            email: formValue.email,
            industryClassId: formData?.industryClass?.id,
            industryId: formData?.industry?.id,
            insuredLocationId: data.data?.id,
            insuredType: 2,
            numOfEmployees: formData?.numberOfEmp?.id,
            revenue: Number(formValue.revenue),
            webPressence: 'true',
            revenueUSPerc: Number(formValue.revenuePercentUS),
            revenueCAPerc: Number(formValue.revenuePercentCA),
            revenueOtherPerc: Number(formValue.revenuePercentOther),
          };

          this.insuredService.Create(insured).subscribe({
            next: async (data) => {
              const insuredId = data.data.id;
              let billingContactPayload = {};
              let firstName = this.form.value['addCompanyForm'].firstName ?? '';
              let lastName = this.form.value['addCompanyForm'].lastName ?? '';
              let email = this.form.value['addCompanyForm'].email;
              if (firstName) {
                billingContactPayload = {
                  ...billingContactPayload,
                  firstName: this.form.value['addCompanyForm'].firstName ?? '',
                };
              }
              if (lastName) {
                billingContactPayload = {
                  ...billingContactPayload,
                  lastName: this.form.value['addCompanyForm'].lastName ?? '',
                };
              }
              if (email) {
                billingContactPayload = {
                  ...billingContactPayload,
                  email: this.form.value['addCompanyForm'].email ?? null,
                };
              }

              try {
                if (Object.keys(billingContactPayload).length > 0) {
                  billingContactPayload = {
                    ...billingContactPayload,
                    insuredId: insuredId,
                  };
                  const addBillingContactData =
                    this.billingCompanyContactService.Create(
                      billingContactPayload,
                    );
                  let insuredBillingContactData = await firstValueFrom(
                    addBillingContactData,
                  );
                }

                this.store.dispatch(
                  new InsuredAction.setInsuredAlertAction({
                    show: true,
                    type: 'success',
                    header: 'common.successHeader',
                    body: 'insured.success.insuredCreated',
                  }),
                );
              } catch (error) {
                this.showBodySpinner = false;
                this.store.dispatch(
                  new InsuredAction.setInsuredAlertAction({
                    show: true,
                    type: 'error',
                    header: 'common.errorHeader',
                    body: getErrorMessage(error),
                  }),
                );
              }
              let isInsuredTypeCompany;
              let versionId = 1;
              if (isInsuredTypeCompany) {
                versionId = 2;
              }
              this.showBodySpinner = false;
              this.router.navigate(['/dashboard/insured/form', insuredId], {
                skipLocationChange: true,
              });
            },
            error: (error) => {
              this.showBodySpinner = false;
              if (![500].includes(error?.status)) {
                this.handleErrorOrSuccess({
                  show: true,
                  type: 'error',
                  header: 'common.errorHeader',
                  body: getErrorMessage(error),
                });
              }
            },
          });
        });
      } catch (error) {
        if (![500].includes(error?.status)) {
          this.handleErrorOrSuccess({
            show: true,
            type: 'error',
            header: 'common.errorHeader',
            body: getErrorMessage(error),
          });
        }
        this.showBodySpinner = false;
      }
    });
  }

  ofacCheckRequired(newForm, oldForm) {
    if (
      !(
        newForm?.businessName == oldForm?.businessName &&
        newForm.website == oldForm.website &&
        newForm.address == oldForm.address &&
        newForm.province == oldForm.province &&
        newForm.city == oldForm.city &&
        newForm.zipPostalCode == oldForm.zipPostalCode &&
        newForm.country == oldForm.country
      )
    ) {
      return true;
    }
    return false;
  }

  async addOrUpdateCompanyInsured(formData) {
    this.formSubmitted = true;

    if (this.form.get('addCompanyForm').valid) {
      if (this.isEditMode) {
        if (this.quoteExist) {
          this.actionPopupDetails = {
            ...this.actionPopupDetails,
            insured: this.insuredName,
          };

          const formValue = this.form.value['addCompanyForm'];
          if (formValue?.province !== formData?.initialFormData?.province) {
            this.formDataAddCompanyForm = formData;
            this.latestFormData = formData;
            this.showModalStateChange = true;
            return;
          }
          if (
            formData?.initialFormData &&
            this.ofacCheckRequired(formValue, formData?.initialFormData)
          ) {
            const entitySearchData = {
              cases: [
                {
                  name: formValue?.businessName,
                  type: 'entity',
                  ids: [
                    {
                      id: formValue?.website,
                    },
                  ],
                  address: {
                    address1: formValue.address,
                    city: formValue?.city,
                    country: formValue?.country,
                    postalCode: formValue?.zipPostalCode,
                  },
                },
              ],
              isMock: this.mockExternalAPIs,
            };

            const ofacSearchData = this.ofacService.Create(entitySearchData);
            const ofacData = await firstValueFrom(ofacSearchData);

            if (ofacData?.data?.length > 0) {
              this.sanctionPayload = [...ofacData?.data];
            } else {
              this.sanctionPayload = [];
            }
          } else {
            this.sanctionPayload = [];
          }
          const restrictedIndustry =
            this.industryRestrictedService.GetByIndustryId(
              formData?.industry?.id,
            );
          const restrictedIndustryResponse =
            await firstValueFrom(restrictedIndustry);
          if (restrictedIndustryResponse?.data?.length > 0) {
            this.sanctionPayload.unshift({
              Type: 'Restricted industry',
              Description: formValue?.industry,
              Reasons: '',
              Source: 'Hiscox',
              Score: 'no-data',
            });
            this.isRestrictedIndustry = true;
          } else {
            this.isRestrictedIndustry = false;
          }

          if (
            this.checkIfRiskAnalysisUpdateRequired(
              formData.initialFormData?.revenue,
              Number(formValue['revenue']),
            )
          ) {
            const regionId = formData?.regionId;
            const response = await firstValueFrom(
              this.policyRiskService.GetAllByPolicyPeriodId(
                this.policyPeriodId,
              ),
            );
            if (response?.data?.length > 0) {
              const policyRisksData = response?.data;
              for (const policyRiskData of policyRisksData) {
                const riskId = policyRiskData?.riskId;
                const data = await firstValueFrom(
                  this.riskRegionService.getRiskRegionByRegionId(
                    regionId,
                    riskId,
                  ),
                );
                this.riskRegionId = data?.data[0].id;
                this.policyRiskId = policyRiskData?.id;
                this.questionsCategory = this.getQuestionsCategoryByRevenue(
                  Number(formValue['revenue']),
                );
                const response = await firstValueFrom(
                  this.riskQuestionService.GetByRiskRegionIdAndCategory(
                    this.riskRegionId,
                    this.questionsCategory,
                  ),
                );
                this.riskQuestions = response?.data;
              }
            }
          } else {
            this.riskQuestions = [];
          }

          if (this.sanctionPayload.length > 0) {
            this.formDataAddCompanyForm = formData;
            this.latestFormData = formData;
            if (!this.isRestrictedIndustry && this.riskQuestions?.length > 0) {
              if (this.existingQuoteStatus !== QUOTESTATUS_SUBMISSION) {
                this.showSanctionsRiskQuestionModal = true;
                return;
              } else {
                this.showModalRiskAnalysis = true;
                return;
              }
            }
            this.showSanctionModal = true;
            return;
          } else {
            if (this.riskQuestions?.length > 0) {
              this.formDataAddCompanyForm = formData;
              this.latestFormData = formData;
              if (this.existingQuoteStatus !== QUOTESTATUS_SUBMISSION) {
                this.showModalRiskQuestions = true;
                return;
              } else {
                this.showModalRiskAnalysis = true;
                return;
              }
            }
            if (
              this.checkForRecalculate(
                'addCompanyForm',
                formData.initialFormData,
              ) === true ||
              this.checkIfUSRevenuePercTriggerRecalc(
                formData.initialFormData?.revenuePercentUS,
                Number(formValue?.revenuePercentUS),
              )
            ) {
              this.formDataAddCompanyForm = null;
              this.formDataAddCompanyForm = formData;
              this.showRecalcConfModal = true;
            } else {
              this.updateInsuredCompany(formData);
            }
          }
        } else {
          this.updateInsuredCompany(formData);
        }
      } else {
        this.addInsuredCompany(formData);
      }
    }
  }

  checkIfUSRevenuePercTriggerRecalc(
    initialUSRevenuePerc,
    updatedUSRevenuePerc,
  ) {
    const usRevenuePercMargin = 10;
    if (
      initialUSRevenuePerc === updatedUSRevenuePerc ||
      (initialUSRevenuePerc <= usRevenuePercMargin &&
        updatedUSRevenuePerc <= usRevenuePercMargin) ||
      (initialUSRevenuePerc > usRevenuePercMargin &&
        updatedUSRevenuePerc > usRevenuePercMargin)
    ) {
      return false;
    } else {
      return true;
    }
  }

  checkIfRiskAnalysisUpdateRequired(initialRevenue, updatedRevenue) {
    if (
      initialRevenue === updatedRevenue ||
      (initialRevenue < this.revenueMarginValue &&
        updatedRevenue < this.revenueMarginValue) ||
      (initialRevenue >= this.revenueMarginValue &&
        updatedRevenue >= this.revenueMarginValue)
    ) {
      return false;
    } else {
      return true;
    }
  }

  getQuestionsCategoryByRevenue(revenue) {
    const revenueMarginValue = REVENUE_MARGIN_VALUE;
    if (revenue < this.revenueMarginValue) {
      return RISK_QUESTION_UNDER20M_CATEGORY;
    } else {
      return RISK_QUESTION_OVER20M_CATEGORY;
    }
  }

  async addOrUpdateIndividualInsured(formData) {
    this.formSubmitted = true;

    if (this.form.get('addIndividualForm').valid) {
      if (this.isEditMode) {
        this.updateInsuredIndividual(formData);
      } else {
        this.addInsuredIndividual(formData);
      }
    }
  }

  handleSaveRiskAnalysis(riskAnswers) {
    this.updateInsuredCompany(
      this.formDataAddCompanyForm,
      false,
      true,
      riskAnswers,
    );
  }

  handleSaveRevenueChanges() {
    this.updateInsuredCompany(this.formDataAddCompanyForm, false);
  }

  updateInsuredIndividual(formData) {
    if (formData.insuredLocationId) {
      this.showBodySpinner = true;
      const individualForm = this.form.value['addIndividualForm'];
      this.regionService
        .GetByName(individualForm.province)
        .subscribe(async (data) => {
          const regionId = data.data[0].id;

          let location = {
            address: !!individualForm.address ? individualForm.address : null,
            city: !!individualForm.city ? individualForm.city : null,
            country: !!individualForm.country ? individualForm.country : null,
            zipPostalCode: !!individualForm.zipPostalCode
              ? individualForm.zipPostalCode
              : null,
            addressLine2: !!individualForm.addressLine2
              ? individualForm.addressLine2
              : null,
            geoLocationLatitude: 'string',
            geoLocationLongitude: ' string',
            source: 0,
            regionId: regionId,
            id: formData.insuredLocationId,
          };

          const hasLocationChanged = this.hasLocationDataChanged(
            location,
            formData?.initialFormData,
          );

          try {
            if (hasLocationChanged) {
              const updateLocation =
                this.locationService.updateLocation(location);
              const locationUpdated = await firstValueFrom(updateLocation);
            }

            const insuredPayload = {
              insuredType: 1,
              insuredLocationId: data.data?.id,
              email: individualForm.email,
              firstName: individualForm.firstName,
              lastName: individualForm.lastName,
              middleName: individualForm.middleName,
              numOfHouseholdMem: formData?.numberOfHouseholdMembersId,
            };
            try {
              const updateInsuredDetails = this.insuredService.Update(
                this.insuredId,
                insuredPayload,
              );
              const insuredUpdated = await firstValueFrom(updateInsuredDetails);

              this.store.dispatch(
                new InsuredAction.setInsuredNameAction(
                  individualForm?.businessName ?? '',
                ),
              );
              this.store.dispatch(
                new InsuredAction.setInsuredAlertAction({
                  show: true,
                  header: 'common.successMsg',
                  body: 'insured.success.insuredUpdated',
                  type: 'success',
                }),
              );
              this.showBodySpinner = false;
              this.router
                .navigate(['/dashboard'], { skipLocationChange: true })
                .then(() => {
                  // to trigger the page reload
                  this.router.navigate(
                    ['/dashboard/insured/form', this.insuredId, 0],
                    { skipLocationChange: true },
                  );
                });
            } catch (error) {
              if (![500].includes(error?.status)) {
                this.handleErrorOrSuccess({
                  show: true,
                  header: 'common.errorHeader',
                  body: getErrorMessage(error),
                  type: 'error',
                });
              }
              this.showBodySpinner = false;
            }
          } catch (error) {
            if (![500].includes(error?.status)) {
              this.handleErrorOrSuccess({
                show: true,
                header: 'common.errorHeader',
                body: getErrorMessage(error),
                type: 'error',
              });
            }
            this.showBodySpinner = false;
          }
        });
    }
  }

  addInsuredIndividual(formData) {
    this.showBodySpinner = true;
    const individualForm = this.form.value['addIndividualForm'];

    if (this.form.get('addIndividualForm').valid) {
      try {
        this.regionService
          .GetByName(individualForm.province)
          .subscribe((data) => {
            const regionId = data.data[0].id;

            let location = {
              address: !!individualForm.address ? individualForm.address : null,
              city: !!individualForm.city ? individualForm.city : null,
              country: !!individualForm.country ? individualForm.country : null,
              zipPostalCode: !!individualForm.zipPostalCode
                ? individualForm.zipPostalCode
                : null,
              addressLine2: !!individualForm.addressLine2
                ? individualForm.addressLine2
                : null,
              geoLocationLatitude: 'string',
              geoLocationLongitude: ' string',
              source: 0,
              regionId: regionId,
            };

            this.locationService.Create(location).subscribe((data) => {
              let insured: InsuredIndividualPostRequest;
              insured = {
                insuredType: 1,
                insuredLocationId: data.data?.id,
                email: individualForm.email,
                firstName: individualForm.firstName,
                lastName: individualForm.lastName,
                middleName: individualForm.middleName,
                numOfHouseholdMem: formData?.numberOfHouseholdMembersId,
              };

              this.insuredService.Create(insured).subscribe({
                next: (data) => {
                  this.insuredId = data.data.id;
                  this.store.dispatch(
                    new InsuredAction.setInsuredAlertAction({
                      show: true,
                      type: 'success',
                      header: 'common.successHeader',
                      body: 'insured.success.insuredCreated',
                    }),
                  );
                  this.showBodySpinner = false;
                  this.router.navigate(
                    ['/dashboard/insured/form', this.insuredId, 0],
                    {
                      skipLocationChange: true,
                    },
                  );
                },
                error: (error) => {
                  this.showBodySpinner = false;
                  if (![500].includes(error?.status)) {
                    this.handleErrorOrSuccess({
                      show: true,
                      type: 'error',
                      header: 'common.errorHeader',
                      body: getErrorMessage(error),
                    });
                  }
                },
              });
            });
          });
      } catch (error) {
        if (![500].includes(error?.status)) {
          this.handleErrorOrSuccess({
            show: true,
            type: 'error',
            header: 'common.errorHeader',
            body: getErrorMessage(error),
          });
        }
        this.showBodySpinner = false;
      }
    } else {
      this.showBodySpinner = false;
    }
  }

  closeModalRecalculateConf() {
    this.showRecalcConfModal = false;
  }

  closeModal() {
    this.showModalRiskQuestions = false;
    this.showModalRiskQuestions = false;
    this.showSanctionsRiskQuestionModal = false;
    this.showModalStateChange = false;
    this.showModalRiskAnalysis = false;
  }

  handleSubmissionRecalculate(action: any, formData: any) {
    this.updateInsuredCompany(this.formDataAddCompanyForm, true);
  }

  handleSanctionProceed() {
    const doRecalculate =
      this.checkForRecalculate(
        'addCompanyForm',
        this.latestFormData.initialFormData,
      ) === true ||
      this.checkIfUSRevenuePercTriggerRecalc(
        this.latestFormData?.currentFormValue?.revenuePercentUS,
        Number(this.latestFormData?.initialFormData?.revenuePercentUS),
      );
    this.updateInsuredCompany(this.formDataAddCompanyForm, doRecalculate);
  }

  handleDiscardRecalculate(action: any) {
    this.addCompanyForm.patchValue(this.formDataAddCompanyForm.initialFormData);
    this.showRecalcConfModal = false;
    this.showSanctionModal = false;
    this.showModalRiskQuestions = false;
    this.showSanctionsRiskQuestionModal = false;
    this.showModalStateChange = false;
  }

  handleDeclineAndSave() {
    const PolicyDeclineRequest = {
      policyPeriodId: Number(this.policyPeriodId),
      declineReason: 173,
    };

    this.policyLifecycleService
      .TransactionRiskDecline(PolicyDeclineRequest)
      .subscribe({
        next: (response) => {
          this.updateInsuredCompany(
            this.formDataAddCompanyForm,
            false,
            false,
            [],
            true,
          );
          this.showSanctionsRiskQuestionModal = false;
        },
        error: (error) => {
          this.handleErrorOrSuccess({
            show: true,
            header: 'common.errorHeader',
            body: getErrorMessage(error),
            type: 'error',
          });
        },
      });
  }

  checkForRecalculate(formControlName: string, initialValues): boolean {
    let changedControls = ['industry', 'industryClass', 'revenue'];
    let triggerRecalculate = false;
    if (formControlName === 'addCompanyForm') {
      triggerRecalculate = this.getChangedFieldsStatus(
        this.form.controls[formControlName].value,
        initialValues,
        changedControls,
      );
    }
    return triggerRecalculate;
  }

  getChangedFieldsStatus(
    obj1: any,
    obj2: any,
    fieldsToCheck: string[],
  ): boolean {
    const stringifyAndSort = (obj: any): string =>
      JSON.stringify(obj, Object.keys(obj).sort());

    const normalizeObject = (obj: any): any =>
      JSON.parse(JSON.stringify(obj), (key, value) =>
        typeof value === 'string' && !isNaN(Number(value))
          ? this.roundNumber(Number(value), 2)
          : value,
      );

    const normalizedObj1 = normalizeObject(obj1);
    const normalizedObj2 = normalizeObject(obj2);

    const stringifiedObj1 = stringifyAndSort(normalizedObj1);
    const stringifiedObj2 = stringifyAndSort(normalizedObj2);

    if (stringifiedObj1 === stringifiedObj2) {
      return false;
    }

    const changedFields: string[] = [];
    fieldsToCheck.forEach((field) => {
      if (normalizedObj1[field] !== normalizedObj2[field]) {
        changedFields.push(field);
      }
    });

    return changedFields.length === 0 ? false : true;
  }

  roundNumber(value: number, precision: number): number {
    const factor = Math.pow(10, precision);
    return Math.round(value * factor) / factor;
  }

  doRecalculate() {
    let policyRiskList;
    if (this.policyPeriodId) {
      this.showBodySpinner = true;
      this.policyRiskService
        .GetAllByPolicyPeriodId(this.policyPeriodId)
        .subscribe(async (data) => {
          if (data?.data[0]) {
            policyRiskList = data?.data;

            const resultArray = policyRiskList.flatMap((item) =>
              item.PolicyRiskTrxes.flatMap((trx) =>
                trx.QuoteOptions.map((option) => option.id),
              ),
            );

            for (const element of resultArray) {
              try {
                const riskRating =
                  await this.riskRatingCalculateService.GetByQuoteOption(
                    element,
                  );
                const ratingData = await firstValueFrom(riskRating);
              } catch (error) {}
            }

            this.closeModalRecalculateConf();
            this.showBodySpinner = false;
          }
        });
    }
  }

  closeSanctionPopup() {
    this.showSanctionModal = false;
    this.sanctionPayload = [];
  }
}
