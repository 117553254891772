<ng-container *ngIf="showBodySpinner">
  <div class="body-spinner">
    <boxxds-spinner
      [containerStyle]="{ width: '112px', height: '112px', '--b': '15px' }"
    ></boxxds-spinner>
  </div>
</ng-container>
<ng-container *ngIf="!showBodySpinner">
  <app-navigation-v2
    [showHeader]="true"
    [showHeaderBanner]="false"
    (suggestedSearchSelected)="handleSearch($event)"
    [items]="itemsMenu"
    [badgeData]="'0 Records'"
    (buttonClicked)="handleStartNewQuote()"
  ></app-navigation-v2>
  <div *ngIf="form$ | async as form">
    <div class="lifecycleHeader">
      <app-lifecycle-header
        [header]="'Quote'"
        [policyId]="policyId"
        [details]="details"
        [transactionType]="transactionType"
        (handleBind)="
          this.permissionList[this.currentScreen] &&
            currentProductDetails.active &&
            handleBind()
        "
        (handleClose)="
          this.permissionList[this.currentScreen] &&
            currentProductDetails.active &&
            handleRiskAction(transactionRiskActions.Close)
        "
        (handleDecline)="
          this.permissionList[this.currentScreen] &&
            handleRiskAction(transactionRiskActions.Decline)
        "
        [isProductActive]="currentProductDetails.active"
      ></app-lifecycle-header>
    </div>
    <div class="lifecycleContainer">
      <app-lifecycle-information
        [details]="details"
        [hasWritePermission]="
          this.permissionList[this.currentScreen] &&
          currentProductDetails.active
        "
        (changeProducer)="
          this.permissionList[this.currentScreen] &&
            currentProductDetails.active &&
            changeProducer()
        "
      ></app-lifecycle-information>
      <div class="LatestTransactionConatiner">
        <div class="header">
          <div class="left">
            <h2 class="head">Quote current state</h2>
            <boxxds-status-badge-v2
              [status]="details.status"
              [statusType]="getStatusType(details.status)"
            ></boxxds-status-badge-v2>
          </div>
          <div class="right">
            <boxxds-button-v2
              [buttonText]="'quoteDetails.seeQuoteOptions' | translate"
              [isDisabled]="!currentProductDetails.active"
              [size]="'sm'"
              [btnClass]="'secondary gray'"
              [btnType]="'default'"
              [isDropdown]="false"
              [class.noPointerEvents]="!currentProductDetails.active"
              (handleClick)="
                currentProductDetails.active && handleSeeQuoteOptions()
              "
            >
            </boxxds-button-v2>
            <boxxds-button-v2
              [isDisabled]="false"
              [size]="'sm'"
              [btnClass]="'secondary gray'"
              [btnType]="'only'"
              [isDropdown]="false"
              [leftImage]="'edit-05'"
              (handleClick)="toggleNotesPopup($event)"
            >
            </boxxds-button-v2>
            <boxxds-button-v2
              [isDisabled]="!currentProductDetails.active"
              [class.noPointerEvents]="!currentProductDetails.active"
              [size]="'sm'"
              [btnClass]="'secondary gray'"
              [btnType]="'only'"
              [isDropdown]="false"
              [leftImage]="'send-01'"
              (click)="
                this.permissionList[this.currentScreen] &&
                  currentProductDetails.active &&
                  !['quote-closed'].includes(this.currentQuoteStatus) &&
                  handleSendQuote()
              "
            >
            </boxxds-button-v2>
            <boxxds-button-v2
              [isDisabled]="false"
              [size]="'sm'"
              [btnClass]="'secondary gray'"
              [btnType]="'only'"
              [isDropdown]="false"
              [leftImage]="'dots-vertical'"
              (handleClick)="showQuoteSlideOut($event)"
            >
            </boxxds-button-v2>
          </div>
        </div>
        <div class="content">
          <table class="transactionTable">
            <thead>
              <tr class="tableHeader">
                <th class="head">Product</th>
                <th class="head">Status</th>
                <th class="head">Premium</th>
                <th class="head">Limit</th>
                <th class="head">Retention</th>
                <th class="head">Effective date</th>
                <th class="head">Invoice number</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let nav of subNavData; let i = index">
                <ng-container *ngIf="getPdtDetailsByIndex(i) as product">
                  <tr class="tableContent">
                    <td>
                      <boxxds-radio-v2
                        [control]="'product'"
                        [items]="[subNavData[i]]"
                        [form]="productForm"
                        (handleChange)="handleNav($event, i)"
                        [labelStyle]="{
                          color: '#2D2D39',
                          fontFamily: 'Gibson',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: '500',
                          lineHeight: '20px'
                        }"
                      ></boxxds-radio-v2>
                      <!-- <p class="productName">{{nav.name}}</p> -->
                    </td>
                    <td>
                      <boxxds-status-badge-v2
                        [status]="getStatusName(product.status)"
                        [statusType]="getStatusType(product.status)"
                      ></boxxds-status-badge-v2>
                    </td>
                    <td>
                      {{
                        product.premium
                          | currency: currency : "symbol-narrow" : "1.0-0"
                      }}
                    </td>
                    <td>
                      {{
                        product.limit
                          | currency: currency : "symbol-narrow" : "1.0-0"
                      }}
                    </td>
                    <td>
                      {{
                        product.deductible
                          | currency: currency : "symbol-narrow" : "1.0-0"
                      }}
                    </td>
                    <td>{{ product.transactionEffectiveDate ?? "---" }}</td>
                    <td>{{ product.invoiceNumber ?? "---" }}</td>
                    <td>
                      <div
                        class="button-container"
                        *ngIf="product.status.toLowerCase() !== 'referral'"
                      >
                        <boxxds-button-v2
                          [buttonText]="'Refer'"
                          [isDisabled]="false"
                          [size]="'sm'"
                          [btnClass]="'link'"
                          [isDropdown]="false"
                          (handleClick)="
                            this.permissionList[this.currentScreen] &&
                              handleRiskAction(transactionRiskActions.Refer)
                          "
                        >
                        </boxxds-button-v2>
                      </div>
                    </td>
                  </tr>
                  <!-- <tr class="transactionForm">
                          <td colspan="9">
                              <app-plc-endorsement-section-v2
                                  [policyRiskTrxId]="product.policyRiskTrxId"
                                  [product]="nav.name"
                                  [showEndorseForm]="showForm"
                                  [trxTypesList]="riskTrxTypes"
                              > </app-plc-endorsement-section-v2>
                          </td>
                      </tr> -->
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div class="footer-timestamp">
          <app-pagefooter-timestamp-v2
            *ngIf="true"
            [createdBy]="createdBy"
            [createdAt]="createdAt"
          >
          </app-pagefooter-timestamp-v2>
        </div>
        <!-- <div class="footer" [ngClass]="{ expandedFooter: openFooter }">
          <div class="footer-head">
            <div class="footer-dropdown" (click)="openFooter = !openFooter">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M5 7.5L10 12.5L15 7.5"
                  stroke="#98A2B3"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p class="text">Annual Premium</p>
            </div>
            <div class="mainContent">
              {{
                currentProductDetails.annualPremium
                  | currency: currency : "symbol-narrow" : "1.0-0"
              }}
            </div>
          </div>
          <div class="footer-content">
            <div class="label">Sales tax</div>
            <div class="content">
              {{
                currentProductDetails.taxesServicesAmount
                  | currency: currency : "symbol-narrow" : "1.0-0"
              }}
            </div>
            <div class="label">Insurance tax</div>
            <div class="content">
              {{
                currentProductDetails.taxesPremiumAmount
                  | currency: currency : "symbol-narrow" : "1.0-0"
              }}
            </div>
            <div class="label">Services</div>
            <div class="content">
              {{
                currentProductDetails.servicesAmount
                  | currency: currency : "symbol-narrow" : "1.0-0"
              }}
            </div>
            <div class="label">TRIA</div>
            <div class="content">
              {{
                currentProductDetails.triaAmount
                  | currency: currency : "symbol-narrow" : "1.0-0"
              }}
            </div>
            <div class="label">Policy fee</div>
            <div class="content">
              {{
                currentProductDetails.policyFee
                  | currency: currency : "symbol-narrow" : "1.0-0"
              }}
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <app-change-producer-popup-v2
      *ngIf="showChangeProducerPopup"
      [regionId]="regionId"
      [details]="details"
      (handleActionConfirm)="producerChanged($event)"
      (handleClose)="closeChangeProducer()"
    >
    </app-change-producer-popup-v2>
    <app-quote-action-popup
      [showModal]="showActionPopup"
      [details]="actionPopupDetails"
      (handleActionConfirm)="
        this.permissionList[this.currentScreen] &&
          handleConfirmRiskAction($event)
      "
      (handleClose)="showActionPopup = false"
    ></app-quote-action-popup>
    <app-notes-v2
      *ngIf="showNotesPopup"
      [showModalNotesPopup]="showNotesPopup"
      [policyPeriodId]="details.policyPeriodId"
      (handleClose)="handleNotesPopupClose($event)"
    ></app-notes-v2>
    <app-modal-email-quote-v2
      *ngIf="showDocTempSelectionPopup"
      [showModal]="showDocTempSelectionPopup"
      [isLoader]="isLoader"
      [docTempContainerStyle]="{}"
      [details]="docPopupDetails"
      [productId]="policyRiskId"
      [popupHeading]="'workFlow3.emailPopupV2.heading.sendQuote' | translate"
      (handleClose)="handleExit($event)"
      (handleSuccess)="handleSuccessDocTemplate($event)"
    >
    </app-modal-email-quote-v2>
    <app-modal-send-package-workflow3
      *ngIf="showSendModal"
      [showModal]="showSendModal"
      [isLoader]="isLoader"
      [templateDetails]="templateDetails"
      [docDetails]="docDetails"
      [title]="'workFlow3.emailPopupV2.heading.sendQuote' | translate"
      (handleSend)="handleSend($event)"
      (handleBackButton)="handleBack()"
      (handleClose)="closeSendPackage()"
      [formDetails]="form.ui"
      [disableBackButton]="hasTemplatePopup"
      [tempType]="1"
    >
    </app-modal-send-package-workflow3>

    <div class="slide-out">
      <app-slideout-popup-base
        *ngIf="showSlideOut"
        [isPopupOpen]="showSlideOut"
        [isLoading]="isLoading"
        (handleClose)="handleSlideOutClose()"
      >
        <app-quote-slideout-v2
          [details]="details"
          [isProductActive]="currentProductDetails.active"
          (handleLoading)="handleLoading($event)"
        ></app-quote-slideout-v2>
      </app-slideout-popup-base>
    </div>
  </div>
</ng-container>
