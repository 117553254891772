import { Component, OnInit, Input } from '@angular/core';
import { Breadcrumb } from 'src/app/models/breadcrumb.model';
import { ActivatedRoute, Router } from '@angular/router';
import { BrokerageBranchService } from 'src/app/services/brokerage-branch/brokerage-branch.service';
import { ViewportScroller } from '@angular/common';
import { Store, select } from '@ngrx/store';
import { getAlertHead, getErrorMessage } from 'src/app/utils/utils';
import { formatDateTime } from 'src/app/utils/formatDate';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { AlertService } from 'src/app/services/alert.service';
import { NewQuoteService } from 'src/app/services/new-quote.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-branch-producer',
  templateUrl: './branch-producer.component.html',
  styleUrls: ['./branch-producer.component.less'],
})
export class BranchProducerComponent implements OnInit {
  @Input() brokerageId: number;
  showProducerForm: boolean = false;
  activeCategoryFilter: string = 'Branch information';
  categoryFilters: string[] = ['Branch information', 'Producer'];
  branchId: number;
  branchName: string = '';
  brokerageName: string = '';
  isActiveBranch: boolean = false;
  showBodySpinner: boolean;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;

  alertData = {
    show: false,
    type: '',
    header: '',
    body: '',
  };
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  shortDateFormat: string = '';
  longDateFormat: string = '';
  shortDateTimeFormat: string = '';
  deactivateBtnLabel: string = 'Deactivate';
  longDateTimeFormat: string = '';
  breadcrumbsMenu;
  isRestrictedRoleSubmission: boolean = false;

  addProducer: boolean;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private brokerageBranchService: BrokerageBranchService,
    private viewPortScroller: ViewportScroller,
    private store: Store,
    private alertService: AlertService,
    private newQuoteService: NewQuoteService,
    private translateService: TranslateService,
  ) {
    activatedRoute.params.subscribe((params) => {
      this.branchId = params['id'] as number;
    });
  }

  ngOnInit(): void {
    const branchMsg = this.newQuoteService.getBranchMsg();
    this.newQuoteService.clearBranchMsg();
    if (branchMsg) {
      this.alertService.addAlert(branchMsg);
    }
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.longDateFormat = data.longDateFormat;
      this.shortDateTimeFormat = data.shortDateTimeFormat;
      this.longDateTimeFormat = data.longDateTimeFormat;
      this.isRestrictedRoleSubmission = data.isRestrictedRoleSubmission;
    });
    this.brokerageBranchService.getBranchById(this.branchId).subscribe({
      next: (data) => {
        this.branchName = data.data.name;
        this.brokerageName = data.data.brokerage.name;
        this.brokerageId = data.data.brokerage.id;
        this.breadcrumbsMenu = [
          {
            label: 'Members',
            path: '/dashboard/workflow3/brokerage/directory',
          },
          {
            label: 'Brokerage directory',
            path: '/dashboard/workflow3/brokerage/directory',
          },
          {
            label: `${this.brokerageName}`,
            path: `/dashboard/workflow3/brokerage/profile/${this.brokerageId}`,
          },
          {
            label: `${this.branchName}`,
            path: null,
          },
        ];
        this.isActiveBranch = data.data.active;
        this.createdAt = formatDateTime(
          data.data.createdDt,
          this.longDateTimeFormat,
        );
        this.createdBy = data.data.createdBy;
        this.updatedBy = data.data.updatedBy;
        this.updatedAt = formatDateTime(
          data.data.updatedDt,
          this.longDateTimeFormat,
        );
      },
    });

    if (this.activatedRoute.snapshot.queryParams['producer'] === 'true') {
      this.handleAddProducer();
    }
  }
  handleAddProducer() {
    this.handleCategoryFilter('Producer');
    this.showProducerForm = true;
    this.addProducer = true;
  }
  handleCategoryFilter(category: string): void {
    this.activeCategoryFilter = category;
    this.showProducerForm = false;
  }

  handleDiscard(event) {
    this.router.navigate([`/dashboard/workflow3/brokerage/profile/`], {
      relativeTo: this.activatedRoute,
      skipLocationChange: true,
    });
    //${this.brokerageId}
  }

  handleDeactivateBranch(event) {
    if (this.isActiveBranch) {
      this.showBodySpinner = true;
      this.brokerageBranchService.deleteBranch(this.branchId).subscribe({
        next: (responseData: any) => {
          const responseBranch = responseData?.data;
          this.updatedBy = `${responseBranch.updatedBy}, ${formatDateTime(
            responseBranch.updatedDt,
            this.longDateTimeFormat,
          )}`.toUpperCase();
          this.viewPortScroller.scrollToPosition([0, 0]);
          this.deactivateBtnLabel = 'Activate';
          this.showBodySpinner = false;
          this.isActiveBranch = false;
          this.alertService.clearAlerts(-1);
          this.alertData = {
            show: true,
            type: 'success',
            header: 'common.successHeader',
            body: 'workFlow3.branchProfile.success.deActivateMessage',
          };
        },
        error: (error) => {
          this.showBodySpinner = false;
          // this.operationSuccess = false;
          this.alertData = {
            show: true,
            type: 'error',
            header: 'common.errorHeader',
            body: getErrorMessage(error),
          };
          // this.handlerErrorBranch(error);
        },
      });
    }
  }

  handleActivateBranch(event) {
    if (!this.isActiveBranch) {
      this.showBodySpinner = true;
      this.brokerageBranchService.restoreBranch(this.branchId).subscribe({
        next: (responseData: any) => {
          const responseBranch = responseData?.data;
          this.updatedBy = `${responseBranch.updatedBy}, ${formatDateTime(
            responseBranch.updatedDt,
            this.longDateTimeFormat,
          )}`.toUpperCase();
          // this.operationSuccess = true;
          // this.hasError = false;
          this.showBodySpinner = false;
          this.isActiveBranch = true;
          this.deactivateBtnLabel = 'Deactivate';
          // this.successMessage = 'brokerage.branch.success.activateMessage';
          // this.branchSelected.active = true;
          // this.viewPortScroller.scrollToPosition([0, 0]);
          // this.form[this.isActive ? 'enable' : 'disable']({
          //   emitEvent: false,
          // });
          this.alertService.clearAlerts(-1);
          this.alertData = {
            show: true,
            type: 'success',
            header: 'common.successHeader',
            body: 'workFlow3.branchProfile.success.activateMessage',
          };
          this.deactivateBtnLabel = 'Deactivate';
        },
        error: (error) => {
          this.showBodySpinner = false;
          // this.operationSuccess = false;
          // this.handlerErrorBranch(error);
        },
      });
    }
  }

  handleAlertClose() {
    this.alertData = {
      show: false,
      type: '',
      header: '',
      body: '',
    };
  }

  handleDeactivateBranchChild(event) {
    this.alertService.clearAlerts(-1);
    this.isActiveBranch = event;
  }

  handleGlobalSearch(object) {
    if (object.searchIn === 'Insured name') {
      let insuredType = 1;
      if (object?.type?.toLowerCase() == 'company') {
        insuredType = 2;
      }
      this.router.navigateByUrl(
        `dashboard/search/${insuredType}/${object.selectedSearch.value}/${object.selectedSearch.id}`,
        { skipLocationChange: true },
      );
    } else {
      if (this.isRestrictedRoleSubmission === true) {
        this.alertService.clearAlerts(-1);
        const alertData = {
          type: 'warn',
          headerText: getAlertHead('warning'),
          bodyText: this.translateService.instant(
            'common.error.noAccessToPageErrorMsg',
          ),
        };
        this.alertService.addAlert(alertData);
        return;
      }
      this.router.navigateByUrl(
        `dashboard/workflow3/policies/${object.selectedSearch.value}`,
        { skipLocationChange: true },
      );
    }
  }
}
