import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Observable, firstValueFrom, lastValueFrom, take } from 'rxjs';
import { Quote } from 'src/app/models/quote.model';
import { BoxxUserService } from 'src/app/services/boxx-user.service';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { NewQuoteService } from 'src/app/services/new-quote.service';
import { PolicyDashboardService } from 'src/app/services/policy-dashboard.service';
import { PolicyRiskDocPreviewService } from 'src/app/services/policy-risk-doc-preview.service';
import { PolicyRiskService } from 'src/app/services/policy-risk.service';
import { TranslateService } from '@ngx-translate/core';
import {
  addDaysToDateString,
  getErrorMessage,
  validateEmail,
} from 'src/app/utils/utils';
import {
  getAllMailIds,
  getQuoteSelector,
} from 'src/app/store/create-quote/create-quote.selector';
import {
  getCurrencySelector,
  getDashboardSelector,
} from 'src/app/store/dashboard/dashboard.selector';
import {
  encodeToBase64,
  extractTextInsideDoubleCurlyBrackets,
  extractTextInsideDoubleCurlyBracketsWithReplaceText,
  formatAmountWithCurrency,
  removeDuplicatedKeys,
  replacePlaceholders,
} from 'src/app/utils/utils';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DomainsService } from 'src/app/services/domains.service';
import { InsuredService } from 'src/app/services/insured.service';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { getFormatDate } from 'src/app/utils/formatDate';
import { PolicyRiskDocumentResponse } from 'src/app/entities/common';

@Component({
  selector: 'app-modal-send-package-workflow3',
  templateUrl: './modal-send-package-workflow3.component.html',
  styleUrls: ['./modal-send-package-workflow3.component.less'],
})
export class ModalSendPackageWorkflow3Component implements OnInit, OnChanges {
  @Input() showModal: boolean = false;
  @Input() policyRiskId;
  @Input() formDetails;
  @Input() title;
  @Input() templateSelectionPlaceholder;
  @Input() templateDetails: { [x: string]: string | number }[];
  @Input() docDetails: { [x: string]: any }[];
  @Input() isLoader: boolean = false;
  @Input() tempType;
  @Input() disableBackButton;
  @Input() riskId?: number;

  @Output() handleClose = new EventEmitter<any>();
  @Output() handleSend = new EventEmitter<any>();
  @Output() handleBackButton = new EventEmitter<any>();

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '207px',
    minHeight: '207px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: this.translate.instant('common.enterTextHere') + '...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'roboto', name: 'Roboto' },
      { class: 'sans-serif', name: 'Sans-serif' },
    ],

    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [],
      [
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'toggleEditorMode',
      ],
    ],
    customClasses: [
      {
        name: 'text-editor-field',
        class: 'text-editor-field',
      },
    ],
  };
  form$: Observable<Quote>;
  policyPeriodId;
  userID;
  policyRiskTrxId: number = undefined;
  sendOptions: {
    name: string;
    value: string;
    checked: boolean;
    type: string;
    correspondingMails: string[];
    id: string;
    isNull?: boolean;
    disabledMsg?: string;
  }[] = [];
  emailData = {};
  docData = [];
  docOptions = [];
  currentMsgTemplateId: number = undefined;
  currentDocumentIds: number[] = [];

  templateOptions = [];
  templateData = [];

  form: FormGroup;
  docsAttached = [];
  sendToEmailIds = [];
  preview = '';
  attachedFiles = [];
  attachedFileNameReplacements = {
    Policy: this.translate.instant('common.policyDocumentation'),
  };
  extractedSubjectTexts = [];
  extractedMsgBodyTexts = [];
  replaceValueSource = {
    'insured-name': '',
    'client-name': '',
    'policy-number': '',
    'policy-term': '',
    'producer-fullname': '',
    'producer-email': '',
    'insured-company-name': '',
    premium: '',
    'quote-id': '',
    'quote-valid-until': '',
    'claim-contact-email': '',
    'claim-contact-telephone': '',
    'html-attached-docs': '',
  };
  notificationAlert: {
    show?: boolean;
    bodyText?: string;
    type?: string;
    headerText?: string;
  } = { show: false };
  docPreviewLoader: boolean = false;
  showDocPreview: boolean = false;
  currentPreviewDocUrl: string = '';
  defaultMails: string[] = [];
  currency = '';
  isSending: boolean = false;
  latestPolicyRiskTrxId;
  storePolicyRiskTrxId;
  insuredTimezone: string;
  longDateFormat: string = '';
  shortDateFormat: string = '';
  sendToNames: {};

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private localStorageService: LocalStorageService,
    private policyDashboardService: PolicyDashboardService,
    private policyRiskService: PolicyRiskService,
    private boxxUserService: BoxxUserService,
    private newQuoteService: NewQuoteService,
    private PolicyRiskDocPreviewService: PolicyRiskDocPreviewService,
    private domainsService: DomainsService,
    private translate: TranslateService,
    private insuredService: InsuredService,
  ) {
    this.form = this.fb.group({
      subject: [''],
      message: [''],
    });
  }

  async ngOnInit(): Promise<void> {
    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));

    this.userID = this.localStorageService.getBoxxUserId();
    this.store
      .select(getDashboardSelector)
      .pipe(take(1))
      .subscribe((data) => {
        this.shortDateFormat = data.shortDateFormat;
        this.longDateFormat = data.longDateFormat;
      });
    let selectedQuotePremium = this.newQuoteService.getPremiumForSendQuote();
    this.form$ = this.store.pipe(select(getQuoteSelector));
    this.form$.pipe(take(1)).subscribe(async (event) => {
      let storePolicyRiskTrxId = event?.ui.policyRiskTrxId;
      await this.getInsuredTimeZone(event?.ui?.insuredId);
      if (selectedQuotePremium == 0) {
        selectedQuotePremium = Number(event?.ui?.premium) ?? 0;
      }

      this.replaceValueSource = {
        ...this.replaceValueSource,
        'insured-name': event?.ui?.insuredName ?? '',
        'insured-company-name': event?.ui?.insuredName ?? '',
        'client-name': event?.ui?.broker ?? '',
        'policy-term': `${getFormatDate(
          event?.ui?.startDate,
          this.shortDateFormat,
          this.longDateFormat,
        )} - ${getFormatDate(
          event?.ui?.endDate,
          this.shortDateFormat,
          this.longDateFormat,
        )}`,
        'producer-fullname': event?.ui?.producer ?? '',
        premium: formatAmountWithCurrency(selectedQuotePremium, this.currency),
        'quote-id': `${event.ui.policyId ?? ''}`,
        'policy-number': `${event.ui.packagePolicyNumber ?? ''}`,
        'quote-valid-until':
          addDaysToDateString(
            event?.ui?.quoteCreatedDate,
            30,
            this.longDateFormat,
          ) ?? '',
      };

      this.sendToNames = {
        broker: event.ui?.broker,
        branch: event.ui?.branch,
        producer: event.ui?.producer,
        insuredName: event.ui?.insuredName,
        isDisabled: false,
        underWriter: '',
      };

      this.policyPeriodId = event.ui.policyPeriodId;
      this.policyDashboardService.Get(this.policyPeriodId).subscribe({
        next: (response) => {
          let policyRiskTransactions =
            response.data?.PolicyRisks?.[0]?.PolicyRiskTrxes ?? [];
          this.policyRiskTrxId =
            policyRiskTransactions.length > 0
              ? policyRiskTransactions[0].id
              : storePolicyRiskTrxId;
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.notificationAlert = {
              show: true,
              type: 'error',
              headerText: 'error!',
              bodyText: getErrorMessage(error),
            };
          }
        },
      });
      this.domainsService.GetByDomainCode('CLAIMSCONTACT', true).subscribe({
        next: (response) => {
          const claimsContactEmail = response.data.filter(
            (template) => template.subdomaincode === 'CLAIMSCONTACT_EMAIL',
          )[0];

          const claimsContactPhone = response.data.filter(
            (template) => template.subdomaincode === 'CLAIMSCONTACT_PHONE',
          )[0];

          const emailValue = claimsContactEmail.valueString1;
          const phoneValue = claimsContactPhone.valueString1;

          if (emailValue)
            this.replaceValueSource = {
              ...this.replaceValueSource,
              'claim-contact-email': emailValue,
            };
          if (phoneValue)
            this.replaceValueSource = {
              ...this.replaceValueSource,
              'claim-contact-telephone': phoneValue,
            };
        },
        error: (error) => {
          if (![500].includes(error?.status)) {
            this.notificationAlert = {
              show: true,
              type: 'error',
              headerText: 'error!',
              bodyText: getErrorMessage(error),
            };
          }
        },
      });
      this.latestPolicyRiskTrxId =
        event.ui.latestPolicyRiskTrxId ?? this.policyRiskTrxId;
    });

    const label = this.formDetails?.isInsuredTypeCompany
      ? 'Insured included'
      : 'Broker included';
    this.sendOptions = [
      {
        name: '',
        value: 'broker/producer',
        checked: false,
        type: 'cc',
        correspondingMails: ['broker', 'producer'],
        id: 'broker',
      },
    ];

    const emailIds = this.store.pipe(select(getAllMailIds));
    emailIds.pipe(take(1)).subscribe((email) => (this.emailData = email));
    await this.populateUnderwriterEmails();

    if (this.emailData?.['underwriter']?.length > 0) {
      this.sendOptions.unshift({
        name: 'Underwriter on risk insured',
        value: 'underwriter',
        checked: true,
        type: 'to',
        correspondingMails: ['underwriter'],
        id: 'underwriter',
      });
    } else {
      let newDefaultMailIndex = this.sendOptions.findIndex(
        (options) => options.value === 'broker/producer',
      );
      this.sendOptions[newDefaultMailIndex] = {
        ...this.sendOptions[newDefaultMailIndex],
        name: 'Included broker/producer',
        checked: true,
        type: 'to',
      };
    }

    this.sendOptions.unshift({
      name: label,
      value: 'insured',
      checked: false,
      type: 'to',
      correspondingMails: ['insured'],
      id: 'insured',
      isNull: !this.emailData['insured'] ? true : false,
      disabledMsg: 'There is no contact info recorded for this insured',
    });
    this.sendToNames['isDisabled'] = !this.emailData['insured'] ? true : false;

    let defaultCheckedMailIds: { [x: string]: any }[] = this.sendOptions.filter(
      (option: any) => option.checked,
    );

    this.replaceValueSource = {
      ...this.replaceValueSource,
      'producer-email': this.emailData['producer'] ?? '',
    };

    defaultCheckedMailIds.length > 0 &&
      defaultCheckedMailIds.map((selected: any) => {
        selected.correspondingMails.map((mailHead: string) => {
          if (typeof this.emailData[mailHead] === 'string') {
            let validEmailId = validateEmail(this.emailData[mailHead]);
            if (validEmailId) {
              this.sendToEmailIds.push({
                mailId: this.emailData[mailHead],
                type: selected.type,
              });
            }
          } else {
            if (this.emailData[mailHead].length > 0) {
              this.emailData[mailHead].map((mailId: string) => {
                let validEmailId = validateEmail(mailId);
                if (validEmailId) {
                  this.sendToEmailIds.push({
                    mailId: mailId,
                    type: selected.type,
                  });
                }
              });
            }
          }
        });
        this.defaultMails.push(selected.value);
      });
  }

  async getInsuredTimeZone(insuredId: any) {
    insuredId &&
      this.insuredService.GetByInsuredId(insuredId).subscribe({
        next: async (data) => {
          this.insuredTimezone = data.data.insuredLocation.timezone;
          this.store.dispatch(
            new CreateQuoteAction.UpdateInsuredAction({
              timezone: data.data?.insuredLocation.timezone,
            }),
          );
        },
      });
  }

  isString(email: any): boolean {
    return typeof email === 'string';
  }

  isObject(email: any): boolean {
    return typeof email === 'object';
  }

  isChecked(optionValue: string): boolean {
    let checkBoxStatus = this.sendOptions.filter(
      (option) => option.value === optionValue,
    );
    if (checkBoxStatus.length > 0) {
      return checkBoxStatus[0].checked;
    }
    return false;
  }

  async populateUnderwriterEmails() {
    this.form$.pipe(take(1)).subscribe(async (event) => {
      this.policyPeriodId = event.ui.policyPeriodId;
    });
    const getPolicyRiskByPolicyPeriodID =
      this.policyRiskService.GetAllByPolicyPeriodId(this.policyPeriodId);
    let riskData = await firstValueFrom(getPolicyRiskByPolicyPeriodID);
    const underwriterIds = riskData.data.map(
      (policyRiskTrx) => policyRiskTrx.PolicyRiskTrxes[0].underwriterId,
    );

    if (underwriterIds.length > 0) {
      const underwriterEmails = await Promise.all(
        underwriterIds.map(async (underwriterId) => {
          const underwriterEmail =
            await this.getUnderWriterEmail(underwriterId);
          if (underwriterEmail !== '') {
            return underwriterEmail;
          }
        }),
      );
      this.emailData = {
        ...this.emailData,
        underwriter: [...new Set(underwriterEmails.filter(Boolean))],
      };
    }
  }

  private async getUnderWriterEmail(userId: number) {
    try {
      const user$ = this.boxxUserService.GetById(userId);
      const user = await lastValueFrom(user$);
      const email = user.data?.systemUser?.loginEmail;
      const name = user.data?.name;
      const validatedEmail = validateEmail(email);
      if (validatedEmail) {
        this.sendToNames['underwriter'] = name;
        return email;
      } else {
        return '';
      }
    } catch (error) {
      return '';
    }
  }

  getIndexFromUniqueDocList(
    uniqueDocList: PolicyRiskDocumentResponse[],
    docType: string,
  ) {
    let docIndex: number = uniqueDocList.findIndex(
      (doc: PolicyRiskDocumentResponse) => doc.documentType === docType,
    );
    return docIndex;
  }

  ngOnChanges() {
    // handle duplicate documents
    let docTypes: string[] = [];
    let uniqueDocList: PolicyRiskDocumentResponse[] = [];

    this.docDetails.map((doc: PolicyRiskDocumentResponse) => {
      if (!docTypes.includes(doc.documentType)) {
        docTypes.push(doc.documentType);
        uniqueDocList.push(doc);
      } else {
        let docIndex = this.getIndexFromUniqueDocList(
          uniqueDocList,
          doc.documentType,
        );
        if (doc.id > uniqueDocList[docIndex].id) {
          uniqueDocList[docIndex] = doc;
        }
      }
    });

    this.docData = uniqueDocList;
    this.templateDetails = this.templateDetails;
    let documentName = '';
    this.templateData = this.templateDetails;

    this.templateDetails.length > 0 &&
      this.templateDetails.map((template: { [x: string]: string | number }) => {
        this.templateOptions.push({
          key: template['description'],
          value: template['id'],
        });
        this.templateOptions = removeDuplicatedKeys(this.templateOptions);
      });
    this.docData.length > 0 &&
      this.docData.map((document) => {
        documentName = document?.documentName ?? '';
        this.docOptions.push({
          key: documentName,
          value: document.id,
          selected: true,
          url: document.documentPath,
          type: document.documentType,
        });
      });

    this.docsAttached = this.docOptions.map((obj) => obj.key);
    this.currentDocumentIds = this.docOptions.map((obj) => obj.value);
    this.currentDocumentIds = [...new Set(this.currentDocumentIds)];
    this.attachedFiles = this.docOptions.map((obj) => {
      if (this.attachedFileNameReplacements.hasOwnProperty(obj.type)) {
        return this.attachedFileNameReplacements[obj.type];
      }
      return obj.type;
    });
    const riskId = this.riskId ?? this.templateDetails[0]?.['riskId'];

    let htmlString =
      riskId == 2
        ? this.populateAttachmentFilesForCRC()
        : this.populateAttachmentFiles();

    if (this.attachedFiles.length > 0) {
      this.replaceValueSource = {
        ...this.replaceValueSource,
        'html-attached-docs': htmlString,
      };
    }
  }

  handleCheckbox(index, num) {
    let currentOption = this.sendOptions[num];
    if (!this.defaultMails.includes(currentOption.value)) {
      currentOption.checked = !currentOption.checked;
      if (currentOption.checked) {
        currentOption.correspondingMails.map((mailHead) => {
          if (typeof this.emailData[mailHead] === 'string') {
            this.sendToEmailIds.push({
              mailId: this.emailData[mailHead],
              type: currentOption.type,
            });
          } else {
            if (this.emailData[mailHead].length > 0) {
              this.emailData[mailHead].map((mailId: string) => {
                this.sendToEmailIds.push({
                  mailId: mailId,
                  type: currentOption.type,
                });
              });
            }
          }
        });
      } else {
        currentOption.correspondingMails.map((mailHead) => {
          if (typeof this.emailData[mailHead] === 'string') {
            const emailToRemoveId = this.sendToEmailIds.findIndex(
              (emailObj) => this.emailData[mailHead] === emailObj.mailId,
            );
            this.sendToEmailIds.splice(emailToRemoveId, 1);
          } else {
            this.emailData[mailHead].map((mailId: string) => {
              const emailToRemoveId = this.sendToEmailIds.findIndex(
                (emailObj) => mailId === emailObj.mailId,
              );
              this.sendToEmailIds.splice(emailToRemoveId, 1);
            });
          }
        });
      }
    } else {
      this.sendOptions = this.sendOptions.map((options) => {
        if (this.defaultMails.includes(options.value)) {
          return { ...options, checked: true };
        } else {
          return { ...options };
        }
      });
    }
  }

  async handleEmailTemplate(event) {
    // TO DO Update the below section to handle multiple templates case
    let currentTemplate =
      this.templateData?.length === 1
        ? this.templateData
        : this.templateData[0];
    this.currentMsgTemplateId = this.templateData[0].id;

    let subject = currentTemplate?.[0]?.['subject'] ?? '';
    let msgBody = currentTemplate?.[0]?.['msgBody'] ?? '';

    //replace texts inside double curly brackets.
    this.extractedSubjectTexts =
      subject !== ''
        ? extractTextInsideDoubleCurlyBracketsWithReplaceText(subject)
        : [];
    this.extractedMsgBodyTexts =
      msgBody !== ''
        ? extractTextInsideDoubleCurlyBracketsWithReplaceText(msgBody)
        : [];

    if (this.extractedSubjectTexts.length > 0) {
      await this.findReplaceText(this.extractedSubjectTexts);
      subject = replacePlaceholders(subject, this.extractedSubjectTexts);
    }
    if (this.extractedMsgBodyTexts.length > 0) {
      await this.findReplaceText(this.extractedMsgBodyTexts);
      msgBody = replacePlaceholders(msgBody, this.extractedMsgBodyTexts);
    }

    if (!this.form.controls['subject'].dirty) {
      this.form.controls['subject'].setValue(subject);
    }
    this.form.controls['message'].setValue(msgBody);
  }

  async findReplaceText(
    extractedTextArray: { target: string; replaceText: string }[],
  ) {
    extractedTextArray.map((text) => {
      text.replaceText =
        this.replaceValueSource?.[
          extractTextInsideDoubleCurlyBrackets(text.target)
        ] ?? '';
    });
  }

  handleAttachDoc(value: number) {
    const index = this.docOptions.findIndex((docObj) => docObj.value == value);
    if (!this.currentDocumentIds.includes(value)) {
      !this.currentDocumentIds.includes(value) &&
        this.currentDocumentIds.push(value);
      this.docOptions[index].selected = true;
      this.docsAttached = [...this.docsAttached, this.docOptions[index].key];

      //display attachment files in Template
      const option = this.docOptions[index];
      if (this.attachedFileNameReplacements.hasOwnProperty(option.type)) {
        option.type = this.attachedFileNameReplacements[option.type];
      }
      this.attachedFiles = [...this.attachedFiles, option.type];
      let htmlString = this.populateAttachmentFiles();

      if (this.attachedFiles.length > 0) {
        this.replaceValueSource = {
          ...this.replaceValueSource,
          'html-attached-docs': htmlString,
        };
        if (this.currentMsgTemplateId) {
          this.handleEmailTemplate(this.currentMsgTemplateId);
        }
      }
    }
  }

  getUnselectedDocs() {
    return this.docOptions.filter((doc) => !doc.selected);
  }

  handleDocRemove(key) {
    const index = this.docOptions.findIndex((docObj) => docObj.key == key);
    this.docOptions[index].selected = false;
    this.currentDocumentIds.includes(this.docOptions[index].value) &&
      this.currentDocumentIds.splice(
        this.currentDocumentIds.indexOf(this.docOptions[index].value),
        1,
      );
    var indexOfKey = this.docsAttached.indexOf(key);
    if (index !== -1) {
      this.docsAttached.splice(indexOfKey, 1);
    }
    //Removing attachment files form template
    var indexOfKeyType = this.attachedFiles.indexOf(
      this.docOptions[index].value,
    );
    if (index !== -1) {
      this.attachedFiles.splice(indexOfKey, 1);
    }
    const riskId = this.riskId ?? this.templateDetails[0]?.['riskId'];
    let htmlString =
      riskId === 2
        ? this.populateAttachmentFilesForCRC()
        : this.populateAttachmentFiles();
    this.replaceValueSource = {
      ...this.replaceValueSource,
      'html-attached-docs': this.attachedFiles.length > 0 ? htmlString : '',
    };
    if (this.currentMsgTemplateId) {
      this.handleEmailTemplate(this.currentMsgTemplateId);
    }
  }

  populateAttachmentFiles() {
    this.attachedFiles = this.attachedFiles.filter(
      (element, idx) => this.attachedFiles.indexOf(element) === idx,
    );
    let htmlString = `<div style="color: black;">
                      <span style="font-size:14px;">
                        <span style="font-family: 'Roboto ',  'Helvetica Neue ', Helvetica,arial,sans-serif;">
                          <strong>`;
    htmlString += this.translate.instant('common.attachments');
    htmlString += `</strong></span> </span> <ul>`;
    this.attachedFiles.forEach((item) => {
      htmlString += `<li>
                          <span style="font-size:14px;">
                            <span style="font-family: 'Roboto ',  'Helvetica Neue ', Helvetica,arial,sans-serif;">${item}</span>
                            </span>
                          </li>`;
    });
    htmlString += ` </ul> </div>`;

    return htmlString;
  }

  populateAttachmentFilesForCRC() {
    this.attachedFiles = this.attachedFiles.filter(
      (element, idx) => this.attachedFiles.indexOf(element) === idx,
    );
    let htmlString = ``;
    this.attachedFiles.forEach((item) => {
      htmlString += `<li>
                          <span style="font-size:14px;">
                            <span style="font-family: 'Roboto ',  'Helvetica Neue ', Helvetica,arial,sans-serif;">${item}</span>
                            </span>
                          </li>`;
    });

    return htmlString;
  }

  isSendDisabled() {
    return this.form.value.subject == '' || this.form.value.message == '';
  }

  sendHandler() {
    this.isSending = true;
    this.form$.pipe(take(1)).subscribe((event) => {
      this.storePolicyRiskTrxId = event?.ui.policyRiskTrxId;
    });
    let formData = {
      ...this.form.value,
      messageTemplateId: this.currentMsgTemplateId,
      documentIds: this.currentDocumentIds,
      mailIdList: this.sendToEmailIds,
    };

    let to = ``;
    let cc = ``;
    let bcc = ``;
    let subject = formData.subject;
    let bodyMessage = ``;
    let bodyHtml = encodeToBase64(formData.message);
    let policyRiskTrxId =
      `${this.latestPolicyRiskTrxId}` ?? `${this.storePolicyRiskTrxId}`;
    if (policyRiskTrxId === '0') {
      policyRiskTrxId = this.storePolicyRiskTrxId;
    }
    let attachmentsFileIds = this.currentDocumentIds.join(',');
    formData.mailIdList.map((mail) => {
      if (mail.type === 'to') {
        to = to !== '' ? `${to},${mail.mailId}` : `${mail.mailId}`;
      } else if (mail.type === 'cc') {
        cc = cc !== '' ? `${cc},${mail.mailId}` : `${mail.mailId}`;
      } else {
        bcc = bcc !== '' ? `${bcc},${mail.mailId}` : `${mail.mailId}`;
      }
    });

    const apiPayload: FormData = new FormData();
    apiPayload.append('to', to);
    cc !== '' && apiPayload.append('cc', cc);
    bcc !== '' && apiPayload.append('bcc', bcc);
    subject !== '' && apiPayload.append('subject', subject);
    bodyMessage !== '' && apiPayload.append('bodyMessage', bodyMessage);
    bodyHtml !== '' && apiPayload.append('bodyHtml', bodyHtml);
    apiPayload.append('policyRiskTrxId', policyRiskTrxId);
    attachmentsFileIds !== '' &&
      apiPayload.append('policyRiskDocumentIds', attachmentsFileIds);

    this.handleSend.emit(apiPayload);
  }

  handleNotificationAlertClose() {
    this.notificationAlert.show = false;
  }

  closeDocPreview() {
    this.currentPreviewDocUrl = '';
    this.docPreviewLoader = false;
    this.showDocPreview = false;
  }

  handleDocPreview(docName: string) {
    const documentId = this.docOptions.filter(
      (docObj) => docObj?.key === docName,
    )[0]?.value;
    try {
      this.docPreviewLoader = true;
      if (documentId) {
        this.showDocPreview = true;
        this.PolicyRiskDocPreviewService.GetPolicyRiskDocToPreview(
          documentId,
          'document',
        ).subscribe({
          next: (documentData) => {
            if (documentData?.data) {
              this.currentPreviewDocUrl = documentData.data.content;
              this.docPreviewLoader = false;
            } else {
              this.showDocPreview = false;
              this.currentPreviewDocUrl = '';
              this.docPreviewLoader = false;
            }
          },
          error: (error) => {
            this.currentPreviewDocUrl = '';
            this.docPreviewLoader = false;
            this.showDocPreview = false;
            if (![500].includes(error?.status)) {
              this.notificationAlert = {
                show: true,
                type: 'error',
                headerText: 'error!',
                bodyText: getErrorMessage(error),
              };
            }
          },
        });
      } else {
        this.showDocPreview = false;
        this.currentPreviewDocUrl = '';
        this.docPreviewLoader = false;
      }
    } catch (error) {
      if (![500].includes(error?.status)) {
        this.notificationAlert = {
          show: true,
          type: 'error',
          headerText: 'error!',
          bodyText: error?.message ?? '',
        };
      }
      this.currentPreviewDocUrl = '';
      this.docPreviewLoader = false;
      this.showDocPreview = false;
    }
  }
  toggleDocumentSelect(a, b) {}

  handleCloseModal() {
    if (this.isLoader) {
      return;
    }
    this.docData = [];
    this.docDetails = [];
    this.templateDetails = [];
    this.templateOptions = [];
    this.docOptions = [];
    this.handleClose.emit();
  }

  handleBack() {
    this.showModal = !this.showModal;
    this.handleBackButton.emit();
  }
}
