<div class="spinner-container">
  <div class="spinner-holder-submission" *ngIf="showBodySpinner !== 0">
    <div class="body-spinner-submission">
      <div class="body-spinner-wrapper">
        <boxxds-spinner
          [containerStyle]="{
            width: '112px',
            height: '112px',
            '--b': '15px'
          }"
        ></boxxds-spinner>
      </div>
    </div>
  </div>
</div>

<div class="main-card" *ngIf="showBodySpinner === 0">
  <ng-container *ngIf="showBodySpinner === 0">
    <form [formGroup]="form">
      <!-- insured information -->
      <div class="rowInsurer">
        <div class="columnInsurerLeft">
          <boxxds-body
            sz="md"
            [text]="
              (isRestrictedRoleSubmission
                ? 'workFlow3.components.newSubmission.heading.applicantInfo'
                : 'workFlow3.components.newSubmission.heading.insuredInfo'
              ) | translate
            "
            [customStyle]="{
              fontFamily: 'Gibson',
              color: '#2D2D39',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '20px'
            }"
          ></boxxds-body>
          <!-- commented the below as to revisit the logic  - related ticket 2730 -->
          <!-- <p class="subhead-container">
            <span *ngIf="!isExistingInsuredFormEdit">{{
              "workFlow3.components.newSubmission.subHeading.insureInfo"
                | translate
            }}</span>
            <span *ngIf="isExistingInsuredFormEdit">{{
              "workFlow3.components.newSubmission.subHeading.insureInfoEdit"
                | translate
            }}</span>
            <button
              class="edit-button"
              *ngIf="isExistingInsuredFormEdit"
              (click)="handleClearForm()"
            >
              <img src="assets/img/edit-01.svg" alt="edit-icon" />
            </button>
          </p> -->
          <boxxds-body
            sz="md"
            [text]="
              (isRestrictedRoleSubmission
                ? 'workFlow3.components.newSubmission.subHeading.applicantInfo'
                : 'workFlow3.components.newSubmission.subHeading.insureInfo'
              ) | translate
            "
            [customStyle]="{
              fontFamily: 'Gibson',
              color: '#475467',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px'
            }"
          ></boxxds-body>
          <ng-template #popTemplate>
            <div class="existence-check-popover-content">
              <div
                class="no-existence-data"
                *ngIf="!!!predictionEvidence && !!!predictionKeywords"
              >
                {{
                  "workFlow3.components.newSubmission.subHeading.noDataAvailable"
                    | translate
                }}
              </div>
              <div
                class="evidence-section"
                *ngIf="predictionEvidence && predictionEvidence !== ''"
              >
                <div>
                  <span class="evidence-section-heading">{{
                    ("workFlow3.components.newSubmission.heading.naicsPredictionEvidence"
                      | translate) + ": "
                  }}</span>
                  <span class="existence-brackets">(</span>
                  <span class="evidence-section-sub-heading">{{
                    "workFlow3.components.newSubmission.subHeading.naicsPredictionEvidence"
                      | translate
                  }}</span>
                  <span class="existence-brackets">)</span>
                </div>
                <div class="evidence-data">{{ predictionEvidence }}</div>
              </div>
              <div
                class="keywords-section"
                *ngIf="predictionKeywords && predictionKeywords !== ''"
              >
                <div>
                  <span class="keywords-section-heading">{{
                    "workFlow3.components.newSubmission.heading.naicsPredictionKeywords"
                      | translate
                  }}</span>
                  <span class="existence-brackets">(</span>
                  <span class="keywords-section-sub-heading">{{
                    "workFlow3.components.newSubmission.subHeading.naicsPredictionKeywords"
                      | translate
                  }}</span>
                  <span class="existence-brackets">)</span>
                </div>
                <div class="keywords-data">{{ predictionKeywords }}</div>
              </div>
            </div>
          </ng-template>
          <button
            *ngIf="showIndustrySection && showPredictions"
            class="existence-check-popover"
            type="button"
            [popover]="popTemplate"
            [adaptivePosition]="true"
            [outsideClick]="true"
            placement="bottom left"
          >
            {{
              "workFlow3.components.newSubmission.button.existenceCheck"
                | translate
            }}
          </button>
        </div>
        <div class="columnInsurerRight">
          <div
            class="form-inputs business-info"
            *ngIf="typeBusinessInsured === true"
          >
            <boxxds-search-autocomplete-v2
              *ngIf="fromSubmissionFlow === true"
              [inputAutoFocus]="businessAutoFocus"
              [form]="form"
              [isActive]="true"
              [formSubmitted]="checkValidation"
              [requiredErrMsg]="
                'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                  | translate
              "
              [items]="insuredOptns"
              [title]="form?.value?.businessName"
              [label]="
                'workFlow3.components.newSubmission.label.businessName'
                  | translate
              "
              [required]="true"
              [control]="'businessName'"
              [placeholder]="
                'workFlow3.components.newSubmission.placeholder.abcCompany'
                  | translate
              "
              [autocapitalize]="'off'"
              [spellcheck]="'off'"
              [permissionStatus]="this.permissionList[this.currentScreen]"
              [containerStyle]="{ width: '500px', marginBottom: '20px' }"
              [labelStyle]="{ fontFamily: 'Gibson', marginBottom: '6px' }"
              (searchQuery)="handleBusinessNameSearch($event)"
              (itemSelected)="
                insuredOptns = [];
                populateInsuredDetails($event);
                selectInsuredSearch($event)
              "
              [maxLength]="'200'"
              [noResultContent]="'No result'"
              [showNoResultAction]="true"
              [readonly]="isExistingInsuredFormEdit ? true : undefined"
              [class.noPointerEvents]="isExistingInsuredFormEdit"
            ></boxxds-search-autocomplete-v2>
            <boxxds-textinput-v2
              *ngIf="fromSubmissionFlow === false"
              [form]="form"
              [placeholder]="
                'workFlow3.components.newSubmission.placeholder.abcCompany'
                  | translate
              "
              [control]="'businessName'"
              [id]="'businessName'"
              [width]="'100%'"
              [containerStyle]="{ maxWidth: '512px', marginBottom: '20px' }"
              [label]="
                'workFlow3.components.newSubmission.label.businessName'
                  | translate
              "
              class="businessName"
              [requiredErrMsg]="
                'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                  | translate
              "
              [invalidErrMsg]="
                'workFlow3.components.newSubmission.error.invalidErrMsg'
                  | translate
              "
              [formSubmitted]="checkValidation"
              maxLength="100"
              [readonly]="
                !this.permissionList[this.currentScreen] ||
                (insuredData && !insuredData?.active) ||
                isExistingInsuredFormEdit
              "
            >
            </boxxds-textinput-v2>
            <div class="d-flex justify-content-between">
              <boxxds-search-autocomplete-v2
                [maxLength]="'50'"
                [form]="form"
                [isActive]="true"
                [formSubmitted]="checkValidation"
                [requiredErrMsg]="
                  'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                    | translate
                "
                [items]="locations"
                [label]="
                  'workFlow3.components.newSubmission.label.addressLine1'
                    | translate
                "
                [required]="true"
                [control]="'address'"
                [placeholder]="
                  'workFlow3.components.newSubmission.placeholder.searchLocation1'
                    | translate
                "
                [autocapitalize]="'off'"
                [spellcheck]="'off'"
                [permissionStatus]="this.permissionList[this.currentScreen]"
                [containerStyle]="{ width: '376px' }"
                [labelStyle]="{ fontFamily: 'Gibson', marginBottom: '6px' }"
                (searchQuery)="getPlaces($event)"
                (itemSelected)="selectPlaceById($event)"
                [noResultContent]="'No result'"
                [showNoResultAction]="true"
                [selectedFromSuggestion]="selectedFromSuggestion"
                [readonly]="isExistingInsuredFormEdit ? true : undefined"
                [class.noPointerEvents]="isExistingInsuredFormEdit"
              ></boxxds-search-autocomplete-v2>

              <boxxds-textinput-v2
                [form]="form"
                [placeholder]="
                  'workFlow3.components.newSubmission.placeholder.searchLocation2'
                    | translate
                "
                [control]="'addressLine2'"
                [id]="'address'"
                [width]="'124px'"
                [containerStyle]="{
                  maxWidth: '300px',
                  marginBottom: '20px',
                  fontFamily: 'Gibson'
                }"
                [label]="
                  'workFlow3.components.newSubmission.label.addressLine2'
                    | translate
                "
                class="input-fields"
                class="address2"
                maxLength="10"
                [isDisabled]="
                  !this.permissionList[this.currentScreen] || !isActive
                "
                [formSubmitted]="checkValidation"
                [selectedFromSuggestion]="selectedFromSuggestion"
                [readonly]="
                  (insuredData && !insuredData?.active) ||
                  isExistingInsuredFormEdit
                "
              >
              </boxxds-textinput-v2>
            </div>

            <div class="d-flex justify-content-between">
              <div class="d-flex justify-content-between city-state-holder">
                <boxxds-textinput-v2
                  [form]="form"
                  [placeholder]="
                    'workFlow3.components.newSubmission.placeholder.city'
                      | translate
                  "
                  [control]="'city'"
                  [id]="'city'"
                  [width]="'100%'"
                  [containerStyle]="{
                    maxWidth: '177px',
                    marginBottom: '20px',
                    fontFamily: 'Gibson'
                  }"
                  [label]="
                    'workFlow3.components.newSubmission.label.city' | translate
                  "
                  class="input-fields"
                  [isRequired]="true"
                  [requiredErrMsg]="
                    'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                      | translate
                  "
                  [formSubmitted]="checkValidation"
                  class="city"
                  maxLength="25"
                  [isDisabled]="
                    !this.permissionList[this.currentScreen] || !isActive
                  "
                  [selectedFromSuggestion]="selectedFromSuggestion"
                  [readonly]="
                    (insuredData && !insuredData?.active) ||
                    isExistingInsuredFormEdit
                  "
                >
                </boxxds-textinput-v2>

                <boxxds-dropdown-v2
                  [form]="form"
                  [control]="'province'"
                  [placeholder]="
                    'workFlow3.components.newSubmission.placeholder.state'
                      | translate
                  "
                  [options]="provinceOrStateList"
                  [label]="
                    province ?? 'workFlow3.components.newSubmission.label.state'
                      | translate
                  "
                  [btnStyle]="{ marginTop: '8px', maxWidth: '186px' }"
                  [dropdownContainerStyle]="{
                    width: '177px',
                    fontFamily: 'Gibson'
                  }"
                  class="input-fields"
                  [requiredErrMsg]="
                    'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                      | translate
                  "
                  [isRequired]="true"
                  [formSubmitted]="checkValidation"
                  class="province"
                  [currentValue]="form?.value?.province"
                  [currentSelectedOption]="currentProvinceObj"
                  [isDisabled]="
                    !this.permissionList[this.currentScreen] || !isActive
                  "
                  [hasScrollablePagination]="true"
                  [totalCount]="regionTotalRecords"
                  [totalPages]="regionTotalPages"
                  (loadMoreOptions)="
                    this.permissionList[this.currentScreen] &&
                      loadMoreRegion($event)
                  "
                  (currentValueChange)="
                    this.permissionList[this.currentScreen] &&
                      handleProvinceChange($event)
                  "
                  [isSuggestedOption]="selectedFromSuggestion"
                  [isReadonly]="
                    (insuredData && !insuredData?.active) ||
                    isExistingInsuredFormEdit
                  "
                  [class.noPointerEvents]="
                    (insuredData && !insuredData?.active) ||
                    isExistingInsuredFormEdit
                  "
                >
                </boxxds-dropdown-v2>
              </div>

              <boxxds-textinput-v2
                [form]="form"
                [width]="'124px'"
                [placeholder]="
                  'workFlow3.components.newSubmission.placeholder.zipCode'
                    | translate
                "
                [control]="'zipPostalCode'"
                [id]="'postalCode'"
                [containerStyle]="{ maxWidth: '186px' }"
                [label]="
                  zip ?? 'workFlow3.components.newSubmission.label.zipCode'
                    | translate
                "
                class="input-fields"
                [customErrMsg]="
                  'workFlow3.components.newSubmission.error.invalidErrMsg'
                    | translate
                "
                [isRequired]="true"
                [requiredErrMsg]="
                  'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                    | translate
                "
                [formSubmitted]="checkValidation"
                class="postal-code"
                maxLength="10"
                [mask]="mask"
                [isDisabled]="
                  !this.permissionList[this.currentScreen] || !isActive
                "
                [dropSpecialCharacters]="false"
                [selectedFromSuggestion]="selectedFromSuggestion"
                [readonly]="
                  (insuredData && !insuredData?.active) ||
                  isExistingInsuredFormEdit
                "
              >
              </boxxds-textinput-v2>
            </div>

            <boxxds-textinput-v2
              [form]="form"
              [placeholder]="
                'workFlow3.components.newSubmission.placeholder.webSite'
                  | translate
              "
              [control]="'website'"
              [id]="'website'"
              [width]="'100%'"
              [containerStyle]="{ maxWidth: '512px', marginBottom: '20px' }"
              [label]="
                'workFlow3.components.newSubmission.label.website' | translate
              "
              class="website"
              [requiredErrMsg]="
                'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                  | translate
              "
              [invalidErrMsg]="
                'workFlow3.components.newSubmission.error.invalidErrMsg'
                  | translate
              "
              [formSubmitted]="checkValidation"
              maxLength="100"
              [readonly]="
                !this.permissionList[this.currentScreen] ||
                (insuredData && !insuredData?.active) ||
                isExistingInsuredFormEdit
              "
            >
            </boxxds-textinput-v2>

            <boxxds-dropdown-v2
              [form]="form"
              [control]="'noOfEmployees'"
              [placeholder]="
                'workFlow3.components.newSubmission.placeholder.noOfEmployees'
                  | translate
              "
              [options]="noOfEmpOptns"
              [currentValue]="form?.value['noOfEmployees']"
              (currentValueChange)="handleNoOfEmployees($event)"
              [label]="
                'workFlow3.components.newSubmission.label.numberOfEmployee'
                  | translate
              "
              [dropdownContainerStyle]="{ marginBottom: '20px' }"
              class="employees"
              [formSubmitted]="checkValidation"
              [isReadonly]="
                !this.permissionList[this.currentScreen] ||
                (insuredData && !insuredData?.active) ||
                isExistingInsuredFormEdit
              "
            >
            </boxxds-dropdown-v2>
          </div>
        </div>
      </div>
      <!-- insured information end -->

      <!-- contact details -->
      <!-- contact details section -->
      <!-- *ngIf="
          typeBusinessInsured === true &&
          showIndustrySection &&
          this.permissionList[this.currentScreen]
        " -->
      <ng-container
        *ngIf="
          typeBusinessInsured === true &&
          this.permissionList[this.currentScreen]
        "
      >
        <!-- contact enable toggle btn -->
        <div class="rowInsurer">
          <div class="columnInsurerLeft"></div>
          <div class="columnInsurerRight">
            <div class="contact-panel-title">
              <boxxds-switch-v2
                [checked]="contactToggleEnabled"
                name="contact_details"
                [label]="
                  'workFlow3.components.newSubmission.heading.contactDetails'
                    | translate
                "
                [disabled]="isExistingInsuredFormEdit"
                [ngStyle]="{ marginBottom: '8px', marginTop: '40px' }"
                (changed)="!isExistingInsuredFormEdit && toggleChanged($event)"
              >
              </boxxds-switch-v2>
            </div>
          </div>
        </div>
        <!-- contact enable toggle btn end -->

        <ng-container
          *ngIf="showContactDetails && permissionList[this.currentScreen]"
        >
          <!-- contact form (loop) -->
          <form [formGroup]="contactForm">
            <div formArrayName="contacts">
              <div
                *ngFor="
                  let contactFormItem of contactForm.get('contacts')[
                    'controls'
                  ];
                  let i = index
                "
              >
                <div class="rowInsurer businessContactRow">
                  <!-- for future use - (click)="removeContact(i)" -->
                  <div class="columnInsurerLeft">
                    <boxxds-body
                      sz="md"
                      [text]="'common.contact' | translate"
                      [customStyle]="{
                        fontFamily: 'Gibson',
                        color: '#2D2D39',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '20px'
                      }"
                    ></boxxds-body>
                    <boxxds-body
                      sz="md"
                      [text]="'common.enterContactInformation' | translate"
                      [customStyle]="{
                        fontFamily: 'Gibson',
                        color: '#475467',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px'
                      }"
                    ></boxxds-body>
                  </div>
                  <div class="columnInsurerRight">
                    <div
                      [formGroupName]="i"
                      class="form-inputs contact-details"
                    >
                      <div class="d-flex row-input-holder">
                        <boxxds-textinput-v2
                          [form]="contactFormItem"
                          [placeholder]="
                            'workFlow3.components.newSubmission.placeholder.firstName'
                              | translate
                          "
                          [control]="'firstName'"
                          [id]="'firstName'"
                          [label]="
                            'workFlow3.components.newSubmission.label.firstName'
                              | translate
                          "
                          class="contact-field"
                          [formSubmitted]="checkContactValidation"
                          [width]="'100%'"
                          maxLength="200"
                          [readonly]="
                            !this.permissionList[this.currentScreen] ||
                            (insuredData && !insuredData?.active) ||
                            isExistingInsuredFormEdit
                          "
                          [containerStyle]="{ marginBottom: '20px' }"
                          [inputAutoFocus]="contactToggleFocus"
                          [leftIcon]="'assets/img/search-md.svg'"
                          [leftIconStyle]="{
                            filter:
                              'invert(45%) sepia(20%) saturate(410%) hue-rotate(182deg) brightness(92%) contrast(88%)',
                            width: '20px',
                            height: '20px'
                          }"
                          [invalidErrMsg]="
                            'workFlow3.components.newSubmission.error.invalidErrMsg'
                              | translate
                          "
                        >
                        </boxxds-textinput-v2>
                        <boxxds-textinput-v2
                          [form]="contactFormItem"
                          [placeholder]="
                            'workFlow3.components.newSubmission.placeholder.lastName'
                              | translate
                          "
                          [control]="'lastName'"
                          [id]="'lastName'"
                          [label]="
                            'workFlow3.components.newSubmission.label.lastName'
                              | translate
                          "
                          class="contact-field"
                          [formSubmitted]="checkContactValidation"
                          [width]="'100%'"
                          maxLength="200"
                          [readonly]="isExistingInsuredFormEdit"
                          [leftIcon]="'assets/img/search-md.svg'"
                          [leftIconStyle]="{
                            filter:
                              'invert(45%) sepia(20%) saturate(410%) hue-rotate(182deg) brightness(92%) contrast(88%)',
                            width: '20px',
                            height: '20px'
                          }"
                          [invalidErrMsg]="
                            'workFlow3.components.newSubmission.error.invalidErrMsg'
                              | translate
                          "
                        >
                        </boxxds-textinput-v2>
                      </div>
                      <boxxds-textinput-v2
                        [form]="contactFormItem"
                        [placeholder]="
                          'workFlow3.components.newSubmission.placeholder.emailAddress'
                            | translate
                        "
                        [control]="'email'"
                        [id]="'email'"
                        [width]="'100%'"
                        [label]="
                          'workFlow3.components.newSubmission.label.emailAddress'
                            | translate
                        "
                        class="contact-field margin-horizontal"
                        [invalidErrMsg]="
                          'workFlow3.components.newSubmission.error.invalidErrMsg'
                            | translate
                        "
                        [formSubmitted]="checkContactValidation"
                        maxLength="150"
                        [readonly]="
                          !this.permissionList[this.currentScreen] ||
                          (insuredData && !insuredData?.active) ||
                          isExistingInsuredFormEdit
                        "
                        [containerStyle]="{ marginBottom: '20px' }"
                        [invalidErrMsg]="
                          'workFlow3.components.newSubmission.error.invalidErrMsg'
                            | translate
                        "
                        [requiredErrMsg]="
                          'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                            | translate
                        "
                      >
                      </boxxds-textinput-v2>
                      <div class="d-flex row-input-holder">
                        <boxxds-textinput-v2
                          [form]="contactFormItem"
                          [placeholder]="
                            'workFlow3.components.newSubmission.placeholder.title'
                              | translate
                          "
                          [control]="'title'"
                          [id]="'title'"
                          [label]="
                            'workFlow3.components.newSubmission.label.title'
                              | translate
                          "
                          class="contact-field"
                          [formSubmitted]="checkContactValidation"
                          [width]="'100%'"
                          maxLength="200"
                          [readonly]="
                            !this.permissionList[this.currentScreen] ||
                            (insuredData && !insuredData?.active) ||
                            isExistingInsuredFormEdit
                          "
                          [invalidErrMsg]="
                            'workFlow3.components.newSubmission.error.invalidErrMsg'
                              | translate
                          "
                        >
                        </boxxds-textinput-v2>
                        <boxxds-textinput-v2
                          [form]="contactFormItem"
                          [placeholder]="
                            'workFlow3.components.newSubmission.placeholder.phone'
                              | translate
                          "
                          [control]="'phone'"
                          [id]="'phone'"
                          [label]="
                            'workFlow3.components.newSubmission.label.phone'
                              | translate
                          "
                          class="contact-field"
                          [formSubmitted]="checkContactValidation"
                          [width]="'100%'"
                          maxLength="200"
                          [readonly]="isExistingInsuredFormEdit"
                          [mask]="'(000) 000-0000'"
                          [invalidErrMsg]="
                            'workFlow3.components.newSubmission.error.invalidErrMsg'
                              | translate
                          "
                        >
                        </boxxds-textinput-v2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <!-- contact form (loop) end -->

          <!-- footer with add contact btn -->
          <div class="rowInsurer">
            <div class="columnInsurerLeft"></div>
            <div class="columnInsurerRight">
              <boxxds-button-v2
                [size]="'lg'"
                [buttonText]="'Add contact'"
                [btnClass]="'link'"
                [leftImage]="'plus-circle'"
                [isDisabled]="
                  !isAtLeastOneFieldFilled || isExistingInsuredFormEdit
                "
                [class.noPointerEvents]="
                  !isAtLeastOneFieldFilled || isExistingInsuredFormEdit
                "
                [btnStyle]="{
                  margin: '0px 0px 0 8px',
                  height: '36px'
                }"
                (handleClick)="
                  (isAtLeastOneFieldFilled || !isExistingInsuredFormEdit) &&
                    handleAddContact()
                "
              >
              </boxxds-button-v2>
            </div>
          </div>
          <!-- footer with add contact btn end -->
        </ng-container>
      </ng-container>

      <!-- contact details section end-->

      <div class="rowInsurer">
        <div class="columnInsurerLeft"></div>
        <div class="columnInsurerRight">
          <div
            class="d-flex justify-content-end mb-2"
            *ngIf="typeBusinessInsured === true"
          >
            <boxxds-button-v2
              [size]="'lg'"
              [buttonText]="
                'workFlow3.components.newSubmission.button.existenceCheck'
                  | translate
              "
              [btnClass]="'secondary'"
              [rightImage]="'assets/img/check-circle-broken.svg'"
              [rightImageStyle]="{ marginLeft: '5px' }"
              class="confirm-industry"
              (handleClick)="handleGetIndustryPredictions()"
              [isDisabled]="
                !enableConfirmIndustry() || isExistingInsuredFormEdit
              "
              [class.noPointerEvents]="
                !enableConfirmIndustry() || isExistingInsuredFormEdit
              "
            >
            </boxxds-button-v2>
          </div>
        </div>
      </div>

      <div class="lineDivider" *ngIf="showIndustrySection"></div>

      <!-- Industry class and Industry -->
      <div class="rowInsurer" *ngIf="typeBusinessInsured">
        <div class="columnInsurerLeft" *ngIf="confirmIndustryShow">
          <boxxds-body
            *ngIf="showIndustrySection"
            sz="md"
            [text]="
              (isRestrictedRoleSubmission
                ? 'workFlow3.components.newSubmission.heading.industryClassPredictions'
                : 'workFlow3.components.newSubmission.heading.industryClassAndIndustry'
              ) | translate
            "
            [customStyle]="{
              color: '#2D2D39',
              fontFamily: 'Gibson',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '20px'
            }"
            [isRequired]="true"
          ></boxxds-body>
          <boxxds-body
            *ngIf="showIndustrySection"
            sz="md"
            [text]="
              (showPredictions
                ? 'workFlow3.components.newSubmission.subHeading.industryClassPredictions'
                : 'workFlow3.components.newSubmission.subHeading.industryDetails'
              ) | translate
            "
            [customStyle]="{
              color: '#475467',
              fontFamily: 'Gibson',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px'
            }"
          ></boxxds-body>
        </div>

        <div class="columnInsurerRight" *ngIf="confirmIndustryShow">
          <!-- *ngIf="showIndustrySection" -->
          <div
            class="form-inputs industry-container"
            *ngIf="showIndustrySection"
          >
            <div *ngIf="showPredictions">
              <boxxds-radio-v2
                [control]="'industry'"
                [items]="industryPredictions"
                [form]="industryPredictionForm"
                [formSubmitted]="checkValidation"
                [labelContainerStyle]="{ flexDirection: 'column' }"
                [requiredErrMsg]="
                  'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                    | translate
                "
                (handleChange)="
                  !isExistingInsuredFormEdit && handleRadioCheck($event)
                "
                [labelStyle]="{
                  color: '#2D2D39',
                  fontFamily: 'Gibson',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '24px',
                  marginBottom: '24px'
                }"
                [subLabelStyle]="{
                  color: '#475467',
                  fontFamily: 'Gibson',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '20px'
                }"
                [class.noPointerEvents]="isExistingInsuredFormEdit"
              ></boxxds-radio-v2>
            </div>
            <div class="industry-text-box" *ngIf="showIndustryDropdowns">
              <boxxds-searchable-dropdown-v2
                [form]="form"
                [control]="'industryClass'"
                [placeholder]="
                  'workFlow3.components.newSubmission.placeholder.industryClass'
                    | translate
                "
                [options]="businessSectorOptns"
                (textInputChangeEventEmmiter)="
                  handleIndustryClassSearch($event)
                "
                [dispatchTextInputChange]="true"
                (currentValueChange)="handleBusinessSector($event)"
                [currentValue]="form?.value?.industryClass"
                [label]="
                  'workFlow3.components.newSubmission.label.industryClass'
                    | translate
                "
                [btnStyle]="{ marginTop: '8px', maxWidth: '804px' }"
                [dropdownContainerStyle]="{
                  maxWidth: '804px',
                  marginBottom: '20px'
                }"
                class="industry-class"
                [requiredErrMsg]="
                  'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                    | translate
                "
                [formSubmitted]="checkValidation"
                [readonly]="
                  !this.permissionList[this.currentScreen] ||
                  (insuredData && !insuredData?.active) ||
                  isExistingInsuredFormEdit
                "
                [class.noPointerEvents]="
                  !this.permissionList[this.currentScreen] ||
                  (insuredData && !insuredData?.active) ||
                  isExistingInsuredFormEdit
                "
                [isRequired]="true"
                [isSuggestedOption]="isSuggestedOption"
              >
              </boxxds-searchable-dropdown-v2>
              <boxxds-searchable-dropdown-v2
                [form]="form"
                [control]="'industry'"
                [placeholder]="
                  'workFlow3.components.newSubmission.placeholder.industry'
                    | translate
                "
                [options]="businessIndustrySectorOptns"
                [dispatchTextInputChange]="true"
                (textInputChangeEventEmmiter)="handleIndustrySearch($event)"
                (currentValueChange)="handleBusinessIndustrySector($event)"
                [currentValue]="form?.value?.industry"
                [label]="
                  'workFlow3.components.newSubmission.label.industry'
                    | translate
                "
                [btnStyle]="{ marginTop: '8px', maxWidth: '804px' }"
                [dropdownContainerStyle]="{ maxWidth: '804px' }"
                class="industry"
                [requiredErrMsg]="
                  'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                    | translate
                "
                [formSubmitted]="checkValidation"
                [readonly]="
                  !this.permissionList[this.currentScreen] ||
                  (insuredData && !insuredData?.active) ||
                  isExistingInsuredFormEdit
                "
                [class.noPointerEvents]="
                  !this.permissionList[this.currentScreen] ||
                  (insuredData && !insuredData?.active) ||
                  isExistingInsuredFormEdit
                "
                [isRequired]="true"
                [isSuggestedOption]="isSuggestedOption"
              >
              </boxxds-searchable-dropdown-v2>
            </div>
            <app-restricted-industry-warning
              *ngIf="showRestrictedIndustryWarning"
              [isUASubmission]="
                (isRestrictedRoleSubmission && fromSubmissionFlow) ||
                (isRestrictedRoleSubmission &&
                  !fromSubmissionFlow &&
                  insuredId &&
                  existingQuoteIsSubmission)
              "
              [containerStyle]="
                !(
                  (isRestrictedRoleSubmission && fromSubmissionFlow) ||
                  (isRestrictedRoleSubmission &&
                    !fromSubmissionFlow &&
                    insuredId &&
                    existingQuoteIsSubmission)
                )
                  ? {
                      height: '52px',
                      justifyContent: 'flex-start',
                      marginTop: showIndustryDropdowns && '24px'
                    }
                  : { marginTop: showIndustryDropdowns && '24px' }
              "
            >
            </app-restricted-industry-warning>
          </div>
        </div>
      </div>
      <!-- Industry class and Industry end -->

      <div
        class="lineDivider"
        *ngIf="typeBusinessInsured === true && showIndustrySection"
      ></div>

      <!-- revenue details -->
      <div
        class="rowInsurer"
        *ngIf="typeBusinessInsured === true && showIndustrySection"
      >
        <div class="columnInsurerLeft">
          <boxxds-body
            sz="md"
            [text]="
              'workFlow3.components.newSubmission.heading.revenueDetails'
                | translate
            "
            [customStyle]="{
              color: '#2D2D39',
              fontFamily: 'Gibson',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '20px'
            }"
          ></boxxds-body>
          <boxxds-body
            sz="md"
            [text]="
              'workFlow3.components.newSubmission.subHeading.revenueDetails'
                | translate
            "
            [customStyle]="{
              color: '#475467',
              fontFamily: 'Gibson',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px'
            }"
          ></boxxds-body>
        </div>

        <div class="columnInsurerRight">
          <boxxds-textinput-v2
            *ngIf="showRevenueField"
            [form]="form"
            [inputAutoFocus]="fromSubmissionFlow ? revenueAutoFocus : false"
            [placeholder]="
              'workFlow3.components.newSubmission.placeholder.amountInDollar'
                | translate
            "
            [control]="'revenue'"
            [id]="'revenue'"
            [width]="'100%'"
            [label]="
              'workFlow3.components.newSubmission.label.annualRevenue'
                | translate
            "
            class="revenue"
            [isRequired]="true"
            [requiredErrMsg]="
              'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                | translate
            "
            [formSubmitted]="checkValidation"
            maxLength="15"
            [invalidErrMsg]="
              'workFlow3.components.newSubmission.error.invalidErrMsg'
                | translate
            "
            mask="separator.0"
            [dropSpecialCharacters]="true"
            thousandSeparator=","
            [readonly]="
              !this.permissionList[this.currentScreen] ||
              (insuredData && !insuredData?.active) ||
              isExistingInsuredFormEdit
            "
            [leftIcon]="'assets/img/currency-dollar.svg'"
            (handleKeyUp)="clickRevenueDetais()"
            (handleBlur)="handleRevenueBlur()"
          >
          </boxxds-textinput-v2>

          <div class="d-flex justify-content-between revenue-percentage">
            <!-- [validatePercentage]="true" -->
            <boxxds-textinput-v2
              [form]="form"
              [placeholder]="
                'workFlow3.components.newSubmission.placeholder.usPercent'
                  | translate
              "
              [control]="'revenuePercentUS'"
              [id]="'revenueUS'"
              [width]="'100%'"
              [label]="
                'workFlow3.components.newSubmission.label.usPercent' | translate
              "
              [requiredErrMsg]="
                'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                  | translate
              "
              [invalidErrMsg]="
                'workFlow3.components.newSubmission.error.invalidErrMsg'
                  | translate
              "
              [customErrMsg]="
                'workFlow3.components.newSubmission.error.sumOfPercentage'
                  | translate
              "
              [formSubmitted]="checkValidation"
              maxLength="6"
              [readonly]="
                !this.permissionList[this.currentScreen] ||
                (insuredData && !insuredData?.active) ||
                isExistingInsuredFormEdit
              "
            >
            </boxxds-textinput-v2>
            <boxxds-textinput-v2
              [form]="form"
              [placeholder]="
                'workFlow3.components.newSubmission.placeholder.canPercent'
                  | translate
              "
              [control]="'revenuePercentCA'"
              [id]="'revenueCA'"
              [width]="'100%'"
              [label]="
                'workFlow3.components.newSubmission.label.canPercent'
                  | translate
              "
              [requiredErrMsg]="
                'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                  | translate
              "
              [invalidErrMsg]="
                'workFlow3.components.newSubmission.error.invalidErrMsg'
                  | translate
              "
              [customErrMsg]="
                'workFlow3.components.newSubmission.error.sumOfPercentage'
                  | translate
              "
              [formSubmitted]="checkValidation"
              maxLength="6"
              [readonly]="
                !this.permissionList[this.currentScreen] ||
                (insuredData && !insuredData?.active) ||
                isExistingInsuredFormEdit
              "
            >
            </boxxds-textinput-v2>
            <boxxds-textinput-v2
              [form]="form"
              [placeholder]="
                'workFlow3.components.newSubmission.placeholder.otherPercent'
                  | translate
              "
              [control]="'revenuePercentOther'"
              [id]="'revenueOT'"
              [width]="'100%'"
              [label]="
                'workFlow3.components.newSubmission.label.otherPercent'
                  | translate
              "
              [requiredErrMsg]="
                'workFlow3.components.newSubmission.error.thisFieldIsRequired'
                  | translate
              "
              [invalidErrMsg]="
                'workFlow3.components.newSubmission.error.invalidErrMsg'
                  | translate
              "
              [customErrMsg]="
                'workFlow3.components.newSubmission.error.sumOfPercentage'
                  | translate
              "
              [formSubmitted]="checkValidation"
              maxLength="6"
              [readonly]="
                !this.permissionList[this.currentScreen] ||
                (insuredData && !insuredData?.active) ||
                isExistingInsuredFormEdit
              "
            >
            </boxxds-textinput-v2>
          </div>
        </div>
      </div>
      <ng-container
        *ngIf="
          typeBusinessInsured === true &&
          showIndustrySection &&
          !(fromSubmissionFlow && !isRestrictedRoleSubmission)
        "
      >
        <div class="lineDivider"></div>
      </ng-container>
    </form>
  </ng-container>
</div>
